import { Layer, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { Button, Switch } from '@skand/ui';
import { ModelNode, Tileset, TilesetStyle } from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo } from 'react';
import { InputSlider } from '../InputSlider';

export interface TilesetSettingsMenuProps {
  layer: Layer;
}

export const TilesetTab = ({ layer }: TilesetSettingsMenuProps) => {
  const tileset = (layer.sceneNode as ModelNode).getModel() as Tileset;

  const tilesetSettings = useViewer(state => state.tilesetSettings.get(layer.id));

  const initialStyle = useMemo(() => tileset.getStyle(), [tileset]);
  const initialMSSE = useMemo(() => tileset.getMSSE(), [tileset]);

  const style = tilesetSettings?.style ?? initialStyle;
  const msse = tilesetSettings?.msse ?? initialMSSE;

  // Update the tileset style
  useEffect(() => {
    tileset.setStyle(style);
    tileset.setMSSE(msse);
  }, [msse, style, tileset]);

  // Update the maximum screen space error in the viewer state
  const setQuality = useCallback(
    (quality: number) => {
      // Invert the quality to determine MSSE
      const msse = 65 - quality;
      useViewer.setState(prev => {
        const tilesetSettings = new Map(prev.tilesetSettings);
        tilesetSettings.set(layer.id, { style, msse });
        return { tilesetSettings };
      });
    },
    [layer.id, style],
  );

  // Update the style in the viewer state
  const setStyle = useCallback(
    (style: TilesetStyle) => {
      useViewer.setState(prev => {
        const tilesetSettings = new Map(prev.tilesetSettings);
        tilesetSettings.set(layer.id, { style, msse });
        return { tilesetSettings };
      });
    },
    [layer.id, msse],
  );

  // Reset to the initial style.
  const handleReset = () => {
    useViewer.setState(prev => {
      const tilesetSettings = new Map(prev.tilesetSettings);
      tilesetSettings.set(layer.id, {
        style: tileset.getDefaultStyle(),
        msse: 2,
      });
      return { tilesetSettings };
    });
  };

  return (
    <div className={cn('flex flex-col max-h-120')}>
      <InputSlider
        max={64}
        min={1}
        setValue={setQuality}
        step={1}
        title="Refinement Quality"
        value={65 - msse}
      />
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-3')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Backface Culling</span>
        <Switch
          checked={style.backfaceCulling}
          onChange={value => setStyle({ ...style, backfaceCulling: value })}
        />
      </div>
      <div className={cn('flex gap-2 flex-col mt-3')}>
        <Button className="w-full" onClick={handleReset} size="s">
          Reset Settings
        </Button>
      </div>
    </div>
  );
};
