import { queryClient } from '@/LegacyExplore/graphql/client';
import { ListAnnotationsByAnnotationGroupIdQuery } from '@/LegacyExplore/graphql/codegen/graphql';
import { LIST_ANNOTATIONS_BY_ANNOTATION_GROUP_ID } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { AnnotationGroup, resolveAnnotationGroup, useViewer } from '@/LegacyExplore/stores/viewer';
import { findAnnotationColorFromTemplate } from '@/LegacyExplore/utils/annotation';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { createAnnotation } from '@/LegacyExplore/utils/transformers';
import { useCallback } from 'react';
import { useAnnotationTemplates } from './useAnnotationTemplates';

const getQueryKey = (annotationGroupId?: string) => [
  'LIST_ANNOTATIONS_BY_ANNOTATION_GROUP_ID',
  annotationGroupId,
];

export const fetchAnnotations = (groups: AnnotationGroup[]) => {
  const promises = groups.map(async group => {
    group.loadState = 'fetching';
    const queryResult = await queryClient.fetchQuery({
      queryKey: getQueryKey(group.id),
      queryFn: () =>
        request(LIST_ANNOTATIONS_BY_ANNOTATION_GROUP_ID, {
          annotationGroupId: group.id,
        }),
    });
    group.loadState = 'ready';

    return queryResult;
  });

  return promises;
};

export const useFetchAnnotations = () => {
  const api3D = useViewer(state => state.api3D);
  const api2D = useViewer(state => state.api2D);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const { templates } = useAnnotationTemplates();

  const createAnnotations = useCallback(
    async (
      queryResult: ListAnnotationsByAnnotationGroupIdQuery,
      annotationGroup: AnnotationGroup,
    ) => {
      const newGroup: AnnotationGroup = { ...annotationGroup, annotations: [] };
      for (const annotation of queryResult.listAnnotationsByAnnotationGroupId ?? []) {
        if (isEmpty(annotation) || isEmpty(annotation.id) || isEmpty(annotation.groupId)) continue;
        const template = templates.find(template => template.id === annotation.templateId);
        if (!template || !api3D || !api2D) continue;
        const color = findAnnotationColorFromTemplate(annotation, template);
        const newAnnotation = await createAnnotation(
          annotation,
          newGroup,
          template,
          photo2DGroups,
          api3D,
          api2D,
          color,
        );
        if (newAnnotation) newGroup.annotations.push(newAnnotation);
      }
      return newGroup;
    },
    [api2D, api3D, photo2DGroups, templates],
  );

  const fetch = useCallback(
    (annotationGroups: AnnotationGroup[]) => {
      return fetchAnnotations(annotationGroups).map(async (query, i) => {
        const queryResult = await query;
        const newGroup = await createAnnotations(queryResult, annotationGroups[i]);
        resolveAnnotationGroup(newGroup);
        return newGroup;
      });
    },
    [createAnnotations],
  );

  return { fetch };
};

export const useRefetchAnnotations = () => {
  const annotationGroups = useViewer(state => state.annotationGroups);
  const { fetch } = useFetchAnnotations();

  const refetch = useCallback(
    (ids: string[]) => {
      const groups = ids.reduce((prev, curr) => {
        const group = annotationGroups.find(group => group.id === curr);
        if (group) return [...prev, group];
        return prev;
      }, [] as AnnotationGroup[]);

      return fetch(groups);
    },
    [annotationGroups, fetch],
  );

  return { refetch };
};

useFetchAnnotations.getQueryKey = getQueryKey;
