import { Drawable, LineDrawable } from '@skand/viewer-component-v2';
import { Color, Vector2 } from 'three';
import { Transform2 } from '../../Transform2';
import { Sketch2 } from './Sketch2';

/**
 * 2D line sketch object.
 */
export class LineSketch2 implements Sketch2 {
  private vertices: Vector2[];
  private color: Color;
  private width: number;

  constructor(vertices: Vector2[], color: Color) {
    this.vertices = vertices;
    this.color = color;
    this.width = 3;
  }

  buildDrawables() {
    const vertices = this.getVertices();
    const lines: LineDrawable[] = [];
    for (let i = 0; i < vertices.length - 1; i++) {
      lines.push({
        type: 'line',
        start: vertices[i],
        end: vertices[i + 1],
        color: this.color,
        opacity: 1.0,
        width: 3,
      });
    }
    return lines;
  }

  getVertices() {
    return this.vertices.map(vertex => vertex.clone());
  }

  transformDrawables(drawables: Drawable[], transform: Transform2): void {
    const lines = drawables as LineDrawable[];
    for (let i = 0; i < this.vertices.length - 1; i++) {
      lines[i].start = transform.imageToCanvasSpace(this.vertices[i]);
      lines[i].end = transform.imageToCanvasSpace(this.vertices[i + 1]);
    }
  }

  /**
   * Test if a point is colliding with any of the lines.
   *
   * Algorithm: For each segment, project the clicked point onto it. If the distance
   * between the projected and clicked points is less than some threshold T, and the
   * projected point lies within the segment, then we have a collision.
   *
   * @param point
   * @param transform
   * @returns
   */
  isColliding(point: Vector2, transform: Transform2) {
    const T = this.width * 2;
    for (let i = 0; i < this.vertices.length - 1; i++) {
      const start = transform.imageToCanvasSpace(this.vertices[i]);
      const end = transform.imageToCanvasSpace(this.vertices[i + 1]);

      const A = point.clone().sub(start);
      const B = end.clone().sub(start);

      // Calculate the projection point
      const dotAB = A.dot(B);
      const dotBB = B.dot(B);
      const K = dotAB / dotBB;
      const proj = B.multiplyScalar(K).add(start);

      // Test if the projection point is between start and end
      const projStart = proj.clone().sub(start);
      const projEnd = end.clone().sub(proj);
      if (projStart.length() + projEnd.length() !== Math.sqrt(dotBB)) {
        continue;
      }

      // Test the distance between the clicked and projected points
      const D = proj.clone().sub(point);
      if (D.lengthSq() <= T * T) {
        return true;
      }
    }
    return false;
  }

  setColor(color: Color): void {
    this.color = color;
  }

  getColor(): Color {
    return this.color;
  }
}
