import { IconButton, PencilIcon } from '@/LegacyExplore/components/IconButton';
import { ANNOTATION_FIELD_TYPE } from '@/LegacyExplore/constants/annotation';
import {
  AnnotationTemplate,
  AnnotationTemplateUrlField,
  AnnotationUrl,
  AnnotationUrlField,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { editAnnotationUpdateField, useExplore } from '@/LegacyExplore/stores/explore';
import { cn } from '@/LegacyExplore/utils/classname';
import { Input } from '@skand/ui';
import { useCallback, useState } from 'react';

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

export const FieldLink = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateUrlField;
  disabled: boolean;
}) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const urls = useExplore(state => {
    const urlField = state.annotationDraft?.fields?.find(item => item && item.fieldId === field.id);
    return (urlField?.urls ?? []) as AnnotationUrl[];
  });

  const handleChangeName = useCallback(
    (index: number, name: string) => {
      editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.URL, prev => {
        const oldUrls = (prev as AnnotationUrlField).urls ?? [];
        const newUrls = [];
        for (let i = 0; i < oldUrls.length; i++) {
          if (i !== index) newUrls.push(oldUrls[i]);
          else newUrls.push({ ...oldUrls[i], name });
        }

        return { ...prev, urls: newUrls };
      });
    },
    [field],
  );

  const handleChangeUrl = useCallback(
    (index: number, url: string) => {
      editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.URL, prev => {
        const oldUrls = (prev as AnnotationUrlField).urls ?? [];
        const newUrls = [];
        for (let i = 0; i < oldUrls.length; i++) {
          if (i !== index) newUrls.push(oldUrls[i]);
          else newUrls.push({ ...oldUrls[i], url });
        }

        return { ...prev, urls: newUrls };
      });
    },
    [field],
  );

  const handleAddLink = useCallback(() => {
    if (disabled) return;

    setEnableEdit(true);
    editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.URL, prev => {
      const oldUrls = (prev as AnnotationUrlField).urls ?? [];
      return { ...prev, urls: [...oldUrls, { name: undefined, url: undefined }] };
    });
  }, [field, disabled]);

  const handleRemove = useCallback(
    (index: number) => {
      editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.URL, prev => {
        const oldUrls = (prev as AnnotationUrlField).urls ?? [];
        return { ...prev, urls: oldUrls.filter((_, i) => i !== index) };
      });
    },
    [field],
  );

  const handleUrl = (url: AnnotationUrl['url']) => {
    window.open(url ? url : '');
  };

  return (
    <div
      className={cn(
        'mt-3',
        ' w-full',
        'flex',
        ' flex-col',
        ' gap-2',
        'border-solid',
        'border-neutral-400',
        'rounded-4px',
        'border-1px',
        'p-3',
      )}
      key={field.name}
    >
      <p className="color-neutral-800 typo-text-xxs">{field.name}</p>
      <div className="flex flex-col gap-1">
        {urls.map(({ name, url }, index) => {
          const isRemovable = urls.length > 1;

          if (!enableEdit) {
            return (
              <div className={cn('flex', 'flex-row', 'items-center')} key={index}>
                <p className={cn('color-neutral-600', 'typo-text-s')}> {name ?? ''}</p>

                <div className={cn('flex', 'flex-row', 'flex-1', 'justify-end', 'items-center')}>
                  <p
                    className={cn('color-neutral-600', 'typo-link-xs')}
                    onClick={() => handleUrl(url)}
                  >
                    {url ? url.slice(0, 40) + '...' : ''}
                  </p>

                  <IconButton
                    buttonIcon={<PencilIcon />}
                    buttonState="default"
                    className={cn('bg-transparent', 'cursor-pointer')}
                    disabled={disabled}
                    onClick={() => setEnableEdit(true)}
                  />
                  <div
                    className={cn(
                      'i-skand-close w-3 color-neutral-200',
                      isRemovable && 'cursor-pointer color-neutral-300 hover:color-primary-400',
                    )}
                    onClick={() => {
                      if (isRemovable) {
                        handleRemove(index);
                      }
                    }}
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div className="flex gap-2" key={index}>
                <div className="w-full">
                  <Input
                    onChange={value => handleChangeName(index, value)}
                    placeholder="Link"
                    value={name ?? ''}
                  />
                </div>
                <div className="w-full">
                  <Input
                    onChange={value => handleChangeUrl(index, value)}
                    placeholder="Link"
                    tail={
                      <div className={cn('i-skand-link', 'color-neutral-400', 'text-4', 'ml-1')} />
                    }
                    value={url ?? ''}
                  />
                </div>

                <div className="w-9 flex items-center justify-center px-1">
                  <div
                    className={cn(
                      'i-skand-close w-3 color-neutral-200',
                      isRemovable && 'cursor-pointer color-neutral-300 hover:color-primary-400',
                    )}
                    onClick={() => {
                      if (isRemovable) {
                        handleRemove(index);
                      }
                    }}
                  />
                </div>
              </div>
            );
          }
        })}
      </div>

      <div className="ml-auto flex justify-end" onClick={handleAddLink}>
        <p className="cursor-pointer color-neutral-800 typo-link-xs">+ Add Link</p>
      </div>
    </div>
  );
};
