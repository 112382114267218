import { ANNOTATION_FIELD_TYPE } from '@/LegacyExplore/constants/annotation';
import { AnnotationTemplateDateField } from '@/LegacyExplore/graphql/codegen/graphql';
import { editAnnotationSetField, useExplore } from '@/LegacyExplore/stores/explore';
import { DatePicker } from '@skand/ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

const START_MONTH = new Date(1950, 0);
const END_MONTH = new Date(2050, 0);

export const FieldDate = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateDateField;
  disabled: boolean;
}) => {
  const annotationId = useExplore(state => {
    return state.annotationDraft?.id;
  });

  const current = useExplore(state => {
    return state.annotationDraft?.fields?.find(item => item && item.fieldId === field.id);
  });

  const start = useMemo(() => (current?.start ? dayjs(current?.start) : ''), [current?.start]);

  const handleChangeStart = useCallback(
    (value: string) => {
      const day = dayjs(value);
      editAnnotationSetField(field, ANNOTATION_FIELD_TYPE.DATE, { start: day.format(), end: null });
    },
    [field],
  );

  return (
    <div className="mt-3" key={`${annotationId}-${field.name}`}>
      <DatePicker
        disabled={disabled}
        endMonth={END_MONTH}
        label={field.name ?? ''}
        onChange={handleChangeStart}
        startMonth={START_MONTH}
        value={start && start.format('YYYY-MM-DD')}
      />
    </div>
  );
};
