import { FIND_ACTIVE_VERSION_BY_ANNOTATION_ID } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (id: string) => ['FIND_ACTIVE_VERSION_BY_ANNOTTION_ID', id];

export const useFetchAnnotation = (id: string) => {
  const { refetch: fetchAnnotation } = useQuery({
    enabled: false,
    queryFn: () => request(FIND_ACTIVE_VERSION_BY_ANNOTATION_ID, { annotationId: id as string }),
    queryKey: getQueryKey(id),
  });

  return useCallback(async () => {
    const result = await fetchAnnotation();
    const annotation = result.data?.findActiveVersionByAnnotationId;
    if (!isEmpty(annotation) && !isEmpty(annotation?.id)) {
      return annotation;
    }
  }, [fetchAnnotation]);
};

useFetchAnnotation.getQueryKey = getQueryKey;
