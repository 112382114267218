import { MoreMenu } from '@/components/MoreMenu';
import { canPolicyActionEdit } from '@/constants/policy';
import { DeleteAnnotationMutationVariables } from '@/graphql/codegen/graphql';
import { DELETE_ANNOTATION } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useRefetchAnnotations } from '@/hooks/useFetchAnnotations';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import { SetOpenConfirmationModal } from '@/pages/ProjectPage/DeleteConfirmationModal';
import { useAnnotationStore } from '@/stores/annotation';
import { cancelEditAnnotation, startUpdateAnnotation } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { useShareLink } from '@/stores/shareLink';
import {
  Annotation,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Menu, MenuItem } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { ListItemButton } from './ListItemButton';

export interface AnnotationNodeProps {
  annotation: Annotation;
  selected: boolean;
  setOpenConfirmationModal: SetOpenConfirmationModal;
}

export const AnnotationNode = ({
  annotation,
  selected,
  setOpenConfirmationModal,
}: AnnotationNodeProps) => {
  const api3D = useViewer(state => state.api3D);
  const api2D = useViewer(state => state.api2D);
  const loadingMutex = useViewer(state => state.loadingMutex);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const pinnedAnnotations = useViewer(state => state.pinnedAnnotations);
  const isVisible = visibleAnnotations.has(annotation.id);
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);

  const isShowingLeftSideBarII = useLayout(state => state.isShowingLeftSideBarII);
  const showLeftSideBarII = useLayout(state => state.showLeftSideBarII);
  const setLeftSideBarII = useLayout(state => state.setLeftSideBarII);
  const switchTab = useLayout(state => state.switch);

  const setActiveAnnotationGroupId = useAnnotationStore(state => state.setActiveAnnotationGroupId);
  const setActiveAnnotationId = useAnnotationStore(state => state.setActiveAnnotationId);

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(annotation.group.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);
  const { refetch: refetchAnnotations } = useRefetchAnnotations();

  const deleteAnnotation = useMutation({
    mutationFn: (variables: DeleteAnnotationMutationVariables) =>
      request(DELETE_ANNOTATION, variables),
    onSuccess: () => {
      refetchAnnotations([annotation.group.id]);

      useViewer.setState(state => {
        for (const group of state.annotationGroups) {
          group.loadState = 'pending';
        }
        return { annotationGroups: [...state.annotationGroups] };
      });
    },
  });

  const handleFlyTo = async () => {
    if (annotation.photo && !loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (annotation.photo.type === 'photo2D') {
        await startPhotoOverlay(annotation.photo);
      } else {
        await startPanoramaWalkthrough(annotation.photo);
      }
    }
    if (api3D && annotation.sketch3D) {
      api3D.navigation.lookAt(annotation.sketch3D);
    }
    if (api2D && annotation.sketch2D) {
      api2D.editor.lookAt(annotation.sketch2D);
    }
  };

  const handleToggle = () => {
    if (visibleAnnotations.has(annotation.id)) {
      useViewer.setState(prev => {
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        visibleAnnotations.delete(annotation.id);
        return { visibleAnnotations };
      });
    } else {
      useViewer.setState(prev => {
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        visibleAnnotations.add(annotation.id);
        return { visibleAnnotations };
      });
    }
  };

  const handleEdit = async () => {
    if (enabledSelectMode) return;
    startUpdateAnnotation(annotation.metadata, annotation.template);
  };

  const handlePinAnnotatioNode = async () => {
    const updatedPinnedAnnotations = new Set<string>(pinnedAnnotations);
    updatedPinnedAnnotations.add(annotation.id);
    useViewer.setState({ pinnedAnnotations: updatedPinnedAnnotations });
  };

  const handleUnpinAnnotatioNode = async () => {
    const updatedPinnedAnnotations = new Set<string>(pinnedAnnotations);
    updatedPinnedAnnotations.delete(annotation.id);
    useViewer.setState({ pinnedAnnotations: updatedPinnedAnnotations });
  };

  const handleDeleteAnnotatioNode = () => {
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Annotation',
      description: `Are you sure you want to delete the annotation ${annotation.name}?`,
      actionButton: 'Delete Annotation',
      actionFunction: async () => {
        await deleteAnnotation.mutateAsync({
          annotationId: annotation.id,
        });
        cancelEditAnnotation();
        setOpenConfirmationModal(state => {
          return { ...state, isOpen: false };
        });
      },
    });
  };

  const handleCreateAnnotation = (annotationId: Annotation['id']) => {
    switchTab('sharelink');
    useShareLink.setState({
      shareLinkTab: 'create',
      selectedAnnotationIdByLayers: annotationId,
      selectedShareLink: null,
    });
  };

  return (
    <div className={cn('flex items-center gap-2 flex-row')}>
      {annotation.sketch2D && (
        <div className={cn('color-neutral-600', 'i-skand-annotation2doutlined')} />
      )}

      {annotation.sketch3D && (
        <div className={cn('color-neutral-600', 'i-skand-annotation3doutlined')} />
      )}

      <p
        className={cn(
          'group',
          'cursor-pointer',
          'flex-1 whitespace-nowrap',
          selected ? 'typo-text-small-em text-neutral-800' : 'typo-text-small text-neutral-600',
        )}
        onClick={handleEdit}
        title={annotation.name}
      >
        {annotation.name}
      </p>

      <div className="w-72px" />
      <div
        className={cn(
          'fixed right-0 h-32px flex flex-none items-center gap-2 pl-2',
          !enabledSelectMode && 'bg-neutral-100',
        )}
        style={{
          boxShadow: !enabledSelectMode ? '-8px 0px 8px -2px rgba(255,255,255,1)' : 'none',
        }}
      >
        <ListItemButton
          disabled={enabledPanoramaWalkthrough}
          icon={<div className="skand-tree-row-override i-skand-flyto" />}
          onClick={handleFlyTo}
        />

        <ListItemButton
          icon={
            <div
              className={cn(
                'skand-tree-row-override i-skand-eye',
                isVisible ? 'i-skand-show' : 'i-skand-hide',
              )}
            />
          }
          onClick={handleToggle}
        />
        <MoreMenu className={cn('cursor-pointer', enabledSelectMode && 'hidden')}>
          <Menu className="z-2">
            <MenuItem className="cursor-pointer" onClick={handleEdit}>
              Edit
            </MenuItem>
            <MenuItem
              className="cursor-pointer"
              onClick={() => handleCreateAnnotation(annotation.id)}
            >
              Create share link to this annotation
            </MenuItem>

            {canEdit && (
              <MenuItem
                className="cursor-pointer"
                onClick={() => {
                  setActiveAnnotationId(annotation.id);
                  setActiveAnnotationGroupId(annotation.group.id);
                  setLeftSideBarII('annotationGroup');
                  !isShowingLeftSideBarII && showLeftSideBarII();
                }}
              >
                Move to group
              </MenuItem>
            )}
            <MenuItem
              className="cursor-pointer"
              onClick={() => {
                setActiveAnnotationId(annotation.id);
                setLeftSideBarII('annotationHistory');
                !isShowingLeftSideBarII && showLeftSideBarII();
              }}
            >
              View history
            </MenuItem>
            {canEdit && (
              <MenuItem className="cursor-pointer" onClick={handleDeleteAnnotatioNode}>
                Delete
              </MenuItem>
            )}

            {pinnedAnnotations.has(annotation.id) ? (
              <MenuItem className="cursor-pointer" onClick={handleUnpinAnnotatioNode}>
                Unpin
              </MenuItem>
            ) : (
              <MenuItem className="cursor-pointer" onClick={handlePinAnnotatioNode}>
                Pin
              </MenuItem>
            )}
          </Menu>
        </MoreMenu>
      </div>
    </div>
  );
};
