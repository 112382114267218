import { RadioButton } from '@/components/RadioButton';
import {
  Annotation,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';

export interface AnnotationNodeProps {
  annotation: Annotation;
  setSelectedAnnotationId: (value: string) => void;
  selectedAnnotationId: string | null | undefined;
}

export const AnnotationNode = ({
  annotation,
  setSelectedAnnotationId,
  selectedAnnotationId,
}: AnnotationNodeProps) => {
  const api3D = useViewer(state => state.api3D);
  const api2D = useViewer(state => state.api2D);
  const loadingMutex = useViewer(state => state.loadingMutex);

  const handleSelect = async (annotation: Annotation) => {
    if (annotation.photo && !loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (annotation.photo.type === 'photo2D') {
        await startPhotoOverlay(annotation.photo);
      } else {
        await startPanoramaWalkthrough(annotation.photo);
      }
    }
    if (annotation.sketch3D) {
      api3D?.navigation.lookAt(annotation.sketch3D);
    } else if (annotation.sketch2D) {
      api2D?.editor.lookAt(annotation.sketch2D);
    }
    setSelectedAnnotationId(annotation.id);
  };

  return (
    <div
      className={cn('flex justify-between items-center py-1')}
      onClick={() => handleSelect(annotation)}
    >
      <div className={cn('flex items-center gap-2 text-neutral-600 justify-center')}>
        <RadioButton
          checked={annotation.id === selectedAnnotationId}
          className={cn('flex', 'flex-row', 'items-center', 'cursor-default')}
          id="default"
          onChange={() => {}}
          value={annotation.id}
        />

        <div className={cn('i-skand-annotation2doutlined')} />
        <div className={cn('i-skand-annotation3doutlined')} />
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap cursor-pointer')}>
          {annotation.name}
        </p>
      </div>
    </div>
  );
};
