import { cn } from '@/LegacyExplore/utils/classname';

interface LabelTypes {
  labelType: 'default' | 'warning' | 'annotation' | 'info';
}

interface Label {
  labelTitle: string;
  textLength?: number;
  ellipsis?: boolean;
  css?: string;
}

export interface LabelProps extends LabelTypes, Label {}

const CommonLabelContent = ({ labelTitle, className, css }: Label & { className?: string }) => (
  <span
    className={`${css} text-neutral-800 whitespace-break-spaces typo-text-xs h-5 flex justify-center items-center ${className}`}
  >
    {labelTitle}
  </span>
);

const DefaultLabel = ({ labelTitle, css }: Label) => (
  <div
    className={cn(
      `${css} w-fit flex justify-center items-center gap-2 px-3 py-1 rounded-1  border-1 border-solid border-gray-400 shadow-md`,
    )}
  >
    <CommonLabelContent css={css} labelTitle={labelTitle} />
  </div>
);

const WarningLabel = ({ labelTitle }: Label) => {
  return (
    <div
      className={cn(
        ' w-fit flex justify-center items-center gap-2 px-2 py-1 rounded-1  border-1 border-solid border-alert-400 shadow-md bg-alert-100',
      )}
    >
      <div className="i-skand-warn color-alert-400" />
      <CommonLabelContent labelTitle={labelTitle} />
    </div>
  );
};

const AnnotationLabel = ({ labelTitle }: Label) => {
  return (
    <div
      className={cn(' w-fit flex justify-center items-center bg-[#53108980] py-1 px-2 rounded-md')}
    >
      <CommonLabelContent className="text-neutral-100" labelTitle={labelTitle} />
    </div>
  );
};

const InfoLabel = ({ labelTitle, css }: Label) => {
  return (
    <div
      className={cn(
        `${css}  w-fit flex justify-center items-center gap-2 px-2 py-1 rounded-1  border-1 border-solid border-info-400 shadow-md bg-info-100`,
      )}
    >
      <div className="i-skand-info color-info-400" />
      <CommonLabelContent css={css} labelTitle={labelTitle} />
    </div>
  );
};

const LabelComponents = {
  default: DefaultLabel,
  warning: WarningLabel,
  annotation: AnnotationLabel,
  info: InfoLabel,
};

export const Label = ({
  labelType,
  labelTitle,
  textLength = 24,
  ellipsis = true,
  css,
}: LabelProps) => {
  const LabelComponent = LabelComponents[labelType];
  const shortenedTitle = labelTitle.slice(0, textLength) + `${ellipsis ? '...' : ''}`;

  return <LabelComponent css={css} labelTitle={shortenedTitle} />;
};
