import { FindIcon } from '@/LegacyExplore/components/IconButton';
import { cn } from '@/LegacyExplore/utils/classname';
import { Project } from '@/LegacyExplore/utils/project';
import { search } from '@/LegacyExplore/utils/search';
import { Input } from '@skand/ui';
import { useMemo, useState } from 'react';
import './groupInfoModal.css';

export interface GroupInfoModalProps {
  projects: Solid<Project, 'location'>[];
  setProject: (project: Solid<Project, 'location'>[]) => void;
}

export const GroupInfoModal = ({ projects, setProject }: GroupInfoModalProps) => {
  const [searchKey, setSearchKey] = useState('');

  // Filter projects by search key
  const filteredProjects = useMemo(
    () => projects.filter(project => search(project.name, searchKey)),
    [projects, searchKey],
  );

  const handleProject = (project: Solid<Project, 'location'>) => {
    setProject([project]);
  };

  return (
    <div
      className={cn(
        'flex',
        'flex-col',
        'w-363px',
        'bg-neutral-100',
        'p-3',
        'rounded-2',
        'border-1px',
        'border-solid',
        'border-neutral-400',
        'min-h-10px',
        'overflow-hidden',
        'cursor-pointer',
      )}
      onClick={ev => ev.stopPropagation()}
      onDoubleClick={ev => ev.stopPropagation()}
      onDrag={ev => ev.stopPropagation()}
      onMouseDown={ev => ev.stopPropagation()}
      onWheel={ev => ev.stopPropagation()}
    >
      <div className={cn('mb-2')}>
        <Input
          onChange={setSearchKey}
          placeholder="Search for projects"
          tail={<FindIcon />}
          value={searchKey}
        />
      </div>
      <div className="project-list max-h-200px min-h-10px overflow-x-hidden overflow-y-scroll">
        {filteredProjects.map((project, index) => (
          <div
            className={cn('flex', 'w-full', 'justify-between', 'mt-2')}
            key={index}
            onClick={() => handleProject(project)}
          >
            <span className="text-end color-neutral-700 typo-text-s">{project.name}</span>
            <div
              className={cn('i-skand-dropdownarrow', 'rotate-270', 'text-3', 'text-neutral-500')}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
