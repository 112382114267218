import { cn } from '@/LegacyExplore/utils/classname';

export interface ListItemButtonProps {
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
  icon: React.ReactNode;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  analyticsEventObject?: string;
}

export const ListItemButton = ({
  disabled,
  onClick,
  icon,
  active,
  onMouseLeave,
  onMouseEnter,
  analyticsEventObject,
}: ListItemButtonProps) => (
  <button
    className={cn(
      'contents text-4',
      !disabled ? (active ? 'text-primary-400' : 'text-neutral-600') : 'color-neutral-300',
      !disabled && 'cursor-pointer',
    )}
    data-analytics-event-object={analyticsEventObject}
    disabled={disabled}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {icon}
  </button>
);
