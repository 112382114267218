import { ANNOTATION_FIELD_TYPE } from '@/LegacyExplore/constants/annotation';
import {
  AnnotationTemplate,
  AnnotationTemplateImageField,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { FILE_SIGNED_GET_OBJECT_DOWNLOAD_URL_BY_ID } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useAccountId } from '@/LegacyExplore/hooks/useAccountId';
import { useImageFile } from '@/LegacyExplore/hooks/useImageFile';
import { editAnnotationUpdateField, useExplore } from '@/LegacyExplore/stores/explore';
import { cn } from '@/LegacyExplore/utils/classname';
import { downloadBlob, downloadUrl } from '@/LegacyExplore/utils/download';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import logger from '@/LegacyExplore/utils/logger';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { Button, ImagePreview, Progress } from '@skand/ui';
import { useCreateNewSimpleUploadSession, useSimpleUploadSession } from '@skand/uploader';
import { useMutation } from '@tanstack/react-query';
import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { simpleUploadSessionRequestService, toastError, uploaderFactory } from '../upload';

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

export const Thumbnail = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateImageField;
  disabled: boolean;
}) => {
  const isReadOnly = getShareLinkToken() !== null || disabled;

  const accountId = useAccountId();
  const { simpleUploadSession, createNewSimpleUploadSession } = useCreateNewSimpleUploadSession(
    uploaderFactory,
    simpleUploadSessionRequestService,
  );
  const { uploadProgress, stage } = useSimpleUploadSession(simpleUploadSession);
  const isUploading = stage !== 'initial';
  const uploadProgressCalculated =
    uploadProgress.bytesTotal === 0 ? 0 : uploadProgress.bytesUploaded / uploadProgress.bytesTotal;

  const [blob, setBlob] = useState<null | Blob>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [enabledViewer, setEnabledViewer] = useState(false);
  const [isThumbnailHovered, setIsThumbnailHovered] = useState(false);

  const uploadFilesAndUpdateFileId = async () => {
    try {
      await simpleUploadSession.start(accountId as string);
    } catch (e) {
      logger.error(e);
      toastError();
    }

    // retrieve file id from first file
    const fileNodes = simpleUploadSession.fileTree.mapFileNodes(fileNode => fileNode);
    const fileNode = fileNodes[0];
    if (!fileNode) return;

    editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.IMAGE, prev => {
      return { ...prev, fileId: fileNode.fileId };
    });

    createNewSimpleUploadSession();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const files = [...(e.target.files ?? [])];
    e.target.value = '';

    simpleUploadSession.addFiles(files);
    uploadFilesAndUpdateFileId();
  };

  const fileId = useExplore(state => {
    return (
      state.annotationDraft?.fields?.find(item => item && item.fieldId === field.id)?.fileId ??
      undefined
    );
  });
  const { file } = useImageFile(fileId ?? null);

  useEffect(() => {
    if (file?.originalUrl) {
      downloadBlob(file?.originalUrl, setDownloadProgress).then(setBlob);
    } else {
      setBlob(null);
      setDownloadProgress(0);
    }
  }, [file?.originalUrl]);

  const imageUrl = useMemo(() => {
    if (blob) {
      return window.URL.createObjectURL(blob);
    }
  }, [blob]);

  const { mutateAsync: getFile } = useMutation({
    mutationFn: (fileId: string) => request(FILE_SIGNED_GET_OBJECT_DOWNLOAD_URL_BY_ID, { fileId }),
  });

  const handleDownload = useCallback(async () => {
    if (fileId) {
      const response = await getFile(fileId);
      const url = response.file?.signedGetObjectDownloadUrl;
      if (url) downloadUrl(url, file?.fileName ?? undefined);
    }
  }, [file?.fileName, fileId, getFile]);

  const handleRemove = useCallback(() => {
    editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.IMAGE, prev => {
      return { ...prev, fileId: undefined };
    });
  }, [field]);

  const inputFile = useRef<HTMLInputElement>(null);
  const handleChangeImage = () => {
    if (!isEmpty(inputFile) && !isEmpty(inputFile.current)) inputFile.current.click();
  };

  if (isUploading) {
    return (
      <div
        className="mt-3 w-full flex items-center b-1 b-neutral-400 rounded-1 b-solid px-3 py-2"
        key={field.name}
      >
        <div className="flex-1">
          <p className="color-neutral-800 typo-text-xxs">{field.name}</p>
          <div className="h-4 py-1 pr-2">
            <Progress progress={uploadProgressCalculated} />
          </div>
        </div>

        <div className="flex-0 i-skand-image text-3 color-neutral-400" />
      </div>
    );
  }

  return (
    <div>
      <p className="pb-4px pt-12px leading-14px color-neutral-800 typo-text-xs">{field.name}</p>
      <div
        className="relative h-200px w-full overflow-hidden b-1 b-neutral-400 rounded-1 b-solid"
        key={field.name}
        onMouseEnter={() => setIsThumbnailHovered(true)}
        onMouseLeave={() => setIsThumbnailHovered(false)}
      >
        {imageUrl ? (
          <>
            <div
              className={cn('absolute', 'right-2', 'top-2', 'flex', 'items-center', 'gap-2', 'z-2')}
            >
              <div
                className={cn(
                  'border-solid border-neutral-500',
                  'rounded-4px',
                  'border-1px',
                  'flex',
                  'justify-center',
                  'items-center',
                  'h-20px',
                  'w-20px',
                  'bg-neutral-100',
                )}
                onClick={handleDownload}
              >
                <div className="i-skand-download cursor-pointer text-12px color-neutral-500" />
              </div>
              {!isReadOnly && (
                <div
                  className={cn(
                    'border-solid border-neutral-500',
                    'rounded-4px',
                    'border-1px',
                    'h-20px',
                    'w-20px',
                    'flex',
                    'justify-center',
                    'items-center',
                    'bg-neutral-100',
                  )}
                  onClick={handleRemove}
                >
                  <div className="i-skand-close cursor-pointer text-12px color-neutral-500" />
                </div>
              )}
            </div>

            <img
              className={cn(
                'h-auto',
                'w-full',
                'max-h-200px',
                'object-cover',
                isThumbnailHovered && 'opacity-30',
              )}
              src={imageUrl}
            />
          </>
        ) : (
          <div className="h-200px w-full flex items-center justify-center bg-neutral-200">
            <div className="w-[75%]">
              <Progress progress={downloadProgress} />
            </div>
          </div>
        )}

        {isThumbnailHovered && !isUploading && downloadProgress === 1 && (
          <div
            className={cn(
              'gap-2',
              'flex',
              'absolute',
              'top-50% left-50%',
              'translate-x--50% translate-y--50%',
            )}
          >
            {!isReadOnly && (
              <Button onClick={handleChangeImage} size="xs">
                CHANGE IMAGE
              </Button>
            )}
            <Button
              className="p-x-2"
              filled
              onClick={() => {
                setEnabledViewer(true);
              }}
              primary
              size="xs"
            >
              PREVIEW
            </Button>
          </div>
        )}
        {enabledViewer && !isEmpty(file) && !isEmpty(file.originalUrl) && (
          <ImagePreview
            close={() => {
              setEnabledViewer(false), setIsThumbnailHovered(false);
            }}
            downloadable
            imageFileName={file?.fileName ?? `image-${Date.now()}`}
            url={file.originalUrl}
            zoomable
          />
        )}

        <input
          accept="image/*"
          className="hidden"
          onChange={handleChange}
          ref={inputFile}
          type="file"
        />
      </div>
    </div>
  );
};
