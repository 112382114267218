import { default as PanoramaClusterIcon } from '@/assets/panoramaCluster.png';
import { ArrowIcon } from '@/LegacyExplore/components/IconButton';
import { Loading } from '@/LegacyExplore/components/Loading';
import { GOOGLE_MAP_API_KEY } from '@/LegacyExplore/constants/env';
import {
  addViewerEventListener,
  removeViewerEventListener,
  startPanoramaWalkthrough,
  useViewer,
} from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { GoogleMap, Libraries, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Rnd } from '@skand/ui';
import {
  CameraMotionCallback,
  Cartographic,
  toCartesian,
  toDegrees,
  toRadians,
} from '@skand/viewer-component-v2';
import { useEffect, useMemo, useState } from 'react';
import { Quaternion, Vector3 } from 'three';
import { MarkerGroup } from './MarkerGroup';
import { mapContainerStyle, mapStyles } from './MinimapStyles';
import { toHeading, toLatLng } from './Utils';

const libraries: Libraries = ['places'];

export interface MinimapProps {
  disableMinimap: () => void;
}

export const Minimap = ({ disableMinimap }: MinimapProps) => {
  const api3D = useViewer(state => state.api3D);
  const filteredPanoramas = useViewer(state => state.filteredPanoramas);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);

  // Setup the Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: 'siteMap',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [position, setPosition] = useState<Vector3>(
    api3D?.navigation.getPosition() ?? toCartesian(new Cartographic()),
  );
  const [rotation, setRotation] = useState<Quaternion>(
    api3D?.navigation.getRotation() ?? new Quaternion(),
  );

  // Set the camera callback
  useEffect(() => {
    const cb: CameraMotionCallback = (position, rotation) => {
      setPosition(position);
      setRotation(rotation);
    };
    addViewerEventListener('onCameraMotion', cb);
    return () => {
      removeViewerEventListener('onCameraMotion', cb);
    };
  }, [api3D]);

  // Panorama dataset
  const PanoramaGroup = useMemo(() => {
    const panorama3Ds = filteredPanoramas.filter(panorama => panorama.widget);
    const positions = panorama3Ds.map(panorama => panorama.widget?.getPosition() as Vector3);
    const onMarkerSelect = (index: number) => {
      const target = panorama3Ds[index];
      if (target.widget) {
        if (!enabledPanoramaWalkthrough) {
          api3D?.navigation.lookAt(target.widget);
        } else {
          startPanoramaWalkthrough(target);
        }
      }
    };
    if (isLoaded) {
      return (
        <MarkerGroup
          clusterStyles={[
            {
              url: PanoramaClusterIcon,
              width: 30,
              height: 30,
            },
          ]}
          markerIcon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 5,
            fillColor: '#FFFFFF',
            fillOpacity: 1,
            strokeWeight: 0,
          }}
          onMarkerSelect={onMarkerSelect}
          positions={positions}
        />
      );
    }
  }, [api3D, enabledPanoramaWalkthrough, filteredPanoramas, isLoaded]);

  // Handle clicking on the minimap
  const handleClickOnMap = (event: google.maps.MapMouseEvent) => {
    if (api3D) {
      const walkthrough = api3D.panorama.getWalkthrough();
      const editor = api3D.panorama.getEditor();
      if (event.latLng && !walkthrough.isActive() && !editor.isActive()) {
        const cartographic = new Cartographic(
          toRadians(event.latLng.lng()),
          toRadians(event.latLng.lat()),
          100,
        );
        api3D.navigation.moveTo(toCartesian(cartographic), rotation);
      }
    }
  };

  return (
    <Rnd
      className={cn('pointer-events-auto', 'absolute', 'bottom-0', 'right-0')}
      disableDragX
      disableDragY
      height={241}
      width={361}
    >
      <div className={cn('w-full', 'h-full', 'block', 'border-rounded-tl-1', 'bg-neutral-100')}>
        <div
          className={cn(
            'color-neutral-100',
            'z-1',
            'absolute',
            'transition',
            'cursor-pointer',
            'top--0.5',
            'left--0.5',
          )}
          onClick={disableMinimap}
        >
          <ArrowIcon />
        </div>
        {!isLoaded ? (
          <Loading />
        ) : (
          <GoogleMap
            center={toLatLng(position)}
            mapContainerStyle={mapContainerStyle}
            onClick={handleClickOnMap}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              zoomControl: false,
              rotateControl: false,
              panControl: false,
              scaleControl: false,
              styles: mapStyles,
              gestureHandling: 'greedy',
            }}
            zoom={15}
          >
            {PanoramaGroup}
            <MarkerF
              options={{
                icon: {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  anchor: new google.maps.Point(0, 2.5),
                  scale: 5,
                  strokeColor: '#FFFFFF',
                  strokeWeight: 3,
                  strokeOpacity: 1,
                  fillColor: '#555555',
                  fillOpacity: 1,
                  rotation: -toDegrees(toHeading(position, rotation)),
                },
              }}
              position={toLatLng(position)}
            />
          </GoogleMap>
        )}
      </div>
    </Rnd>
  );
};
