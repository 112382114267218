/* eslint-disable @typescript-eslint/no-explicit-any */

import { GRAPHQL_URL } from '@/LegacyExplore/constants/env';
import { EMPTY_RESOURCE, ERROR_404, ROOT } from '@/LegacyExplore/constants/paths';
import { clearAuth } from '@/LegacyExplore/utils/auth';
import { getHeaders } from '@/LegacyExplore/utils/headers';
import * as logger from '@/LegacyExplore/utils/logger';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { GraphQLClient, ResponseMiddleware } from 'graphql-request';

const INVALID_TOKEN_ENUM = 'INVALID_TOKEN';

const responseMiddleware: ResponseMiddleware = response => {
  if (response instanceof Error) {
    logger.error('network error', response);
    return;
  }

  if (response.errors) {
    logger.error('backend error', response.status, response.errors);

    if (response.errors[0].extensions?.code === INVALID_TOKEN_ENUM) {
      const shareLinkToken = getShareLinkToken();
      if (shareLinkToken) {
        // HOTFIX: whitelist these endpoints for sharelinks
        if ((response.data as any).listAnnotationProcessesByProjectId === null) return;
        if ((response.data as any).listProjectGroupsByAccountContext === null) return;
        if ((response.data as any).listProjectsByAccountContext === null) return;
        if ((response.data as any).listRenderObjectByIds === null) return;
        if ((response.data as any).readUserByContext === null) return;
        if ((response.data as any).listAnnotationTemplatesByAccountContext === null) return;

        location.replace(ERROR_404 + '/403'); // redirect without clear token
        return;
      }

      clearAuth();
      location.replace(ROOT);
      return;
    }
    if (
      response.errors &&
      response.errors[0] &&
      response.errors[0].message.includes('does not have access to READ project')
    ) {
      location.replace(EMPTY_RESOURCE + '/project');
      return;
    } else if (
      response.errors &&
      response.errors[0] &&
      response.errors[0].message.includes('Project not found by Id')
    ) {
      location.replace(ERROR_404 + '/404');
      return;
    } else if (
      response.errors &&
      response.errors[0] &&
      response.errors[0].message.includes('Invalid ObjectId')
    ) {
      location.replace(ERROR_404 + '/404');
      return;
    }

    throw new Error(response.errors[0].message);
  }
};

const client = new GraphQLClient(GRAPHQL_URL, {
  errorPolicy: 'all',
  headers: () => getHeaders(),
  method: 'POST',
  responseMiddleware,
});

export const request = client.request.bind(client);
