import {
  DrawIcon,
  IconButton,
  PanoGroupIcon,
  PhotoGroupIcon,
  ResetIcon,
} from '@/components/IconButton';
import { Label } from '@/components/Label';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { queryClient } from '@/graphql/client';
import {
  UpdatePanoramicImagesStatusMutationVariables,
  UpdateProcessImagesStatusMutationVariables,
} from '@/graphql/codegen/graphql';
import {
  UPDATE_PANORAMIC_PROCESS_IMAGE_STATUS,
  UPDATE_PROCESS_IMAGES_STATUS,
} from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchProcesses } from '@/hooks/useFetchProcesses';
import { useExplore } from '@/stores/explore';
import {
  Panorama,
  Photo2D,
  startDraw2D,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopDraw2D,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { persist } from '@/utils/Persist';
import { ProcessImage } from '@/utils/process';
import { getShareLinkToken } from '@/utils/shareLink';
import { AI_PREDICTOR } from '@/utils/split';
import { Button, toast } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { SettingsMenu } from '../../../SettingsMenu';
import { TransformControl } from './TransformControl';

export interface ActionButtonsProps {
  togglePredictor: () => void;
  enabledPredictor: boolean;
}

export const ActionButtons = ({ togglePredictor, enabledPredictor }: ActionButtonsProps) => {
  const treatment = useTreatments([AI_PREDICTOR]);
  const processTabFlag = treatment[AI_PREDICTOR].treatment === 'on';

  const api2D = useViewer(state => state.api2D);
  const api3D = useViewer(state => state.api3D);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const enabledDraw2D = useViewer(state => state.enabledDraw2D);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const enabledPhotoOverlay = useViewer(state => state.enabledPhotoOverlay);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const enabled3DMode = !enabledPhotoOverlay && !enabledPanoramaWalkthrough;
  const photos = useViewer(state => state.ribbonPhotos);
  const loadingMutex = useViewer(state => state.loadingMutex);
  const projectId = useExplore(state => state.projectId);

  // Process related states
  const targetProcess = useViewer(state => state.targetProcess);
  const processImage = targetProcess?.images.get(targetPhoto?.id ?? '');

  const hasShareLinkToken = getShareLinkToken();
  const annotationDraft = useExplore(state => state.annotationDraft);

  const [drawModeTooltip, setDrawModeTooltip] = useState(false);
  const [resetTooltip, setResetTooltip] = useState(false);
  const [settingsTooltip, setSettingsTooltip] = useState(false);

  const [enabledSettingsMenu, setEnabledSettingsMenu] = useState(false);

  const updateAnnotationProcessImagesStatus = useMutation({
    mutationFn: (params: UpdateProcessImagesStatusMutationVariables) =>
      request(UPDATE_PROCESS_IMAGES_STATUS, params),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProcesses.getQueryKey(projectId));
    },
  });

  const updatePanoramicProcessImagesStatus = useMutation({
    mutationFn: (params: UpdatePanoramicImagesStatusMutationVariables) =>
      request(UPDATE_PANORAMIC_PROCESS_IMAGE_STATUS, params),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProcesses.getQueryKey(projectId));
    },
  });

  // Toggle the process image status
  const toggleProcessImageStatus = (status: ProcessImage['status']) => {
    if (targetProcess && processImage) {
      if (targetProcess.kind === 'annotation') {
        updateAnnotationProcessImagesStatus.mutate({
          annotationProcessImageStatusIds: [processImage.processImageId],
          status: status.toUpperCase(),
        });
      } else if (targetProcess.kind === 'panorama') {
        updatePanoramicProcessImagesStatus.mutate({
          panoramicProcessImageStatusIds: [processImage.processImageId],
          status: processImage.status === 'created' ? 'PLACED' : 'NOT_PLACED',
        });
      }
    }
  };

  // Toggle the 2D draw tool.
  const toggleDrawTool = () => {
    if (!enabledDraw2D) {
      startDraw2D();
    } else {
      stopDraw2D();
    }
  };

  // Handle resetting the 2D viewer.
  const handleResetView = () => {
    api2D?.editor.resetView();
  };

  // Toggle the settings menu
  const toggleSettingsMenu = () => {
    setEnabledSettingsMenu(!enabledSettingsMenu);
  };

  // Enter 3D mode (stop image views)
  const handle3DMode = () => {
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();
    }
  };

  // Start the image overlay at prioritized image
  const handlePhotoOverlay = async () => {
    if (!api3D || loadingMutex) return;
    const targets = photos.filter(photo => photo.type === 'photo2D' && photo.widget) as Photo2D[];
    if (targets.length > 0) {
      handle3DMode();
      startPhotoOverlay(targets[0]);
      persist.set('image', targets[0].id);
      persist.url.refreshURL();
    } else {
      toast({
        type: 'info',
        message: 'Could not start image overlay. Toggle the layer/s with 2D images.',
        lifespan: 5000,
      });
    }
  };

  // Start the panorama walkthrough at prioritized image
  const handlePanoramaWalkthrough = async () => {
    if (!api3D || loadingMutex) return;
    const targets = photos.filter(photo => photo.type === 'panorama' && photo.widget) as Panorama[];
    if (targets.length > 0) {
      handle3DMode();
      startPanoramaWalkthrough(targets[0]);
      persist.set('image', targets[0].id);
      persist.url.refreshURL();
    } else {
      toast({
        type: 'info',
        message: 'Could not start panorama walkthrough. Toggle the layer/s with panoramic images.',
        lifespan: 5000,
      });
    }
  };

  return (
    <div className="absolute right-0 top-0 mr-3">
      <div
        className={cn(
          'pt-12px',
          'flex',
          'justify-start',
          'items-center',
          'z-1',
          'pointer-events-auto',
        )}
      >
        {processImage && (
          <>
            {processImage.status !== 'ignored' && processImage.status !== 'checked' && (
              <Button
                active
                className={cn(
                  'h-30px mr-10px',
                  'b-1px! b-solid! b-neutral-100!',
                  'color-neutral-100!',
                  'cursor-pointer',
                )}
                filled
                onClick={() => toggleProcessImageStatus('ignored')}
                primary
                size="small"
              >
                Ignore
              </Button>
            )}
            {processImage.status !== 'checked' && processImage.status !== 'ignored' && (
              <Button
                active
                className={cn(
                  'h-30px mr-10px',
                  'b-1px! b-solid! b-neutral-100!',
                  'color-neutral-100!',
                  'cursor-pointer',
                )}
                filled
                onClick={() => toggleProcessImageStatus('checked')}
                primary
                size="small"
              >
                Submit
              </Button>
            )}
            {processImage.status !== 'created' && (
              <Button
                active
                className={cn(
                  'h-30px mr-10px',
                  'b-1px! b-solid! b-neutral-100!',
                  'color-neutral-100!',
                  'cursor-pointer',
                )}
                filled
                onClick={() => toggleProcessImageStatus('created')}
                primary
                size="small"
              >
                Reopen
              </Button>
            )}
            <TransformControl />
          </>
        )}

        <div className={cn('relative mr-10px')}>
          {targetPhoto?.type === 'photo2D' && !hasShareLinkToken && processTabFlag && (
            <Button
              className="cursor-pointer"
              filled
              onClick={togglePredictor}
              primary={enabledPredictor}
            >
              Predict
            </Button>
          )}
        </div>

        <div className={cn('relative')}>
          {targetPhoto?.type !== 'panorama' && !hasShareLinkToken && (
            <IconButton
              analyticsEventObject={ANALYTICS_EVENT_OBJECT.DRAW_TOOL_2D}
              buttonIcon={<DrawIcon />}
              buttonState={enabledDraw2D ? 'active' : 'default'}
              className={cn(
                'h-30px',
                'mr-10px',
                enabledDraw2D || drawModeTooltip ? 'bg-neutral-400' : 'bg-transparent',
              )}
              disabled={
                targetPhoto === null || enabledDraw3D || annotationDraft?.annotation2d !== undefined
              }
              onClick={toggleDrawTool}
              onMouseEnter={() => setDrawModeTooltip(true)}
              onMouseLeave={() => setDrawModeTooltip(false)}
            />
          )}
          {drawModeTooltip && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Draw tool"
                labelType="default"
              />
            </div>
          )}
        </div>
        <div className={cn('relative')}>
          <IconButton
            buttonIcon={<ResetIcon />}
            buttonState="default"
            className={cn('h-30px', 'mr-10px', resetTooltip ? 'bg-neutral-400' : 'bg-transparent')}
            disabled={targetPhoto === null}
            onClick={handleResetView}
            onMouseEnter={() => setResetTooltip(true)}
            onMouseLeave={() => setResetTooltip(false)}
          />
          {resetTooltip && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Reset View"
                labelType="default"
              />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={ANALYTICS_EVENT_OBJECT.VIEWER_SETTINGS}
            buttonIcon={<div className="i-skand-gear text-18px" />}
            buttonState="default"
            className={cn(
              'h-30px',
              'mr-10px',
              enabledSettingsMenu || settingsTooltip
                ? 'bg-neutral-400'
                : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleSettingsMenu}
            onMouseEnter={() => setSettingsTooltip(true)}
            onMouseLeave={() => setSettingsTooltip(false)}
          />
          {settingsTooltip && !enabledSettingsMenu && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Settings"
                labelType="default"
              />
            </div>
          )}
          {enabledSettingsMenu && (
            <div className={cn('absolute w-260px', 'h-524px', 'right-0', 'mt-2')}>
              <SettingsMenu />
            </div>
          )}
        </div>

        <div className={cn('relative flex flex-row')}>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !enabled3DMode ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-0px',
              'border-t-1',
              'border-b-1',
              'rounded-bl-1',
              'rounded-tl-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.VIEW_3D}
            disabled={enabled3DMode}
            onClick={handle3DMode}
          >
            <div className={cn('color-neutral-100 typo-text-s')}>3D</div>
          </button>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !enabledPanoramaWalkthrough ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-1',
              'border-t-1',
              'border-b-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.VIEW_PANORAMA}
            disabled={enabledPanoramaWalkthrough}
            onClick={handlePanoramaWalkthrough}
          >
            <PanoGroupIcon className="color-neutral-100" />
          </button>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !enabledPhotoOverlay ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-1',
              'border-t-1',
              'border-b-1',
              'rounded-br-1',
              'rounded-tr-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.IMAGE_VIEWER_2D_IMAGE}
            disabled={enabledPhotoOverlay}
            onClick={handlePhotoOverlay}
          >
            <PhotoGroupIcon className="color-neutral-100" />
          </button>
        </div>
      </div>
    </div>
  );
};
