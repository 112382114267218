import { AnnotationFieldType } from '@/LegacyExplore/constants/annotation';
import { AnnotationTemplateField } from '@/LegacyExplore/graphql/codegen/graphql';
import { WithMode, useTemplate } from './store';

export const addField = (type: AnnotationFieldType) => {
  useTemplate.setState(state => {
    const newField: WithMode<AnnotationTemplateField> = {
      mode: 'update',
      order: state.next,
      type,
    };

    return { fields: [...state.fields, newField], next: state.next + 1 };
  });
};

export const renameField = (field: WithMode<AnnotationTemplateField>, name: string) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) newFields.push(f);
      else newFields.push({ ...f, name });
    }

    // if colorFromField equals to old name, change it to new name
    // otherwise leave it untouched
    const oldColorFromField = state.template?.colorFromField;
    const colorFromField =
      oldColorFromField !== undefined && oldColorFromField === field.name
        ? name
        : state.template?.colorFromField;
    return { fields: newFields, template: { ...state.template, colorFromField } };
  });
};

export const removeField = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];
    let isRemoved = false;

    for (const f of state.fields) {
      if (f === field) {
        isRemoved = true;
        continue;
      }

      if (!isRemoved) {
        newFields.push(f);
        continue;
      }

      newFields.push({ ...f, order: (f.order as number) - 1 });
    }

    return { fields: newFields, next: state.next - 1 };
  });
};

export const editField = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) newFields.push(f);
      else newFields.push({ ...f, mode: 'update' });
    }

    return { fields: newFields };
  });
};

export const saveField = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) newFields.push(f);
      else newFields.push({ ...f, mode: 'view' });
    }

    return { fields: newFields };
  });
};

export const moveField = (fromIndex: number, toIndex: number) => {
  useTemplate.setState(state => {
    const fields = state.fields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

    const fromField = fields[fromIndex];

    const tmpFields = fields.filter((_, i) => i !== fromIndex);
    const newFields = [];

    for (let i = 0; i < fields.length; i++) {
      if (i < toIndex)
        newFields.push({
          ...tmpFields[i],
          order: i,
        });

      if (i === toIndex) {
        newFields.push({
          ...fromField,
          order: i,
        });
      }

      if (i > toIndex) {
        newFields.push({
          ...tmpFields[i - 1],
          order: i,
        });
      }
    }

    return { fields: newFields };
  });
};
