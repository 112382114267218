import { ListItemButton } from '@/components/SceneTab/ListItemButton';
import { AnnotationTemplateSelectOption } from '@/graphql/codegen/graphql';
import { Sketch2 } from '@/utils/Viewer2D';
import { cn } from '@skand/ui';
import { Sketch } from '@skand/viewer-component-v2';

export interface Prediction {
  id: number;
  option: AnnotationTemplateSelectOption;
  sketch2D: Sketch2;
  sketch3D?: Sketch;
}

export interface PredictionRowProps {
  prediction: Prediction;
  selected: boolean;
  visible: boolean;
  handleCreate: () => void;
  handleToggle: () => void;
  handleDelete: () => void;
}

export const PredictionRow = ({
  prediction,
  selected,
  visible,
  handleCreate,
  handleToggle,
  handleDelete,
}: PredictionRowProps) => {
  return (
    <div
      className={cn(
        'flex',
        'flex-row',
        'w-full',
        'typo-text-m',
        'text-neutral-800',
        'h-8',
        'hover:bg-neutral-3',
        selected && 'bg-neutral-4',
        'p-1',
        'b-rounded',
        'items-center',
      )}
    >
      <div className={cn('grow-2', 'hover:cursor-pointer')} onClick={handleCreate}>
        {prediction.id} - {prediction.option.value}
      </div>
      <div className={cn('flex', 'flex-1', 'flex-row', 'gap-2')}>
        <ListItemButton
          icon={<div className={cn('i-skand-eye', visible ? 'i-skand-show' : 'i-skand-hide')} />}
          onClick={handleToggle}
        />
        <ListItemButton icon={<div className={cn('i-skand-trashbin')} />} onClick={handleDelete} />
      </div>
    </div>
  );
};
