import { AnnotationFieldType } from '@/constants/annotation';
import {
  Annotation,
  AnnotationFieldInput,
  AnnotationSelectField,
  AnnotationTemplate,
  AnnotationTemplateField,
  AnnotationTemplateSelectField,
  CreateAnnotationInput,
  UpdateAnnotationInput,
} from '@/graphql/codegen/graphql';
import { persist } from '@/utils/Persist';
import { inferShapeType, setColor } from '@/utils/annotation';
import { EPSG } from '@skand/data-3d-loader';
import { MeasurementUnit } from '@skand/viewer-component-v2';
import { Color, Matrix4, Quaternion, Vector2, Vector3 } from 'three';
import { create } from 'zustand';
import { isEmpty } from '../utils/empty';
import { useAnnotationStore } from './annotation';
import { useLayout } from './layout';
import { Layer, stopDraw2D, stopDraw3D, stopViewerScreenshot, useViewer } from './viewer';

type Scenes = 'projectSelect' | 'projectExplore';

export interface AnnotationGeometry {
  sketch3D?: {
    vertices: Vector3[];
    closed: boolean;
    color: Color;
  };
  sketch2D?: {
    vertices: Vector2[];
    closed: boolean;
    photoId: string;
    color: Color;
  };
}

export interface AnnotationDraft extends CreateAnnotationInput, UpdateAnnotationInput {
  id?: null | string;
  mode: 'create' | 'update' | 'preview';
  template?: AnnotationTemplate;
}

/**
 * Hotkey settings.
 */
export interface HotKeys {
  lockSorting: string;
}

interface CurrentAnnotation {
  template: AnnotationDraft['template'];
  groupId: AnnotationDraft['groupId'];
  color: AnnotationDraft['color'];
  selectFields: AnnotationSelectField[];
}

interface ExploreState {
  scene: Scenes;
  projectId: null | string;
  measurementUnit: MeasurementUnit;
  epsg: EPSG;

  /**
   * Curently editing annotation;
   */
  annotationDraft: null | AnnotationDraft;

  /**
   * Reference annotation, used for comparison with annotation draft .
   */
  annotationOriginal: null | Annotation;
  currentAnnotation: CurrentAnnotation | null;

  hotkeys: HotKeys;
  placeLayerTarget: null | Layer;
  isShowingPlaceLayerModal: boolean;
  isShowingLocationAlertModal: boolean;

  /**
   * Store annotation options while creating.
   */
  annotationGroupIdCache: null | string;
  annotationTemplateIdCache: null | string;
  annotationSelectFieldCache: Map<string, string>;

  showPlaceLayerModal: () => void;
  hidePlaceLayerModal: () => void;
  showLocationAlertModal: () => void;
  hidelLocationAlertModal: () => void;
}

type TypeOfProps<T, K extends keyof T> = T[K];

interface UpdatedAnnotation {
  annotation2d?: TypeOfProps<AnnotationDraft, 'annotation2d'>;
  annotation3d?: TypeOfProps<AnnotationDraft, 'annotation3d'>;
  annotationId?: TypeOfProps<AnnotationDraft, 'annotationId'>;
  color?: TypeOfProps<AnnotationDraft, 'color'>;
  fields?: TypeOfProps<AnnotationDraft, 'fields'>;
  name?: TypeOfProps<AnnotationDraft, 'name'>;
}

export const useExplore = create<ExploreState>()(set => ({
  scene: 'projectSelect',
  projectId: null,
  measurementUnit: 'metric',
  epsg: persist.get('srs') ?? 'EPSG:4978',

  annotation: null,
  annotationDraft: null,
  annotationOriginal: null,
  currentAnnotation: null,
  hotkeys: {
    autoOrient3D: 'C',
    showCameras: 'X',
    lockSorting: 'Z',
  },
  isShowingPlaceLayerModal: false,
  isShowingLocationAlertModal: false,
  placeLayerTarget: null,
  annotationGroupIdCache: null,
  annotationTemplateIdCache: null,
  annotationSelectFieldCache: new Map(),
  showPlaceLayerModal: () => set({ isShowingPlaceLayerModal: true }),
  hidePlaceLayerModal: () => set({ isShowingPlaceLayerModal: false }),
  showLocationAlertModal: () => set({ isShowingLocationAlertModal: true }),
  hidelLocationAlertModal: () => set({ isShowingLocationAlertModal: false }),
}));

export const selectProject = (id: string) => {
  persist.set('project', id);
  useExplore.setState({ scene: 'projectExplore', projectId: id });
};

export const initializeExplore = () => {
  const projectId = persist.get('project');
  if (projectId) selectProject(projectId);
};

export const startCreateAnnotation = (geometry: Partial<AnnotationGeometry>, edit = false) => {
  const { annotationGroups } = useViewer.getState();
  const { annotationGroupIdCache } = useExplore.getState();

  useExplore.setState(state => {
    const annotationDraft: AnnotationDraft = {
      ...state.annotationDraft,
      mode: edit ? 'update' : 'create',
    };
    if (!isEmpty(state.currentAnnotation) && !edit) {
      annotationDraft.color = state.currentAnnotation.color;
      annotationDraft.groupId = state.currentAnnotation.groupId;

      const fields: AnnotationFieldInput[] = [];
      for (const field of state.currentAnnotation.template?.fields ?? []) {
        if (!field) continue;
        if (field.type !== 'SELECT') {
          const annoField = state.annotationDraft?.fields?.find(f => f?.fieldId === field.id) || [];
          fields.push({ ...annoField, fieldId: field.id, name: field.name, type: field.type });
        }
      }
      if (state.currentAnnotation.selectFields.length !== 0) {
        for (const field of state.currentAnnotation.selectFields) {
          fields.push(field);
        }
      }
      annotationDraft.fields = fields;
      annotationDraft.template = state.currentAnnotation.template;
      annotationDraft.templateId = state.currentAnnotation.template?.id;
    }
    if (geometry.sketch2D) {
      annotationDraft.annotation2d = {
        points: geometry.sketch2D.vertices,
        imageFileId: geometry.sketch2D.photoId,
        shapeType: inferShapeType(geometry.sketch2D.closed, geometry.sketch2D.vertices.length),
      };
      annotationDraft.color = `#${geometry.sketch2D.color.getHexString()}`;
    }
    if (geometry.sketch3D) {
      // Set first group of the array as default annotation group if there's no saved annotationGroupIdCache
      if (!annotationDraft.groupId && !annotationGroupIdCache)
        annotationDraft.groupId = annotationGroups[0]?.id;
      const group = annotationGroups.find(group => group.id === annotationDraft?.groupId);
      const inverseGroupTransform = new Matrix4()
        .compose(
          group?.sceneNode?.getPosition() ?? new Vector3(),
          group?.sceneNode?.getRotation() ?? new Quaternion(),
          group?.sceneNode?.getScale() ?? new Vector3(1, 1, 1),
        )
        .invert();
      annotationDraft.annotation3d = geometry.sketch3D && {
        positions: geometry.sketch3D.vertices.map(point =>
          point.applyMatrix4(inverseGroupTransform),
        ),
        rotations: geometry.sketch3D.vertices.map(() => ({ x: 0, y: 0, z: 0, w: 1 })),
        shapeType: inferShapeType(geometry.sketch3D.closed, geometry.sketch3D.vertices.length),
      };

      annotationDraft.color = `#${geometry.sketch3D.color.getHexString()}`;
    }
    return { annotationDraft, annotationOriginal: null };
  });
  useLayout.getState().showRightSideBar();
};

export const startUpdateAnnotation = (annotation: Annotation, template: AnnotationTemplate) => {
  useExplore.setState(state => {
    if (!template.colorFromField && state.annotationOriginal?.color)
      editAnnotationSetColor(state.annotationOriginal?.color);

    return {
      annotationDraft: { ...annotation, mode: 'update', template },
      annotationOriginal: annotation,
    };
  });
  if (annotation.annotationId)
    useAnnotationStore.getState().setActiveAnnotationId(annotation.annotationId);
  useLayout.getState().showRightSideBar();
};

export const startPreviewAnnotation = (annotation: Annotation, template: AnnotationTemplate) => {
  useExplore.setState(state => {
    if (!template.colorFromField && state.annotationOriginal?.color)
      editAnnotationSetColor(state.annotationOriginal?.color);

    return {
      annotationDraft: { ...annotation, mode: 'preview', template },
      annotationOriginal: annotation,
    };
  });
  if (annotation.annotationId)
    useAnnotationStore.getState().setActiveAnnotationId(annotation.annotationId);
  useLayout.getState().showRightSideBar();
};

export const editAnnotationSetTemplate = (template: AnnotationTemplate) => {
  useExplore.setState(state => {
    if (!state.annotationDraft) return {};

    const fields: AnnotationFieldInput[] = [];

    for (const field of template?.fields ?? []) {
      if (!field) continue;
      const annoField = state.annotationDraft?.fields?.find(f => f?.fieldId === field.id) || [];
      fields.push({ ...annoField, fieldId: field.id, name: field.name, type: field.type });
    }

    return {
      annotationDraft: {
        ...state.annotationDraft,
        fields,
        template,
        templateId: template.id,
      },
    };
  });
};

export const editAnnotationSetField = (
  field: AnnotationTemplateField,
  type: AnnotationFieldType,
  payload: Omit<AnnotationFieldInput, 'name' | 'type'>,
) => {
  useExplore.setState(state => {
    if (!state.annotationDraft) return {};

    let isFieldExisting = false;
    const fields: AnnotationFieldInput[] = [];
    for (const f of state.annotationDraft?.fields || []) {
      if (!f) continue;
      if (f.fieldId === field.id) {
        isFieldExisting = true;
        fields.push({ ...f, ...payload, type });
      } else fields.push(f);
    }
    if (!isFieldExisting) {
      const dummyField = { fieldId: field.id, name: field.name, type: field.type };
      fields.push({ ...dummyField, ...payload, type });
    }

    return {
      annotationDraft: {
        ...state.annotationDraft,
        fields,
      },
    };
  });
};

/**
 * Same as editAnnotationSetField but use a callback to update
 * @param field
 * @param updater
 */
export const editAnnotationUpdateField = (
  field: AnnotationTemplateField,
  type: AnnotationFieldType,
  updater: (prev: AnnotationTemplateField) => Omit<AnnotationFieldInput, 'name' | 'type'>,
) => {
  useExplore.setState(state => {
    if (!state.annotationDraft) return {};

    let isFieldExisting = false;
    const fields: AnnotationFieldInput[] = [];
    for (const f of state.annotationDraft?.fields || []) {
      if (!f) continue;
      if (f.fieldId === field.id) {
        isFieldExisting = true;
        fields.push({ ...f, ...updater(f), type });
      } else fields.push(f);
    }
    if (!isFieldExisting) {
      const dummyField = { fieldId: field.id, name: field.name, type: field.type };
      fields.push({ ...dummyField, ...updater(dummyField), type });
    }

    return {
      annotationDraft: {
        ...state.annotationDraft,
        fields,
      },
    };
  });
};

export const editAnnotationSetName = (name: string) => {
  useExplore.setState(state => {
    if (!state.annotationDraft) return {};

    return {
      annotationDraft: {
        ...state.annotationDraft,
        name,
      },
    };
  });
};

export const editAnnotationSetColor = (color: string) => {
  const api2D = useViewer.getState().api2D;
  const api3D = useViewer.getState().api3D;
  const viewerAnnotationGroups = useViewer.getState().annotationGroups;

  useExplore.setState(state => {
    const { annotationDraft } = state;
    if (!annotationDraft) return {};

    const viewerAnnotationGroup = viewerAnnotationGroups.find(
      group => group.id === annotationDraft.groupId,
    );
    const viewerAnnotation = viewerAnnotationGroup?.annotations.find(
      annotation => annotation.versionId === annotationDraft.id,
    );

    api3D?.draw.setColor(new Color(color));
    api2D?.editor.getDrawController().setColor(new Color(color));
    if (annotationDraft?.groupId && annotationDraft?.id && viewerAnnotation) {
      setColor(viewerAnnotation, color);
    }

    return {
      annotationDraft: {
        ...annotationDraft,
        color,
      },
    };
  });
};

export const editAnnotationSetGroup = (groupId: string) => {
  const { annotationGroups } = useViewer.getState();
  useExplore.setState(state => {
    if (!state.annotationDraft) return {};

    // Update relative positions of annotation
    const currGroup = annotationGroups.find(group => group.id === state.annotationDraft?.groupId);
    const nextGroup = annotationGroups.find(group => group.id === groupId);
    const transform = new Matrix4().compose(
      currGroup?.sceneNode?.getPosition() ?? new Vector3(),
      currGroup?.sceneNode?.getRotation() ?? new Quaternion(),
      currGroup?.sceneNode?.getScale() ?? new Vector3(1, 1, 1),
    );
    const worldPositions = state.annotationDraft?.annotation3d?.positions?.map(point =>
      new Vector3(point?.x ?? 0, point?.y ?? 0, point?.z ?? 0).applyMatrix4(transform),
    );
    transform
      .compose(
        nextGroup?.sceneNode?.getPosition() ?? new Vector3(),
        nextGroup?.sceneNode?.getRotation() ?? new Quaternion(),
        nextGroup?.sceneNode?.getScale() ?? new Vector3(1, 1, 1),
      )
      .invert();
    state.annotationDraft.groupId = groupId;
    if (state.annotationDraft.annotation3d) {
      state.annotationDraft.annotation3d = {
        ...state.annotationDraft.annotation3d,
        positions: worldPositions?.map(point => point.applyMatrix4(transform)),
      };
    }
    return {
      annotationDraft: { ...state.annotationDraft },
    };
  });
};

export const cancelEditAnnotation = () => {
  useExplore.setState(state => {
    const { annotationOriginal, annotationDraft } = state;

    stopDraw2D();
    stopDraw3D();
    stopViewerScreenshot();

    // Clear annotation from persistent storage
    persist.clear('annotation');
    persist.url.refreshURL();

    if (!isEmpty(annotationDraft) && !isEmpty(annotationOriginal)) {
      // Check current active annotation color by either Template or Field Custom
      if (annotationDraft.template?.colorFromField === null && annotationOriginal.color) {
        // If it's by Field cusotom, set original color
        editAnnotationSetColor(annotationOriginal.color);
      } else {
        // If it's by Template, set selected template color
        const templateColorSourceField = annotationDraft.template?.fields?.find(
          field => field && field.name === annotationDraft.template?.colorFromField,
        );
        const colorSourceField = annotationOriginal.fields?.find(
          field => field && field.fieldId === templateColorSourceField?.id,
        );

        if (
          !isEmpty(colorSourceField) &&
          !isEmpty(annotationDraft.fields) &&
          !isEmpty(annotationDraft.template) &&
          !isEmpty(annotationDraft.template.fields)
        ) {
          for (const field of annotationDraft.template.fields) {
            if (field?.type === 'SELECT' && colorSourceField.fieldId === field.id) {
              const annotationField = colorSourceField as AnnotationSelectField;
              const templateField = field as AnnotationTemplateSelectField;
              const activeColorOption = templateField?.options?.find(
                option => option?.id === annotationField.optionId,
              );
              if (activeColorOption) {
                editAnnotationSetColor(activeColorOption.color as string);
              }
            }
          }
        }
      }
    }
    return { annotationDraft: null, annotationOriginal: null };
  });

  useLayout.getState().hideRightSideBar();
};

export const saveEditAnnotation = (id?: string) => {
  stopDraw2D();
  stopDraw3D();
  stopViewerScreenshot();
  useExplore.setState({ annotationDraft: null, annotationOriginal: null });
  useLayout.getState().hideRightSideBar();
  if (id) useAnnotationStore.getState().setActiveAnnotationId(id);
};

export const setMeasurementUnit = (measurementUnit: MeasurementUnit) => {
  useExplore.setState({ measurementUnit });
};

export const setEPSG = (epsg: EPSG) => {
  useExplore.setState({ epsg });
};

export const updateAnnotationDraft = (updatedAnnotation: UpdatedAnnotation) => {
  useExplore.setState(state => {
    if (!state.annotationDraft) return {};
    return {
      annotationDraft: {
        ...state.annotationDraft,
        annotation2d: updatedAnnotation.annotation2d ?? state.annotationDraft.annotation2d,
        annotation3d: updatedAnnotation.annotation3d ?? state.annotationDraft.annotation3d,
        annotationId: updatedAnnotation.annotationId ?? state.annotationDraft.annotationId,
        color: updatedAnnotation.color ?? state.annotationDraft.color,
        fields: updatedAnnotation.fields ?? state.annotationDraft.fields,
        name: updatedAnnotation.name ?? state.annotationDraft.name,
      },
    };
  });
};

export const setCurrentAnnotation = (
  color: AnnotationDraft['color'],
  template: AnnotationDraft['template'],
  groupId: AnnotationDraft['groupId'],
  selectFields: AnnotationSelectField[],
) => {
  useExplore.setState({
    currentAnnotation: {
      color,
      template,
      groupId,
      selectFields,
    },
  });
};
