import { MoreMenu } from '@/LegacyExplore/components/MoreMenu';
import { ANNOTATION_TEMPLATE_CREATED_BY } from '@/LegacyExplore/constants/annotation';
import { client, queryClient } from '@/LegacyExplore/graphql/client';
import { AnnotationTemplate } from '@/LegacyExplore/graphql/codegen/graphql';
import {
  DELETE_ANNOTATION_TEMPLATE,
  LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID,
} from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useAnnotationTemplates } from '@/LegacyExplore/hooks/useAnnotationTemplates';
import { useFetchCountTemplateAnnotations } from '@/LegacyExplore/hooks/useFetchCountTemplateAnnotations';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/LegacyExplore/pages/ProjectPage/DeleteConfirmationModal';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { useLayout } from '@/LegacyExplore/stores/layout';
import { updateTemplate, useTemplate, viewTemplate } from '@/LegacyExplore/stores/template';
import { cn } from '@/LegacyExplore/utils/classname';
import { Button, Menu, MenuItem } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

export interface TemplateItemProps {
  template: SolidId<AnnotationTemplate>;
  disabled?: boolean;
  isSuperAdmin?: boolean;
}

export const TemplateItem = ({
  template,
  disabled = false,
  isSuperAdmin = false,
}: TemplateItemProps) => {
  const hideTemplatePanel = useLayout(state => state.hideTemplatePanel);
  const showTemplatePanel = useLayout(state => state.showTemplatePanel);
  const currentTemplate = useTemplate(state => state.template);
  const createdAt = dayjs(template.createdAt).format('DD/MM/YYYY');
  const updatedAt = dayjs(template.updatedAt).format('DD/MM/YYYY');
  const projectId = useExplore(state => state.projectId);

  const fetchAnnotationCount = useFetchCountTemplateAnnotations(template.id);

  const isCurrent = currentTemplate?.id === template.id;
  const isDefaultTemplate = template.createdBy === ANNOTATION_TEMPLATE_CREATED_BY.SYSTEM;

  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const annotationCount = useMemo(() => {
    return fetchAnnotationCount();
  }, [fetchAnnotationCount]);

  const handleEditOrView = useCallback(() => {
    hideTemplatePanel();

    if (template.createdBy === ANNOTATION_TEMPLATE_CREATED_BY.SYSTEM && !isSuperAdmin)
      viewTemplate(template);
    else updateTemplate(template);

    showTemplatePanel();
  }, [hideTemplatePanel, showTemplatePanel, template, isSuperAdmin]);

  const deleteAnnotationTemplate = useMutation({
    mutationFn: (annotationTemplateId: string) =>
      request(DELETE_ANNOTATION_TEMPLATE, {
        annotationTemplateId,
      }),
    onSuccess: () => {
      hideTemplatePanel();
      client.refetchQueries({
        include: [LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID],
      });
      queryClient.invalidateQueries(useAnnotationTemplates.getQueryKey(projectId));
      queryClient.invalidateQueries(['LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID', projectId]);
    },
  });

  const handleDeleteAnnotationTemplate = useCallback(() => {
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Annotation Template',
      description: `Are you sure you want to delete the annotation template ${template.name} ?`,
      actionButton: 'Delete Annotation Template',
      actionFunction: e => {
        e.stopPropagation();
        deleteAnnotationTemplate.mutate(template.id);
        setOpenConfirmationModal(state => {
          return { ...state, isOpen: false };
        });
      },
    });
  }, [deleteAnnotationTemplate, template]);

  return (
    <div
      className={cn(
        'mt-3 rounded-2 ring-inset p-3 first:mt-0 cursor-pointer',
        isCurrent ? 'ring-primary-400 ring-2' : 'ring-neutral-400 ring-1',
      )}
      key={template.id}
      onClick={handleEditOrView}
    >
      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <div className="flex items-center">
        <div className="flex flex-1 flex-row overflow-hidden">
          <span
            className="truncate text-ellipsis color-neutral-800 typo-text-s"
            title={template?.name ?? ''}
          >
            {template?.name}{' '}
          </span>

          {isDefaultTemplate && (
            <span className="color-neutral-400 typo-text-s">- System default</span>
          )}
        </div>

        <div className="flex gap-2">
          <Button active={isCurrent} onClick={handleEditOrView} size="xs">
            {isDefaultTemplate || disabled ? 'View' : 'Edit'}
          </Button>

          {!disabled && (
            <span
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <MoreMenu>
                <Menu className="z-1">
                  <MenuItem disabled={annotationCount > 0} onClick={handleDeleteAnnotationTemplate}>
                    Delete
                  </MenuItem>
                </Menu>
              </MoreMenu>
            </span>
          )}
        </div>
      </div>

      <div className="mt-3">
        <span className="color-neutral-800 underline typo-link-xs">
          Used in {annotationCount} annotations
        </span>
        <span className="px-2 color-neutral-300">|</span>
        <span className="color-neutral-500 typo-text-xs">Created {createdAt}</span>
        <span className="px-2 color-neutral-300">|</span>
        <span className="color-neutral-500 typo-text-xs">Updated {updatedAt}</span>
      </div>
    </div>
  );
};
