import { DropdownSelector, ItemProps } from '@/components/DropdownSelector';
import { useFetchProject } from '@/hooks/useFetchProject';
import { setEPSG, setMeasurementUnit, useExplore } from '@/stores/explore';
import {
  addViewerEventListener,
  removeViewerEventListener,
  setDataAttribution,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { warn } from '@/utils/logger';
import { GOOGLE_EARTH_ACCOUNT, GOOGLE_EARTH_USER } from '@/utils/split';
import { EPSG, toProjected } from '@skand/data-3d-loader';
import { CursorListener, MeasurementUnit, lengthToString } from '@skand/viewer-component-v2';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect, useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { epsgCodeList } from './epsgCodeList';
import { measurmentUnitList } from './measurmentUnitList';

export interface EpsgCodeList {
  label: string;
  value: EPSG;
}

export interface MeasurementUnitList {
  label: string;
  value: MeasurementUnit;
}

export const InfoBar = () => {
  const measurementUnit = useExplore(state => state.measurementUnit);
  const epsg = useExplore(state => state.epsg);
  const enabledDataAttribution = useViewer(state => state.enabledDataAttribution);
  const treatment = useTreatments([GOOGLE_EARTH_USER, GOOGLE_EARTH_ACCOUNT]);
  const googleEarthGlobeFlag =
    treatment[GOOGLE_EARTH_USER].treatment === 'on' ||
    treatment[GOOGLE_EARTH_ACCOUNT].treatment === 'on';

  const { fetch: fetchProject } = useFetchProject();

  const [mousePosition, setMousePosition] = useState(new Vector3());
  const [geoid, setGeoid] = useState('');

  const [enableUnitsMenu, setEnableUnitsMenu] = useState(false);
  const [enableEPSGMenu, setEnableEPSGMenu] = useState(false);

  // Update the geoid
  useEffect(() => {
    fetchProject().then(project => {
      if (project) {
        setGeoid(project.geoid);
      }
    });
  }, [fetchProject]);

  // Handle listener for mouse position
  useEffect(() => {
    const handleCursorTap: CursorListener = async position => {
      try {
        const epsgTokens = epsg.split('-');
        const projected = (
          await toProjected([position], epsgTokens[0] as EPSG, epsgTokens.length > 1)
        )[0];
        setMousePosition(projected);
      } catch (e) {
        warn(e);
      }
    };
    addViewerEventListener('onCursorHover', handleCursorTap);
    return () => {
      removeViewerEventListener('onCursorHover', handleCursorTap);
    };
  }, [epsg]);

  // Handle selecting unit of measurement
  const handleUnitClick = (item: ItemProps) => {
    setMeasurementUnit(item.value as MeasurementUnit);
    setEnableUnitsMenu(false);
  };

  // Handle selecting EPSG code
  const handleEPSGClick = (item: ItemProps) => {
    setEPSG(`EPSG:${item.value}` as EPSG);
    setEnableEPSGMenu(false);
  };

  const toggleUnitsMenu = () => {
    setEnableEPSGMenu(false);
    setEnableUnitsMenu(prev => !prev);
  };

  const toggleEPSGMenu = () => {
    setEnableUnitsMenu(false);
    setEnableEPSGMenu(prev => !prev);
  };

  const eastingString = useMemo(() => {
    if (epsg === 'EPSG:4978') {
      return `X ${lengthToString(mousePosition.x, measurementUnit, 5)}`;
    } else if (epsg === 'EPSG:4326') {
      return `LNG ${mousePosition.x.toFixed(5)}`;
    } else {
      return `E ${lengthToString(mousePosition.x, measurementUnit, 5)}`;
    }
  }, [epsg, measurementUnit, mousePosition.x]);

  const northingString = useMemo(() => {
    if (epsg === 'EPSG:4978') {
      return `Y ${lengthToString(mousePosition.y, measurementUnit, 5)}`;
    } else if (epsg === 'EPSG:4326') {
      return `LAT ${mousePosition.y.toFixed(5)}`;
    } else {
      return `N ${lengthToString(mousePosition.y, measurementUnit, 5)}`;
    }
  }, [epsg, measurementUnit, mousePosition.y]);

  const elevationString = useMemo(() => {
    if (epsg === 'EPSG:4978') {
      return `Z ${lengthToString(mousePosition.z, measurementUnit, 2)}`;
    } else {
      return `ELEVATION ${lengthToString(mousePosition.z, measurementUnit, 2)}`;
    }
  }, [epsg, measurementUnit, mousePosition.z]);

  const epsgString = useMemo(() => {
    const code = epsg.split(':')[1];
    return epsgCodeList.find(key => key.value === code)?.label || '';
  }, [epsg]);

  return (
    <div
      className={cn(
        'bg-neutral-800',
        'bg-opacity-50',
        'color-neutral-400',
        'typo-text-s',
        'flex flex-wrap',
        'py-0.5 px-1 mr-30px',
        'absolute',
        'bottom-0',
        'left-0',
        'rounded-tr-1',
      )}
    >
      <div className={cn('relative cursor-pointer pr-2 pointer-events-auto whitespace-nowrap')}>
        <p className={cn('text-neutral-100 underline')} onClick={toggleEPSGMenu}>
          {epsgString}
        </p>
        {enableEPSGMenu && (
          <DropdownSelector enableSearch items={epsgCodeList} onItemClick={handleEPSGClick} />
        )}
      </div>

      <div className={cn('relative cursor-pointer pr-2 pointer-events-auto')}>
        <div className={cn('flex flex-nowrap')} onClick={toggleUnitsMenu}>
          <p className={cn('text-neutral-100 underline p-r-1')}>{measurementUnit.toUpperCase()}</p>
          <div className="i-skand-dropdown m-b-auto m-t-auto text-size-1.5 text-neutral-100" />
        </div>
        {enableUnitsMenu && (
          <DropdownSelector items={measurmentUnitList} onItemClick={handleUnitClick} />
        )}
      </div>

      <div className="flex flex-wrap">
        <p className="whitespace-nowrap pr-2">{geoid.toUpperCase()}</p>
        <p className="whitespace-nowrap pr-2">{eastingString}</p>
        <p className="whitespace-nowrap pr-2">{northingString}</p>
        <p>{elevationString}</p>
        {googleEarthGlobeFlag && (
          <div
            className={cn('flex relative cursor-pointer p-r-2 pointer-events-auto items-center')}
          >
            <p
              className={cn('text-neutral-100 pl-2 typo-text-xs')}
              onClick={() => {
                setDataAttribution(!enabledDataAttribution);
              }}
            >
              DATA ATTRIBUTION
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
