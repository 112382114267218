import { useAccountAnnotationTemplates } from '@/LegacyExplore/hooks/useAccountAnnotationTemplates';
import { copyTemplate } from '@/LegacyExplore/stores/template';
import { Select } from '@skand/ui';

export const TemplateCopy = () => {
  const { templates } = useAccountAnnotationTemplates();

  const options = templates.map(template => ({
    name: template.name ?? '',
    key: template.id,
  }));

  const handleChange = (key: string) => {
    const template = templates.find(t => t.id === key);
    if (template) copyTemplate(template);
  };

  return (
    <>
      <p className="mt-6 color-neutral-800 typo-text-m">Select from existing templates</p>
      <Select
        className="mt-3 w-50%"
        label="Select template"
        onChange={handleChange}
        options={options}
        width="full"
      />
    </>
  );
};
