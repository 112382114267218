import { MoreMenu } from '@/LegacyExplore/components/MoreMenu';
import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { UNGROUPED_GROUP_ID } from '@/LegacyExplore/constants/project';
import { queryClient } from '@/LegacyExplore/graphql/client';
import { DELETE_PROJECT_GROUP } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useFetchProjectPermissions } from '@/LegacyExplore/hooks/useFetchProjectPermissions';
import { useFetchProjects } from '@/LegacyExplore/hooks/useFetchProjects';
import { setEditGroupGroup, toggleGroup, useProjectStore } from '@/LegacyExplore/stores/project';
import { cn } from '@/LegacyExplore/utils/classname';
import { ProjectGroup } from '@/LegacyExplore/utils/project';
import * as Dialog from '@radix-ui/react-dialog';
import { Menu, MenuItem } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { ConfirmationModalState, DeleteConfirmationModal } from '../DeleteConfirmationModal';
import { EditGroup } from '../EditGroup';

export interface GroupRowProps {
  group?: ProjectGroup;
}

export const GroupRow = ({ group }: GroupRowProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });
  const [rowWidth, setRowWidth] = useState(0);

  const { getGroupPermission } = useFetchProjectPermissions();
  const groupPermission = getGroupPermission(group?.id ?? null);

  const currentGroup = useProjectStore(state => state.editGroup.group);
  const open = group?.id === currentGroup?.id;
  const setOpen = (open: boolean) => {
    if (group) setEditGroupGroup(open ? group : null);
  };

  const isFolded = useProjectStore(
    state => !!state.table.foldState[group?.id ?? UNGROUPED_GROUP_ID],
  );

  const name = group?.name ?? 'Ungrouped Projects';
  const date = group?.createdAt?.toLocaleDateString() ?? null;

  // Delete group mutation
  const deleteGroup = useMutation({
    mutationFn: () => request(DELETE_PROJECT_GROUP, { projectGroupId: group?.id as string }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
    },
  });

  // Handle deleting a group
  const handleDeleteGroup = useCallback(() => {
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Project Group',
      description: `Are you sure you want to delete this group ${name}?`,
      actionButton: 'Delete Project Group',
      actionFunction: () => {
        deleteGroup.mutate();
        setOpenConfirmationModal(state => {
          return { ...state, isOpen: false };
        });
      },
    });
  }, [name, deleteGroup]);

  // Listen for container resize
  useEffect(() => {
    if (container) {
      const observer = new ResizeObserver(entries => {
        setRowWidth(entries[0].contentRect.width);
      });
      observer.observe(container);
      return () => container && observer.unobserve(container);
    }
  }, [container]);

  const actions = group ? (
    <div
      className="ml-auto w-fit flex items-center justify-end gap-3"
      onClick={e => e.stopPropagation()}
    >
      <Dialog.Root onOpenChange={setOpen} open={open}>
        <Dialog.Portal>
          <Dialog.Overlay />
          {open && <EditGroup />}
        </Dialog.Portal>
      </Dialog.Root>

      <MoreMenu>
        <Menu className="z-1 w-96px">
          <MenuItem onClick={() => setOpen(true)}>View details</MenuItem>
          {canPolicyActionEdit(groupPermission) && (
            <MenuItem onClick={handleDeleteGroup}>Delete</MenuItem>
          )}
        </Menu>
      </MoreMenu>
    </div>
  ) : null;

  return (
    <div
      className={cn(
        'b-neutral-200',
        'b-solid',
        group ? 'bg-neutral-200' : 'bg-neutral-100 pb-2.5',
        'pt-6px',
        'min-h-44px',
        'p-x-4',
        'w-full',
        'flex',
        'justify-center',
        'items-center',
        'rounded-tr-2',
        'rounded-tl-2',
        !group && isFolded && 'rounded-bl-2 rounded-br-2',
      )}
      onClick={e => {
        e.stopPropagation();
        toggleGroup(group?.id ?? UNGROUPED_GROUP_ID);
      }}
      ref={setContainer}
    >
      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <div className={cn('w-35%')}>
        <div className="flex flex-1 items-center">
          <div
            className={cn(
              'i-skand-dropdown mr-4 text-3 color-neutral-600 cursor-pointer',
              isFolded && 'rotate-180',
            )}
            onClick={e => {
              e.stopPropagation();
              toggleGroup(group?.id ?? UNGROUPED_GROUP_ID);
            }}
          />
          <div className="color-neutral-800 typo-text-m">{name}</div>
        </div>
      </div>

      <div className={cn('text-left', rowWidth < 480 && 'hidden')}>
        <span className="color-neutral-800 typo-text-s">{date}</span>
      </div>
      <div className={cn('flex-1')}>{actions}</div>
    </div>
  );
};
