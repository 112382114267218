import { Checkbox } from '@/components/Checkbox';
import { InputSlider } from '@/components/SceneTab/Menus/InputSlider';
import { PhotoSortMode, setViewer2DSettings, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, Dropdown, DropdownItem, Input, Switch } from '@skand/ui';
import { useState } from 'react';

const SORT_MODES: PhotoSortMode[] = [
  'name',
  'relevance to point',
  'similarity to camera',
  'weighted similarity to camera',
  'projected point',
  'distance to camera',
  'distance to clicked point',
];

export const Viewer2DMenu = () => {
  const viewer2DSettings = useViewer(state => state.viewer2DSettings);

  const [count, setCount] = useState(viewer2DSettings.count);
  const [showAll, setShowAll] = useState(viewer2DSettings.showAll);
  const [sortWeight, setSortWeight] = useState(viewer2DSettings.sortWeight);
  const [gapSize, setGapSize] = useState(viewer2DSettings.gapSize);
  const [sortMode, setSortMode] = useState(viewer2DSettings.sortMode);

  const [dropdown, setDropdown] = useState<HTMLElement | null>(null);

  // Handle selecting a sort mode
  const handleSortModeSelect = (mode: PhotoSortMode) => {
    setSortMode(mode);

    dropdown?.focus();
    dropdown?.blur();
  };

  // Handle applying settings that affect count and sort
  const handleApply = () => {
    setViewer2DSettings({ count, showAll, sortWeight, gapSize, sortMode });
  };

  // Check if any of the deferred update settings have changed
  const isDirty =
    count !== viewer2DSettings.count ||
    showAll !== viewer2DSettings.showAll ||
    sortWeight !== viewer2DSettings.sortWeight ||
    gapSize !== viewer2DSettings.gapSize ||
    sortMode !== viewer2DSettings.sortMode;

  return (
    <div className={'w-full h-full'}>
      <div className={cn('flex', 'flex-col', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800', 'mb-2')}>
          Show number of images
        </span>
        <div className="w-full flex flex-row items-center justify-between gap-4">
          <div className="flex-1">
            <Input
              onChange={value => setCount(value.length ? parseInt(value) : 0)}
              type="number"
              value={count}
            />
          </div>
          <div className="flex flex-row gap-2">
            <Checkbox checked={showAll} setToggleCheckbox={() => setShowAll(!showAll)} />
            <p className={cn('typo-text-small text-neutral-800')}>All</p>
          </div>
        </div>
      </div>

      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start')}>
        <InputSlider
          className={'typo-text-s-em w-full'}
          max={50}
          min={0}
          setValue={setGapSize}
          step={1}
          title="Minimum gap distance (m)"
          value={gapSize}
        />
      </div>

      <div className={cn('flex', 'flex-col', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Sort mode</span>
        <Dropdown
          className={cn('z-10 w-full mt-12px capitalize')}
          label={sortMode}
          ref={setDropdown}
        >
          {SORT_MODES.map((mode, index) => (
            <DropdownItem key={index} onClick={() => handleSortModeSelect(mode as PhotoSortMode)}>
              {SORT_MODES[index]}
            </DropdownItem>
          ))}
        </Dropdown>
      </div>

      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start')}>
        <InputSlider
          className={'typo-text-s-em w-full'}
          max={1}
          min={0}
          setValue={setSortWeight}
          step={0.01}
          title="Distance-angle weight"
          value={sortWeight}
        />
      </div>

      <Button
        className="mt-12px w-full"
        disabled={!isDirty}
        filled
        onClick={handleApply}
        primary
        size="s"
      >
        Apply
      </Button>

      <div className={cn('border-1px', 'border-solid', 'border-b-neutral-500', 'mt-12px')} />

      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Lock Sorting</span>
        <Switch
          checked={viewer2DSettings.lockSorting}
          onChange={value => setViewer2DSettings({ lockSorting: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Panorama icon size</span>
        <input
          className={cn(
            'flex',
            'justify-center',
            'text-center',
            'border-solid',
            'border-neutral-400',
            'b-1px',
            'rounded-4px',
            'w-36px',
            'h-28px',
            'typo-text-small',
            'text-neutral-700',
          )}
          onChange={e =>
            setViewer2DSettings({ panoramaIconSize: Math.max(0, parseFloat(e.target.value)) })
          }
          step={0.1}
          type="number"
          value={viewer2DSettings.panoramaIconSize}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Panorama camera height</span>
        <input
          className={cn(
            'flex',
            'justify-center',
            'text-center',
            'border-solid',
            'border-neutral-400',
            'b-1px',
            'rounded-4px',
            'w-36px',
            'h-28px',
            'typo-text-small',
            'text-neutral-700',
          )}
          onChange={e =>
            setViewer2DSettings({ panoramaCameraHeight: Math.max(0, parseFloat(e.target.value)) })
          }
          step={0.1}
          type="number"
          value={viewer2DSettings.panoramaCameraHeight}
        />
      </div>
    </div>
  );
};
