import { Controls3DIcon, PlaceIcon } from '@/LegacyExplore/components/IconButton';
import { useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { Button } from '@skand/ui';
import { TransformTool, Transformable } from '@skand/viewer-component-v2';
import { useEffect, useState } from 'react';
import { Quaternion, Vector3 } from 'three';

export interface TransformControlProps {
  model: Transformable;
  setTransform: (position: Vector3, rotation: Quaternion) => void;
}

export const TransformControl = ({ model, setTransform }: TransformControlProps) => {
  const api3D = useViewer(state => state.api3D);

  const [mode, setMode] = useState<'point' | 'widget' | null>(null);

  // Refresh tool here if model changes
  useEffect(() => {
    let tool: TransformTool | null = null;
    if (api3D) {
      if (mode === 'point') {
        api3D.transform.createPointTool(model).then(newTool => {
          newTool.start(setTransform);
          tool = newTool;
        });
      } else if (mode === 'widget') {
        tool = api3D.transform.createWidgetTool(model);
        tool.start(setTransform);
      }
    }
    return () => {
      tool?.stop();
    };
  }, [api3D, mode, model, setTransform]);

  // Handle toggling the widget tool
  const toggleWidgetTool = () => {
    if (api3D) {
      if (mode === 'widget') {
        setMode(null);
      } else {
        setMode('widget');
      }
    }
  };

  // Handle toggling the point tool
  const togglePointTool = async () => {
    if (api3D) {
      if (mode === 'point') {
        setMode(null);
      } else {
        setMode('point');
      }
    }
  };

  return (
    <div>
      <p className={cn('typo-text-small text-neutral-800 mt-10px')}>Transform tools</p>
      <div className={cn('flex items-center mt-8px gap-1 ')}>
        <Button
          className="h-28px w-99px flex flex-1 flex-grow items-center justify-center gap-2 whitespace-nowrap px-2 py-5"
          disabled={mode === 'point'}
          filled={mode === 'widget'}
          onClick={toggleWidgetTool}
          primary={mode === 'widget'}
          style={{ padding: 0 }}
        >
          <Controls3DIcon />
          <p className="typo-text-small">3D controls</p>
        </Button>
        <Button
          className="h-28px w-99px flex flex-1 flex-grow items-center justify-center gap-2 whitespace-nowrap px-2 py-5"
          disabled={mode === 'widget'}
          filled={mode === 'point'}
          onClick={togglePointTool}
          primary={mode === 'point'}
          style={{ padding: 0 }}
        >
          <PlaceIcon />
          <p className="typo-text-small">Place in 3D</p>
        </Button>
      </div>
    </div>
  );
};
