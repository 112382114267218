import { Checkbox } from '@/LegacyExplore/components/Checkbox';
import { SceneEntity, SceneEntityTree } from '@/LegacyExplore/components/SceneEntityTree';
import { Annotation, Layer, LayerGroup, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { getAllSceneEntityIds } from '@/LegacyExplore/utils/sceneTree';
import { Button, Modal, TreeNodeProps } from '@skand/ui';
import { useCallback, useMemo, useState } from 'react';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { LayerGroupNode } from './LayerGroupNode';
import { LayerNode } from './LayerNode';

interface AnnotationPermissionMenuProps {
  cancelAction: () => void;
  annotations: Annotation[];
  type: string;
  proceedAction: () => void;
  sceneEntityIdsWithoutPermissions: SceneEntity['entity']['id'][];
}

export const AnnotationPermissionMenu = ({
  annotations,
  cancelAction,
  proceedAction,
  type,
  sceneEntityIdsWithoutPermissions,
}: AnnotationPermissionMenuProps) => {
  const [targetLayer, setTargetLayer] = useState<Layer | LayerGroup | null>(null);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const layerGroups = useViewer(state => state.layerGroups);
  const layers = useViewer(state => state.layers);
  const [checked, setChecked] = useState(false);

  // Define Tree node component
  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<SceneEntity>) => (
      <div
        className={cn(
          'inline-flex flex-row items-center gap-2 py-1 pr-2 h-7 w-full',
          targetLayer?.id === data.entity.id && 'bg-neutral-200',
        )}
        style={{ marginLeft: `${depth * 0.5}rem` }}
      >
        <div
          className={cn(
            !isLeaf ? 'i-skand-dropdownarrow cursor-pointer' : 'h-1em',
            'min-w-1em',
            'text-neutral-400',
            'text-3',
            'mr-2',

            !isOpen && 'rotate-270',
          )}
          onClick={() => setOpen(!isOpen)}
        />
        {data.entity.type === 'layerGroup' && (
          <LayerGroupNode group={data.entity} setTargetLayer={setTargetLayer} />
        )}
        {data.entity.type === 'layer' && (
          <LayerNode layer={data.entity} setTargetLayer={setTargetLayer} />
        )}
        {data.entity.type === 'annotation' && <AnnotationNode annotation={data.entity} />}
        {data.entity.type === 'annotationGroup' && <AnnotationGroupNode group={data.entity} />}
      </div>
    ),
    [targetLayer],
  );

  // Get all sceneEntityIds of selected annotation group including ascendants
  const selectedAnnoGroupSEIds = useMemo(() => {
    const sceneEntityIds: SceneEntity['entity']['id'][] = [];

    for (const annotation of annotations) {
      sceneEntityIds.push(annotation.group.sceneEntityId);
    }

    const entities = [...layerGroups, ...layers, ...annotationGroups];
    const filteredEntities = entities.filter(group => sceneEntityIds.includes(group.sceneEntityId));

    return [...new Set(getAllSceneEntityIds(filteredEntities))];
  }, [annotationGroups, annotations, layerGroups, layers]);

  const [selectedAnnotationIds] = useMemo(() => {
    const selectedAnnotationIds = [];

    for (const annotation of annotations) {
      selectedAnnotationIds.push(annotation.id);
    }
    return [selectedAnnotationIds];
  }, [annotations]);

  return (
    <Modal className="h-[524px] w-[692px] flex">
      <></>
      <div className="flex flex-1 flex-col px-6 pb-6 pt-7">
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <p className="color-neutral-800 typo-text-m">
              Warning: {sceneEntityIdsWithoutPermissions.length} annotations cannot be {''}
              {type === 'delete' ? 'deleted' : 'moved to another group'}
            </p>
          </div>
          <p className="mb-3 color-neutral-800 typo-text-s">
            You can only {type === 'delete' ? 'delete' : 'move'} annotations for which you have
            permission rights.
          </p>
        </div>
        <div
          className={cn(
            'h-[310px] flex flex-col rounded-lg gap-[10px]	p-3 border-[1px] border-solid border-neutral-400',
          )}
        >
          <div
            className={cn(
              'px-3 py2 bg-warning-100 border border-skandI-400 border-solid	rounded flex flex-row',
            )}
          >
            <div className="flex items-center gap-[14px]">
              <div className="i-skand-warn text-[20px] text-skandI-400" />
              <div>
                <p className="color-neutral-800 typo-text-s">
                  <strong className="font-bold color-skandI-400 typo-text-s">
                    Permission issues ({sceneEntityIdsWithoutPermissions.length}):
                  </strong>{' '}
                  Some of the selected annotations have encountered permission issues. As a result,
                  these annotations cannot be{' '}
                  {type === 'delete' ? 'deleted' : 'moved to another group'}.
                </p>
              </div>
            </div>
          </div>

          <div
            className={cn(
              'flex flex-row items-center border-b-[1px]  border-neutral-500 border-b-solid pb-3',
            )}
          >
            <p className="flex-1 color-neutral-800 typo-text-xs">NAME</p>
            <p className="flex-1 color-neutral-800 typo-text-xs">HAS PERMISSION</p>
          </div>

          <div className={cn('h-[360px] overflow-y-scroll')}>
            <SceneEntityTree
              filteredAnnotationIds={selectedAnnotationIds}
              sceneEntityIds={selectedAnnoGroupSEIds}
              searchKey={''}
              skippedEntities={['photoGroup', 'photo']}
              targetLayer={targetLayer as LayerGroup | Layer}
            >
              {TreeNode}
            </SceneEntityTree>
          </div>
        </div>
        <div className={cn('flex', 'flex-row', 'items-center', 'my-6')}>
          <Checkbox checked={checked} setToggleCheckbox={() => setChecked(!checked)} />
          <p className={cn('typo-text-small', 'color-neutral-800', 'ml-2')}>
            I confirm that I understand some annotations may not be{' '}
            {type === 'delete' ? 'delete' : 'move'} due to permission issues.
          </p>
        </div>
        <div className="w-full flex gap-2">
          <Button className="w-full cursor-pointer" onClick={cancelAction} size="s">
            Cancel
          </Button>
          <Button
            className="w-full cursor-pointer"
            disabled={!checked || annotations.length === sceneEntityIdsWithoutPermissions.length}
            filled
            onClick={proceedAction}
            primary
            size="s"
          >
            {type === 'delete' ? 'Delete' : 'Move'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
