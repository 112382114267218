import { queryClient } from '@/LegacyExplore/graphql/client';
import {
  AnnotationImageField,
  AnnotationTemplate,
  AnnotationTemplateImageField,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { request } from '@/LegacyExplore/graphql/request';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { ADVANCED_UPLOADER } from '@/LegacyExplore/utils/split';
import { QueryClientProvider, RequestContextProvider } from '@skand/uploader';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Thumbnail } from './Thumbnail';
import { ThumbnailLegacy } from './ThumbnailLegacy';
import { Upload } from './Upload';
import { UploadLegacy } from './UploadLegacy';

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

export const FieldImage = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateImageField;
  disabled: boolean;
}) => {
  const treatments = useTreatments([ADVANCED_UPLOADER]);
  const isAdvancedUploaderEnabled = treatments[ADVANCED_UPLOADER].treatment === 'on';

  const storeField = useExplore(state => {
    return (
      (state.annotationDraft?.fields?.find(item => item && item.fieldId === field.id) as
        | undefined
        | AnnotationImageField) ?? undefined
    );
  });

  if (storeField?.fileId) {
    return isAdvancedUploaderEnabled ? (
      <Thumbnail disabled={disabled} field={field} />
    ) : (
      <QueryClientProvider client={queryClient}>
        <RequestContextProvider value={request}>
          <ThumbnailLegacy disabled={disabled} field={field} />
        </RequestContextProvider>
      </QueryClientProvider>
    );
  }

  return (
    <div
      className="mt-3 w-full flex items-center b-1 b-neutral-400 rounded-1 b-solid px-3 py-2"
      key={field.name}
    >
      <div className="flex-1">
        <p className="color-neutral-800 typo-text-xxs">{field.name}</p>
        <p>
          {isAdvancedUploaderEnabled ? (
            <Upload field={field} />
          ) : (
            <QueryClientProvider client={queryClient}>
              <RequestContextProvider value={request}>
                <UploadLegacy disabled={disabled} field={field} />
              </RequestContextProvider>
            </QueryClientProvider>
          )}
        </p>
      </div>

      <div className="flex-0 i-skand-image text-3 color-neutral-400" />
    </div>
  );
};
