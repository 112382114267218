import { DrawContextMenu } from '@/components/DrawContextMenu';
import { useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { useEffect, useState } from 'react';
import { Vector2 } from 'three';
import { PredictorMenu } from '../../PredictorMenu';
import { ActionButtons } from './ActionButtons';
import { InfoBar } from './InfoBar';

export const Overlay = () => {
  const targetPhoto = useViewer(state => state.targetPhoto);
  const api2D = useViewer(state => state.api2D);
  const drawController = api2D?.editor.getDrawController();

  const [contextMenuPosition, setContextMenuPosition] = useState<Vector2 | null>(null);
  const [removeVertex, setRemoveVertex] = useState<() => void>(() => {});
  const [enabledPredictor, setEnabledPredictor] = useState(false);

  // Register context menu handler
  useEffect(() => {
    drawController?.setContextMenuHandler((position, removeVertex) => {
      setContextMenuPosition(position);
      setRemoveVertex(() => removeVertex);
    });
  }, [drawController]);

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'left-0',
        'top-0',
        'absolute',
        'z-1',
        'pointer-events-none',
      )}
    >
      {contextMenuPosition && drawController && (
        <DrawContextMenu
          canClose={drawController.canClosePolygon()}
          closeOnClick={() => drawController.closePolygon()}
          doneOnClick={() => drawController.submit()}
          trashOnClick={removeVertex}
          undoOnClick={() => drawController.undo()}
        />
      )}
      {enabledPredictor && targetPhoto && (
        <PredictorMenu handleClose={() => setEnabledPredictor(false)} photo={targetPhoto} />
      )}
      <ActionButtons
        enabledPredictor={enabledPredictor}
        togglePredictor={() => setEnabledPredictor(v => !v)}
      />
      {targetPhoto && <InfoBar photo={targetPhoto} />}
    </div>
  );
};
