import { useRef } from 'react';
import { cn } from '../../utils/classname';
import { View } from './View';

interface SplitViewProps {
  views: React.ReactNode[];
  viewIndex: number;
}

export const MultiView = ({ views, viewIndex }: SplitViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={cn('w-full', 'h-full', 'flex', 'flex-row', 'relative')} ref={containerRef}>
      {views.map((view, i) => (
        <View key={i} size={i == viewIndex ? 100 : 0}>
          {view}
        </View>
      ))}
    </div>
  );
};
