import { useExplore } from '@/LegacyExplore/stores/explore';
import { Layer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { Button, Toast } from '@skand/ui';

export interface LocationAlertModalProps {
  layer: Layer;
}

export const LocationAlertModal = ({ layer }: LocationAlertModalProps) => {
  const showPlaceLayerModal = useExplore(state => state.showPlaceLayerModal);
  const hidelLocationAlertModal = useExplore(state => state.hidelLocationAlertModal);

  const handlePlaceLayerModalClick = () => {
    hidelLocationAlertModal();
    showPlaceLayerModal();
  };

  return (
    <div className={cn('w-full flex flex-col p-0')}>
      <p className={cn('typo-text-medium', 'text-neutral-800', 'mb-2')}>Model Location</p>
      <Toast
        clickToDismiss
        dismiss={() => {}}
        message={`${layer.name} might be placed incorrectly`}
        props={{ className: 'shadow-none' }}
        type="info"
      />
      <p className={cn('typo-text-small', 'text-neutral-600', 'mb-5', 'mt-2')}>
        Would you like to place the model?
      </p>

      <Button className="w-full" filled onClick={handlePlaceLayerModalClick} primary size="s">
        Place model
      </Button>
    </div>
  );
};
