import { GOOGLE_MAP_API_KEY } from '@/LegacyExplore/constants/env';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { Input } from '@skand/ui';
import { useEffect, useState } from 'react';

export interface AutoCompleteProps {
  setPosition: (latlng: google.maps.LatLngLiteral) => void;
  position: google.maps.LatLngLiteral;
}

const libraries: Libraries = ['places'];

export const AutoComplete = ({ position, setPosition }: AutoCompleteProps) => {
  const [value, setValue] = useState('');

  const { isLoaded } = useJsApiLoader({
    id: 'siteMap',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      const updateField = async () => {
        const result = await geocoder.geocode({
          location: { lat: position.lat, lng: position.lng },
        });
        setValue(result.results[0].formatted_address);
      };
      updateField();
    }
  }, [position.lat, position.lng, isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      const node = document.getElementById('address-input');
      if (node instanceof HTMLInputElement) {
        const autoComplete = new window.google.maps.places.Autocomplete(node, {
          types: ['geocode'],
        });
        autoComplete.addListener('place_changed', () => {
          const place = autoComplete.getPlace();
          if (place.formatted_address) {
            setValue(place.formatted_address);
          }
          if (place.geometry && place.geometry.location) {
            setPosition({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
          }
        });
        return () => window.google.maps.event.clearInstanceListeners(node);
      }
    }
  }, [isLoaded, setPosition]);

  return (
    <Input
      id="address-input"
      onChange={setValue}
      placeholder="Input the site location"
      type="text"
      value={value}
    />
  );
};
