import { cn } from '@/utils/classname';
import { ReactNode } from 'react';

interface ViewProps {
  size: number;
  children: ReactNode;
}

export const View = ({ size, children }: ViewProps) => {
  return (
    <div
      className={cn('absolute', 'w-100%', size === 0 && 'display-none', `top-0`)}
      style={{ height: `${size}%` }}
    >
      {children}
    </div>
  );
};
