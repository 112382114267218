import { isEmpty } from '@/LegacyExplore/utils/empty';
import { OBB } from '@skand/viewer-component-v2';
import { Color, Matrix4, Quaternion, Vector3 } from 'three';
import { ExploreState, Store } from './Store';

const EXCLUDED_FIELDS: (keyof ExploreState)[] = [
  'cameraPosition',
  'cameraRotation',
  'orthoMatrix',
  'projection',
  'shareLinkToken',
  'annotation',
  'layers',
  'image',
];

export class LocalStorageStore implements Store {
  serialize<T extends keyof ExploreState>(value: ExploreState[T] | null): string | null {
    if (isEmpty(value)) {
      return null;
    } else {
      return JSON.stringify(structuredClone(value));
    }
  }

  deserialize<T extends keyof ExploreState>(value: string | null): ExploreState[T] | null {
    if (value === null) {
      return null;
    }
    try {
      return JSON.parse(value, (_, value) => {
        if (value['isVector3']) {
          return new Vector3(value['x'], value['y'], value['z']);
        } else if (value['isQuaternion']) {
          return new Quaternion(value['x'], value['y'], value['z'], value['w']);
        } else if (value['isColor']) {
          return new Color(value['r'], value['g'], value['b']);
        } else if (value['isOBB']) {
          const center = new Vector3(
            value['center']['x'],
            value['center']['y'],
            value['center']['z'],
          );
          const halfAxes = new Matrix4().fromArray(value['halfAxes']['elements']);
          return new OBB(center, halfAxes);
        } else {
          return value;
        }
      });
    } catch {
      return null;
    }
  }

  get<T extends keyof ExploreState>(field: T): ExploreState[T] | null {
    const str = window.localStorage.getItem(field);
    if (isEmpty(str) || EXCLUDED_FIELDS.includes(field)) {
      return null;
    } else {
      return this.deserialize(str);
    }
  }

  set<T extends keyof ExploreState>(field: T, value: ExploreState[T]): void {
    if (!EXCLUDED_FIELDS.includes(field)) {
      const str = this.serialize(value);
      if (str) {
        window.localStorage.setItem(field, str);
      }
    }
  }

  clear<T extends keyof ExploreState>(field: T): void {
    window.localStorage.removeItem(field);
  }
}
