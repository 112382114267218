import { useLayout } from '@/LegacyExplore/stores/layout';
import { cn } from '../utils/classname';
import { AnnotationTabAddMoveGroup, AnnotationTabHistoryView } from './AnnotationViews';
import { ShareLinkContentPanel, ShareLinkSettingsPanel } from './ShareLinkTab/LeftSideBarViews';

export const LeftSideBarSecond = () => {
  const isShowing = useLayout(state => state.isShowingLeftSideBarII);
  const leftSideBarII = useLayout(state => state.leftSideBarII);

  return (
    isShowing && (
      <div
        className={cn(
          'absolute',
          'bg-neutral-100',
          'border-l-1',
          'border-l-neutral-400',
          'border-l-solid',
          'h-full',
          'left-400px',
          'w-400px',
          'p-y-18px',
          'p-x-3',
          'z-20',
        )}
      >
        {leftSideBarII == 'annotationHistory' && <AnnotationTabHistoryView />}
        {leftSideBarII == 'annotationGroup' && <AnnotationTabAddMoveGroup />}
        {leftSideBarII == 'shareLinkContentPanel' && <ShareLinkContentPanel />}
        {leftSideBarII == 'shareLinkSettingsPanel' && <ShareLinkSettingsPanel />}
      </div>
    )
  );
};
