import { Repeat } from '@/LegacyExplore/components/Repeat';
import { Skeleton } from '@skand/ui';

export const Loading = () => {
  return (
    <div>
      <Repeat n={4}>
        <Skeleton className="mb-3 h-20px" />
        <Repeat n={4}>
          <div className="flex gap-4">
            <Repeat n={5}>
              <Skeleton className="mb-3 h-20px flex-1" />
            </Repeat>
          </div>
        </Repeat>
      </Repeat>
    </div>
  );
};
