import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { DATASET_ROOT } from '@/constants/paths';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import {
  CreateProjectFileNodesWithNodeTreeMutationVariables,
  CreateProjectMutationVariables,
  ProjectFileNode,
} from '@/graphql/codegen/graphql';
import { CREATE_PROJECT, CREATE_PROJECT_FILE_NODES_WITH_NODE_TREE } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { useFetchProjects } from '@/hooks/useFetchProjects';
import { resetCreateProject, useCreateProject } from '@/stores/createProject';
import { cn } from '@/utils/classname';
import { ProjectGroup } from '@/utils/project';
import { Button, Modal } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { FileSelection } from './FileSelection';
import { InsertProjectDetails } from './InsertProjectDetails';

export interface CreateProjectProps {
  groups: ProjectGroup[];
  active: boolean;
  setActive: (value: boolean) => void;
  handleResetGroupSearchAndDialogState: () => void;
}

export const CreateProject = ({
  groups,
  active,
  setActive,
  handleResetGroupSearchAndDialogState,
}: CreateProjectProps) => {
  const [step, setStep] = useState(1);
  const [selectedNodeIds, setSelectedNodeIds] = useState<ProjectFileNode['id'][]>([]);
  const { allProjectsPermission } = useFetchProjectPermissions();

  const description = useCreateProject(state => state.description);
  const name = useCreateProject(state => state.name);
  const projectGroup = useCreateProject(state => state.projectGroup);
  const selectedGeoId = useCreateProject(state => state.selectedGeoId);
  const address = useCreateProject(state => state.address);
  const longitude = useCreateProject(state => state.longitude);
  const latitude = useCreateProject(state => state.latitude);

  const projectDetails = useMemo(() => {
    return {
      address,
      description,
      geoid: selectedGeoId.value,
      name,
      projectGroupId: projectGroup?.id ?? null,
      longitude,
      latitude,
    };
  }, [address, description, latitude, longitude, name, projectGroup?.id, selectedGeoId.value]);

  const isDisabled = useMemo(() => {
    if (step === 1) {
      // Cannot create project without group if the user doesn't have all project edit permission.
      if (projectGroup === null) {
        return !canPolicyActionEdit(allProjectsPermission);
      }
      return projectDetails.name.length === 0;
    } else if (step === 2) {
      return false;
    }
  }, [step, projectGroup, projectDetails.name, allProjectsPermission]);

  useEffect(() => {
    if (!active) setStep(1);
  }, [active]);

  const createProject = useMutation({
    mutationFn: (variables: CreateProjectMutationVariables) => {
      return request(CREATE_PROJECT, variables);
    },
  });

  const createProjectFileNode = useMutation({
    mutationFn: (variables: CreateProjectFileNodesWithNodeTreeMutationVariables) => {
      return request(CREATE_PROJECT_FILE_NODES_WITH_NODE_TREE, variables);
    },
    onSuccess: (_, { siteId }) => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
      handleResetGroupSearchAndDialogState();
      resetCreateProject();
      window.location.href = `${DATASET_ROOT}/projects/${siteId}`;
    },
  });

  const renderTitle = () => {
    switch (step) {
      case 1:
        return 'Create Project';
      case 2:
        return 'Add resources to project';
    }
  };

  const renderDescription = () => {
    switch (step) {
      case 1:
        return 'Enter Details';
      case 2:
        return 'Files will be copied into the project folder';
    }
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return <InsertProjectDetails groups={groups} />;
      case 2:
        return <FileSelection setSelectedNodeIds={setSelectedNodeIds} />;
    }
  };

  const handleCancel = () => {
    if (step === 2) {
      setStep(1);
    } else {
      setActive(false);
      resetCreateProject();
    }
  };

  const handleSubmit = async () => {
    if (step === 1) {
      setStep(2);
    } else {
      const response = await createProject.mutateAsync(projectDetails);
      const projectId = response.createProject?.id ?? null;
      createProjectFileNode.mutate({
        siteId: projectId as string,
        parentNodeId: null,
        nodeIds: selectedNodeIds as string[],
      });
    }
  };

  const isMutating = createProject.isLoading || createProjectFileNode.isLoading;

  if (active)
    return (
      <Modal>
        <p className="color-neutral-800 typo-text-l">{renderTitle()}</p>
        <div
          className={cn(
            step === 1 ? 'color-neutral-800 typo-text-m ' : 'color-neutral-400 typo-text-medium',
          )}
        >
          {renderDescription()}
        </div>

        <div className={cn('flex-1 ')}>{renderContent()}</div>

        <div className={cn('flex', 'justify-end', 'gap-3')}>
          <Button className={cn('cursor-pointer flex-1')} onClick={handleCancel} size="s">
            {step === 1 ? 'Cancel' : 'Back'}
          </Button>
          <Button
            className={cn('flex-1', 'cursor-pointer')}
            data-analytics-event-object={
              step === 1
                ? ANALYTICS_EVENT_OBJECT.CREATE_PROJECT_NEXT_STEP
                : ANALYTICS_EVENT_OBJECT.CREATE_PROJECT
            }
            disabled={isDisabled || isMutating}
            onClick={handleSubmit}
            primary
            size="s"
          >
            {step === 1 ? 'Next' : 'Create'}
          </Button>
        </div>
      </Modal>
    );
};
