import {
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useShareLink } from '@/LegacyExplore/stores/shareLink';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (shareLinkId: string | null) => [
  'LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT',
  shareLinkId,
  PolicySubjectTypeInput.ShareLink,
  PolicyObjectTypeInput.SceneEntity,
  PolicyActionTypeInput.Read,
  null,
];

export const useFetchShareLinkPermissions = () => {
  const shareLinkId = useShareLink(state => state.selectedShareLink?.id);

  const response = useQuery({
    enabled: !!shareLinkId,
    queryFn: () =>
      request(LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT, {
        subjectId: shareLinkId as string,
        subjectType: PolicySubjectTypeInput.ShareLink,
        objectType: PolicyObjectTypeInput.SceneEntity,
        actionType: PolicyActionTypeInput.Read,
        objectId: null,
      }),
    queryKey: getQueryKey(shareLinkId as string),
  });

  const permissionObjectIDs = useMemo(() => {
    const permissionObjectIDs: string[] = [];
    if (
      !isEmpty(response.data) &&
      !isEmpty(response.data.listPermissionPoliciesWithAccountContext)
    ) {
      for (const permission of response.data.listPermissionPoliciesWithAccountContext) {
        if (permission && !isEmpty(permission.objectId)) {
          permissionObjectIDs.push(permission.objectId);
        }
      }
    }
    return permissionObjectIDs;
  }, [response.data]);

  return permissionObjectIDs;
};

useFetchShareLinkPermissions.getQueryKey = getQueryKey;
