import { ANNOTATION_FIELD_TYPE } from '@/LegacyExplore/constants/annotation';
import {
  AnnotationTemplateField,
  AnnotationTemplateSelectField,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { WithMode } from '@/LegacyExplore/stores/template';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { OptionEdit } from './OptionEdit';
import { OptionView } from './OptionView';

export const FieldValue = ({ field }: { field: WithMode<AnnotationTemplateField> }) => {
  if (field.type !== ANNOTATION_FIELD_TYPE.SELECT) return null;

  if (field.mode === 'view')
    return <OptionView field={field as WithMode<AnnotationTemplateSelectField>} />;

  return (
    <DndProvider backend={HTML5Backend}>
      <OptionEdit field={field as WithMode<AnnotationTemplateSelectField>} />
    </DndProvider>
  );
};
