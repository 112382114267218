import { FindIcon } from '@/LegacyExplore/components/IconButton';
import { RadioButton } from '@/LegacyExplore/components/RadioButton';
import { cn } from '@/LegacyExplore/utils/classname';
import { search } from '@/LegacyExplore/utils/search';
import { Input } from '@skand/ui';
import { useMemo, useState } from 'react';

export interface ItemProps {
  label: string;
  value: string;
}

export interface DropdownSelectorProps {
  items: ItemProps[];
  onItemClick: (item: ItemProps) => void;
  enableSearch?: boolean;
  containerStyles?: string;
  itemContainerStyles?: string;
  itemStyles?: string;
  radio?: boolean;
  selectedItem?: ItemProps['value'];
  placeholder?: string;
}

export const DropdownSelector = ({
  items,
  onItemClick,
  enableSearch = false,
  containerStyles,
  itemStyles,
  itemContainerStyles,
  radio = false,
  selectedItem,
  placeholder,
}: DropdownSelectorProps) => {
  const [searchKey, setSearchKey] = useState('');
  const filtered = useMemo(
    () => items.filter(item => search(item.label, searchKey)),
    [items, searchKey],
  );

  return (
    <div
      className={
        containerStyles
          ? containerStyles
          : cn(
              'absolute',
              'w-auto',
              'left-0',
              'mt-2',
              'bg-neutral-800',
              'bottom-5.5',
              'rounded-1',
              'min-w-78px',
              'bg-opacity-50',
              'backdrop-blur-sm	',
              'p-2',
            )
      }
    >
      {enableSearch && (
        <div className="pb-2">
          <Input
            label={placeholder ? placeholder : 'Search'}
            onChange={value => setSearchKey(value)}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
      )}

      <div
        className={cn(
          'overflow-y-scroll',
          'max-h-300px',
          'flex flex-col gap-1',
          itemContainerStyles,
        )}
      >
        {filtered.map((item, index) =>
          radio ? (
            <div className={cn('flex', 'flex-row', 'px-2')} key={`item-${index}`}>
              <RadioButton
                checked={selectedItem === item.value}
                id={`item-${index}`}
                label={item.label}
                onChange={() => onItemClick(item)}
                value={selectedItem ?? ''}
              />
            </div>
          ) : (
            <p
              className={cn(
                'py-2',
                'px-2',
                'rounded-1',
                'typo-text-s',
                'text-neutral-100',
                'hover:text-neutral-800',
                'hover:bg-neutral-100',
                'cursor-pointer',
                itemStyles,
              )}
              key={`item-${index}`}
              onClick={() => onItemClick(item)}
            >
              {item.label.toUpperCase()}
            </p>
          ),
        )}
      </div>
    </div>
  );
};
