import { Controls3DIcon, PlaceIcon } from '@/components/IconButton';
import { UpdatePanoramicImageTransformMutationVariables } from '@/graphql/codegen/graphql';
import { UPDATE_PANORAMIC_IMAGE_TRANSFORM } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import { startPanoramaEdit, stopPanoramaView, useViewer } from '@/stores/viewer';
import { Button, cn, toast } from '@skand/ui';
import {
  DynamicModel,
  ModelNode,
  Panorama as PanoramaModel,
  TransformTool,
} from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef, useState } from 'react';
import { Quaternion, Vector3 } from 'three';

export const TransformControl = () => {
  const toolRef = useRef<null | TransformTool>(null);
  const api3D = useViewer(state => state.api3D);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const viewer3DSettings = useViewer(state => state.viewer3DSettings);

  const fetchUrl = useFetchImageUrls([targetPhoto?.id as string], false);
  const fetchThumbnailUrl = useFetchImageUrls([targetPhoto?.id as string], true);

  const [editMode, setEditMode] = useState<'widget' | 'point' | 'panorama' | null>(null);

  const updatePanoramicImagePosition = useMutation({
    mutationFn: (params: UpdatePanoramicImageTransformMutationVariables) =>
      request(UPDATE_PANORAMIC_IMAGE_TRANSFORM, params),
    onSuccess: () => {
      toast({
        type: 'success',
        message: `Successfully moved entity '${targetPhoto?.name}'.`,
        lifespan: 5000,
      });
    },
  });

  // Save the transform of the image
  const saveTransform = useCallback(() => {
    if (targetPhoto?.widget) {
      const position = targetPhoto.widget.getLocalPosition().toArray();
      const rotation = targetPhoto.widget.getLocalRotation().toArray();
      if (targetPhoto.type === 'panorama') {
        updatePanoramicImagePosition.mutate({
          renderObjectId: targetPhoto.group.renderObjectId,
          fileId: targetPhoto.id,
          name: targetPhoto.name,
          position,
          rotation,
        });
      }
    }
  }, [targetPhoto, updatePanoramicImagePosition]);

  // Create a model for the image if there is none
  const createTemporaryModel = async () => {
    if (api3D && targetPhoto && !targetPhoto.widget) {
      const toastHandle = toast({ type: 'info', message: 'Setting up panoramic widget.' });
      const url = await fetchUrl();
      const thumbnailUrl = await fetchThumbnailUrl();
      const model = await api3D.model.create({
        type: 'panorama',
        panoramaType: 'full',
        name: targetPhoto.name,
        url: url.get(targetPhoto.id) as string,
        thumbnailUrl: thumbnailUrl.get(targetPhoto.id) as string,
      });
      (model as PanoramaModel).setOpacity360(viewer3DSettings.overlayOpacity);
      targetPhoto.widget = new ModelNode(model as DynamicModel);
      targetPhoto.group.sceneNode.add(targetPhoto.widget);
      targetPhoto.widget.setLocalPosition(new Vector3());
      targetPhoto.widget.setLocalRotation(new Quaternion());
      targetPhoto.widget.setLocalScale(new Vector3(1, 1, 1));
      await targetPhoto.widget.show();
      toastHandle.dismiss();
    }
  };

  // Toggle the widget tool
  const toggleWidgetTool = async () => {
    await createTemporaryModel();
    toolRef.current?.stop();
    stopPanoramaView();
    if (api3D && targetPhoto?.widget && editMode !== 'widget') {
      toolRef.current = api3D.transform.createWidgetTool(targetPhoto.widget);
      toolRef.current.start(saveTransform);
      setEditMode('widget');
    } else {
      setEditMode(null);
    }
  };

  // Toggle the point tool
  const togglePointTool = async () => {
    await createTemporaryModel();
    toolRef.current?.stop();
    stopPanoramaView();
    if (api3D && targetPhoto?.widget && editMode !== 'point') {
      api3D.transform.createPointTool(targetPhoto.widget).then(newTool => {
        newTool.start(saveTransform);
        toolRef.current = newTool;
      });
      setEditMode('point');
    } else {
      setEditMode(null);
    }
  };

  const togglePanoramaEdit = async () => {
    await createTemporaryModel();
    toolRef.current?.stop();
    stopPanoramaView();
    if (targetPhoto?.type === 'panorama' && editMode !== 'panorama') {
      startPanoramaEdit(targetPhoto, (position, rotation) => {
        targetPhoto.widget?.setPosition(position);
        targetPhoto.widget?.setRotation(rotation);
        saveTransform();
      });
      setEditMode('panorama');
    } else {
      setEditMode(null);
    }
  };

  return (
    <>
      <Button
        className={cn(
          'mr-10px',
          'h-30px',
          'flex',
          'flex-1',
          'flex-grow',
          'items-center',
          'justify-center',
          'gap-2',
          'whitespace-nowrap',
        )}
        disabled={editMode !== null && editMode !== 'widget'}
        filled
        onClick={toggleWidgetTool}
        primary={editMode === 'widget'}
        size="small"
      >
        <Controls3DIcon />
        <p className="typo-text-small">3D controls</p>
      </Button>
      <Button
        className={cn(
          'mr-10px',
          'h-30px',
          'flex',
          'flex-1',
          'flex-grow',
          'items-center',
          'justify-center',
          'gap-2',
          'whitespace-nowrap',
        )}
        disabled={editMode !== null && editMode !== 'point'}
        filled
        onClick={togglePointTool}
        primary={editMode === 'point'}
        size="small"
      >
        <PlaceIcon />
        <p className="typo-text-small">Place in 3D</p>
      </Button>
      {targetPhoto?.type === 'panorama' && (
        <Button
          className={cn(
            'mr-10px',
            'h-30px',
            'flex',
            'flex-1',
            'flex-grow',
            'items-center',
            'justify-center',
            'gap-2',
            'whitespace-nowrap',
          )}
          disabled={editMode !== null && editMode !== 'panorama'}
          filled
          onClick={togglePanoramaEdit}
          primary={editMode === 'panorama'}
          size="small"
        >
          <PlaceIcon />
          <p className="typo-text-small">Adjust Panorama</p>
        </Button>
      )}
    </>
  );
};
