import { DraggableMenu } from '@/LegacyExplore/components/DraggableMenu';
import { LeftSideBarFirst } from '@/LegacyExplore/components/LeftSideBarFirst';
import { LeftSideBarSecond } from '@/LegacyExplore/components/LeftSideBarSecond';
import { LeftTabBar } from '@/LegacyExplore/components/LeftTabBar';
import { LocationAlertModal } from '@/LegacyExplore/components/LocationAlertModal';
import { PlaceLayerModal } from '@/LegacyExplore/components/PlaceLayerModal';
import { RightSideBar } from '@/LegacyExplore/components/RightSideBar';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { useLayout } from '@/LegacyExplore/stores/layout';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { TemplatePanel } from './TemplatePanel';
import { Viewer } from './Viewer';

export const ProjectExploreScene = () => {
  const shareLinkToken = getShareLinkToken();
  const isShowingTemplatePanel = useLayout(state => state.isShowingTemplatePanel);
  const isShowingPlaceLayerModal = useExplore(state => state.isShowingPlaceLayerModal);
  const isShowingModalLocationAlert = useExplore(state => state.isShowingLocationAlertModal);
  const hidelLocationAlertModal = useExplore(state => state.hidelLocationAlertModal);
  const placeLayerTarget = useExplore(state => state.placeLayerTarget);

  return (
    <>
      <div className="h-full flex flex-col overflow-hidden bg-neutral-200">
        <LeftTabBar />
      </div>

      <div className="relative flex flex-1">
        <LeftSideBarFirst />
        <LeftSideBarSecond />
        {isShowingTemplatePanel && <TemplatePanel />}
        {/* TODO: need to keep an eye on it, react portal inside Viewer can penetrate display: none */}
        <Viewer style={isShowingTemplatePanel ? { display: 'none' } : undefined} />
        {!isShowingTemplatePanel && <RightSideBar />}
        {placeLayerTarget && (
          <PlaceLayerModal active={isShowingPlaceLayerModal} layer={placeLayerTarget} />
        )}
        {placeLayerTarget && isShowingModalLocationAlert && !shareLinkToken && (
          <DraggableMenu closeMenu={hidelLocationAlertModal} x={420} y={300}>
            <LocationAlertModal layer={placeLayerTarget} />
          </DraggableMenu>
        )}
      </div>
    </>
  );
};
