import SatelliteViewIcon from '@/assets/satelliteView@2x.png';
import StreetViewIcon from '@/assets/streetView@2x.png';
import { IconButton } from '@/components/IconButton';
import { Label } from '@/components/Label';
import { RadioButton } from '@/components/RadioButton';
import { ENTITLEMENT_NAME, useEntitlements } from '@/hooks/useEntitlements';
import { setViewer3DSettings, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { GOOGLE_EARTH_USER } from '@/utils/split';
import { ColorPicker, Toast } from '@skand/ui';
import { GlobeMode, ImageryBaseMap } from '@skand/viewer-component-v2';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useCallback, useState } from 'react';
import { Color } from 'three';

export const GlobeControlMenu = () => {
  const googleEarthGlobeEntitlement = useEntitlements().feature(
    ENTITLEMENT_NAME.GOOGLE_EARTH_GLOBE,
  );
  const treatment = useTreatments([GOOGLE_EARTH_USER]);
  const googleEarthGlobeFlag = treatment[GOOGLE_EARTH_USER].treatment === 'on';

  const enableGlobeUI = useViewer(state => state.enableGlobeUI);
  const globeMode = useViewer(state => state.globeMode);
  const baseMapType = useViewer(state => state.baseMapType);
  const viewer3DSettings = useViewer(state => state.viewer3DSettings);

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [googleEarthTooltip, setGoogleEarthTooltip] = useState(false);

  // Handle updating the globe view mode
  const handleGlobeMode = (globeMode: GlobeMode) => {
    useViewer.setState({ globeMode });
  };

  // Handle updating the base imagery map
  const handleBaseMapType = (baseMapType: ImageryBaseMap) => {
    useViewer.setState({ baseMapType });
  };

  // Handle setting the background color
  const handleBackgroundColor = useCallback((backgroundColor: Color) => {
    setViewer3DSettings({ backgroundColor });
  }, []);

  return (
    <div
      className={cn(
        'w-full',
        'top-0',
        'absolute',
        'flex',
        'flex-col',
        'z-1',
        'bg-neutral-100',
        'p-12px',
        'rounded-1',
      )}
    >
      <span className={cn('typo-text-medium', 'text-neutral-800')}>View mode</span>
      <div className={cn('mt-8px', 'mb-8px')}>
        <Label
          css="h-36px"
          ellipsis={false}
          labelTitle="Select the visibility of the globe and 3D terrain. Choose the map imagery type to texture the globe."
          labelType="info"
          textLength={100}
        />
      </div>
      <div className={cn('flex', 'flex-col')}>
        <RadioButton
          checked={globeMode === 'default'}
          disabled={!enableGlobeUI}
          id="1"
          label="Default"
          onChange={value => handleGlobeMode(value as GlobeMode)}
          value={'default'}
        />
        <RadioButton
          checked={globeMode === 'imagery'}
          disabled={!enableGlobeUI}
          id="2"
          label="2D Aerial Map"
          onChange={value => handleGlobeMode(value as GlobeMode)}
          value={'imagery'}
        />
        <RadioButton
          checked={globeMode === 'terrain'}
          disabled={!enableGlobeUI}
          id="3"
          label="3D Terrain"
          onChange={value => handleGlobeMode(value as GlobeMode)}
          value={'terrain'}
        />
        {(googleEarthGlobeEntitlement || googleEarthGlobeFlag) && (
          <div className="relative flex flex-row items-center">
            <RadioButton
              checked={globeMode === 'google'}
              disabled={!enableGlobeUI}
              id="4"
              label="Google Earth Globe"
              onChange={value => handleGlobeMode(value as GlobeMode)}
              value={'google'}
            />
            <IconButton
              buttonIcon={
                <span
                  className={cn(
                    'i-skand-info',
                    'color-neutral-500',
                    'ml-3',
                    'text-3.5',
                    'mb-12px',
                    'cursor-pointer',
                  )}
                />
              }
              buttonState="default"
              className={cn('bg-transparent')}
              onClick={() => setGoogleEarthTooltip(!googleEarthTooltip)}
              onMouseEnter={() => setGoogleEarthTooltip(true)}
              onMouseLeave={() => setGoogleEarthTooltip(false)}
            />
            {googleEarthTooltip && (
              <div className="absolute top-6">
                <Toast
                  clickToDismiss
                  dismiss={() => {}}
                  message="Orthophoto and terrain layers will not be visible in this mode."
                  type="info"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <span className={cn('typo-text-small-em', 'text-neutral-800')}>Map type</span>
      <div className={cn('flex', 'flex-row', 'mt-4px')}>
        <div className={cn('flex', 'flex-col', 'justify-center', 'items-center', 'mr-4px')}>
          <button
            className={cn(
              'bg-contain',
              'b-0 b-none',
              'h-60px w-60px',
              'mt-1',
              'rounded-1',
              baseMapType === 'street' && 'ring-2 ring-inset ring-primary-400',
              !enableGlobeUI && baseMapType !== 'street' ? 'opacity-20' : 'opacity-100',
            )}
            disabled={!enableGlobeUI}
            onClick={() => handleBaseMapType('street')}
            style={{ backgroundImage: `url('${StreetViewIcon}')` }}
          />
          <span className={cn('typo-text-small', 'text-neutral-800')}>Street</span>
        </div>

        <div className={cn('flex', 'flex-col', 'justify-center', 'items-center', 'ml-4px')}>
          <button
            className={cn(
              'bg-contain',
              'b-0 b-none',
              'h-60px w-60px',
              'mt-1',
              'rounded-1',
              baseMapType === 'satellite' && 'ring-2 ring-inset ring-primary-400',
              !enableGlobeUI && baseMapType !== 'satellite' ? 'opacity-20' : 'opacity-100',
            )}
            disabled={!enableGlobeUI}
            onClick={() => handleBaseMapType('satellite')}
            style={{ backgroundImage: `url('${SatelliteViewIcon}')` }}
          />

          <span className={cn('typo-text-small', 'text-neutral-800')}>Satellite</span>
        </div>
      </div>
      <span className={cn('typo-text-small-em', 'text-neutral-800')}>Background Colour</span>
      <div className={cn('flex', 'flex-row', 'mt-4px')}>
        <div className={cn('flex', 'flex-col', 'justify-center', 'items-center', 'ml-4px')}>
          <button
            className={cn(
              'bg-contain',
              'h-60px w-60px',
              'mt-1',
              'rounded-1',
              'b-1px b-solid b-neutral-400',
            )}
            onClick={() => setShowColorPicker(!showColorPicker)}
            style={{ backgroundColor: `#${viewer3DSettings.backgroundColor.getHexString()}` }}
          />
        </div>
        {showColorPicker && (
          <ColorPicker
            closeMenu={() => setShowColorPicker(false)}
            color={viewer3DSettings.backgroundColor.getHexString()}
            colorPickerHeader="Custom colour"
            openingCoordinates={{ x: -156, y: 65 }}
            setColor={color => handleBackgroundColor(new Color(color.hex))}
          />
        )}
      </div>
    </div>
  );
};
