import { ANALYTICS_EVENT_OBJECT } from '@/LegacyExplore/constants/analytics';
import { Layer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { IfcElementNode } from '@skand/viewer-component-v2';
import { useState } from 'react';
import { IFCTab } from './IFCTab';
import { LayeringTab } from './LayeringTab';

export interface IFCSettingsMenuProps {
  layer: Layer;
  setSelectedIfcElement: (node: IfcElementNode | null) => void;
  selectedIfcElement: IfcElementNode | null;
}

export const IFCSettingsMenu = ({
  layer,
  selectedIfcElement,
  setSelectedIfcElement,
}: IFCSettingsMenuProps) => {
  const [currentTab, setCurrentTab] = useState<'layering' | 'ifc'>('layering');

  return (
    <div className={cn('w-full flex flex-col')}>
      <div
        className={cn(
          'items-center pb-2 flex h-auto justify-around w-full b-solid b-neutral-400 border-b-1 border-l-0 border-r-0 border-t-0',
        )}
      >
        <button
          className={cn('bg-transparent', 'b-none', 'cursor-pointer', 'flex', 'flex-1')}
          onClick={() => setCurrentTab('layering')}
        >
          <p
            className={cn(
              'typo-text-small',
              currentTab === 'layering' ? 'text-neutral-800' : 'text-neutral-400',
            )}
          >
            Layering
          </p>
        </button>
        <button
          className={cn(
            'bg-transparent',
            'b-none',
            'cursor-pointer',
            'flex',
            'flex-1',
            'b-neutral-400',
          )}
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.IFC_TOOL}
          onClick={() => setCurrentTab('ifc')}
        >
          <p
            className={cn(
              'typo-text-small',
              currentTab === 'ifc' ? 'text-neutral-800' : 'text-neutral-400',
            )}
          >
            IFC
          </p>
        </button>
      </div>
      {currentTab === 'ifc' ? (
        <IFCTab
          layer={layer}
          selectedIfcElement={selectedIfcElement}
          setSelectedIfcElement={setSelectedIfcElement}
        />
      ) : (
        <LayeringTab layer={layer} />
      )}
    </div>
  );
};
