import {
  Annotation,
  AnnotationDateField,
  AnnotationField,
  AnnotationFieldInput,
  AnnotationFileField,
  AnnotationImageField,
  AnnotationSelectField,
  AnnotationTextField,
  AnnotationUrlField,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { AnnotationDraft } from '@/LegacyExplore/stores/explore';

/**
 * Compare if draft and original fields are the same
 * returns true if they are same
 */
export const isFieldUntouched = (
  draftField: AnnotationFieldInput,
  originalField: AnnotationField,
): boolean => {
  if (draftField?.fieldId !== originalField?.fieldId) return false;
  if (draftField?.type !== originalField?.type) return false;
  if (draftField?.name !== originalField?.name) return false;

  if (draftField?.end !== (originalField as AnnotationDateField)?.end) return false;
  if (draftField?.start !== (originalField as AnnotationDateField)?.start) return false;
  if (draftField?.text !== (originalField as AnnotationTextField)?.text) return false;
  if (draftField?.optionId !== (originalField as AnnotationSelectField)?.optionId) return false;
  if (draftField?.value !== (originalField as AnnotationSelectField)?.value) return false;
  if (draftField?.fileId !== (originalField as AnnotationImageField)?.fileId) return false;

  // compare files
  if (draftField?.files !== (originalField as AnnotationFileField)?.files) {
    const draftFiles = draftField?.files;
    const originalFiles = (originalField as AnnotationFileField)?.files;

    if (!!draftFiles && !originalFiles) return false;
    if (!draftFiles && !!originalFiles) return false;

    if (!!draftFiles && !!originalFiles) {
      if (draftFiles.length !== originalFiles.length) return false;

      for (let j = 0; j < draftFiles.length; j++) {
        const draftFile = draftFiles[j];
        const originalFile = originalFiles[j];

        if (draftFile?.name !== originalFile?.name) return false;
        if (draftFile?.fileId !== originalFile?.fileId) return false;
      }
    }
  }

  // compare urls
  if (draftField?.urls !== (originalField as AnnotationUrlField)?.urls) {
    const draftUrls = draftField?.urls;
    const originalUrls = (originalField as AnnotationUrlField)?.urls;

    if (!!draftUrls && !originalUrls) return false;
    if (!draftUrls && !!originalUrls) return false;

    if (!!draftUrls && !!originalUrls) {
      if (draftUrls.length !== originalUrls.length) return false;

      for (let j = 0; j < draftUrls.length; j++) {
        const draftUrl = draftUrls[j];
        const originalUrl = originalUrls[j];

        if (draftUrl !== originalUrl) return false;
      }
    }
  }

  return true;
};

/** Only compares color, name and fields properties */
export const isDraftUntouched = (draft: AnnotationDraft, original: Annotation) => {
  if (draft.color !== original.color) return false;
  if (draft.name !== original.name) return false;
  if (draft.fields?.length !== original.fields?.length) return false;

  // compare fields
  const template = draft.template;
  for (const field of template?.fields ?? []) {
    if (!field) continue;

    const draftField = draft.fields?.find(f => f && f.fieldId === field?.id);
    const originalField = original.fields?.find(f => f && f.fieldId === field?.id);

    if (!draftField || !originalField) return false;
    if (isFieldUntouched(draftField, originalField) === false) return false;
  }

  return true;
};
