// @unocss-include
import { Annotation, Layer } from '@/LegacyExplore/stores/viewer';
import { EPSG } from '@skand/data-3d-loader';
import { GlobeMode, ImageryBaseMap } from '@skand/viewer-component-v2';
import { Color } from 'three';
import { create } from 'zustand';
export interface ShareLink {
  id: string;
  name: string;
  active: boolean;
  accountId: string;
  shareToken: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Settings {
  globeMode: GlobeMode;
  backgroundColor: Color;
  panoramaIconSize: number;
  panoramaCameraHeight: number;
  currentEpsg: EPSG;
  shareCameraTransform: boolean;
  baseMapType: ImageryBaseMap;
  annotationMeasurementVisibility: boolean;
  annotationNameVisibility: boolean;
}

interface ShareLinkState {
  selectedShareLink: ShareLink | null;
  shareLinkTab: string;
  settings: Map<string, Settings>;
  selectedAnnotationIdByLayers: null | Annotation['id'];
  selectedImageIdByLayers: null | string;
  selectedPhotoGroupIds: null | string[];
  selectedImageIdByImageViewer: null | string;
  visibleLayerIds: Set<Layer['id']>;
  selectedSceneEntityIds: string[];
}

export const useShareLink = create<ShareLinkState>()(() => ({
  selectedShareLink: null,
  selectedAnnotationIdByLayers: null,
  selectedImageIdByLayers: null,
  selectedPhotoGroupIds: null,
  selectedImageIdByImageViewer: null,
  shareLinkTab: 'card',
  settings: new Map(),
  visibleLayerIds: new Set(),
  selectedSceneEntityIds: [],
}));

export const setSelectedShareLink = (shareLink: ShareLink | null) => {
  useShareLink.setState({ selectedShareLink: shareLink });
};
