import { Photo, PhotoSortMode, setImageViewerSettings } from '@/LegacyExplore/stores/viewer';

const PHOTO2D_SORT_MODES: PhotoSortMode[] = [
  'name',
  'relevance to point',
  'similarity to camera',
  'weighted similarity to camera',
  'projected point',
];

const PANORAMA_SORT_MODES: PhotoSortMode[] = [
  'name',
  'distance to camera',
  'distance to clicked point',
];

export interface SortMenuProps {
  currentTab: Photo['type'];
  closeModal: () => void;
}

export const SortMenu = ({ currentTab, closeModal }: SortMenuProps) => {
  const items = currentTab === 'photo2D' ? PHOTO2D_SORT_MODES : PANORAMA_SORT_MODES;

  // Handle selecting the sorting mode.
  const handleSelectMode = (value: string) => {
    setImageViewerSettings({ sortMode: value as PhotoSortMode }, currentTab);
    closeModal();
  };

  return (
    <div className="absolute right-0 top-5 z1 w-189px flex flex-col gap-1 b-1 b-neutral-400 rounded b-solid bg-neutral-100 p-1">
      {items.map((item, index) => (
        <p
          className="cursor-pointer rounded p-1 text-neutral-600 typo-text-xs hover:bg-gray-100 hover:text-neutral-800"
          key={`sort-mode-${index}`}
          onClick={() => handleSelectMode(item)}
        >
          {item.toUpperCase()}
        </p>
      ))}
    </div>
  );
};
