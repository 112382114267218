export const PopupIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 16 17" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.88867 11.5V14.3502C2.88867 14.8391 3.28867 15.2391 3.77756 15.2391H14.1838C14.6727 15.2391 15.0727 14.8391 15.0727 14.3502V3.5H11.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.68"
      />
      <path d="M6.5 1.5H1V7" stroke="currentColor" strokeLinecap="round" strokeWidth="1.68" />
      <path
        d="M7.88719 10.0745C8.20448 10.413 8.73606 10.4301 9.07451 10.1128C9.41296 9.79552 9.4301 9.26394 9.11281 8.92549L7.88719 10.0745ZM0.387189 2.07451L7.88719 10.0745L9.11281 8.92549L1.61281 0.925489L0.387189 2.07451Z"
        fill="currentColor"
      />
    </svg>
  );
};
