import { MoreMenu } from '@/LegacyExplore/components/MoreMenu';
import { useLayout } from '@/LegacyExplore/stores/layout';
import { useShareLink } from '@/LegacyExplore/stores/shareLink';
import {
  Photo,
  enableViewer2D,
  setImageViewerTab,
  setTargetPhoto,
  useViewer,
} from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { Menu, MenuItem } from '@skand/ui';

export interface PhotoNodeProps {
  photo: Photo;
  selected: boolean;
}

export const PhotoNode = ({ photo, selected }: PhotoNodeProps) => {
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);
  const switchTab = useLayout(state => state.switch);

  // Handle viewing the photo in the image viewer
  const handleViewImage = () => {
    setImageViewerTab(photo.type);
    setTargetPhoto(photo);
    enableViewer2D();
  };

  const handleCreateAnnotation = (photoId: string) => {
    switchTab('sharelink');
    useShareLink.setState({
      shareLinkTab: 'create',
      selectedImageIdByLayers: photoId,
      selectedShareLink: null,
    });
  };

  return (
    <>
      <div className="i-skand-image w-5 text-3 color-neutral-600" />
      <p
        className={cn(
          'group',
          'cursor-pointer',
          'flex-1 truncate',
          selected ? 'typo-text-small-em text-neutral-800' : 'typo-text-small text-neutral-600',
        )}
        onClick={handleViewImage}
        title={photo.name}
      >
        {photo.name}
      </p>
      <div className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2">
        <MoreMenu className={cn('cursor-pointer', enabledSelectMode && 'hidden')}>
          <Menu className="z-2">
            <MenuItem className="cursor-pointer" onClick={() => handleCreateAnnotation(photo.id)}>
              Create share link to this image
            </MenuItem>
          </Menu>
        </MoreMenu>
      </div>
    </>
  );
};
