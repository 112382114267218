import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import {
  Photo,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { QuickList, QuickListAPI, QuickListItemProps } from '@skand/ui';
import { Panorama } from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Thumbnail } from './Thumbnail';

export const ImageRibbon = () => {
  const photos = useViewer(state => state.ribbonPhotos);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const loadingMutex = useViewer(state => state.loadingMutex);

  const [, setReady] = useState(false);
  const [quickListAPI, setQuickListAPI] = useState<QuickListAPI | null>(null);

  const noUrlPhotos = useMemo(
    () => photos.filter(photo => !photo.url).map(photo => photo.id),
    [photos],
  );
  const fetchUrls = useFetchImageUrls(noUrlPhotos, false);

  const noThumbnailPhotos = useMemo(
    () => photos.filter(photo => !photo.thumbnailUrl).map(photo => photo.id),
    [photos],
  );
  const fetchThumbnailUrls = useFetchImageUrls(noThumbnailPhotos, true);

  // Handle selecting an image
  const handleSelect = async (photo: Photo) => {
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (photo.type === 'photo2D') {
        await startPhotoOverlay(photo);
      } else {
        await startPanoramaWalkthrough(photo);
      }
    }
  };

  // Fetch urls in bulk for photos in the sublist
  useEffect(() => {
    const fetchPhotoUrls = async () => {
      setReady(false);
      const urls: Map<string, string> = noUrlPhotos.length ? await fetchUrls() : new Map();
      const thumbnailUrls: Map<string, string> = noThumbnailPhotos.length
        ? await fetchThumbnailUrls()
        : new Map();

      for (const photo of photos) {
        const url = urls.get(photo.id);
        const thumbnailUrl = thumbnailUrls.get(photo.id);

        const widget = photo.widget?.getModel();
        if (url) {
          photo.url = url;
          if (widget instanceof Panorama && !photo.tileset) {
            widget.setUrl(url);
          }
        }
        if (thumbnailUrl) {
          photo.thumbnailUrl = thumbnailUrl;
          if (widget instanceof Panorama) {
            widget.setThumbnailUrl(thumbnailUrl);
          }
        }
      }
      setReady(true);
    };
    fetchPhotoUrls();
  }, [fetchThumbnailUrls, fetchUrls, noThumbnailPhotos, noUrlPhotos, photos]);

  // Scroll to selected photo
  useEffect(() => {
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (quickListAPI) {
      quickListAPI?.scrollTo(index * quickListAPI.itemSize);
    }
  }, [photos, quickListAPI, targetPhoto]);

  // Handler for keyboard input
  const onKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const { key } = event;
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (index < 0) {
      return;
    } else if (key === 'ArrowUp' && index > 0) {
      handleSelect(photos[index - 1]);
    } else if (key === 'ArrowDown' && index < photos.length - 1) {
      handleSelect(photos[index + 1]);
    }
  };

  // List item component wrapper
  const ListItem = useCallback(
    ({ index }: QuickListItemProps) => {
      const photo = photos[index];
      return (
        <div key={`photo-${index}`}>
          <Thumbnail photo={photo} />
        </div>
      );
    },
    [photos],
  );

  return (
    <div className="h-full min-w-400px flex flex-col bg-neutral-100 pl-18px pr-18px">
      <div className="mt-30px flex justify-between border-1 b-neutral-300 border-b-solid pb-8px">
        <p className={cn('typo-heading-4 color-neutral-800')}>Image viewer</p>
      </div>
      <div className={cn('h-full overflow-scroll w-full mt-2')} onKeyDown={onKeyDown} tabIndex={0}>
        <QuickList itemCount={photos.length} ref={setQuickListAPI}>
          {ListItem}
        </QuickList>
      </div>
    </div>
  );
};
