import {
  DeprojectionIcon,
  DrawIcon,
  IconButton,
  ResetIcon,
} from '@/LegacyExplore/components/IconButton';
import { Label } from '@/LegacyExplore/components/Label';
import { ANALYTICS_EVENT_OBJECT } from '@/LegacyExplore/constants/analytics';
import { ANNOTATION_SHAPE_TYPE } from '@/LegacyExplore/constants/annotation';
import { queryClient } from '@/LegacyExplore/graphql/client';
import {
  UpdatePanoramicImagesStatusMutationVariables,
  UpdateProcessImagesStatusMutationVariables,
} from '@/LegacyExplore/graphql/codegen/graphql';
import {
  UPDATE_PANORAMIC_PROCESS_IMAGE_STATUS,
  UPDATE_PROCESS_IMAGES_STATUS,
} from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useFetchProcesses } from '@/LegacyExplore/hooks/useFetchProcesses';
import { useExplore } from '@/LegacyExplore/stores/explore';
import {
  setTargetAnnotation3D,
  startDraw2D,
  startDraw3D,
  stopDraw2D,
  useViewer,
} from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { ProcessImage } from '@/LegacyExplore/utils/process';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { AI_PREDICTOR } from '@/LegacyExplore/utils/split';
import { Photo } from '@skand/data-3d-loader';
import { Button } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Color, Vector2 } from 'three';
import { isEmpty } from '../../../../../../utils/empty';
import { TransformControl } from './TransformControl';

export interface ActionButtonsProps {
  togglePredictor: () => void;
  enabledPredictor: boolean;
}

export const ActionButtons = ({ togglePredictor, enabledPredictor }: ActionButtonsProps) => {
  const treatment = useTreatments([AI_PREDICTOR]);
  const processTabFlag = treatment[AI_PREDICTOR].treatment === 'on';

  const api2D = useViewer(state => state.api2D);
  const api3D = useViewer(state => state.api3D);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const cameraModels = useViewer(state => state.cameraModels);
  const enabledDraw2D = useViewer(state => state.enabledDraw2D);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const projectId = useExplore(state => state.projectId);

  // Process related states
  const targetProcess = useViewer(state => state.targetProcess);
  const processImage = targetProcess?.images.get(targetPhoto?.id ?? '');

  const hasShareLinkToken = getShareLinkToken();
  const annotationDraft = useExplore(state => state.annotationDraft);

  const annotation = useMemo(() => {
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotation.id === annotationDraft?.annotationId) {
          return annotation;
        }
      }
    }
    return undefined;
  }, [annotationDraft, annotationGroups]);

  const [drawModeTooltip, setDrawModeTooltip] = useState(false);
  const [resetTooltip, setResetTooltip] = useState(false);
  const [deprojectionTooltip, setDeprojectionTooltip] = useState(false);

  const updateAnnotationProcessImagesStatus = useMutation({
    mutationFn: (params: UpdateProcessImagesStatusMutationVariables) =>
      request(UPDATE_PROCESS_IMAGES_STATUS, params),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProcesses.getQueryKey(projectId));
    },
  });

  const updatePanoramicProcessImagesStatus = useMutation({
    mutationFn: (params: UpdatePanoramicImagesStatusMutationVariables) =>
      request(UPDATE_PANORAMIC_PROCESS_IMAGE_STATUS, params),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProcesses.getQueryKey(projectId));
    },
  });

  // Toggle the process image status
  const toggleProcessImageStatus = (status: ProcessImage['status']) => {
    if (targetProcess && processImage) {
      if (targetProcess.kind === 'annotation') {
        updateAnnotationProcessImagesStatus.mutate({
          annotationProcessImageStatusIds: [processImage.processImageId],
          status: status.toUpperCase(),
        });
      } else if (targetProcess.kind === 'panorama') {
        updatePanoramicProcessImagesStatus.mutate({
          panoramicProcessImageStatusIds: [processImage.processImageId],
          status: processImage.status === 'created' ? 'PLACED' : 'NOT_PLACED',
        });
      }
    }
  };

  // Toggle the 2D draw tool.
  const toggleDrawTool = () => {
    if (!enabledDraw2D) {
      startDraw2D();
    } else {
      stopDraw2D();
    }
  };

  // Handle resetting the 2D viewer.
  const handleResetView = () => {
    api2D?.editor.resetView();
  };

  // Handle deprojecting the 2D sketch.
  const handleDeprojection = async () => {
    if (
      api3D &&
      annotationDraft?.annotation2d?.points &&
      targetPhoto?.type === 'photo2D' &&
      targetPhoto.widget &&
      targetPhoto.cameraModelId !== undefined
    ) {
      const cameraModel = cameraModels.get(targetPhoto.cameraModelId);
      if (cameraModel) {
        const point2Ds = annotationDraft?.annotation2d?.points?.map(
          point => new Vector2(point?.x ?? 0, point?.y ?? 0),
        );
        const photo: Photo = {
          fileName: targetPhoto.name,
          position: targetPhoto.widget.getPosition(),
          rotation: targetPhoto.widget.getRotation(),
          cameraId: targetPhoto.cameraModelId,
        };
        const closed = annotationDraft.annotation2d.shapeType === ANNOTATION_SHAPE_TYPE.POLYGON;
        const point3Ds = point2Ds.map(point =>
          api3D.deproject.deproject(point, photo, cameraModel),
        );

        // Check whether create or edit
        if (!isEmpty(annotationDraft) && annotation) {
          setTargetAnnotation3D(annotation);
          await startDraw3D(point3Ds, closed, new Color(annotationDraft?.color ?? '#FFFFFF'), true);
        } else {
          await startDraw3D(point3Ds, closed);
        }

        // Immediately save the 3D projection
        api3D.draw.submit();
      }
    }
  };

  return (
    <div className="absolute right-0 top-0 mr-3">
      <div
        className={cn(
          'pt-12px',
          'flex',
          'justify-start',
          'items-center',
          'z-1',
          'pointer-events-auto',
        )}
      >
        {processImage && (
          <>
            {processImage.status !== 'ignored' && processImage.status !== 'checked' && (
              <Button
                active
                className={cn(
                  'h-30px mr-10px',
                  'b-1px! b-solid! b-neutral-100!',
                  'color-neutral-100!',
                  'cursor-pointer',
                )}
                filled
                onClick={() => toggleProcessImageStatus('ignored')}
                primary
                size="small"
              >
                Ignore
              </Button>
            )}
            {processImage.status !== 'checked' && processImage.status !== 'ignored' && (
              <Button
                active
                className={cn(
                  'h-30px mr-10px',
                  'b-1px! b-solid! b-neutral-100!',
                  'color-neutral-100!',
                  'cursor-pointer',
                )}
                filled
                onClick={() => toggleProcessImageStatus('checked')}
                primary
                size="small"
              >
                Submit
              </Button>
            )}
            {processImage.status !== 'created' && (
              <Button
                active
                className={cn(
                  'h-30px mr-10px',
                  'b-1px! b-solid! b-neutral-100!',
                  'color-neutral-100!',
                  'cursor-pointer',
                )}
                filled
                onClick={() => toggleProcessImageStatus('created')}
                primary
                size="small"
              >
                Reopen
              </Button>
            )}
            <TransformControl />
          </>
        )}
        <div className={cn('relative')}>
          {targetPhoto?.type !== 'panorama' && !hasShareLinkToken && (
            <IconButton
              analyticsEventObject={ANALYTICS_EVENT_OBJECT.DRAW_TOOL_2D}
              buttonIcon={<DrawIcon />}
              buttonState={enabledDraw2D ? 'active' : 'default'}
              className={cn(
                'h-30px',
                'mr-10px',
                enabledDraw2D || drawModeTooltip ? 'bg-neutral-400' : 'bg-transparent',
              )}
              disabled={
                targetPhoto === null || enabledDraw3D || annotationDraft?.annotation2d !== undefined
              }
              onClick={toggleDrawTool}
              onMouseEnter={() => setDrawModeTooltip(true)}
              onMouseLeave={() => setDrawModeTooltip(false)}
            />
          )}
          {drawModeTooltip && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Draw tool"
                labelType="default"
              />
            </div>
          )}
        </div>
        <div className={cn('relative')}>
          <IconButton
            buttonIcon={<ResetIcon />}
            buttonState="default"
            className={cn('h-30px', 'mr-10px', resetTooltip ? 'bg-neutral-400' : 'bg-transparent')}
            disabled={targetPhoto === null}
            onClick={handleResetView}
            onMouseEnter={() => setResetTooltip(true)}
            onMouseLeave={() => setResetTooltip(false)}
          />
          {resetTooltip && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Reset View"
                labelType="default"
              />
            </div>
          )}
        </div>
        <div className={cn('relative')}>
          {targetPhoto?.type !== 'panorama' && !hasShareLinkToken && targetPhoto?.widget && (
            <IconButton
              buttonIcon={<DeprojectionIcon />}
              buttonState="default"
              className={cn(
                'h-30px',
                'mr-10px',
                deprojectionTooltip ? 'bg-neutral-400' : 'bg-transparent',
              )}
              data-analytics-event-object={ANALYTICS_EVENT_OBJECT.DEPROJECTION_TOOL}
              disabled={targetPhoto === null || annotationDraft?.annotation2d === undefined}
              onClick={handleDeprojection}
              onMouseEnter={() => setDeprojectionTooltip(true)}
              onMouseLeave={() => setDeprojectionTooltip(false)}
            />
          )}
          {deprojectionTooltip && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Deproject"
                labelType="default"
              />
            </div>
          )}
        </div>
        <div className={cn('relative')}>
          {targetPhoto?.type === 'photo2D' && !hasShareLinkToken && processTabFlag && (
            <Button
              className="cursor-pointer"
              filled
              onClick={togglePredictor}
              primary={enabledPredictor}
            >
              Predict
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
