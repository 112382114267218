import { LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export type EntitlementType = 'quantity' | 'switch';
export const ENTITLEMENT_NAME = {
  ANNOTATION_TEMPLATE: 'annotation-template',
  BRANDING: 'branding',
  PERMISSION: 'permission',
  ROLE: 'role',
  STORAGE: 'storage',
  USER_GROUP: 'user-group',
  USER: 'user',
  GOOGLE_EARTH_GLOBE: 'google-earth-globe',
} as const;
export type EntitlementName = (typeof ENTITLEMENT_NAME)[keyof typeof ENTITLEMENT_NAME];

export const useEntitlements = () => {
  const query = useQuery({
    queryFn: () => request(LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT),
    queryKey: ['LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT'],
    refetchOnWindowFocus: true,
  });

  const entitlements = useMemo(() => {
    return query.data?.listFeatureEntitlementsByAccountContext ?? [];
  }, [query.data?.listFeatureEntitlementsByAccountContext]);

  const feature = useCallback(
    (name: EntitlementName) => {
      return entitlements.find(item => item?.name === name) ?? undefined;
    },
    [entitlements],
  );

  return { query, entitlements, feature };
};
