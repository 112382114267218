import {
  LIST_PROJECTS_BY_ACCOUNT_CONTEXT,
  LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT,
} from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { Project, ProjectGroup } from '@/LegacyExplore/utils/project';
import { createProject, createProjectGroup } from '@/LegacyExplore/utils/transformers';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getProjectGroupQueryKey = () => ['LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT'];
const getProjectQueryKey = () => ['LIST_PROJECTS_BY_ACCOUNT_CONTEXT'];

export const useFetchProjects = () => {
  const groupsResult = useQuery({
    queryFn: () => request(LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT),
    queryKey: getProjectGroupQueryKey(),
  });
  const projectsResult = useQuery({
    queryFn: () => request(LIST_PROJECTS_BY_ACCOUNT_CONTEXT),
    queryKey: getProjectQueryKey(),
  });

  const isLoading = groupsResult.isLoading || projectsResult.isLoading;
  const fetch = useCallback(async () => {
    const groups: ProjectGroup[] = [];
    const projects: Project[] = [];

    for (const groupQuery of groupsResult.data?.listProjectGroupsByAccountContext ?? []) {
      if (!isEmpty(groupQuery)) {
        const group = createProjectGroup(groupQuery);
        if (group) {
          groups.push(group);
        }
      }
    }

    for (const projectQuery of projectsResult.data?.listProjectsByAccountContext ?? []) {
      if (!isEmpty(projectQuery)) {
        const group = groups.find(group => group.id === projectQuery.projectGroupId);
        const project = createProject(projectQuery, group);
        if (project) {
          group?.projects.push(project);
          projects.push(project);
        }
      }
    }
    return { groups, projects };
  }, [groupsResult.data, projectsResult.data]);

  return { fetch, isLoading };
};

useFetchProjects.getProjectGroupQueryKey = getProjectGroupQueryKey;
useFetchProjects.getProjectQueryKey = getProjectQueryKey;
