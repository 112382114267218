import { LIST_MODELS_BY_ACCOUNT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = () => ['LIST_MODELS_BY_ACCOUNT_ID'];

export const useModels = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => request(LIST_MODELS_BY_ACCOUNT_ID),
    queryKey: getQueryKey(),
  });

  const models = useMemo<Array<{ id: string; name: string }>>(() => {
    return (data?.listModelsByAccountId ?? []).filter(
      (model): model is { id: string; name: string } => !!model?.id && !!model?.name,
    );
  }, [data]);

  return { models, isLoading };
};

useModels.getQueryKey = getQueryKey;
