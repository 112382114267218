import { ColorPalette } from '@/LegacyExplore/components/ColorPalette/ColorPalette';
import { DraggableMenu } from '@/LegacyExplore/components/DraggableMenu';
import { cn } from '@/LegacyExplore/utils/classname';
import { TilesetStyle } from '@skand/viewer-component-v2';
import { useState } from 'react';
import { Color } from 'three';

const POINT_CLOUD_CLASSES = [
  'Never Classified',
  'Unassigned',
  'Ground',
  'Low Vegetation',
  'Medium Vegetation',
  'High Vegetation',
  'Building',
  'Low Point (Noise)',
  'Reserved',
  'Water',
  'Rail',
  'Road Surface',
  'Reserved',
  'Wire - Guard (Shield)',
  'Wire - Conductor (Phase)',
  'Transmission Tower',
  'Wire Structure Connector',
  'Bridge Deck',
  'High Noise',
];

export interface ClassificationSettingsProps {
  style: TilesetStyle;
  setStyle: (style: TilesetStyle) => void;
}

export const ClassificationSettings = ({ style, setStyle }: ClassificationSettingsProps) => {
  const [colorTarget, setColorTarget] = useState<number | null>(null);

  const handleClassClick = (index: number) => {
    setColorTarget(colorTarget === index ? null : index);
  };

  const handleClassToggle = (index: number) => {
    setStyle({
      ...style,
      classificationColors: {
        ...style.classificationColors,
        [index]: {
          ...style.classificationColors[index],
          show: !style.classificationColors[index].show,
        },
      },
    });
  };

  const setColor = (index: number, color: Color) => {
    setColorTarget(null);
    setStyle({
      ...style,
      classificationColors: {
        ...style.classificationColors,
        [index]: {
          ...style.classificationColors[index],
          base: color,
        },
      },
    });
  };

  return (
    <div className={cn('flex flex-col mt-3')}>
      {POINT_CLOUD_CLASSES.map((name, index) => (
        <div
          className={cn('mt-2', 'flex', 'flex-row', 'justify-between', 'items-center')}
          key={`class-${index}`}
        >
          <p className={cn('typo-text-small', 'text-neutral-800')}>{name}</p>

          <div className={cn('flex', 'flex-row', 'justify-center', 'items-center')}>
            <button
              className={cn('cursor-pointer', 'bg-transparent', 'b-0', 'mr-2')}
              onClick={() => handleClassClick(index)}
            >
              <div
                className={cn(
                  'h-20px',
                  'w-20px',
                  'b-rounded-4px',
                  'border-solid',
                  'border-neutral-400',
                  'border-1px',
                )}
                style={{
                  background: `#${new Color(
                    style.classificationColors[index].base,
                  ).getHexString()}`,
                }}
              />
            </button>
            <button
              className={cn('cursor-pointer', 'bg-transparent', 'b-0', 'text-neutral-400')}
              onClick={() => handleClassToggle(index)}
            >
              <div
                className={cn(
                  style.classificationColors[index].show ? 'i-skand-show' : 'i-skand-hide',
                )}
              />
            </button>
          </div>
          {colorTarget === index && (
            <DraggableMenu disableCloseButton x={237}>
              <ColorPalette selectColor={color => setColor(index, color)} />
            </DraggableMenu>
          )}
        </div>
      ))}
    </div>
  );
};
