import { SplitIcon } from '@/LegacyExplore/components/IconButton';
import { cn } from '@/LegacyExplore/utils/classname';
import { MouseEvent } from 'react';
interface HandleProps {
  rightSize: number;
  dragging: boolean;
  startDragging: (event: MouseEvent) => void;
}

export const Handle = ({ rightSize, dragging, startDragging }: HandleProps) => {
  return (
    <div
      className={cn(
        'hover:bg-neutral-400',
        !dragging && 'hover:cursor-grab',
        dragging ? 'bg-neutral-400' : 'bg-neutral-100',
        'absolute',
        'w-5px',
        'h-full',
        'z-1',
        'flex',
        'justify-center',
        'items-center',
      )}
      onMouseDown={startDragging}
      style={{ right: `calc(${rightSize}% - 5px)` }}
    >
      <div>
        <SplitIcon />
      </div>
    </div>
  );
};
