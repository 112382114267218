import {
  AnnotationTemplateSelectField,
  AnnotationTemplateSelectOption,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { WithMode, changeOption } from '@/LegacyExplore/stores/template';
import { cn } from '@/LegacyExplore/utils/classname';
import * as Popover from '@radix-ui/react-popover';
import { ColorPicker } from '@skand/ui';
import { useCallback, useState } from 'react';
import { ColorResult } from 'react-color';

export interface ColorProps {
  field: WithMode<AnnotationTemplateSelectField>;
  index: number;
  option: null | AnnotationTemplateSelectOption;
}

export const Color = ({ field, index, option }: ColorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (color: ColorResult) => {
      changeOption(field, index, { color: color.hex.toUpperCase() });
    },
    [field, index],
  );

  return (
    <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
      <Popover.Trigger asChild>
        <div
          className={cn(
            'w-128px',
            'b-1 b-solid hover:b-neutral-800',
            'flex-nowrap flex-row flex',
            'items-center justify-between',
            'p-x-3 p-y-2',
            'rounded-1',
            'typo-text-s',
            'color-neutral-600',
            'cursor-pointer',
            isOpen ? 'b-neutral-800' : 'b-neutral-400',
          )}
          onClick={() => setIsOpen(true)}
        >
          {option?.color ?? 'Select colour'}

          <div
            className={cn('w-3 h-3', 'ml-4', 'rounded-full', 'ring-1 ring-inset ring-neutral-300')}
            style={{ backgroundColor: option?.color ?? '#FFFFFF' }}
          />
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className="z-1" side="left" sideOffset={224}>
          <ColorPicker
            closeMenu={() => setIsOpen(false)}
            color={option?.color ?? '#FFFFFF'}
            colorPickerHeader="Custom colour"
            openingCoordinates={{ x: 0, y: -274 }}
            setColor={handleChange}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
