import { FindIcon } from '@/LegacyExplore/components/IconButton';
import { cn } from '@/LegacyExplore/utils/classname';
import { search } from '@/LegacyExplore/utils/search';
import { Input } from '@skand/ui';
import { IfcElementNode } from '@skand/viewer-component-v2';
import { useCallback, useMemo, useState } from 'react';

type PropertyValue = null | string | boolean | number | string;

type Field = [string, PropertyValue | PropertyValue[]];

interface DropdownProps {
  field: Field;
}

export interface IFCPropertiesMenuProps {
  node: IfcElementNode;
}

export const IFCPropertiesMenu = ({ node }: IFCPropertiesMenuProps) => {
  const fields: Field[] = useMemo(
    () => [
      ['Name', node.name],
      ['ID', node.id],
      ['GUID', node.guid],
      ['Type', node.type],
      ['Description', node.description],
      ['Object Type', node.objectType],
      ['Tag', node.tag],
      ['Materials', node.materials],
      ...node.properties
        .map(set => set.fields.map(field => [`${set.name} ${field.key}`, field.value] as Field))
        .flat(1),
    ],
    [node],
  );
  const [toggled, setToggled] = useState<Set<Field[0]>>(new Set(fields.map(field => field[0])));
  const [searchKey, setSearchKey] = useState('');

  // Filter categories by search key
  const filtered = useMemo(
    () => fields.filter(field => search(field[0] + JSON.stringify(field[1]), searchKey)),
    [fields, searchKey],
  );

  // Toggle a dropdown
  const handleToggle = useCallback(
    (node: Field) => {
      if (!toggled.has(node[0])) {
        setToggled(prev => new Set(prev).add(node[0]));
      } else {
        setToggled(prev => {
          const set = new Set(prev);
          set.delete(node[0]);
          return set;
        });
      }
    },
    [toggled],
  );

  const Dropdown = useCallback(
    ({ field }: DropdownProps) => {
      const data: PropertyValue[] = Array.isArray(field[1]) ? field[1] : [field[1]];
      return (
        <div>
          <button
            className={cn(
              'bg-transparent',
              'b-0',
              'flex',
              'flex-row',
              'justify-start',
              'min-w-14rem',
              'items-center',
              'pt-1',
            )}
          >
            <div
              className={cn(
                'i-skand-dropdownarrow',
                'text-neutral-400',
                'text-3',
                'cursor-pointer',
                !toggled.has(field[0]) && 'rotate-270',
              )}
              onClick={() => handleToggle(field)}
            />

            <p
              className={cn(
                'typo-text-small',
                'text-neutral-800',
                'pl-4',
                'pr-4',
                'whitespace-nowrap',
                'cursor-auto',
                'select-text',
                'skand-rnd-disable',
              )}
            >
              {field[0]}
            </p>
          </button>
          {toggled.has(field[0]) && (
            <div className={cn('typo-text-small', 'text-neutral-500', 'ml-2', 'skand-rnd-disable')}>
              {data.map((value, index) => (
                <p key={`property-${index}`}>{value ? value : 'N/A'}</p>
              ))}
            </div>
          )}
        </div>
      );
    },
    [handleToggle, toggled],
  );

  return (
    <div className={cn('w-full flex flex-col')}>
      <p
        className={cn(
          'typo-text-small',
          'text-neutral-800',
          'b-solid',
          'b-neutral-400',
          'border-b-1',
          'border-l-0',
          'border-r-0',
          'border-t-0',
          'pb-3',
          'mb-4',
        )}
      >
        Metadata
      </p>
      <Input label="Search" onChange={setSearchKey} tail={<FindIcon />} value={searchKey} />
      <div className="mt-2 h-100 overflow-scroll">
        {filtered.map((field, index) => (
          <Dropdown field={field} key={`property-${index}`} />
        ))}
      </div>
    </div>
  );
};
