import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { queryClient } from '@/LegacyExplore/graphql/client';
import { UPDATE_PROJECT_GROUP } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useFetchProjectPermissions } from '@/LegacyExplore/hooks/useFetchProjectPermissions';
import { useFetchProjects } from '@/LegacyExplore/hooks/useFetchProjects';
import { closeEditGroup, useProjectStore } from '@/LegacyExplore/stores/project';
import { cn } from '@/LegacyExplore/utils/classname';
import { ProjectGroup } from '@/LegacyExplore/utils/project';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, Input } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { forwardRef, useState } from 'react';
import { dialog, island } from '../classes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditGroupCoreProps {}

const EditGroupCore = (props: EditGroupCoreProps, ref: React.Ref<HTMLDivElement>) => {
  const group = useProjectStore(state => state.editGroup.group) as ProjectGroup;
  const [name, setName] = useState(group.name);
  const [description, setDescription] = useState(group.description);

  const { getGroupPermission } = useFetchProjectPermissions();
  const groupPermission = getGroupPermission(group.id);
  const canEditGroup = canPolicyActionEdit(groupPermission);

  const isDirty = name !== (group.name ?? '') || description !== (group.description ?? '');

  const updateGroup = useMutation({
    mutationFn: () =>
      request(UPDATE_PROJECT_GROUP, { projectGroupId: group.id, name, description: description }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
      closeEditGroup();
    },
  });

  return (
    <Dialog.Content className={dialog} ref={ref} {...props}>
      <Dialog.Title className="color-neutral-800 typo-text-l">Project group details</Dialog.Title>

      <div className="mt-6 flex-none">
        <Input
          disabled={!canEditGroup}
          label="Project group name"
          onChange={setName}
          value={name}
        />
      </div>

      <div className="mt-3 flex-none">
        <Input disabled label="Date created" value={group.createdAt.toLocaleDateString()} />
      </div>

      <div className={cn(island, 'mt-3 h-256px flex-1 overflow-auto')}>
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className="b-b-1 b-b-neutral-500 b-b-solid">
              <td className="pb-2 uppercase color-neutral-800 typo-button-xs">projects</td>
              <td className="pb-2 uppercase color-neutral-800 typo-button-xs">date created</td>
            </tr>
          </thead>

          <tbody>
            {group.projects.map(project => {
              return (
                <tr className="" key={project.id}>
                  <td className="pt-2 color-neutral-800 typo-text-s">{project.name}</td>
                  <td className="pt-2 color-neutral-800 typo-text-s">
                    {project.createdAt.toLocaleDateString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <p className="mt-4 color-neutral-800 typo-text-m">General information</p>
      <textarea
        className={cn(island, 'color-neutral-800 typo-text-s mt-2 resize-none h-64px')}
        disabled={!canEditGroup}
        onChange={e => setDescription(e.target.value)}
        value={description}
      />

      <div className="mt-auto flex gap-3 pt-3">
        <Dialog.Close asChild>
          <Button className="flex-1 hover:cursor-pointer" onClick={closeEditGroup} size="s">
            Cancel
          </Button>
        </Dialog.Close>
        {canEditGroup && (
          <Button
            className="flex-1 hover:cursor-pointer"
            disabled={!isDirty}
            filled
            onClick={() => updateGroup.mutate()}
            primary
            size="s"
          >
            Update
          </Button>
        )}
      </div>
    </Dialog.Content>
  );
};

export const EditGroup = forwardRef(EditGroupCore);
