import { cn } from '@/LegacyExplore/utils/classname';
import { ReactNode } from 'react';

interface PaneProps {
  left: boolean;
  size: number;
  children: ReactNode;
}

export const Pane = ({ left, size, children }: PaneProps) => {
  return (
    <div
      className={cn(
        'absolute',
        'h-100%',
        size === 0 && 'display-none',
        left ? `left-0` : `right-0`,
        'overflow-y-scroll',
      )}
      style={{ width: `${size}%` }}
    >
      {children}
    </div>
  );
};
