import { NodeGroupIcon } from '@/LegacyExplore/components/IconButton';
import { AnnotationGroup } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
export interface AnnotationGroupNodeProps {
  group: AnnotationGroup;
}

export const AnnotationGroupNode = ({ group }: AnnotationGroupNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <NodeGroupIcon className="mr-2 text-3 color-neutral-600" />
      <div className={cn('flex items-center gap-2 text-neutral-600 ')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
      </div>
    </div>
  );
};
