import { queryClient } from '@/graphql/client';
import { request } from '@/graphql/request';
import { ADVANCED_UPLOADER } from '@/utils/split';
import { RequestContextProvider, QueryClientProvider as UploadQCProvider } from '@skand/uploader';
import { useTreatments } from '@splitsoftware/splitio-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Upload } from '../Upload';
import { UploadLegacy } from './UploadLegacy';

export const QueryClientWrapper = () => {
  const treatments = useTreatments([ADVANCED_UPLOADER]);
  const isAdvancedUploader = treatments[ADVANCED_UPLOADER].treatment === 'on';

  return (
    <QueryClientProvider client={queryClient}>
      {isAdvancedUploader ? (
        <Upload />
      ) : (
        <UploadQCProvider client={queryClient}>
          <RequestContextProvider value={request}>
            <UploadLegacy />
          </RequestContextProvider>
        </UploadQCProvider>
      )}
    </QueryClientProvider>
  );
};
