import { PanoGroupIcon, PhotoGroupIcon } from '@/components/IconButton';
import { MoreMenu } from '@/components/MoreMenu';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import {
  CameraModel,
  ImageProjectionProcess,
  ImageProjectionRenderObjectImage,
  ImagesWithStatusField,
  RenameSceneEntityMutationVariables,
  TileImagesMutationVariables,
  UpdateImageProjectionRenderObjectCameraModelsMutationVariables,
  UpdateImageProjectionRenderObjectImagesMutationVariables,
} from '@/graphql/codegen/graphql';
import {
  RENAME_SCENE_ENTITY,
  TILE_IMAGES,
  UPDATE_IMAGE_PROCESS_CAMERAS,
  UPDATE_IMAGE_PROCESS_IMAGES,
} from '@/graphql/mutations';
import { READ_PROCESS_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useFetchPhotos } from '@/hooks/useFetchPhotos';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import { useExplore } from '@/stores/explore';
import { PhotoGroup, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { batch, BentleyLoader } from '@skand/data-3d-loader';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { ModelNode, SceneNode } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Matrix4, Quaternion, Vector3 } from 'three';
import { ListItemButton } from './ListItemButton';

export interface PhotoGroupNodeProps {
  group: PhotoGroup;
  setSettingsNodeTarget: (group: PhotoGroup | null) => void;
  setLinkNodeTarget: (group: PhotoGroup | null) => void;
  settingsNodeTarget: PhotoGroup | null;
  setEditNodeTarget: (node: PhotoGroup | null) => void;
  editNodeTarget: PhotoGroup | null;
}

export const PhotoGroupNode = ({
  group,
  setSettingsNodeTarget,
  setLinkNodeTarget,
  settingsNodeTarget,
  setEditNodeTarget,
  editNodeTarget,
}: PhotoGroupNodeProps) => {
  const projectId = useExplore(state => state.projectId);
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);
  const visiblePhotoGroups = useViewer(state => state.visiblePhotoGroups);

  const isPhoto2D = group.photos.length === 0 || group.photos[0].type === 'photo2D';
  const isVisible = visiblePhotoGroups.has(group.id);

  const [editInput, setEditInput] = useState(group.name);

  const canTile = useMemo(
    () =>
      !group.photos.every(
        photo =>
          ['IN_PROGRESS', 'SUCCESS'].includes(photo.tilesetEventStatus) &&
          ['IN_PROGRESS', 'SUCCESS'].includes(photo.thumbnailEventStatus),
      ),
    [group.photos],
  );

  const tileImages = useMutation({
    mutationFn: (variables: TileImagesMutationVariables) => request(TILE_IMAGES, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchPhotos.getQueryKey([group.renderObjectId]));
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: `Tiling images under '${group.name}'.`,
        lifespan: 5000,
      });
    },
  });

  // Handle tiling the images
  const handleTiling = async () => {
    const fileIds = group.photos.filter(photo => !photo.tileset).map(photo => photo.id);
    await tileImages.mutate({ fileIds });
  };

  const updateCameraModels = useMutation({
    mutationFn: (variables: UpdateImageProjectionRenderObjectCameraModelsMutationVariables) =>
      request(UPDATE_IMAGE_PROCESS_CAMERAS, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchPhotos.getQueryKey([group.renderObjectId]));
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: `Updating camera models for '${group.name}'.`,
        lifespan: 5000,
      });
    },
  });

  const updateImagePoses = useMutation({
    mutationFn: (variables: UpdateImageProjectionRenderObjectImagesMutationVariables) =>
      request(UPDATE_IMAGE_PROCESS_IMAGES, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchPhotos.getQueryKey([group.renderObjectId]));
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: `Updating poses for images under '${group.name}'.`,
        lifespan: 5000,
      });
    },
  });

  // Handle regenerating 2D photo poses
  const handleRegeneratePose = async () => {
    const toastHandle = toast({
      type: 'info',
      message: `Regenerating image poses under '${group.name}'...`,
    });

    const loader = new BentleyLoader();
    try {
      const processResult = await queryClient.fetchQuery({
        queryFn: () => request(READ_PROCESS_BY_ID, { processId: group.processId }),
        queryKey: ['READ_PROCESS_BY_ID', group.processId],
      });
      const process = processResult.readProcessById as ImageProjectionProcess;
      const xmlFileUrl = process.xmlFile?.signedGetObjectUrl as string;
      const imagesWithStatus = process.imagesWithStatus as ImagesWithStatusField[];

      const formatFileResponse = await fetch(xmlFileUrl);
      const blob = await formatFileResponse.text();
      const xml = await loader.read(blob);

      // Update cameras
      const cameraModels: CameraModel[] = [];
      for (const [id, camera] of xml.cameras) {
        cameraModels.push({
          id: `${id}`,
          distortion: camera.distortion,
          focalLength: camera.focalLength,
          imageSize: [camera.imageSize.x, camera.imageSize.y],
          principalPoint: [camera.principalPoint.x, camera.principalPoint.y],
          projectionString: camera.spatialReferenceProj,
          sensorSize: camera.sensorSize,
          skew: camera.skew,
        });
      }
      await updateCameraModels.mutateAsync({
        renderObjectId: group.renderObjectId,
        cameraModels,
      });

      // Update images
      const images: ImageProjectionRenderObjectImage[] = [];
      for (const processImage of imagesWithStatus) {
        const image = xml.photos.find(query => {
          const filename = (processImage.fileName as string).split('.').slice(0, -1).join('.');
          return filename === query.fileName;
        });
        if (!image) continue;

        // Compute parent matrix
        const parentMatrix = new Matrix4();
        if (
          group.parent?.sceneNode instanceof SceneNode ||
          group.parent?.sceneNode instanceof ModelNode
        ) {
          parentMatrix.compose(
            group.parent.sceneNode.getPosition(),
            group.parent.sceneNode.getRotation(),
            group.parent.sceneNode.getScale(),
          );
        }

        // Compute local matrix
        const localPosition = new Vector3();
        const localRotation = new Quaternion();
        new Matrix4()
          .compose(image.position, image.rotation, new Vector3(1, 1, 1))
          .premultiply(parentMatrix.invert())
          .decompose(localPosition, localRotation, new Vector3(1, 1, 1));

        images.push({
          fileId: processImage.fileId,
          imageProjectionRenderObjectId: group.renderObjectId,
          name: image.fileName,
          cameraRelativePosition: [localPosition.x, localPosition.y, localPosition.z],
          cameraRelativeRotation: [
            localRotation.x,
            localRotation.y,
            localRotation.z,
            localRotation.w,
          ],
          cameraModelId: `${image.cameraId}`,
        });
      }

      const batches = batch(images, 1000);
      for (const batch of batches) {
        await updateImagePoses.mutateAsync({
          renderObjectId: group.renderObjectId,
          processId: group.processId,
          images: batch,
        });
      }
    } catch (error) {
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 5000,
      });
    } finally {
      toastHandle.dismiss();
    }
  };

  // Handle toggling the images
  const handleToggle = () => {
    if (visiblePhotoGroups.has(group.id)) {
      useViewer.setState(prev => {
        const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
        visiblePhotoGroups.delete(group.id);
        return { visiblePhotoGroups };
      });
    } else {
      useViewer.setState(prev => {
        const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
        visiblePhotoGroups.add(group.id);
        return { visiblePhotoGroups };
      });
    }
  };

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(group.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);
  const updatePhotoGroup = useMutation({
    mutationFn: (variables: RenameSceneEntityMutationVariables) =>
      request(RENAME_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully updated photo group node.',
        lifespan: 5000,
      });
    },
  });

  // Handle keypress events
  const handleInputKeypress = (key: string) => {
    if (key === 'Enter') {
      handleSubmitName();
    }
    if (key === 'Escape') {
      setEditNodeTarget(null);
    }
  };

  // Handle submitting the edited name
  const handleSubmitName = async () => {
    if (!projectId) return;

    await updatePhotoGroup.mutateAsync({
      sceneEntityId: group.sceneEntityId,
      projectId,
      name: editInput,
    });
    setEditNodeTarget(null);
  };

  return (
    <>
      {editNodeTarget === group ? (
        <div className="flex flex-1">
          <input
            className={cn(
              'px-1',
              'color-neutral-800',
              'typo-text-s-em',
              'rounded',
              'border-1',
              'border-solid',
              'border-primary-400',
              'outline-none',
              'w-full',
            )}
            onChange={e => setEditInput(e.target.value)}
            onKeyDown={e => handleInputKeypress(e.key)}
            value={editInput}
          />
          <Button className="ml-1" filled onClick={handleSubmitName} primary size="xs">
            Save
          </Button>
        </div>
      ) : (
        <>
          {isPhoto2D ? (
            <PhotoGroupIcon className="w-5 text-3 color-neutral-600" />
          ) : (
            <PanoGroupIcon className="w-5 text-3 color-neutral-600" />
          )}
          <p
            className={cn(
              'group',
              'cursor-pointer',
              'typo-text-small',
              'text-neutral-800',
              'flex-1 whitespace-nowrap',
            )}
            onDoubleClick={() => {
              if (canEdit) {
                setEditNodeTarget(group);
                setEditInput(group.name);
              }
            }}
            title={group.name}
          >
            {group.name}
          </p>
          <div
            className={cn(
              'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
            )}
          >
            <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
              {group.photos.length}
            </p>
          </div>

          <div className="w-48px" />
          <div
            className={cn(
              'fixed right-0 h-32px flex flex-none items-center gap-2 pl-2',
              !enabledSelectMode && 'bg-neutral-100',
            )}
            style={{
              boxShadow: !enabledSelectMode ? '-8px 0px 8px -2px rgba(255,255,255,1)' : 'none',
            }}
          >
            {!enabledSelectMode && (
              <ListItemButton
                active={group === settingsNodeTarget}
                icon={<div className="i-skand-gear" />}
                onClick={() => setSettingsNodeTarget(group === settingsNodeTarget ? null : group)}
              />
            )}
            <ListItemButton
              icon={
                <div className={cn('i-skand-eye', isVisible ? 'i-skand-show' : 'i-skand-hide')} />
              }
              onClick={handleToggle}
            />

            <MoreMenu
              className={cn(
                canPolicyActionEdit(permission) ? 'cursor-pointer' : 'opacity-0',
                enabledSelectMode && 'hidden',
              )}
            >
              {canPolicyActionEdit(permission) && (
                <Menu className="z-2">
                  <MenuItem className="cursor-pointer" onClick={() => setLinkNodeTarget(group)}>
                    Link to layer
                  </MenuItem>

                  {group.photos.length && group.photos[0].type === 'photo2D' && (
                    <MenuItem className="cursor-pointer" onClick={handleRegeneratePose}>
                      Regenerate pose
                    </MenuItem>
                  )}

                  {canTile && (
                    <MenuItem className="cursor-pointer" onClick={handleTiling}>
                      Generate image tiles
                    </MenuItem>
                  )}
                </Menu>
              )}
            </MoreMenu>
          </div>
        </>
      )}
    </>
  );
};
