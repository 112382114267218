import { AnnotationGroup, LayerGroup, PhotoGroup } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { LayeringTab } from './LayeringTab';

export interface GroupSettingsMenuProps {
  layer: LayerGroup | AnnotationGroup | PhotoGroup;
}

export const GroupSettingsMenu = ({ layer }: GroupSettingsMenuProps) => {
  return (
    <div className={cn('w-full', 'flex', 'flex-col')}>
      <div
        className={cn(
          'items-center',
          'pb-2',
          'flex',
          'h-auto',
          'justify-start',
          'w-full',
          'mt-1',
          'b-solid',
          'b-neutral-400',
          'border-b-1',
          'border-l-0',
          'border-r-0',
          'border-t-0',
        )}
      >
        <p className={cn('typo-text-small', 'text-neutral-800')}>Layering</p>
      </div>
      <LayeringTab layer={layer} />
    </div>
  );
};
