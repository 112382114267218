import { FindIcon } from '@/LegacyExplore/components/IconButton';
import { EXPLORE_ROOT } from '@/LegacyExplore/constants/paths';
import { cn } from '@/LegacyExplore/utils/classname';
import { Project } from '@/LegacyExplore/utils/project';
import { Button, Input } from '@skand/ui';

export interface SearchProjectProps {
  projects: Project[];
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
}

export const SearchProject = ({ projects, searchKey, setSearchKey }: SearchProjectProps) => {
  return (
    <div className={cn('absolute', 'left-7', 'top-7', 'z-1', 'w-70')}>
      <Input
        onChange={setSearchKey}
        placeholder="Search for projects"
        tail={<FindIcon />}
        value={searchKey}
      />
      {searchKey.trim().length > 0 && (
        <div
          className={cn(
            'flex',
            'w-full',
            'bg-neutral-100',
            'p-3',
            'rounded-1',
            'border-1px',
            'border-solid',
            'border-neutral-400',
            'flex-col',
          )}
        >
          <div className={cn('min-h-1', 'max-h-50', 'overflow-scroll')}>
            {projects.map((project: SolidId<Project>, index: number) => (
              <div
                className={cn(
                  'flex',
                  'w-full',
                  'flex-row',
                  'justify-between',
                  'items-center',
                  'pt-2',
                )}
                key={index}
              >
                <p className="color-neutral-600 typo-text-xs">{project.name}</p>
                <Button
                  className={cn('cursor-pointer w-64px h-18px')}
                  filled
                  onClick={() => {
                    window.location.href = `${EXPLORE_ROOT}?project=${project.id}`;
                  }}
                  primary
                  size="xs"
                >
                  LAUNCH
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
