import { downloadBlob } from '@/LegacyExplore/utils/download';
import { Draw2D, Geometry, GestureControl, ImageDrawable } from '@skand/viewer-component-v2';
import { Color, Vector2 } from 'three';
import { DrawController, NavigationController } from './Controllers';
import { LineSketch2, PointSketch2, PolygonSketch2, Sketch2 } from './Sketch2';
import { Transform2 } from './Transform2';

/**
 * API for rendering and editing 2D sketches on an image.
 */
export class Editor {
  private canvas: HTMLCanvasElement;
  private draw2D: Draw2D;
  private gestures: GestureControl;

  private transform: Transform2;
  private navigationController: NavigationController;
  private drawController: DrawController;

  private dimensions: Vector2;
  private current: ImageDrawable & { id: string | null };

  private sketches: Set<Sketch2>;
  private visible: Set<Sketch2>;

  private loading: boolean;

  private targetTransform: Transform2 | null;

  private abortController: AbortController | undefined;

  private listener: (sketch: Sketch2) => void;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.draw2D = new Draw2D(canvas);
    this.gestures = new GestureControl(canvas);

    this.transform = new Transform2();
    this.navigationController = new NavigationController(this.canvas, this.transform);
    this.drawController = new DrawController(
      this.draw2D,
      this.transform,
      this.navigationController,
    );

    this.dimensions = new Vector2();
    this.current = {
      id: null,
      type: 'image',
      src: {
        imageType: 'element',
        blob: new Image(),
      },
      position: new Vector2(),
      opacity: 1.0,
      destinationSize: new Vector2(),
      zIndex: -1,
    };

    this.sketches = new Set();
    this.visible = new Set();

    this.loading = false;
    this.targetTransform = null;
    this.abortController = undefined;
    this.listener = () => {};

    this.initializeControls();
  }

  /**
   * Calculate the minimum zoom level.
   */
  private getMinZoom() {
    return (this.canvas.width * 0.8) / this.dimensions.x;
  }

  /**
   * Apply translation and scaling to the image and the sketches.
   */
  private applyTransform() {
    this.current.destinationSize = this.transform.scaleDimensions(this.dimensions);
    this.current.position = this.transform.getTranslation();

    for (const sketch of this.sketches) {
      sketch.applyTransform(this.transform);
    }
    this.drawController.applyTransform();
  }

  /**
   * Refresh the editor with the latest updates.
   */
  private refresh() {
    // Refresh the drawn image
    this.draw2D.removeDrawable(this.current);
    this.draw2D.addDrawable(this.current);
  }

  /**
   * Initialize gesture handlers for controlling the camera.
   */
  private initializeControls() {
    this.navigationController.start();
    this.gestures.register('tap', gesture => {
      const { position } = gesture;

      // Sketch clicking
      for (const sketch of this.sketches) {
        if (sketch.isVisible() && sketch.isColliding(position) && !this.drawController.isActive()) {
          this.listener(sketch);
          return;
        }
      }
    });
  }

  /**
   * Cleanup and destroy the editor instance.
   */
  public destroy() {
    this.drawController.stop();
    this.gestures.unregisterAll();
  }

  /**
   * Zoom in to a cluster of points on the image.
   *
   * @param vertices
   */
  public zoomToCluster(vertices: Vector2[]) {
    // Calculate centroid
    const n = vertices.length;
    const centroid = new Vector2();
    for (const vertex of vertices) {
      centroid.add(vertex);
    }
    centroid.divideScalar(n);

    // Calculate zoom level
    let span = 0;
    for (let i = 0; i < n - 1; i++) {
      for (let j = i + 1; j < n; j++) {
        const dist = vertices[i].distanceTo(vertices[j]);
        span = Math.max(span, dist);
      }
    }
    const zoom = Math.min((this.canvas.width * 0.75) / span, (this.canvas.height * 0.75) / span);

    // Set target transform
    this.targetTransform = new Transform2();
    this.targetTransform.setZoom(zoom);
    this.targetTransform.setTranslation(centroid.x, centroid.y);
  }

  /**
   * Zoom in to a polygon on the image.
   *
   * @param vertices
   */
  public lookAt(sketch: Sketch2) {
    this.zoomToCluster(sketch.getVertices());
  }

  /**
   * Reset translation and zoom so the image fills the width of the canvas.
   */
  public resetView() {
    const zoom = this.canvas.width / this.dimensions.x;
    this.transform.setZoom(zoom);
    this.transform.setTranslation(0, (this.canvas.height - zoom * this.dimensions.y) / 2);
  }

  /**
   * Set the current photo.
   *
   * @param id
   * @param url
   * @param onProgress
   * @returns
   */
  public async setPhoto(id: string, url: string, onProgress: (percent: number) => void = () => {}) {
    // Abort previous request
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    // Load image metadata
    const img = new Image();
    this.loading = true;
    this.current.id = id;
    this.current.src = {
      imageType: 'element',
      blob: img,
    };

    return new Promise<Editor>((resolve, reject) => {
      downloadBlob(url, onProgress, this.abortController)
        .then(blob => (img.src = window.URL.createObjectURL(blob)))
        .catch(error => error !== 'abort' && reject(error));

      img.onload = () => {
        this.abortController = undefined;
        this.dimensions.x = img.width;
        this.dimensions.y = img.height;
        this.loading = false;
        this.refresh();
        this.resetView();
        resolve(this);
      };
      img.onerror = () => {
        reject(new Error(`Could not load image resource \`${url}\``));
      };
    });
  }

  /**
   * Set the size of the editor.
   *
   * @param size
   */
  public setSize(size: Vector2) {
    this.draw2D.setSize(size);
  }

  /**
   * Create a sketch to be drawn on top of the image.
   *
   * @param type
   * @param vertices
   * @param color
   * @returns
   */
  public createSketch(type: Geometry, vertices: Vector2[], color: Color) {
    let sketch: Sketch2;
    const destroySketch = (sketch: Sketch2) => {
      sketch.hide();
      this.sketches.delete(sketch);
    };
    switch (type) {
      case 'point':
        sketch = new PointSketch2(this.draw2D, vertices, color, destroySketch);
        break;
      case 'lines':
        sketch = new LineSketch2(this.draw2D, vertices, color, destroySketch);
        break;
      case 'polygon':
        sketch = new PolygonSketch2(this.draw2D, vertices, color, destroySketch);
        break;
    }
    this.sketches.add(sketch);
    return sketch;
  }

  /**
   * Show all sketches (that are visible)
   */
  public showSketches() {
    for (const sketch of this.visible) {
      sketch.show();
    }
    this.visible.clear();
  }

  /**
   * Hide all sketches (that are visible)
   */
  public hideSketches() {
    for (const sketch of this.sketches) {
      if (sketch.isVisible()) {
        this.visible.add(sketch);
        sketch.hide();
      }
    }
  }

  /**
   * Register an on-click listener for sketches.
   *
   * @param onClick
   */
  public setListener(onClick: (sketch: Sketch2) => void) {
    this.listener = onClick;
  }

  /**
   * Detach an existing on-click listener.
   */
  public removeListener() {
    this.listener = () => {};
  }

  /**
   * Get the draw controller.
   *
   * @returns
   */
  public getDrawController() {
    return this.drawController;
  }

  /**
   * Update the current transform to fly towards target transform.
   */
  private updateFlyTo() {
    if (this.targetTransform !== null && !this.loading) {
      const imagePoint = this.targetTransform.getTranslation();
      const targetZoom = this.targetTransform.getZoom();
      const canvasCenter = new Vector2(this.canvas.width / 2, this.canvas.height / 2);
      const targetTranslation = imagePoint.clone().multiplyScalar(-targetZoom).add(canvasCenter);

      const currentTranslation = this.transform.getTranslation();
      const currentZoom = this.transform.getZoom();
      const deltaZoom = targetZoom - currentZoom;
      const deltaTranslate = targetTranslation.clone().sub(currentTranslation);
      if (
        Math.abs(deltaZoom) >= 0.01 ||
        Math.abs(deltaTranslate.x) >= 3 ||
        Math.abs(deltaTranslate.y) >= 3
      ) {
        this.transform.setZoom(deltaZoom * 0.1 + this.transform.getZoom());
        this.transform.setTranslation(
          deltaTranslate.x * 0.1 + currentTranslation.x,
          deltaTranslate.y * 0.1 + currentTranslation.y,
        );
      } else {
        this.transform.setTranslation(targetTranslation.x, targetTranslation.y);
        this.transform.setZoom(targetZoom);
        this.targetTransform = null;
      }
    }
  }

  /**
   * Main update loop.
   */
  public update() {
    this.draw2D.render();
    this.navigationController.setMinZoom(this.getMinZoom());
    this.navigationController.update();
    this.updateFlyTo();
    this.applyTransform();
  }
}
