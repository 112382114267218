import { PanoGroupIcon, PhotoGroupIcon } from '@/LegacyExplore/components/IconButton';
import { PhotoGroup } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';

export interface PhotoGroupNodeProps {
  group: PhotoGroup;
}
export const PhotoGroupNode = ({ group }: PhotoGroupNodeProps) => {
  const isPhoto2D = group.photos.length === 0 || group.photos[0].type === 'photo2D';
  return (
    <div className={cn('flex justify-between items-center py-1')}>
      {isPhoto2D ? (
        <PhotoGroupIcon className="mr-2 text-3 color-neutral-600" />
      ) : (
        <PanoGroupIcon className="mr-2 text-3 color-neutral-600" />
      )}
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
      </div>
    </div>
  );
};
