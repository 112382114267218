import { OBJECT_ID, Policy } from '@/LegacyExplore/constants/policy';
import { PolicyObjectTypeInput } from '@/LegacyExplore/graphql/codegen/graphql';
import { LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { createPolicy } from '@/LegacyExplore/utils/transformers';
import { useQuery } from '@tanstack/react-query';
import { useFetchUser } from './useFetchUser';

const getQueryKey = (objectType: PolicyObjectTypeInput, objectIds?: string[]) => [
  'LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS',
  objectType,
  objectIds,
];

export const useFetchPermissionPolicies = (
  objectType: PolicyObjectTypeInput,
  objectIds?: string[],
) => {
  const user = useFetchUser();
  const result = useQuery({
    enabled: !!user,
    queryFn: () =>
      request(LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS, {
        subjectId: user?.id as string,
        objectIds: objectIds ? [OBJECT_ID.ALL, ...objectIds] : [OBJECT_ID.ALL],
        objectTypes: [PolicyObjectTypeInput.All, objectType],
        actionTypes: null,
      }),
    queryKey: getQueryKey(objectType, objectIds),
  });

  const policies: Policy[] = [];
  for (const policyQuery of result.data
    ?.listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds ?? []) {
    if (policyQuery) {
      const policy = createPolicy(policyQuery);
      if (policy) {
        policies.push(policy);
      }
    }
  }

  return policies;
};
