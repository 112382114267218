import { canPolicyActionEdit } from '@/constants/policy';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { useFetchProject } from '@/hooks/useFetchProject';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { useIsSuperAdmin } from '@/hooks/useIsSuperAdmin';
import { useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { createTemplate, useTemplate } from '@/stores/template';
import { cn } from '@/utils/classname';
import { debounce } from '@/utils/debounce';
import { Button, Input } from '@skand/ui';
import { useEffect, useMemo, useState } from 'react';
import { AccessGate, FeatureNotIncluded } from '../AccessGate';
import { BlockLoading } from '../BlockLoading';
import { EmptyResourceMessage } from '../EmptyResourceMessage';
import { FindIcon } from '../IconButton';
import { Sort } from './Sort';
import { TemplateItem } from './TemplateItem';
export interface TemplateTabViewProps {
  annotationData: { id: string; annotationGroupId: string }[];
  searchQuery: string;
}

export type SortKey = 'name' | 'createdAt';

interface TemplateTabSearchAndFilterProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  disabled?: boolean;
}

const TemplateTabHeader = () => {
  return (
    <div
      className={cn(
        'flex justify-between items-end border-neutral-300 border-1 border-b-solid pb-3 mb-3',
      )}
    >
      <p className={cn('typo-heading-4 color-neutral-800')}>Templates</p>
    </div>
  );
};

const TemplateTabSearchAndFilter = ({
  searchQuery,
  setSearchQuery,
  disabled = false,
}: TemplateTabSearchAndFilterProps) => {
  const isShowingTemplatePanel = useLayout(state => state.isShowingTemplatePanel);
  const showTemplatePanel = useLayout(state => state.showTemplatePanel);
  const mode = useTemplate(state => state.mode);
  const { fetch: fetchProject } = useFetchProject();

  // Fetch project information
  useEffect(() => {
    fetchProject().then(project => {
      if (project) {
        document.title = project.name;
      }
    });
  }, [fetchProject]);

  return (
    <div className={cn('flex flex-col gap-3 mb-3')}>
      <form className={cn('flex gap-2')} onSubmit={e => e.preventDefault()}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search"
            onChange={event => setSearchQuery(event)}
            tail={<FindIcon />}
            value={searchQuery}
          />
        </div>
      </form>

      <AccessGate
        disabled={() => (
          <FeatureNotIncluded
            button={
              <Button className="w-full" disabled filled primary size="s">
                Create template
              </Button>
            }
            readMoreUrl="https://skand.cargo.site/Annotations#Project-templates"
          />
        )}
        enabled={() => (
          <Button
            active={isShowingTemplatePanel && mode === 'create'}
            className="w-full"
            disabled={disabled}
            filled
            onClick={() => {
              createTemplate();
              showTemplatePanel();
            }}
            primary
            size="s"
          >
            Create template
          </Button>
        )}
        loading={() => (
          <Button className="w-full" disabled filled primary size="s">
            Create template
          </Button>
        )}
        name={ENTITLEMENT_NAME.ANNOTATION_TEMPLATE}
      />
    </div>
  );
};

export const TemplateTab = () => {
  const projectId = useExplore(state => state.projectId);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortKey, setSortKey] = useState<SortKey>('name');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const {
    response: { isLoading },
    templates,
  } = useAnnotationTemplates();

  const { getProjectPermission } = useFetchProjectPermissions();
  const isSuperAdmin = useIsSuperAdmin();
  const permission = getProjectPermission(projectId);
  const canEdit = canPolicyActionEdit(permission);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortTemplates = (a: any, b: any) => {
    if (sortKey === 'name') {
      return (a.name || '').localeCompare(b.name || '');
    } else if (sortKey === 'createdAt') {
      return Date.parse(a.createdAt) - Date.parse(b.createdAt);
    }
    return 0;
  };

  const filteredAndSortedData = useMemo(() => {
    if (debouncedSearchQuery && debouncedSearchQuery.length > 0) {
      return templates.filter(template =>
        template?.name?.toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
      );
    }
    return templates;
  }, [debouncedSearchQuery, templates]);

  const sortedAndFilteredTemplates = filteredAndSortedData.sort(sortTemplates);

  useEffect(() => {
    const debounced = debounce(setDebouncedSearchQuery, 300);
    debounced(searchQuery);
  }, [searchQuery]);

  return (
    <div className="h-full flex flex-col overflow-y-auto px-1">
      <TemplateTabHeader />
      <TemplateTabSearchAndFilter
        disabled={!canEdit}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />

      <div className="h-full">
        {isLoading ? (
          <BlockLoading />
        ) : !sortedAndFilteredTemplates.length ? (
          <EmptyResourceMessage
            emptyResourceContent={{
              emptyDesc: 'Use the create button to create a new template.',
              emptyTitle: 'No templates available',
            }}
          />
        ) : (
          <>
            <Sort onChangeSortKey={setSortKey} />
            {sortedAndFilteredTemplates.map(template => {
              return (
                <TemplateItem
                  disabled={!canEdit}
                  isSuperAdmin={!!isSuperAdmin}
                  key={template.id}
                  template={template}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
