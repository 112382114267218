import { LIST_TRAININGS_BY_MODEL_ID } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (modelId: string) => ['LIST_TRAININGS_BY_MODEL_ID', modelId];

export const useTrainings = (modelId: string | null) => {
  const { data, isLoading } = useQuery({
    queryFn: () => request(LIST_TRAININGS_BY_MODEL_ID, { modelId: modelId as string }),
    queryKey: getQueryKey(modelId as string),
    enabled: !!modelId,
  });

  const trainings = useMemo<
    Array<{ id: string; modelId: string; status: string; startTime: string; duration: string }>
  >(() => {
    return (data?.listTrainingsByModelId ?? []).filter(
      (
        training,
      ): training is {
        id: string;
        modelId: string;
        status: string;
        startTime: string;
        duration: string;
      } =>
        !!training?.id &&
        !!training?.modelId &&
        !!training?.status &&
        !!training?.startTime &&
        !!training?.duration,
    );
  }, [data]);

  return { trainings, isLoading };
};

useTrainings.getQueryKey = getQueryKey;
