import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useFetchAnnotation } from '@/hooks/useFetchAnnotation';
import { useFetchAnnotationHistory } from '@/hooks/useFetchAnnotationHistory';
import { useAnnotationStore } from '@/stores/annotation';
import {
  startPreviewAnnotation,
  startUpdateAnnotation,
  updateAnnotationDraft,
} from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { AnnotationVersion } from '@/utils/annotationVersion';
import { cn } from '@/utils/classname';
import { Button, Toast } from '@skand/ui';
import { useEffect, useState } from 'react';

export const AnnotationTabHistoryView = () => {
  const activeAnnotationInformation = useAnnotationStore(
    state => state.activeAnnotationInformation,
  );
  const clearActiveAnnotationInformation = useAnnotationStore(
    state => state.clearActiveAnnotationInformation,
  );
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);
  const fetchMetadata = useFetchAnnotation(activeAnnotationInformation.activeAnnotationId);
  const { findTemplateByTemplateId } = useAnnotationTemplates();

  const fetchAnnotationHistory = useFetchAnnotationHistory(
    activeAnnotationInformation.activeAnnotationId,
  );

  const [versions, setVersions] = useState<AnnotationVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<AnnotationVersion | null>(null);

  // Fetch annotation version history
  useEffect(() => {
    fetchAnnotationHistory().then(result => {
      const reversedResult = result.reverse();
      setVersions(reversedResult);
      setSelectedVersion(reversedResult[0]);
    });
  }, [fetchAnnotationHistory]);

  // Handle selecting a version to preview
  const handleSelectVersion = async (version: AnnotationVersion, isLatestVersion: boolean) => {
    setSelectedVersion(version);
    const targetAnnotation = await fetchMetadata();
    const template = findTemplateByTemplateId(targetAnnotation?.templateId);
    if (targetAnnotation && template) {
      // Clean up annotation fields
      const fields = version.fields.map(field =>
        Object.fromEntries(
          Object.entries(field).filter(([key]) => !['order', '__typename'].includes(key)),
        ),
      );

      if (isLatestVersion) {
        startUpdateAnnotation(targetAnnotation, template);
      } else {
        startPreviewAnnotation(targetAnnotation, template);
      }

      updateAnnotationDraft({
        annotation2d: targetAnnotation.annotation2d,
        annotation3d: targetAnnotation.annotation3d,
        annotationId: version.annotationId,
        name: version.name,
        color: `#${version.color.getHexString()}`,
        fields,
      });
    }
  };

  // Handle closing the menu
  const handleClose = () => {
    clearActiveAnnotationInformation();
    hideLeftSideBarII();
  };

  return (
    <div className="relative h-full flex flex-col">
      <div
        className={cn(
          'flex flex-col gap-2 pb-1  items-start border-neutral-300 border-1  border-b-solid relative   ',
        )}
      >
        <p className={cn('typo-heading-4 color-neutral-800')}>Annotation History</p>
        <p className="color-neutral-800 typo-text-xs">
          View track changes for selected annotation with option to revert to older versions.
        </p>

        <div className="mb-2">
          <Toast
            clickToDismiss
            dismiss={() => {}}
            message="Reversions only apply to input in fields, annotation edits will not be restored."
            type="warn"
          />
        </div>
      </div>

      <div className="grid grid-cols-14 border-1 border-neutral-500 border-b-solid py-2">
        <div className="col-span-2">
          <p className="color-neutral-800 typo-text-xs-em">Date</p>
        </div>
        <div className="col-span-7">
          <p className="color-neutral-800 typo-text-xs-em">Action</p>
        </div>
        <div className="col-span-5">
          <p className="color-neutral-800 typo-text-xs-em">User</p>
        </div>
      </div>

      <div className="h-full overflow-y-auto">
        {versions.map((version, index) => (
          <div className="grid grid-cols-14 py-2" key={index}>
            <div className="col-span-2 flex items-center">
              <p className="color-neutral-800 typo-text-xs">
                {version.createdAt.toLocaleString('en-us', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>
            </div>
            <div className="col-span-7 flex items-center">
              <p className="color-neutral-800 typo-text-xs">{version.name}</p>
            </div>
            <div className="col-span-5 flex items-center justify-between">
              <p className="color-neutral-800 typo-text-xs">
                {`${version.createdBy?.firstName ?? 'System'} ${version.createdBy?.lastName ?? ''}`}
              </p>
              <Button
                className="rounded px-2 typo-text-xs"
                filled
                onClick={() => handleSelectVersion(version, index === 0)}
                primary={selectedVersion === version}
                size="xs"
              >
                {version === versions[0] ? 'CURRENT' : 'PREVIEW'}
              </Button>
            </div>
          </div>
        ))}
      </div>

      <Button className="w-full" onClick={handleClose} primary size="s">
        Close
      </Button>
    </div>
  );
};
