import { DND_ITEM_TYPE } from '@/LegacyExplore/constants/annotation';
import { cn } from '@/LegacyExplore/utils/classname';
import { useDrop } from 'react-dnd';

export const DropArea = () => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DND_ITEM_TYPE.CHIP,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  return (
    <div
      className={cn(
        'my-3 h-96px',
        'flex items-center justify-center',
        'b-1 b-neutral-400 rounded-2 b-dashed',
        isActive ? 'bg-neutral-200' : 'bg-neutral-100',
      )}
      ref={drop}
    >
      <div className="i-skand-add text-3 color-neutral-400" />
    </div>
  );
};
