import { setDataAttributionModal, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { Button } from '@skand/ui';

export const DataAttributionModal = () => {
  const api3D = useViewer(state => state.api3D);

  return (
    <div className={cn('flex', 'flex-col', 'p-12px', 'w-full')}>
      <p className={cn('typo-text-medium text-neutral-800', 'mb-24px')}>Data attribution</p>
      <p className={cn('typo-text-small-em text-neutral-800', 'mb-8px')}>
        This map includes data from:
      </p>
      {!isEmpty(api3D) &&
        api3D.globe
          .getAttributions()
          .map((attribute, index) => (
            <p
              className={cn('typo-text-small text-neutral-500', 'mb-1px')}
              dangerouslySetInnerHTML={{ __html: attribute.html as TrustedHTML }}
              key={index}
            />
          ))}
      <Button
        className={cn('mt-24px w-full')}
        filled
        onClick={() => setDataAttributionModal(false)}
        primary
        size="s"
      >
        Close
      </Button>
    </div>
  );
};
