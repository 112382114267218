import { cn } from '@/LegacyExplore/utils/classname';

export interface ProgressBarProps {
  className?: string;

  /** progress in [0, 1] */
  progress: number;
}

export const ProgressBar = ({ className, progress }: ProgressBarProps) => {
  return (
    <div className={cn('bg-neutral-100', 'h-2', 'rounded-1 b-solid b-1 b-neutral-300', className)}>
      <div
        className={cn('bg-primary-400', 'h-full', 'b-rounded-inherit')}
        style={{ width: `${progress * 100}%` }}
      />
    </div>
  );
};
