import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { queryClient } from '@/LegacyExplore/graphql/client';
import { TransformSceneEntityMutationVariables } from '@/LegacyExplore/graphql/codegen/graphql';
import { TRANSFORM_SCENE_ENTITY } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useFetchSceneEntities } from '@/LegacyExplore/hooks/useFetchSceneEntities';
import { useFetchSceneEntityPermissions } from '@/LegacyExplore/hooks/useFetchSceneEntityPermissions';
import { useExplore } from '@/LegacyExplore/stores/explore';
import {
  AnnotationGroup,
  Layer,
  LayerGroup,
  PhotoGroup,
  useViewer,
} from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { Button, toast } from '@skand/ui';
import {
  Cartographic,
  IFC,
  Model3D,
  ModelNode,
  SceneNode,
  toCartesian,
  toCartographic,
  toDegrees,
  toRadians,
} from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Quaternion, Vector3 } from 'three';
import { OpacityControl } from './OpacityControl';
import { PositionControl } from './PositionControl';
import { TransformControl } from './TransformControl';

export interface LayeringTabProps {
  layer: LayerGroup | Layer | PhotoGroup | AnnotationGroup;
}

export const LayeringTab = ({ layer }: LayeringTabProps) => {
  const hasShareLinkToken = getShareLinkToken();

  const model = layer.sceneNode as SceneNode;
  const projectId = useExplore(state => state.projectId);
  const positionCache = useViewer(state => state.positionCache);
  const defaultTransformCache = useViewer(state => state.defaultTransformCache);

  const [initialPosition, initialRotation] = useMemo(() => {
    const cached = positionCache.get(layer.id);
    if (cached) {
      return cached;
    } else {
      const position = model.getPosition();
      const rotation = model.getRotation();
      useViewer.setState(state => ({
        positionCache: new Map(state.positionCache.set(layer.id, [position, rotation])),
      }));
      return [position, rotation];
    }
  }, [layer.id, model, positionCache]);

  const currentPosition = model.getPosition();
  const currentRotation = model.getRotation();

  // Separate state for responsive text input
  const [cartographic, setCartographic] = useState(toCartographic(currentPosition));

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(layer.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);

  // Transform mutation
  const { mutate } = useMutation({
    mutationFn: (variables: TransformSceneEntityMutationVariables) => {
      return request(TRANSFORM_SCENE_ENTITY, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: `Successfully moved entity '${layer.name}'.`,
        lifespan: 5000,
      });
    },
  });

  // Save the position and rotation of the layer
  const updateTransform = useCallback(
    (position: Vector3, rotation: Quaternion) => {
      if (projectId) {
        model.setPosition(position);
        model.setRotation(rotation);

        const localPosition = model.getLocalPosition();
        const localRotation = model.getLocalRotation();
        mutate({
          sceneEntityId: layer.sceneEntityId,
          projectId,
          position: { x: localPosition.x, y: localPosition.y, z: localPosition.z },
          rotation: {
            x: localRotation.x,
            y: localRotation.y,
            z: localRotation.z,
            w: localRotation.w,
          },
        });
      }
    },
    [layer.sceneEntityId, model, mutate, projectId],
  );

  // Set the cartesian transform of the model
  const setCartesianTransform = (position: Vector3, rotation: Quaternion) => {
    setCartographic(toCartographic(position));
    updateTransform(position, rotation);
  };

  // Set the cartographic position of the model
  const setCartographicPosition = (longitude: number, latitude: number, height: number) => {
    const cartographic = new Cartographic(toRadians(longitude), toRadians(latitude), height);
    const cartesian = toCartesian(cartographic);
    setCartographic(cartographic);
    updateTransform(cartesian, currentRotation);
  };

  // Reset the position and rotation of the model
  const handleReset = () => {
    setCartographic(toCartographic(initialPosition));
    updateTransform(initialPosition, initialRotation);
  };

  const handleClear = () => {
    const transformCache = defaultTransformCache.get(layer.id);

    if (transformCache) {
      setCartographic(toCartographic(transformCache[0]));
      updateTransform(transformCache[0], transformCache[1]);
    }
  };

  return (
    <div className={cn('flex flex-col')}>
      {layer.sceneNode instanceof SceneNode && !hasShareLinkToken && canEdit && (
        <TransformControl model={layer.sceneNode} setTransform={setCartesianTransform} />
      )}
      {layer.sceneNode instanceof ModelNode && !(layer.sceneNode.getModel() instanceof IFC) && (
        <OpacityControl model={layer.sceneNode.getModel() as Model3D} />
      )}
      {layer.sceneNode instanceof SceneNode && !hasShareLinkToken && (
        <>
          <PositionControl
            disabled={!canEdit}
            height={cartographic.height}
            latitude={toDegrees(cartographic.latitude)}
            longitude={toDegrees(cartographic.longitude)}
            setCartographicPosition={setCartographicPosition}
          />
          <div className={cn('flex gap-2 flex-col mt-3')}>
            <Button className="w-full" disabled={!canEdit} onClick={handleReset} size="s">
              Reset Position
            </Button>
          </div>
          <div className={cn('flex gap-2 flex-col mt-3')}>
            <Button className="w-full" disabled={!canEdit} onClick={handleClear} size="s">
              Clear Position
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
