import { LIST_USERS_BY_ACCOUNT_ID } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { createUser } from '@/LegacyExplore/utils/transformers';
import { User } from '@/LegacyExplore/utils/user';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = () => ['LIST_USERS_BY_ACCOUNT_ID'];

export const useFetchUsers = () => {
  const response = useQuery({
    enabled: true,
    queryFn: () => request(LIST_USERS_BY_ACCOUNT_ID),
    queryKey: getQueryKey(),
  });

  return useCallback(async () => {
    const result: User[] = [];
    if (!isEmpty(response.data) && !isEmpty(response.data.listUsersByAccountId)) {
      for (const userQuery of response.data.listUsersByAccountId) {
        if (userQuery) {
          const user = createUser(userQuery);
          if (user) {
            result.push(user);
          }
        }
      }
    }
    return result;
  }, [response.data]);
};

useFetchUsers.getQueryKey = getQueryKey;
