import { GET_FILES_BY_IDS } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (fileIds: string[]) => ['GET_FILES_BY_IDS', fileIds];

export const useFetchFileUrls = (fileIds: string[]) => {
  const queryResult = useQuery({
    enabled: fileIds.length > 0,
    queryFn: () => request(GET_FILES_BY_IDS, { fileIds }),
    queryKey: getQueryKey(fileIds),
  });

  const filesWithUrls = useMemo(() => {
    const result = queryResult.data?.filesByIds ?? [];
    const filesWithUrls = result.map(file => {
      if (!file) {
        return null;
      }

      return {
        downloadUrl: file.signedGetObjectDownloadUrl as string,
        fileId: file.id as string,
        previewUrl: file.signedGetObjectUrl as string,
      };
    });

    return filesWithUrls.filter(file => !!file) as {
      downloadUrl: string;
      fileId: string;
      previewUrl: string;
    }[];
  }, [queryResult.data]);

  return filesWithUrls;
};

useFetchFileUrls.getQueryKey = getQueryKey;
