import { ListImageTilesByFileIdsQuery } from '@/LegacyExplore/graphql/codegen/graphql';
import { LIST_IMAGE_TILES_BY_FILE_IDS } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (fileIds: string[]) => ['LIST_IMAGE_TILES_BY_FILE_IDS', fileIds];

const transform = (tiles: Map<string, Map<string, string>>, raw: ListImageTilesByFileIdsQuery) => {
  for (const file of raw.listImageTilesByFileIds ?? []) {
    if (!isEmpty(file) && !isEmpty(file.fileId) && !isEmpty(file.tilesWithSignedUrls)) {
      const tilemap = new Map<string, string>();
      for (const tile of file.tilesWithSignedUrls) {
        if (
          !isEmpty(tile) &&
          !isEmpty(tile.row) &&
          !isEmpty(tile.col) &&
          !isEmpty(tile.signedUrl)
        ) {
          tilemap.set(`${tile.row} ${tile.col}`, tile.signedUrl);
        }
      }
      tiles.set(file.fileId, tilemap);
    }
  }
};

export const useFetchImageTiles = (fileIds: string[]) => {
  const { refetch: fetchTiles } = useQuery({
    enabled: false,
    queryFn: () => request(LIST_IMAGE_TILES_BY_FILE_IDS, { fileIds }),
    queryKey: getQueryKey(fileIds),
  });

  return useCallback(async () => {
    const tiles: Map<string, Map<string, string>> = new Map();
    const result = await fetchTiles();
    if (!isEmpty(result.data)) {
      transform(tiles, result.data);
    }
    return tiles;
  }, [fetchTiles]);
};

useFetchImageTiles.getQueryKey = getQueryKey;
useFetchImageTiles.transform = transform;
