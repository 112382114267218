import { Layer, LayerGroup } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';

export interface LayerGroupNodeProps {
  group: LayerGroup;
  setTargetLayer: (group: LayerGroup | Layer) => void;
}

export const LayerGroupNode = ({ group, setTargetLayer }: LayerGroupNodeProps) => {
  return (
    <div
      className={cn('flex justify-between items-center py-1 gap-2')}
      onClick={() => setTargetLayer(group)}
    >
      <div className="i-skand-layers w-5 text-3 color-neutral-600" />
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
        <div
          className={cn(
            'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
          )}
        >
          <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
            {group.sceneNode.getChildren().length}
          </p>
        </div>
      </div>
    </div>
  );
};
