import { AnnotationFieldType } from '@/LegacyExplore/constants/annotation';
import { AnnotationTemplateField } from '@/LegacyExplore/graphql/codegen/graphql';
import { WithMode } from '@/LegacyExplore/stores/template';
import { createColumnHelper } from '@tanstack/react-table';
import { FieldAction } from './FieldAction';
import { FieldType } from './FieldType';
import { FieldValue } from './FieldValue';
import { Name } from './Name';

const columnHelper = createColumnHelper<WithMode<AnnotationTemplateField>>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'name',
    cell: props => (
      <Name
        field={props.row.original}
        // ref={(props as unknown as { dragRef: RefObject<HTMLDivElement> }).dragRef}
      />
    ),
  }),
  columnHelper.accessor('type', {
    header: 'field type',
    cell: ({ getValue }) => <FieldType type={getValue() as AnnotationFieldType} />,
  }),
  columnHelper.display({
    id: 'values',
    header: () => (
      <div className="flex flex-1 items-center justify-between">
        <span className="flex-1">values</span>
        <span className="w-156px flex-none">colour</span>
      </div>
    ),
    cell: ({ row }) => <FieldValue field={row.original} />,
  }),
  columnHelper.display({
    id: 'actions',
    header: '',
    cell: ({ row }) => <FieldAction field={row.original} />,
  }),
];
