import { FindIcon } from '@/LegacyExplore/components/IconButton';
import { ANALYTICS_EVENT_OBJECT } from '@/LegacyExplore/constants/analytics';
import { Photo, setImageViewerSettings, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { Button, Input } from '@skand/ui';
import { useState } from 'react';
import { FilterMenu } from './FilterMenu';
import { SettingsMenu } from './SettingsMenu';
import { SortMenu } from './SortMenu';

export interface ControlsProps {
  currentTab: Photo['type'];
}

enum TabOptions {
  Settings = 'SETTINGS',
  Filter = 'FILTER',
}

export const Controls = ({ currentTab }: ControlsProps) => {
  const photo2DViewerSettings = useViewer(state => state.photo2DViewerSettings);
  const panoramaViewerSettings = useViewer(state => state.panoramaViewerSettings);
  const viewerSettings = currentTab === 'photo2D' ? photo2DViewerSettings : panoramaViewerSettings;

  const [isSortByModalOpen, setIsSortByModalOpen] = useState(false);
  const [currentTabOption, setCurrentTabOption] = useState<TabOptions | null>(null);

  // Handle updating the search key
  const handleSearchKey = (searchKey: string) => {
    setImageViewerSettings({ searchKey }, currentTab);
  };

  return (
    <div className={cn('flex flex-col mt-2')}>
      <div className={cn('flex gap-2 items-stretch')}>
        <div className="flex-1">
          <Input
            label="Search by image filename"
            onChange={handleSearchKey}
            tail={<FindIcon />}
            value={viewerSettings.searchKey}
          />
        </div>

        <Button
          className={cn(
            'w-64px h-full',
            currentTabOption === TabOptions.Filter ? 'bg-neutral-300' : 'bg-transparent',
          )}
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.IMAGE_VIEWER_FILTER}
          onClick={() => {
            if (currentTabOption === TabOptions.Filter) {
              setCurrentTabOption(null);
            } else {
              setCurrentTabOption(TabOptions.Filter);
            }
          }}
          size="s"
        >
          Filter
        </Button>

        <Button
          className={cn(
            'w-38px h-full flex justify-center items-center',
            currentTabOption === TabOptions.Settings ? 'bg-neutral-300' : 'bg-transparent',
          )}
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.IMAGE_VIEWER_SETTINGS}
          onClick={() => {
            if (currentTabOption === TabOptions.Settings) {
              setCurrentTabOption(null);
            } else {
              setCurrentTabOption(TabOptions.Settings);
            }
          }}
          size="s"
        >
          <div className={cn('i-skand-gear', 'text-18px', 'text-neutral-500')} />
        </Button>
      </div>
      <div>
        {currentTabOption == TabOptions.Settings && (
          <SettingsMenu closeModal={() => setCurrentTabOption(null)} currentTab={currentTab} />
        )}
        {currentTabOption == TabOptions.Filter && (
          <FilterMenu closeModal={() => setCurrentTabOption(null)} currentTab={currentTab} />
        )}
      </div>

      <div className="relative mt-3 w-auto self-end">
        <div
          className="flex cursor-pointer items-center justify-between gap-2 color-neutral-800"
          onClick={() => {
            setIsSortByModalOpen(!isSortByModalOpen);
          }}
        >
          <p className="typo-text-xs">SORT BY: {viewerSettings.sortMode.toUpperCase()}</p>
          <div
            className={cn(
              'i-skand-dropdownarrow text-sm font-bold',
              isSortByModalOpen && 'rotate-180',
            )}
          />
        </div>

        {isSortByModalOpen && (
          <SortMenu closeModal={() => setIsSortByModalOpen(false)} currentTab={currentTab} />
        )}
      </div>
    </div>
  );
};
