import { cn } from '@/LegacyExplore/utils/classname';
import { useState } from 'react';
import { SortKey } from './TemplateTab';
import { SortByModal } from './TemplateTabSortByModal';

export const Sort = ({ onChangeSortKey }: { onChangeSortKey: (key: SortKey) => void }) => {
  const [isSortByModalOpen, setIsSortByModalOpen] = useState(false);
  const [currentSort, setCurrentSort] = useState<SortKey | null>(null);

  const handleSortChange = (key: SortKey) => {
    onChangeSortKey(key);
    setCurrentSort(key);
  };

  const getCurrentSortLabel = () => {
    if (currentSort == 'name') return 'SORTED BY: NAME';
    if (currentSort == 'createdAt') return 'SORTED BY: DATE CREATED';
    return 'SORT BY';
  };

  return (
    <div className="mb-3 flex">
      <div className="flex-1" />

      <div className="relative">
        <div
          className="flex items-center justify-between gap-2"
          onClick={() => setIsSortByModalOpen(!isSortByModalOpen)}
        >
          <p className="color-neutral-800 typo-button-xs">{getCurrentSortLabel()}</p>
          <div
            className={cn(
              'i-skand-dropdown text-14px color-neutral-800',
              isSortByModalOpen && 'rotate-180',
            )}
          />
        </div>

        {isSortByModalOpen && (
          <SortByModal setIsSortByModalOpen={setIsSortByModalOpen} setSortKey={handleSortChange} />
        )}
      </div>
    </div>
  );
};
