import { Vector2 } from 'three';

/**
 * 2D Transformation helper module.
 */
export class Transform2 {
  private translation: Vector2;
  private zoom: number;
  private listener: (transform: Transform2) => void;

  constructor() {
    this.translation = new Vector2();
    this.zoom = 1;
    this.listener = () => {};
  }

  /**
   * Set the transform listener.
   * @param cb
   */
  setListener(cb: (transform: Transform2) => void) {
    this.listener = cb;
  }

  /**
   * Scale a set of dimensions.
   *
   * @param dimensions
   */
  scaleDimensions(dimensions: Vector2) {
    return dimensions.clone().multiplyScalar(this.zoom);
  }

  /**
   * Convert a canvas space point to image space.
   *
   * @param canvasPosition
   */
  canvasToImageSpace(canvasPosition: Vector2) {
    return canvasPosition.clone().sub(this.translation).divideScalar(this.zoom);
  }

  /**
   * Convert an image space point to canvas space.
   *
   * @param imagePosition
   */
  imageToCanvasSpace(imagePosition: Vector2) {
    return imagePosition.clone().multiplyScalar(this.zoom).add(this.translation);
  }

  /**
   * Overwrite the current translation.
   *
   * @param x
   * @param y
   */
  setTranslation(x: number, y: number) {
    this.translation.set(x, y);
    this.listener(this);
  }

  /**
   * Overwrite the current zoom.
   *
   * @param zoom
   */
  setZoom(zoom: number) {
    this.zoom = zoom;
    this.listener(this);
  }

  /**
   * Apply a translation.
   *
   * @param dx
   * @param dy
   */
  translate(dx: number, dy: number) {
    this.translation.x += dx;
    this.translation.y += dy;
    this.listener(this);
  }

  /**
   * Zoom to a point.
   *
   * @param zoomDelta
   * @param point
   * @param minZoom
   */
  zoomToPoint(zoomDelta: number, point: Vector2, minZoom = 0.0001) {
    // Relative to the current translated image
    const relativePosition = point.clone().sub(this.translation);

    // Calculate and clip the new zoom level
    const prevZoom = this.zoom;
    this.zoom = Math.max(minZoom, this.zoom + zoomDelta);

    // Update translation to target the mouse position
    const alpha = this.zoom / prevZoom;
    this.translation.add(relativePosition.multiplyScalar(1 - alpha));

    this.listener(this);
  }

  /**
   * Get the translation vector.
   *
   * @returns
   */
  getTranslation() {
    return this.translation.clone();
  }

  /**
   * Get the scaling value.
   *
   * @returns
   */
  getZoom() {
    return this.zoom;
  }
}
