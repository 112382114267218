import { useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@skand/ui';
import { FrameStatistics } from '@skand/viewer-component-v2';
import { useEffect, useState } from 'react';

export const StatisticsMenu = () => {
  const api3D = useViewer(state => state.api3D);

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    // Need to manually insert the innerHTML to bypass react's rendering loop
    const cb = (statistics: FrameStatistics) => {
      let html = '';
      for (const [key, value] of Object.entries(statistics)) {
        let prettyName = key.replace(/([a-z])([A-Z])/g, '$1 $2');
        prettyName = prettyName.charAt(0).toUpperCase() + prettyName.slice(1);
        html += `
          <div class="flex justify-between typo-text-s-em color-neutral-6 w-full">
            <span>${prettyName}</span>
            <span>${value.toFixed(2)}</span>
          </div>
        `;
      }
      if (container) {
        container.innerHTML = html;
      }
    };
    if (api3D) {
      api3D.statistics.eventRegister.register('updateFrame', cb);
    }
    return () => {
      api3D?.statistics.eventRegister.unregister('updateFrame', cb);
    };
  }, [api3D, container]);

  return <div className={cn('flex flex-col w-full gap-1 mt-3')} ref={setContainer} />;
};
