import { RadioButton } from '@/LegacyExplore/components/RadioButton';
import { cn } from '@/LegacyExplore/utils/classname';
import { TilesetStyle } from '@skand/viewer-component-v2';
import { Color } from 'three';

const INTENSITY_GRADIENTS = [
  {
    label: 'Grayscale',
    colors: [new Color(0, 0, 0), new Color(1, 1, 1)],
  },
  {
    label: 'Skand',
    colors: [
      new Color(1, 0.65, 0),
      new Color(0.52, 0.79, 0.78),
      new Color(0.52, 0.79, 0.78),
      new Color(1, 1, 1),
    ],
  },
  {
    label: 'Spectral',
    colors: [
      new Color(0, 0, 0),
      new Color(1, 0, 0),
      new Color(1, 1, 0),
      new Color(0, 1, 1),
      new Color(0, 0, 1),
      new Color(1, 0, 1),
      new Color(1, 1, 1),
    ],
  },
  {
    label: 'Red-Green-Blue',
    colors: [
      new Color(0, 0, 0),
      new Color(1, 0, 0),
      new Color(0, 1, 0),
      new Color(0, 0, 1),
      new Color(1, 1, 1),
    ],
  },
  {
    label: 'RGB-Red',
    colors: [new Color(0, 0, 0), new Color(1, 0, 0)],
  },
  {
    label: 'RGB-Green',
    colors: [new Color(0, 0, 0), new Color(0, 1, 0)],
  },
  {
    label: 'RGB-Blue',
    colors: [new Color(0, 0, 0), new Color(0, 0, 1)],
  },
  {
    label: 'Green-Yellow',
    colors: [new Color(0, 0, 0), new Color(0, 1, 0), new Color(1, 1, 0), new Color(1, 1, 1)],
  },
  {
    label: 'Green-Violet',
    colors: [new Color(0, 0, 0), new Color(0, 1, 0), new Color(0.33, 0, 67), new Color(1, 1, 1)],
  },
];

const hasSameColors = (a: Color[], b: Color[]) => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i].r !== b[i].r || a[i].g !== b[i].g || a[i].b !== b[i].b) {
      return false;
    }
  }
  return true;
};

export interface IntensitySettingsProps {
  style: TilesetStyle;
  setStyle: (style: TilesetStyle) => void;
}

export const IntensitySettings = ({ style, setStyle }: IntensitySettingsProps) => {
  return (
    <div className={cn('flex flex-col mt-3')}>
      {INTENSITY_GRADIENTS.map((gradient, index) => (
        <div className={cn('flex flex-row justify-between items-center')} key={`gradient-${index}`}>
          <RadioButton
            checked={hasSameColors(style.intensityColors, gradient.colors)}
            id={gradient.label}
            label={gradient.label}
            onChange={() => setStyle({ ...style, intensityColors: gradient.colors })}
            value={gradient.label}
          />
          <div
            className="mb-3 h-5 w-16 b-1px b-neutral-400 b-rounded-1 b-solid"
            style={{
              background: `linear-gradient(
                to right,
                ${gradient.colors.map(color => `#${color.getHexString()}`).join(',')}
              )`,
            }}
          />
        </div>
      ))}
    </div>
  );
};
