import { TableDense } from '@/LegacyExplore/components/TableDense';
import { ANALYTICS_EVENT_OBJECT } from '@/LegacyExplore/constants/analytics';
import {
  NODES_TABLE_DEFAULT_PAGE_INDEX,
  NODES_TABLE_DEFAULT_PAGE_SIZE,
} from '@/LegacyExplore/constants/node';
import { ProjectFileNode, SystemNodeKindInput } from '@/LegacyExplore/graphql/codegen/graphql';
import { useFetchSystemNodes } from '@/LegacyExplore/hooks/useFetchSystemNodes';
import { SystemNode } from '@/LegacyExplore/stores/systemNodes';
import { cn } from '@/LegacyExplore/utils/classname';
import { CheckBox, Input } from '@skand/ui';
import {
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumbs } from './Breadcrumb';
import { Pagination } from './Pagination';
export interface FileSelectionProps {
  setSelectedNodeIds: (ids: ProjectFileNode['id'][]) => void;
}

const columnHelper = createColumnHelper<SystemNode>();

export const FileSelection = ({ setSelectedNodeIds }: FileSelectionProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [parentNodeId, setParentNodeId] = useState<string | null>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
    pageSize: NODES_TABLE_DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    setSelectedNodeIds(Object.keys(rowSelection));
  }, [rowSelection, setSelectedNodeIds]);

  const { nodes: systemNodes, pageCount } = useFetchSystemNodes(
    {
      nodeKinds: [SystemNodeKindInput.FolderNode, SystemNodeKindInput.FileNode],
      pageIndex,
      pageSize,
      parentNodeId: parentNodeId ?? null,
      searchTerm: globalFilter ?? undefined,
    },
    { keepPreviousData: true },
  );

  const handleChangeFilter = useCallback((text: string) => {
    setGlobalFilter(text);
    setPagination(prev => ({ ...prev, pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX }));
  }, []);

  const handleEnterFolder = useCallback((folderNodeId: string | null) => {
    setParentNodeId(folderNodeId);
    setPagination(prev => ({ ...prev, pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX }));
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        enableGlobalFilter: true,
        id: 'name',
        header: ({ table }) => (
          <label className="flex items-center gap-3">
            <CheckBox
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
            name
          </label>
        ),
        cell: ({ row, getValue }) => {
          const handleClick = () => {
            if (row.original.type === 'folder') handleEnterFolder(row.original.id);
          };

          return (
            <div className="flex items-center gap-3">
              <CheckBox
                checked={row.getIsSelected()}
                className="cursor-pointer"
                disabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
              />
              <label
                className={cn(
                  'flex items-center gap-3',
                  row.original.type === 'folder' && 'cursor-pointer underline',
                )}
                onClick={handleClick}
              >
                {getValue()}
              </label>
            </div>
          );
        },
      }),
    ],
    [handleEnterFolder],
  );

  const table = useReactTable({
    columns,
    data: systemNodes,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    pageCount,
    onPaginationChange: setPagination,
    manualFiltering: true,
    manualPagination: true,
    state: { rowSelection, sorting, pagination: { pageIndex, pageSize } },
  });
  const handleNavigateBreadcrumbs = useCallback((folderNodeId: string | null) => {
    setParentNodeId(folderNodeId);
  }, []);

  return (
    <div className={cn('flex-1', 'h-full')}>
      <div className="flex pb-2">
        <Breadcrumbs onNavigate={handleNavigateBreadcrumbs} parentNodeId={parentNodeId} />
      </div>

      <div
        className={cn('b-1 b-neutral-400 rounded-1 b-solid p-3 flex-1')}
        data-analytics-event-object={ANALYTICS_EVENT_OBJECT.FILE_SELECTION_TO_CREATE_PROJECT}
      >
        <Input
          onChange={handleChangeFilter}
          placeholder="Search for folders"
          value={globalFilter}
        />

        <TableDense
          className="mt-3"
          contentRowClassName="grid grid-cols-2"
          headerRowClassName="grid grid-cols-2 p-r-2"
          table={table}
          tableBodyClassName={cn(
            'grid overflow-y-auto auto-rows-max m-y-1',
            'scrollbar scrollbar-rounded',
            'h-[calc(90vh-355px)]',
          )}
        />

        <div className="flex justify-center b-t-1 b-t-neutral-300 b-t-solid pt-3">
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            isNextPageDisabled={!table.getCanNextPage()}
            isPreviewPageDisabled={!table.getCanPreviousPage()}
            onPageChange={newPage => table.setPageIndex(newPage - 1)}
            totalPages={table.getPageCount()}
          />
        </div>
      </div>
    </div>
  );
};
