import { queryClient } from '@/graphql/client';
import { TriggerTrainV2MutationVariables } from '@/graphql/codegen/graphql';
import { CREATE_MODEL, START_MODEL_TRAINING } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useModels } from '@/hooks/useModels';
import { useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, CheckBox, Modal, Input as TextInput, Toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

type JsonConfig = Record<string, string | number | boolean>;

export const ModelTrainingProcess = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: (value: boolean) => void;
}) => {
  const [step, setStep] = useState(1);
  const [trainingStatus, setTrainingStatus] = useState<'idle' | 'loading' | 'success' | 'error'>(
    'idle',
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [trainingAnnotationProcessIds, setTrainingAnnotationProcessIds] = useState<string[]>([]);
  const [testingAnnotationProcessIds, setTestingAnnotationProcessIds] = useState<string[]>([]);
  const [trainingAnnotationTemplateId, setTrainingAnnotationTemplateId] = useState<string | null>(
    null,
  );
  const [trainingAnnotationTemplateFieldIds, setTrainingAnnotationTemplateFieldIds] = useState<
    string[]
  >([]);
  const [trainingModelId, setTrainingModelId] = useState<string | null>(null);
  const [newModelName, setNewModelName] = useState<string>('');
  const [modelNameError, setModelNameError] = useState<string | null>(null);

  const [jsonConfig, setJsonConfig] = useState<JsonConfig | null>(null);
  const [jsonError, setJsonError] = useState<string | null>(null);

  const [discardTrainingData, setDiscardTrainingData] = useState(true);
  const [discardTestingData, setDiscardTestingData] = useState(false);

  const { templates } = useAnnotationTemplates();
  const { models } = useModels();
  const processes = useViewer(state => state.processes);
  const annotationProcesses = useMemo(
    () => processes.filter(process => process.kind === 'annotation'),
    [processes],
  );

  const startModelTraining = useMutation({
    mutationFn: (variables: TriggerTrainV2MutationVariables) =>
      request(START_MODEL_TRAINING, variables),
    onSuccess: data => {
      setTrainingStatus('success');
      setSuccessMessage('Model training started successfully. Task ID: ' + data?.triggerTrainV2);
    },
    onError: error => {
      setTrainingStatus('error');
      setErrorMessage((error as Error).message);
    },
  });

  const createModel = useMutation({
    mutationFn: (variables: { modelName: string }) =>
      request(CREATE_MODEL, { modelName: variables.modelName }),
    onSuccess: () => {
      queryClient.invalidateQueries(useModels.getQueryKey());
    },
    onError: error => {
      setTrainingStatus('error');
      setErrorMessage((error as Error).message);
    },
  });

  const validateModelName = (name: string) => {
    if (!name) {
      setModelNameError(null);
      return;
    }

    const isNameTaken = models?.some(model => model.name.toLowerCase() === name.toLowerCase());

    if (isNameTaken) {
      setModelNameError('This model name already exists');
    } else {
      setModelNameError(null);
    }
  };

  const handleFileUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = e => {
      try {
        const content = JSON.parse(e.target?.result as string);
        setJsonConfig(content);
        setJsonError(null);
      } catch (error) {
        setJsonError('Invalid JSON file. Please check the file format.');
        setJsonConfig(null);
      }
    };
    reader.readAsText(file);
  }, []);

  const isDisabled = () => {
    if (step === 1) {
      if (!trainingAnnotationTemplateId) return true;
      if (!trainingAnnotationTemplateFieldIds.length) return true;
      return false;
    } else if (step === 2) {
      if (!trainingAnnotationProcessIds.length) return true;
      return false;
    } else if (step === 3) {
      if (!testingAnnotationProcessIds.length) return true;
      return false;
    } else if (step === 4) {
      if (jsonError) return true;
      return false;
    } else if (step === 5) {
      if (modelNameError) return true;
      if (!newModelName && !trainingModelId) return true;
      return false;
    } else if (step === 6) {
      return false;
    }
  };

  useEffect(() => {
    if (!active) {
      setStep(1);
      setTrainingAnnotationTemplateId(null);
      setTrainingAnnotationTemplateFieldIds([]);
      setTrainingAnnotationProcessIds([]);
      setTestingAnnotationProcessIds([]);
      setTrainingModelId(null);
      setTrainingStatus('idle');
      setErrorMessage(null);
      setSuccessMessage(null);
      setNewModelName('');
      setModelNameError(null);
      setJsonConfig(null);
      setJsonError(null);
      setDiscardTrainingData(true);
      setDiscardTestingData(false);
    }
  }, [active]);

  const renderDescription = () => {
    switch (step) {
      case 1: {
        if (!trainingAnnotationTemplateId) {
          return 'Select an Annotation Template';
        }

        const selectedTemplate = templates.find(
          template => template.id === trainingAnnotationTemplateId,
        );
        return (
          <>
            <div>Select Fields from the Annotation Template</div>
            <div className="mt-3 flex items-center gap-2">
              <div className="text-neutral-800 typo-text-small">
                {' '}
                Annotation Template: {selectedTemplate?.name}
              </div>
              <Button
                className="cursor-pointer typo-text-small"
                onClick={() => setTrainingAnnotationTemplateId(null)}
                size="s"
              >
                Change Template
              </Button>
            </div>
          </>
        );
      }

      case 2:
        return 'Select Training Annotation Process';
      case 3:
        return 'Select Testing Annotation Process';
      case 4:
        return 'Configure Training Settings';
      case 5:
        return 'Select Model to Train';
      case 6:
        return 'Proceed with Training the Model Using the Selected Data';
    }
  };

  const SummaryItem = ({ label, value }: { label: string; value: string | React.ReactNode }) => (
    <div className="px-3 py-1">
      <div className="text-neutral-600 typo-text-small">{label}</div>
      <div className="mt-1 text-neutral-800">{value}</div>
    </div>
  );

  const toggleArrayItem = useCallback(<T,>(array: T[], item: T): T[] => {
    return array.includes(item) ? array.filter(i => i !== item) : [...array, item];
  }, []);

  const prepareContent = () => {
    switch (step) {
      case 1: {
        if (!trainingAnnotationTemplateId) {
          return templates.map(template => (
            <div
              className={cn(
                'text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
              )}
              key={template.id}
              onClick={() => {
                setTrainingAnnotationTemplateId(template.id);
              }}
            >
              {template.name}
            </div>
          ));
        }

        const selectedTemplate = templates.find(
          template => template.id === trainingAnnotationTemplateId,
        );
        if (!selectedTemplate) return <div>No template selected</div>;

        return selectedTemplate.fields?.map(field => (
          <div
            className={cn(
              'flex items-center text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
            )}
            key={field?.id}
            onClick={() => {
              setTrainingAnnotationTemplateFieldIds([field?.id as string]);
            }}
          >
            <CheckBox
              checked={trainingAnnotationTemplateFieldIds.includes(field?.id as string)}
              className="mr-2 cursor-pointer"
            />
            {field?.name}
          </div>
        ));
      }
      case 2:
        return annotationProcesses.map(process => (
          <div
            className={cn(
              'flex items-center text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
            )}
            key={process.id}
            onClick={() => {
              setTrainingAnnotationProcessIds(prev => toggleArrayItem(prev, process.id));
            }}
          >
            <CheckBox
              checked={trainingAnnotationProcessIds.includes(process.id)}
              className="mr-2 cursor-pointer"
            />
            {process?.name}
          </div>
        ));
      case 3:
        return annotationProcesses.map(process => (
          <div
            className={cn(
              'flex items-center text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
            )}
            key={process.id}
            onClick={() => {
              setTestingAnnotationProcessIds(prev => toggleArrayItem(prev, process.id));
            }}
          >
            <CheckBox
              checked={testingAnnotationProcessIds.includes(process.id)}
              className="mr-2 cursor-pointer"
            />
            {process?.name}
          </div>
        ));
      case 4: {
        return (
          <div className="flex flex-col gap-6 p-4">
            <div className="flex flex-col gap-4">
              <div className="text-sm font-medium text-neutral-800">Data Processing Options</div>
              <div className="flex flex-col gap-2">
                <div
                  className="flex cursor-pointer items-center gap-2 px-3 py-2 text-neutral-800 typo-text-small hover:bg-[#F5F3F6]"
                  onClick={() => setDiscardTrainingData(!discardTrainingData)}
                >
                  <CheckBox checked={discardTrainingData} />
                  Discard training data without annotation
                </div>
                <div
                  className="flex cursor-pointer items-center gap-2 px-3 py-2 text-neutral-800 typo-text-small hover:bg-[#F5F3F6]"
                  onClick={() => setDiscardTestingData(!discardTestingData)}
                >
                  <CheckBox checked={discardTestingData} />
                  Discard testing data without annotation
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="text-sm font-medium text-neutral-800">Configuration JSON</div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <input
                    accept=".json"
                    className="hidden"
                    id="json-file-input"
                    onChange={e => {
                      handleFileUpload(e);
                      e.target.value = '';
                    }}
                    type="file"
                  />
                  <Button
                    onClick={() => document.getElementById('json-file-input')?.click()}
                    size="s"
                  >
                    Choose JSON file
                  </Button>
                  {jsonConfig && (
                    <Button onClick={() => setJsonConfig(null)} size="s">
                      Clear
                    </Button>
                  )}
                </div>

                {jsonError && <div className="text-sm text-red-500">{jsonError}</div>}
                {jsonConfig && (
                  <pre className="border border-neutral-200 rounded-md bg-[#f8f9fc] p-3 font-mono text-neutral-800 typo-text-small">
                    {JSON.stringify(jsonConfig, null, 4)}
                  </pre>
                )}
              </div>
            </div>
          </div>
        );
      }
      case 5: {
        return (
          <>
            <div className="flex flex-col gap-4 px-4 py-4">
              <div className="flex flex-col gap-1">
                <TextInput
                  label="Create a New Model"
                  onBlur={e => validateModelName(e.target.value)}
                  onChange={value => {
                    setNewModelName(value);
                    if (value) {
                      setTrainingModelId(null);
                    }
                    if (modelNameError) {
                      setModelNameError(null);
                    }
                  }}
                  placeholder="Enter new model name"
                  required
                  tail={
                    <div className={cn('i-skand-link', 'color-neutral-400', 'text-4', 'ml-1')} />
                  }
                  value={newModelName}
                />
                {modelNameError && <div className="text-xs text-red-500">{modelNameError}</div>}
              </div>
            </div>

            <div className="mb-2 px-4">
              <div className="text-xs text-neutral-600">Or select an existing model:</div>
            </div>

            {models?.map(model => (
              <div
                className={cn(
                  'text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
                  trainingModelId === model.id && 'bg-[#F5F3F6]',
                )}
                key={model.id}
                onClick={() => {
                  setTrainingModelId(model.id);
                  setNewModelName('');
                  setModelNameError(null);
                }}
              >
                {model.name}
              </div>
            ))}
          </>
        );
      }
      case 6: {
        const selectedTemplate = templates.find(
          template => template.id === trainingAnnotationTemplateId,
        );
        const selectedFields = selectedTemplate?.fields?.filter(field =>
          trainingAnnotationTemplateFieldIds.includes(field?.id as string),
        );
        const trainingProcesses = annotationProcesses.filter(process =>
          trainingAnnotationProcessIds.includes(process.id),
        );
        const testingProcesses = annotationProcesses.filter(process =>
          testingAnnotationProcessIds.includes(process.id),
        );
        const selectedModel = models?.find(model => model.id === trainingModelId);

        return (
          <div className="space-y-4">
            <SummaryItem
              label="Training Annotation Template"
              value={selectedTemplate?.name || 'Not selected'}
            />
            <SummaryItem
              label="Training Fields"
              value={selectedFields?.map(field => field?.name).join(', ') || 'None selected'}
            />
            <SummaryItem
              label="Training Annotation Process"
              value={trainingProcesses.map(process => process.name).join(', ') || 'None selected'}
            />
            <SummaryItem
              label="Testing Annotation Process"
              value={testingProcesses.map(process => process.name).join(', ') || 'None selected'}
            />
            <SummaryItem
              label="Model Name"
              value={newModelName || selectedModel?.name || 'Not selected'}
            />
            <SummaryItem
              label="Data Processing Options"
              value={
                <>
                  <div>
                    • Discard training data without annotation: {discardTrainingData.toString()}
                  </div>
                  <div>
                    • Discard testing data without annotation: {discardTestingData.toString()}
                  </div>
                </>
              }
            />
            <SummaryItem
              label="Configuration JSON"
              value={
                jsonConfig && (
                  <div className="border border-neutral-200 rounded-md bg-[#f8f9fc] p-3 font-mono text-neutral-800 typo-text-small">
                    <pre>{JSON.stringify(jsonConfig, null, 2)}</pre>
                  </div>
                )
              }
            />
          </div>
        );
      }
    }
  };

  const handleCancel = () => {
    switch (step) {
      case 1:
        setActive(false);
        break;
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setStep(3);
        break;
      case 5:
        setTrainingModelId(null);
        setStep(4);
        break;
      case 6:
        setTrainingStatus('idle');
        setStep(5);
        break;
    }
  };

  const handleSubmit = async () => {
    if (step === 6) {
      let modelId = trainingModelId;
      if (newModelName) {
        validateModelName(newModelName);
        if (modelNameError) return;

        setTrainingStatus('loading');
        const data = await createModel.mutateAsync({ modelName: newModelName });
        modelId = data.createModel ?? null;
      }

      const configObject: Record<string, string | boolean> = {
        ...(jsonConfig || {}),
        train_discard_if_no_labels_exists: discardTrainingData,
        test_discard_if_no_labels_exists: discardTestingData,
      };

      setTrainingStatus('loading');
      startModelTraining.mutate({
        request: {
          annotationTemplateId: trainingAnnotationTemplateId,
          labelFieldId: trainingAnnotationTemplateFieldIds[0],
          trainAnnotationProcessIds: trainingAnnotationProcessIds,
          testAnnotationProcessIds: testingAnnotationProcessIds,
          modelId: modelId,
          trainConfig: JSON.stringify(configObject),
        },
      });
    } else {
      setStep(prev => prev + 1);
    }
  };

  if (active)
    return (
      <Modal>
        <div className="flex items-center justify-between">
          <p className="color-neutral-800 typo-text-l">Model Training</p>
          <div
            className={cn(
              'text-12px i-skand-close transform-rotate-90 text-neutral-400 hover:text-neutral-600 cursor-pointer',
            )}
            onClick={() => setActive(false)}
          />
        </div>
        <div className={cn('color-neutral-800 typo-text-m')}>{renderDescription()}</div>

        <div
          className={cn(
            'h-[444px] flex flex-col rounded-md gap-[10px] py-3 border-[1px] border-solid border-neutral-400 overflow-y-auto',
          )}
        >
          {prepareContent()}
        </div>

        <div className="flex justify-end">
          {trainingStatus === 'success' && <Toast message={successMessage} type="info" />}
          {trainingStatus === 'error' && <Toast message={errorMessage} type="warn" />}
          {trainingStatus === 'loading' && (
            <Toast message="Starting model training..." type="info" />
          )}
        </div>

        <div className={cn('flex', 'justify-end', 'gap-3')}>
          <Button className={cn('cursor-pointer flex-1')} onClick={handleCancel} size="s">
            {step === 1 ? 'Cancel' : 'Back'}
          </Button>
          <Button
            className={cn(
              'flex-1',
              isDisabled() || trainingStatus === 'loading'
                ? 'cursor-not-allowed'
                : 'cursor-pointer',
            )}
            disabled={isDisabled() || trainingStatus === 'loading'}
            onClick={() => (trainingStatus === 'success' ? setActive(false) : handleSubmit())}
            primary
            size="s"
          >
            {step === 6
              ? trainingStatus === 'loading'
                ? 'Starting...'
                : trainingStatus === 'success'
                ? 'Close'
                : 'Start Training'
              : 'Next'}
          </Button>
        </div>
      </Modal>
    );
};
