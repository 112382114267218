import { AnnotationFieldType, DND_ITEM_TYPE } from '@/LegacyExplore/constants/annotation';
import { AnnotationTemplateField } from '@/LegacyExplore/graphql/codegen/graphql';
import { WithMode, moveField, useTemplate } from '@/LegacyExplore/stores/template';
import { useRef } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { FieldAction } from './FieldAction';
import { FieldType } from './FieldType';
import { FieldValue } from './FieldValue';
import { Name } from './Name';

export interface DragItem {
  index: number;
  field: WithMode<AnnotationTemplateField>;
}

export const Tr = ({
  index,
  field,
}: {
  index: number;
  field: WithMode<AnnotationTemplateField>;
}) => {
  const mode = useTemplate(state => state.mode);
  const dndRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop<DragItem>({
    accept: DND_ITEM_TYPE.FIELD,
    collect: monitor => ({ handlerId: monitor.getHandlerId() }),
    hover(item: DragItem, monitor) {
      if (!dndRef.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return; // Don't replace items with themselves

      const hoverBoundingRect = dndRef.current?.getBoundingClientRect(); // Determine rectangle on screen
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2; // Get vertical middle
      const clientOffset = monitor.getClientOffset(); // Determine mouse position
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top; // Get pixels to the top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return; // Dragging downwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return; // Dragging upwards

      moveField(dragIndex, hoverIndex); // Time to actually perform the action

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag, preview] = useDrag({
    type: DND_ITEM_TYPE.FIELD,
    item: () => ({ index, field }),
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  drag(dndRef);
  drop(preview(previewRef));

  return (
    <tr className="b-y-1 b-y-neutral-300 b-y-solid" ref={previewRef}>
      <td className="py-4 align-top color-neutral-800">
        <Name field={field} handleRef={dndRef} />
      </td>

      <td className="py-4 align-top color-neutral-800">
        <FieldType type={field.type as AnnotationFieldType} />
      </td>

      <td className="py-4 color-neutral-800">
        <FieldValue field={field} />
      </td>

      <td className="py-4 color-neutral-800">{mode !== 'view' && <FieldAction field={field} />}</td>
    </tr>
  );
};
