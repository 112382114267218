import { Photo, useViewer } from '@/stores/viewer';
import { useEffect, useRef, useState } from 'react';
import { DataAttributionPopup } from './DataAttributionPopup';
import { PhotoPopup } from './PhotoPopup';

export const PopupLayer = () => {
  const prevPopupPhotos = useRef<Set<Photo>>(new Set());
  const popupPhotos = useViewer(state => state.popupPhotos);
  const enabledDataAttribution = useViewer(state => state.enabledDataAttribution);

  const [focusTarget, setFocusTarget] = useState<Photo['id'] | null>(null);

  // Set the focus target to the last added photo
  useEffect(() => {
    for (const photo of popupPhotos) {
      if (!prevPopupPhotos.current.has(photo)) {
        setFocusTarget(photo.id);
      }
    }
    prevPopupPhotos.current = popupPhotos;
  }, [popupPhotos]);

  // Close a photo
  const handleClosePopup = (photo: Photo) => {
    useViewer.setState(prev => {
      const popupPhotos = new Set(prev.popupPhotos);
      popupPhotos.delete(photo);
      return { popupPhotos };
    });
  };

  // Focus on a photo (bring to front).
  const handleFocus = (photo: Photo) => {
    setFocusTarget(photo.id);
  };

  return (
    <div className="fixed z-10">
      {[...popupPhotos].map(photo => (
        <PhotoPopup
          focused={focusTarget === photo.id || popupPhotos.size === 1}
          handleClose={() => handleClosePopup(photo)}
          handleFocus={() => handleFocus(photo)}
          key={photo.id}
          photo={photo}
        />
      ))}
      {enabledDataAttribution && <DataAttributionPopup />}
    </div>
  );
};
