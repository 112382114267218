import { cn } from '@/LegacyExplore/utils/classname';

interface BarProps {
  delay: number;
}

export const Bar = ({ delay }: BarProps) => {
  const animationKeyframes = `
    @keyframes bar-grow {
      0% {
        transform: scaleY(1);
        border-radius: 100%;
      }

      1% {
        border-radius: 40%;
      }

      25% {
        transform: scaleY(4);
        border-radius: 24%;
      }

      49% {
        border-radius: 40%;
      }

      50% {
        transform: scaleY(1);
        border-radius: 100%;
      }
    }
  `;
  return (
    <div>
      <style>{animationKeyframes}</style>
      <span
        className={cn(
          'bg-primary-400',
          'block',
          'h-6px',
          'w-6px',
          'border-rounded-100%',
          'mt-9px',
          'mb-9px',
          'ml-1.5px',
          'mr-1.5px',
          'animate-iteration-count-infinite',
          'animate-[bar-grow]',
          'animate-duration-900ms',
          'animate-ease-in-out',
        )}
        style={{
          animationDelay: `${delay}ms`,
        }}
      />
    </div>
  );
};
