import { ACCOUNT_V2_BY_CONTEXT } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useQuery } from '@tanstack/react-query';

const queryKey = ['ACCOUNT_V2_BY_CONTEXT'];

export const useAccountV2ByContext = () => {
  const response = useQuery({
    queryFn: () => request(ACCOUNT_V2_BY_CONTEXT),
    queryKey,
  });

  const hasWhiteLabelling = response.data?.readAccountByContext?.branding?.webappBannerFileId;

  return hasWhiteLabelling;
};

useAccountV2ByContext.queryKey = queryKey;
