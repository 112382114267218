import { useLayout } from '@/LegacyExplore/stores/layout';
import { cn } from '../utils/classname';
import { ProcessTab } from './ProcessTab';
import { SceneTab } from './SceneTab';
import { ShareLinkTab } from './ShareLinkTab';
import { TemplateTab } from './TemplateTab';

export const LeftSideBarFirst = () => {
  const isShowing = useLayout(state => state.isShowingLeftSideBarI);
  const tab = useLayout(state => state.tab);

  return (
    isShowing && (
      <div
        className={cn(
          'h-full',
          'w-400px',
          'bg-neutral-100',
          'absolute',
          'left-0',
          'p-y-18px',
          'p-x-3',
          'overflow-visible',
        )}
      >
        {tab === 'scene' && <SceneTab />}
        {tab === 'templates' && <TemplateTab />}
        {tab === 'sharelink' && <ShareLinkTab />}
        {tab === 'process' && <ProcessTab />}
      </div>
    )
  );
};
