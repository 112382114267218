import { Checkbox } from '@/LegacyExplore/components/Checkbox';
import { FindIcon } from '@/LegacyExplore/components/IconButton';
import { useShareLink } from '@/LegacyExplore/stores/shareLink';
import { Photo, setImageViewerSettings, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { search } from '@/LegacyExplore/utils/search';
import { Button, Input } from '@skand/ui';
import { useMemo, useState } from 'react';

export interface FilterMenuProps {
  currentTab: Photo['type'];
  closeModal: () => void;
}

export const FilterMenu = ({ currentTab, closeModal }: FilterMenuProps) => {
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const targetProcess = useViewer(state => state.targetProcess);
  const photo2DViewerSettings = useViewer(state => state.photo2DViewerSettings);
  const panoramaViewerSettings = useViewer(state => state.panoramaViewerSettings);
  const viewerSettings = currentTab === 'photo2D' ? photo2DViewerSettings : panoramaViewerSettings;
  const selectedPhotoGroupIds = useShareLink(state => state.selectedPhotoGroupIds);

  const photoGroups = useMemo(() => {
    // filter image groups when sharelink image selection is turned on
    if (selectedPhotoGroupIds) {
      if (currentTab === 'photo2D') {
        return photo2DGroups.filter(group => selectedPhotoGroupIds.includes(group.id));
      } else {
        return panoramaGroups.filter(group => selectedPhotoGroupIds.includes(group.id));
      }
    } else {
      if (currentTab === 'photo2D') {
        return photo2DGroups;
      } else {
        return panoramaGroups;
      }
    }
  }, [currentTab, panoramaGroups, photo2DGroups, selectedPhotoGroupIds]);

  const [filters, setFilters] = useState(viewerSettings.photoGroupIds);
  const [showProcessDone, setShowProcessDone] = useState(viewerSettings.showProcessDone);
  const [showProcessInProgress, setShowProcessInProgress] = useState(
    viewerSettings.showProcessInProgress,
  );
  const [searchKey, setSearchKey] = useState('');

  // Check if all groups are enabled
  const isAllEnabled = useMemo(
    () => photoGroups.every(group => filters.includes(group.id)),
    [filters, photoGroups],
  );

  // Filter photo group by search key
  const filtered = useMemo(
    () => photoGroups.filter(group => search(group.name, searchKey)),
    [photoGroups, searchKey],
  );

  // Handler for toggling all photo groups.
  const handleToggleAll = (on: boolean) => {
    if (on) {
      setFilters(photoGroups.map(group => group.id));
      setShowProcessDone(true);
      setShowProcessInProgress(true);
    } else {
      setFilters([]);
      setShowProcessDone(false);
      setShowProcessInProgress(false);
    }
  };

  // Handler for toggling a photo group
  const handleToggleGroup = (id: string) => {
    if (filters.includes(id)) {
      setFilters(prev => prev.filter(query => query !== id));
    } else {
      setFilters(prev => [...prev, id]);
    }
  };

  // Apply all filters
  const handleApply = () => {
    setImageViewerSettings(
      { photoGroupIds: filters, showProcessDone, showProcessInProgress },
      currentTab,
    );
    closeModal();
  };

  return (
    <div className={cn('relative w-auto')}>
      <div
        className={cn(
          'flex flex-col p-3 absolute z1 p-1 w-full rounded-lg  bg-neutral-100 border border-1 border-neutral-400 border-solid',
        )}
      >
        <div
          className="i-skand-close absolute right-3 h-12px w-12px cursor-pointer color-neutral-400"
          onClick={closeModal}
        />
        <div>
          <p className={cn('typo-text-medium text-neutral-800')}>Filter options</p>
        </div>
        <div className="mb-8px mt-8px w-full">
          <Input
            label="Search for photo groups"
            onChange={setSearchKey}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>

        <div className={cn('overflow-scroll', 'max-h-300px')}>
          <div className={cn('flex flex-row mt-12px self-start items-center')}>
            <Checkbox checked={isAllEnabled} setToggleCheckbox={handleToggleAll} />
            <p className={cn('typo-link-small text-neutral-800 ml-8px')}>All</p>
          </div>
          {targetProcess && (
            <>
              <div className={cn('flex flex-row mt-12px self-start items-center')}>
                <Checkbox
                  checked={showProcessDone}
                  setToggleCheckbox={() => setShowProcessDone(!showProcessDone)}
                />
                <p className={cn('typo-link-small text-neutral-800 ml-8px')}>
                  Finished (Process {targetProcess.id})
                </p>
              </div>
              <div className={cn('flex flex-row mt-12px self-start items-center')}>
                <Checkbox
                  checked={showProcessInProgress}
                  setToggleCheckbox={() => setShowProcessInProgress(!showProcessInProgress)}
                />
                <p className={cn('typo-link-small text-neutral-800 ml-8px')}>
                  In Progress (Process {targetProcess.id})
                </p>
              </div>
            </>
          )}
          {filtered.map((item, index) => (
            <div
              className={cn('flex flex-row mt-12px self-start items-center')}
              key={`photo-group-filter-${index}`}
            >
              <Checkbox
                checked={filters.includes(item.id)}
                setToggleCheckbox={() => handleToggleGroup(item.id)}
              />
              <p className={cn('typo-text-s text-neutral-800 ml-8px')}>{item.name}</p>
            </div>
          ))}
        </div>

        <div className={cn('flex gap-2 mt-3')}>
          <Button className="w-full" filled onClick={handleApply} primary size="s">
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};
