import { RENDERER_TYPE } from '@/LegacyExplore/constants/renderer';
import { SceneEntity } from '@/LegacyExplore/graphql/codegen/graphql';
import {
  LIST_LAYERS_BY_PROJECT_ID,
  LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES,
} from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { AnnotationGroup, Layer, LayerGroup, PhotoGroup } from '@/LegacyExplore/stores/viewer';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import {
  createAnnotationGroup,
  createLayer,
  createLayerGroup,
  createPhotoGroup,
} from '@/LegacyExplore/utils/transformers';
import { Graph } from '@skand/math';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getLayerQueryKey = (projectId: string | null) => ['LIST_LAYERS_BY_PROJECT_ID', projectId];
const getSceneEntityQueryKey = (projectId: string | null) => [
  'LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES',
  projectId,
];

export const useFetchSceneEntities = () => {
  const projectId = useExplore(state => state.projectId);

  const layersResult = useQuery({
    enabled: !!projectId,
    queryFn: () => request(LIST_LAYERS_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: getLayerQueryKey(projectId),
  });
  const sceneEntitiesResult = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES, {
        projectId: projectId as string,
        rendererTypes: [],
      }),
    queryKey: getSceneEntityQueryKey(projectId),
  });

  return useCallback(async () => {
    const layerGroups: LayerGroup[] = [];
    const layers: Layer[] = [];
    const annotationGroups: AnnotationGroup[] = [];
    const panoramaGroups: PhotoGroup[] = [];
    const photo2DGroups: PhotoGroup[] = [];

    // Link nodes together
    const graph = new Graph<string>();
    const sceneEntityMap = new Map<string, SceneEntity>();
    const objectMap = new Map<string, Layer | LayerGroup | AnnotationGroup | PhotoGroup>();

    if (
      !isEmpty(sceneEntitiesResult.data) &&
      !isEmpty(sceneEntitiesResult.data.listSceneEntitiesByProjectIdAndRendererTypes) &&
      !isEmpty(layersResult.data) &&
      !isEmpty(layersResult.data.listLayersByProjectId)
    ) {
      // Pre-process scene entity graph
      for (const entity of sceneEntitiesResult.data.listSceneEntitiesByProjectIdAndRendererTypes) {
        if (!isEmpty(entity) && !isEmpty(entity.id)) {
          graph.addNode(entity.id);
          sceneEntityMap.set(entity.id, entity);

          // Define dependency
          if (!isEmpty(entity.parentSceneEntityId)) {
            graph.addEdge(entity.parentSceneEntityId, entity.id);
          }
        }
      }

      // Process each node in order
      const sorted = graph.topologicalSort() ?? [];
      for (const nodeId of sorted) {
        const node = sceneEntityMap.get(nodeId);
        if (!node) {
          continue;
        }

        // Do not include if parent exists but is not included
        const parentId = graph.getParents(nodeId)[0];
        const parent = objectMap.get(parentId) as Layer | undefined;
        if (parentId && !parent) {
          continue;
        }

        // Create the nodes by renderer type
        if (node.rendererType === null) {
          const group = createLayerGroup(node, parent);
          if (group) {
            layerGroups.push(group);
            objectMap.set(nodeId, group);
          }
        } else if (node.rendererType === RENDERER_TYPE.ANNOTATION) {
          const group = createAnnotationGroup(node, parent);
          if (group) {
            annotationGroups.push(group);
            objectMap.set(nodeId, group);
          }
        } else if (node.rendererType === RENDERER_TYPE.IMAGE_PROJECTION) {
          const group = createPhotoGroup(node, parent);
          if (group) {
            photo2DGroups.push(group);
            objectMap.set(nodeId, group);
          }
        } else if (node.rendererType === RENDERER_TYPE.PANORAMIC_IMAGE) {
          const group = createPhotoGroup(node, parent);

          if (group) {
            panoramaGroups.push(group);
            objectMap.set(nodeId, group);
          }
        } else {
          const layerQuery = layersResult.data.listLayersByProjectId.find(
            layer => layer?.mainSceneEntityId === nodeId,
          );
          if (layerQuery && layerQuery.status === 'PUBLISHED' && !isEmpty(layerQuery.id)) {
            const layer = createLayer(node, layerQuery, parent);
            if (layer) {
              if (layer.type === 'layer') {
                layers.push(layer);
              } else {
                layerGroups.push(layer);
              }
              objectMap.set(nodeId, layer);
            }
          }
        }
      }
    }
    return {
      layerGroups,
      layers,
      annotationGroups,
      panoramaGroups,
      photo2DGroups,
    };
  }, [sceneEntitiesResult.data, layersResult.data]);
};

useFetchSceneEntities.getLayerQueryKey = getLayerQueryKey;
useFetchSceneEntities.getSceneEntityQueryKey = getSceneEntityQueryKey;
