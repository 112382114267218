import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import {
  GEOID_OPTIONS,
  setAddress,
  setDate,
  setDescription,
  setLat,
  setLng,
  setName,
  setProjectGroup,
  setSelectedGeoId,
  useCreateProject,
} from '@/stores/createProject';
import { cn } from '@/utils/classname';
import { ProjectGroup } from '@/utils/project';
import { Button, DatePicker, Input, Select } from '@skand/ui';
import { useEffect, useState } from 'react';
import { AutoComplete } from './AutoComplete';
import { SiteMap } from './SiteMap';

interface InsertProjectDetailsProps {
  groups: ProjectGroup[];
}

const START_MONTH = new Date(1950, 0);
const END_MONTH = new Date(2050, 0);

export const InsertProjectDetails = ({ groups }: InsertProjectDetailsProps) => {
  const date = useCreateProject(state => state.date);
  const description = useCreateProject(state => state.description);
  const latitude = useCreateProject(state => state.latitude);
  const longitude = useCreateProject(state => state.longitude);
  const name = useCreateProject(state => state.name);
  const projectGroup = useCreateProject(state => state.projectGroup);

  const [positions, setPositions] = useState<google.maps.LatLngLiteral>({
    lat: latitude,
    lng: longitude,
  });

  useEffect(() => {
    if (groups.length === 1 && !projectGroup) {
      setProjectGroup(groups[0]);
    }
  }, [groups, projectGroup]);

  useEffect(() => {
    setLat(positions.lat.toString());
    setLng(positions.lng.toString());
  }, [positions]);

  return (
    <div
      className={cn(
        'flex-1',
        'grid overflow-y-auto auto-rows-max m-y-1',
        'scrollbar scrollbar-rounded',
        'h-[calc(90vh-200px)]',
      )}
    >
      <div className={cn('flex-1')}>
        <div className={cn('flex', 'gap-3', 'items-stretch')}>
          <div className="flex-1 [&>div]:h-full [&>div>div]:h-full">
            <Input
              data-analytics-event-object={ANALYTICS_EVENT_OBJECT.CREATE_PROJECT_NAME}
              label="Project name"
              onChange={setName}
              value={name}
            />
          </div>
          <div className="z-20 flex-1 [&>div:first-child]:h-full [&>div:first-child>div]:h-full">
            <DatePicker
              endMonth={END_MONTH}
              label="Date"
              onChange={setDate}
              startMonth={START_MONTH}
              value={date}
            />
          </div>
        </div>

        <div className="flex-1 pt-3">
          <Select
            className="z-10 w-full"
            disabled={!groups.length}
            label={!projectGroup?.name ? 'Add to project group' : 'Project group'}
            onChange={e => {
              setProjectGroup(groups.find(group => group.id === e) ?? null);
            }}
            options={groups.map(group => ({
              key: group.id,
              name: group.name,
            }))}
            tail={<div className="i-skand-dropdown m-l-4 color-neutral-400" />}
            value={projectGroup?.id}
            width="full"
          />
        </div>

        <div className={cn('flex-1', 'pt-3')}>
          <AutoComplete positions={positions} setAddress={setAddress} setPositions={setPositions} />
        </div>
        <div className={cn('typo-text-xs', 'pt-1', 'color-neutral-600')}>
          No address for the project? Select any point on the map to associate the project with a
          location.
        </div>
        <div className={cn('relative', 'pt-3')}>
          <SiteMap positions={positions} setPositions={setPositions} />
          <div className={cn('bottom-4', 'left-4', 'absolute', 'flex', 'flex-row', 'items-center')}>
            <div className={cn('w-30', 'mr-3')}>
              <Input
                className={cn('b-none', 'overflow-hidden', 'h-5', 'text-neutral-500')}
                onChange={setLat}
                placeholder="LAT"
                value={latitude}
              />
            </div>
            <div className={cn('w-30', 'mr-3')}>
              <Input
                className={cn('b-none', 'overflow-hidden', 'h-5', 'text-neutral-500')}
                onChange={setLng}
                placeholder="LNG"
                value={longitude}
              />
            </div>
            <Button
              className={cn('w-15', 'h-9', 'hover:cursor-pointer', 'rounded-1')}
              filled
              onClick={() => setPositions({ lat: latitude, lng: longitude })}
              primary
              size="small"
            >
              Go
            </Button>
          </div>
        </div>
      </div>
      <Select
        className="z-4 mt-2 w-full"
        label="GEOID"
        onChange={value => {
          const selectedItem = GEOID_OPTIONS.find(item => item.value === value);

          if (selectedItem !== undefined) {
            setSelectedGeoId(selectedItem);
          }
        }}
        options={GEOID_OPTIONS.map(item => ({
          key: item.value,
          name: item.label,
        }))}
        placeholder="placeholder"
        tail={<div className="i-skand-dropdown ml-4 color-neutral-400" />}
        width="full"
        xPlacement="left"
        yPlacement="bottom"
      />
      <div className={cn('flex-1', 'mt-3')}>
        <Input
          label="General dataset information"
          onChange={setDescription}
          tail={<div className={cn('i-skand-textfield', 'text-3', 'text-neutral-400')} />}
          value={description}
        />
      </div>
    </div>
  );
};
