import { ACCOUNT_BY_CONTEXT } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { useQuery } from '@tanstack/react-query';

const queryKey = ['ACCOUNT_BY_CONTEXT'];

export const useAccountId = () => {
  const isReadOnly = getShareLinkToken() !== null;

  const response = useQuery({
    queryFn: () => request(ACCOUNT_BY_CONTEXT),
    enabled: !isReadOnly,
    queryKey,
  });

  return response.data?.accountByContext?.id;
};

useAccountId.queryKey = queryKey;
