import { InputSlider } from '@/components/SceneTab/Menus/InputSlider';
import { setViewer3DSettings, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { RENDERER_STATISTICS_FLAG } from '@/utils/split';
import { CheckBox, Switch } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';

export const Viewer3DMenu = () => {
  const treatment = useTreatments([RENDERER_STATISTICS_FLAG]);
  const statisticsTabFlag = treatment[RENDERER_STATISTICS_FLAG].treatment === 'on';
  const viewer3DSettings = useViewer(state => state.viewer3DSettings);

  return (
    <div className={'w-full h-full'}>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start')}>
        <InputSlider
          className={'typo-text-s-em w-full'}
          max={2048}
          min={512}
          setValue={value => setViewer3DSettings({ tileMemoryBudget: value })}
          step={1}
          title="Tile memory budget"
          value={viewer3DSettings.tileMemoryBudget}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start')}>
        <InputSlider
          className={'typo-text-s-em w-full'}
          max={100}
          min={1}
          setValue={value => setViewer3DSettings({ pointBudget: value * 100000 })}
          step={1}
          title="Point budget in 100,000"
          value={viewer3DSettings.pointBudget / 100000}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>User local cache</span>
        <Switch
          checked={viewer3DSettings.localCacheEnabled}
          onChange={value => setViewer3DSettings({ localCacheEnabled: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Network request sorting</span>
        <Switch
          checked={viewer3DSettings.networkRequestSorting}
          onChange={value => setViewer3DSettings({ networkRequestSorting: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Tile request cancelling</span>
        <Switch
          checked={viewer3DSettings.tileRequestCancelling}
          onChange={value => setViewer3DSettings({ tileRequestCancelling: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Max network requests</span>
        <input
          className={cn(
            'flex',
            'justify-center',
            'text-center',
            'border-solid',
            'border-neutral-400',
            'b-1px',
            'rounded-4px',
            'w-36px',
            'h-28px',
            'typo-text-small',
            'text-neutral-700',
          )}
          onChange={e => setViewer3DSettings({ maxNetworkRequests: parseInt(e.target.value) })}
          step={1}
          type="number"
          value={viewer3DSettings.maxNetworkRequests}
        />
      </div>
      <div className={cn('border-1px', 'border-solid', 'border-b-neutral-500', 'mt-12px')} />
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Near plane clipping</span>
        <Switch
          checked={viewer3DSettings.orthoNearPlaneClipping}
          onChange={value => setViewer3DSettings({ orthoNearPlaneClipping: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Eye dome lighting</span>
        <Switch
          checked={viewer3DSettings.eyeDomeLighting}
          onChange={value => setViewer3DSettings({ eyeDomeLighting: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Point size attenuation</span>
        <Switch
          checked={viewer3DSettings.pointSizeAttenuation}
          onChange={value => setViewer3DSettings({ pointSizeAttenuation: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Globe clipping</span>
        <Switch
          checked={viewer3DSettings.globeClipping}
          onChange={value => setViewer3DSettings({ globeClipping: value })}
        />
      </div>
      {statisticsTabFlag && (
        <>
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
            <span className={cn('typo-text-s-em', 'color-neutral-800')}>Show statistics</span>
            <Switch
              checked={viewer3DSettings.enabledStatistics}
              onChange={value => setViewer3DSettings({ enabledStatistics: value })}
            />
          </div>
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
            <span className={cn('typo-text-s-em', 'color-neutral-800')}>
              Show tile request priorities
            </span>
            <Switch
              checked={viewer3DSettings.showRequestPriorities}
              onChange={value => setViewer3DSettings({ showRequestPriorities: value })}
            />
          </div>
        </>
      )}
      <div className={cn('border-1px', 'border-solid', 'border-b-neutral-500', 'mt-12px')} />
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Label visibility</span>
        <div className={cn('flex', 'flex-col', 'items-start')}>
          <div className={cn('flex', 'flex-row', 'items-center')}>
            <CheckBox
              checked={viewer3DSettings.annotationNameVisibility}
              onChange={event =>
                setViewer3DSettings({ annotationNameVisibility: event.target.checked })
              }
            />
            <span className={cn('color-neutral-800', 'typo-text-s', 'ml-2')}>Name labels</span>
          </div>
          <div className={cn('flex', 'flex-row', 'items-center', 'mt-0.5')}>
            <CheckBox
              checked={viewer3DSettings.annotationMeasurementVisibility}
              onChange={event =>
                setViewer3DSettings({ annotationMeasurementVisibility: event.target.checked })
              }
            />
            <span className={cn('color-neutral-800', 'typo-text-s', 'ml-2')}>
              Measurement labels
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
