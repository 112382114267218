import { cn } from '@skand/ui';
import { useState } from 'react';
import { Viewer2DMenu } from './Viewer2DMenu';
import { Viewer3DMenu } from './Viewer3DMenu';

export const SettingsMenu = () => {
  const [currentTab, setCurrentTab] = useState<'3d' | '2d'>('3d');

  const getTabComponent = () => {
    switch (currentTab) {
      case '3d':
        return <Viewer3DMenu />;
      case '2d':
        return <Viewer2DMenu />;
    }
  };

  return (
    <div
      className={cn(
        'w-full',
        'top-0',
        'absolute',
        'flex',
        'flex-col',
        'bg-neutral-100',
        'p-12px',
        'rounded-4px',
        'bg-neutral-100',
        'z-1',
      )}
    >
      <div className={cn('w-full flex flex-col')}>
        <div
          className={cn(
            'items-center pb-2 flex h-auto justify-around w-full mt-1 b-solid b-neutral-400 border-b-1 border-l-0 border-r-0 border-t-0',
          )}
        >
          <button
            className={cn('bg-transparent', 'b-none', 'cursor-pointer', 'flex', 'flex-1')}
            onClick={() => setCurrentTab('3d')}
          >
            <p
              className={cn(
                'typo-text-small',
                currentTab === '3d' ? 'text-neutral-800' : 'text-neutral-400',
              )}
            >
              3D Viewer
            </p>
          </button>
          <button
            className={cn(
              'bg-transparent',
              'b-none',
              'cursor-pointer',
              'flex',
              'flex-1',
              'b-neutral-400',
            )}
            onClick={() => setCurrentTab('2d')}
          >
            <p
              className={cn(
                'typo-text-small',
                currentTab === '2d' ? 'text-neutral-800' : 'text-neutral-400',
              )}
            >
              Images
            </p>
          </button>
        </div>
        {getTabComponent()}
      </div>
    </div>
  );
};
