import { ACCOUNT_BY_CONTEXT } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useQuery } from '@tanstack/react-query';

const queryKey = ['ACCOUNT_BY_CONTEXT'];

export const useAccount = () => {
  const response = useQuery({
    queryFn: () => request(ACCOUNT_BY_CONTEXT),
    queryKey,
  });

  return response;
};

useAccount.queryKey = queryKey;
