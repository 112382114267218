import { cn } from '@/LegacyExplore/utils/classname';

export interface ItemProps {
  label: string;
  value: string;
}

export const Item = ({ label, value }: ItemProps) => {
  return (
    <div className={cn('flex', 'justify-between', 'mt-2')}>
      <span className={cn('color-neutral-600', 'typo-text-s')}>{label}</span>
      <span className={cn('color-neutral-800', 'typo-text-s')}>{value}</span>
    </div>
  );
};
