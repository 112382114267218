import { cn } from '@/LegacyExplore/utils/classname';
import { Bar } from './Bar';

export const Loading = () => {
  return (
    <div className={cn('items-center', 'flex', 'h-full', 'justify-center', 'w-full')}>
      <Bar delay={0} />
      <Bar delay={100} />
      <Bar delay={200} />
    </div>
  );
};
