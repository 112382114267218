import { AnnotationTemplateField } from '@/LegacyExplore/graphql/codegen/graphql';
import { WithMode, renameField, useTemplate } from '@/LegacyExplore/stores/template';
import { Input } from '@skand/ui';
import { RefObject } from 'react';

export const Name = ({
  field,
  handleRef,
}: {
  field: WithMode<AnnotationTemplateField>;
  handleRef?: RefObject<HTMLDivElement>;
}) => {
  const templateMode = useTemplate(state => state.mode);

  return (
    <div className="flex items-center">
      {templateMode !== 'view' && (
        <div
          className="i-skand-reorder mr-3 cursor-grab color-neutral-400 hover:color-primary-400"
          ref={handleRef}
        />
      )}

      {field.mode === 'view' ? (
        <span className="color-neutral-800 typo-text-s">{field.name}</span>
      ) : (
        <div className="mr-3 flex-1">
          <Input onChange={(text: string) => renameField(field, text)} value={field.name ?? ''} />
        </div>
      )}
    </div>
  );
};
