import {
  GetImageThumbnailsByFileIdsQuery,
  GetImageUrlsByFileIdsQuery,
} from '@/LegacyExplore/graphql/codegen/graphql';
import {
  GET_IMAGE_THUMBNAIL_URLS_BY_FILE_IDS,
  GET_IMAGE_URLS_BY_FILE_ID,
} from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (fileIds: string[], thumbnail: boolean) => [
  thumbnail ? 'GET_IMAGE_THUMBNAIL_URLS_BY_FILE_IDS' : 'GET_IMAGE_URLS_BY_FILE_ID',
  fileIds,
];

const transformThumbnailUrls = (
  urls: Map<string, string>,
  raw: GetImageThumbnailsByFileIdsQuery,
) => {
  for (const file of raw.filesByIds ?? []) {
    if (
      !isEmpty(file) &&
      file.__typename === 'ImageFile' &&
      !isEmpty(file.id) &&
      !isEmpty(file.thumbnailUrl)
    ) {
      urls.set(file.id, file.thumbnailUrl);
    }
  }
};

const transformUrls = (urls: Map<string, string>, raw: GetImageUrlsByFileIdsQuery) => {
  for (const file of raw.filesByIds ?? []) {
    if (
      !isEmpty(file) &&
      file.__typename === 'ImageFile' &&
      !isEmpty(file.id) &&
      !isEmpty(file.signedGetObjectUrl)
    ) {
      urls.set(file.id, file.signedGetObjectUrl);
    }
  }
};

export const useFetchImageUrls = (fileIds: string[], thumbnail: boolean) => {
  const { refetch: fetchThumbnailUrl } = useQuery({
    enabled: false,
    queryFn: () => request(GET_IMAGE_THUMBNAIL_URLS_BY_FILE_IDS, { fileIds }),
    queryKey: getQueryKey(fileIds, true),
  });
  const { refetch: fetchUrl } = useQuery({
    enabled: false,
    queryFn: () => request(GET_IMAGE_URLS_BY_FILE_ID, { fileIds }),
    queryKey: getQueryKey(fileIds, false),
  });

  return useCallback(async () => {
    const urls: Map<string, string> = new Map();
    if (thumbnail) {
      const result = await fetchThumbnailUrl();
      if (!isEmpty(result.data)) {
        transformThumbnailUrls(urls, result.data);
      }
    } else {
      const result = await fetchUrl();
      if (!isEmpty(result.data)) {
        transformUrls(urls, result.data);
      }
    }
    return urls;
  }, [fetchThumbnailUrl, fetchUrl, thumbnail]);
};

useFetchImageUrls.getQueryKey = getQueryKey;
useFetchImageUrls.transformUrls = transformUrls;
useFetchImageUrls.transformThumbnailUrls = transformThumbnailUrls;
