import { COLOR_FROM_FIELD_CUSTOM } from '@/LegacyExplore/constants/misc';
import { AnnotationTemplate } from '@/LegacyExplore/graphql/codegen/graphql';
import { useTemplate } from './store';

export const createTemplate = () => {
  useTemplate.setState({
    accountLevelTemplate: null,
    fields: [],
    mode: 'create',
    next: 0,
    template: {
      name: '',
      description: '',
    },
  });
};

export const clearTemplate = () => {
  useTemplate.setState({
    accountLevelTemplate: null,
    fields: [],
    mode: null,
    template: null,
    next: 0,
  });
};

export const updateTemplate = (template: SolidId<AnnotationTemplate>) => {
  const fields = template.fields ?? [];

  useTemplate.setState({
    fields: fields.map(f => ({ ...f, mode: 'view' })),
    mode: 'update',
    next: fields.length,
    template,
  });
};

export const viewTemplate = (template: SolidId<AnnotationTemplate>) => {
  const fields = template.fields ?? [];

  useTemplate.setState({
    fields: fields.map(f => ({ ...f, mode: 'view' })),
    mode: 'view',
    next: fields.length,
    template,
  });
};

export const changeTemplateName = (name: string) => {
  useTemplate.setState(state => {
    return { template: { ...state.template, name } };
  });
};

export const changeTemplateDesc = (desc: string) => {
  useTemplate.setState(state => {
    return { template: { ...state.template, description: desc } };
  });
};

export const copyTemplate = (from: SolidId<AnnotationTemplate>) => {
  useTemplate.setState(() => {
    return {
      accountLevelTemplate: from,
      fields: [...(from.fields ?? [])].map(f => ({ ...f, mode: 'view' })),
      next: from.fields?.length ?? 0,
    };
  });
};

export const changeTemplateColor = (field: string) => {
  useTemplate.setState(state => {
    return {
      template: {
        ...state.template,
        colorFromField: field === COLOR_FROM_FIELD_CUSTOM ? null : field,
      },
    };
  });
};
