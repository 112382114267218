import { LAYER_FORMAT_TYPE } from '@/LegacyExplore/constants/layer';
import { Layer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { useMemo } from 'react';

export interface LayerNodeProps {
  layer: Layer;
}

export const LayerNode = ({ layer }: LayerNodeProps) => {
  const iconName = useMemo(() => {
    switch (layer.formatType) {
      case LAYER_FORMAT_TYPE.IFC:
        return 'i-skand-ifc';
      case LAYER_FORMAT_TYPE.OBJ:
        return 'i-skand-obj';
      case LAYER_FORMAT_TYPE.POINT_CLOUD:
        return 'i-skand-pointcloud';
      case LAYER_FORMAT_TYPE.MESH_3D:
        return 'i-skand-mesh3d';
      case LAYER_FORMAT_TYPE.ORTHO_2D:
      case LAYER_FORMAT_TYPE.TERRAIN:
        return 'i-skand-ortho';
      case LAYER_FORMAT_TYPE.IMAGES:
        return 'i-skand-image';
      case LAYER_FORMAT_TYPE.DXF:
        return 'i-skand-dxf';
      case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
        return 'i-skand-bim';
    }
  }, [layer.formatType]);

  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <div className={cn('w-5 color-neutral-600 text-3 flex-none', iconName)} />
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
      </div>
    </div>
  );
};
