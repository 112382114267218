import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { useFetchProject } from '@/LegacyExplore/hooks/useFetchProject';
import { useFetchProjectPermissions } from '@/LegacyExplore/hooks/useFetchProjectPermissions';
import { useFetchShareLinks } from '@/LegacyExplore/hooks/useFetchShareLinks';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { ShareLink, setSelectedShareLink, useShareLink } from '@/LegacyExplore/stores/shareLink';
import { cn } from '@/LegacyExplore/utils/classname';
import { search } from '@/LegacyExplore/utils/search';
import { Button, Input } from '@skand/ui';
import { useEffect, useMemo, useState } from 'react';
import { BlockLoading } from '../BlockLoading';
import { EmptyResourceMessage } from '../EmptyResourceMessage';
import { FindIcon } from '../IconButton';
import { ShareLinkSettingsPanel } from './LeftSideBarViews/ShareLinkSettingsPanel';
import { ShareLinkCard } from './ShareLinkCard';

export const ShareLinkTab = () => {
  const projectId = useExplore(state => state.projectId);
  const shareLinkTab = useShareLink(state => state.shareLinkTab);

  const [searchKey, setSearchKey] = useState('');
  const { shareLinks, response } = useFetchShareLinks();
  const { fetch: fetchProject } = useFetchProject();

  const filtered = useMemo(
    () =>
      shareLinks
        .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        .filter(shareLink => search(shareLink.name, searchKey)),
    [searchKey, shareLinks],
  );

  const { getProjectPermission } = useFetchProjectPermissions();
  const permission = getProjectPermission(projectId);
  const canEdit = canPolicyActionEdit(permission);

  // Fetch project information
  useEffect(() => {
    fetchProject().then(project => {
      if (project) {
        document.title = project.name;
      }
    });
  }, [fetchProject]);

  const renderShareLinkTab = () => {
    switch (shareLinkTab) {
      case 'card':
        return <ShareLinkCardsPanel />;
      case 'settings':
      case 'create':
        return <ShareLinkSettingsPanel />;
    }
  };

  const shareLinkTabTitle = useMemo(() => {
    switch (shareLinkTab) {
      case 'card':
        return 'Share link';
      case 'settings':
        return 'Share link settings';
      case 'create':
        return 'Create share link';
    }
  }, [shareLinkTab]);

  const ShareLinkCardsPanel = () => (
    <div className={cn('flex overflow-auto flex-col')}>
      <div className={cn('flex gap-2')}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search for share links"
            onChange={event => setSearchKey(event)}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
      </div>
      <Button
        className={cn('w-full my-2')}
        disabled={!canEdit}
        filled
        onClick={() => {
          setSelectedShareLink(null);
          useShareLink.setState({ shareLinkTab: 'create' });
        }}
        primary
        size="s"
      >
        Create share link
      </Button>

      {response.isLoading ? (
        <BlockLoading />
      ) : !filtered.length ? (
        <EmptyResourceMessage
          emptyResourceContent={{
            emptyDesc: 'Use the Create button to create a new share link.',
            emptyTitle: 'No share links available',
          }}
        />
      ) : (
        <div className="overflow-auto">
          {filtered.map((shareLink: ShareLink) => (
            <ShareLinkCard disabled={!canEdit} key={shareLink.id} shareLink={shareLink} />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className={cn('flex flex-col px-[6px] gap-3  h-full')}>
      <div
        className={cn(
          'border-neutral-300',
          'flex',
          'flex-row',
          'gap-3',
          'items-center',
          'border-1',
          'border-b-solid',
          'pb-3',
        )}
      >
        <p className={cn('typo-heading-4 color-neutral-800')}>{shareLinkTabTitle}</p>
      </div>
      {renderShareLinkTab()}
    </div>
  );
};
