import { AnnotationTemplate } from '@/LegacyExplore/graphql/codegen/graphql';
import { LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { hasId } from '@/LegacyExplore/utils/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT'];

export const useAccountAnnotationTemplates = () => {
  const projectId = useExplore(state => state.projectId);

  const response = useQuery({
    enabled: !!projectId,
    queryFn: () => request(LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT),
    queryKey,
  });

  const templates: SolidId<AnnotationTemplate>[] = useMemo(() => {
    return response.data?.listAnnotationTemplatesByAccountContext?.filter(hasId) ?? [];
  }, [response.data?.listAnnotationTemplatesByAccountContext]);

  return { response, templates };
};

useAccountAnnotationTemplates.queryKey = queryKey;
