import { Drawable, PolygonDrawable } from '@skand/viewer-component-v2';
import { Color, Vector2 } from 'three';
import { Transform2 } from '../../Transform2';
import { Sketch2 } from './Sketch2';

/**
 * 2D polygon sketch object.
 */
export class PolygonSketch2 implements Sketch2 {
  private vertices: Vector2[];
  private color: Color;

  constructor(vertices: Vector2[], color: Color) {
    this.vertices = vertices;
    this.color = color;
  }

  buildDrawables() {
    const fill: PolygonDrawable = {
      type: 'polygon',
      vertices: this.getVertices(),
      color: this.color,
      opacity: 0.5,
      fill: true,
    };
    const outline: PolygonDrawable = {
      type: 'polygon',
      vertices: this.getVertices(),
      color: this.color,
      opacity: 1.0,
      fill: false,
      border: 3,
    };
    return [fill, outline];
  }

  getVertices() {
    return this.vertices.map(vertex => vertex.clone());
  }

  transformDrawables(drawables: Drawable[], transform: Transform2): void {
    const [fill, outline] = [drawables[0] as PolygonDrawable, drawables[1] as PolygonDrawable];
    fill.vertices = this.vertices.map(vertex => transform.imageToCanvasSpace(vertex));
    outline.vertices = this.vertices.map(vertex => transform.imageToCanvasSpace(vertex));
  }

  /**
   * Test if a point is colliding with the polygon.
   *
   * Algorithm: Cast a ray from the point and count the number of times it intersects
   * with the edge of the polygon. The point is in the polygon if this number is even.
   *
   * Let
   *  P' be the intersection point
   *  P be the clicked point
   *  D be the direction of the ray from P
   *  S, T be the endpoints of a polygon edge
   *  k, v be parametric equation scalars
   *
   * Ray:     P' = P + k * D
   * Segment: P' = S + v * (T - S)
   *
   * They intersect iff (P + k * D) = (S + v * (T - S))
   *
   * @param point
   * @param transform
   * @returns
   */
  isColliding(point: Vector2, transform: Transform2) {
    let count = 0;
    for (let i = 0; i < this.vertices.length; i++) {
      const s = transform.imageToCanvasSpace(this.vertices[i]);
      const t = transform.imageToCanvasSpace(this.vertices[(i + 1) % this.vertices.length]);

      const v1 = point.clone().sub(s);
      const v2 = t.clone().sub(s);
      const v3 = new Vector2(0, 1);

      const cross21 = v2.cross(v1);
      const dot13 = v1.dot(v3);
      const dot23 = v2.dot(v3);
      const k = cross21 / dot23;
      const v = dot13 / dot23;
      if (k >= 0 && v >= 0 && v <= 1) {
        count++;
      }
    }
    return count % 2 !== 0;
  }

  setColor(color: Color): void {
    this.color = color;
  }

  getColor(): Color {
    return this.color;
  }
}
