import { ACTION_TYPE, OBJECT_ID, OBJECT_TYPE } from '@/LegacyExplore/constants/policy';
import {
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { Project, ProjectGroup } from '@/LegacyExplore/utils/project';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchPermissionPolicies } from './useFetchPermissionPolicies';
import { useFetchProjects } from './useFetchProjects';
import { useIsSuperAdmin } from './useIsSuperAdmin';

export const useFetchProjectPermissions = () => {
  const [projects, setProjects] = useState<Map<string, Project>>(new Map());
  const [groups, setGroups] = useState<Map<string, ProjectGroup>>(new Map());

  const { fetch } = useFetchProjects();

  // Fetch project and group data
  useEffect(() => {
    fetch().then(result => {
      setProjects(new Map(result.projects.map(project => [project.id, project])));
      setGroups(new Map(result.groups.map(group => [group.id, group])));
    });
  }, [fetch]);

  const isSuperAdmin = useIsSuperAdmin();
  const projectPolicies = useFetchPermissionPolicies(PolicyObjectTypeInput.Project, [
    ...projects.keys(),
  ]);
  const groupPolicies = useFetchPermissionPolicies(PolicyObjectTypeInput.ProjectGroup, [
    ...groups.keys(),
  ]);

  // Permission level for ALL project groups
  const allGroupsPermission = useMemo(() => {
    if (isSuperAdmin) return ACTION_TYPE.ADMIN;
    if (
      groupPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.ADMIN &&
          policy.objectId === OBJECT_ID.ALL &&
          (policy.objectType === OBJECT_TYPE.ALL ||
            policy.objectType === OBJECT_TYPE.PROJECT_GROUP),
      )
    ) {
      return ACTION_TYPE.ADMIN;
    } else if (
      groupPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.EDIT &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.PROJECT_GROUP,
      )
    ) {
      return ACTION_TYPE.EDIT;
    } else if (
      groupPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.READ &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.PROJECT_GROUP,
      )
    ) {
      return ACTION_TYPE.READ;
    } else if (
      groupPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.LIST &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.PROJECT_GROUP,
      )
    ) {
      return ACTION_TYPE.LIST;
    } else {
      return null;
    }
  }, [groupPolicies, isSuperAdmin]);

  // Permission level for ALL projects
  const allProjectsPermission = useMemo(() => {
    if (isSuperAdmin) return ACTION_TYPE.ADMIN;
    if (
      projectPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.ADMIN &&
          policy.objectId === OBJECT_ID.ALL &&
          (policy.objectType === OBJECT_TYPE.ALL || policy.objectType === OBJECT_TYPE.PROJECT),
      )
    ) {
      return ACTION_TYPE.ADMIN;
    } else if (
      projectPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.EDIT &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.PROJECT,
      )
    ) {
      return ACTION_TYPE.EDIT;
    } else if (
      projectPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.READ &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.PROJECT,
      )
    ) {
      return ACTION_TYPE.READ;
    } else if (
      projectPolicies.some(
        policy =>
          policy.actionType === ACTION_TYPE.LIST &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.PROJECT,
      )
    ) {
      return ACTION_TYPE.LIST;
    } else {
      return null;
    }
  }, [projectPolicies, isSuperAdmin]);

  // Get the permission level of a specific project group
  const getGroupPermission = useCallback(
    (groupId: string | null) => {
      const findActionType = (actionType: PolicyActionTypeInput) => {
        if (!groupId) return false;
        return groupPolicies.some(
          policy => policy.objectId === groupId && policy.actionType === actionType,
        );
      };
      if (isSuperAdmin) return ACTION_TYPE.ADMIN;
      if (
        allGroupsPermission === ACTION_TYPE.ADMIN ||
        findActionType(PolicyActionTypeInput.Admin)
      ) {
        return ACTION_TYPE.ADMIN;
      } else if (
        allGroupsPermission === ACTION_TYPE.EDIT ||
        findActionType(PolicyActionTypeInput.Edit)
      ) {
        return ACTION_TYPE.EDIT;
      } else if (
        allGroupsPermission === ACTION_TYPE.READ ||
        findActionType(PolicyActionTypeInput.Read)
      ) {
        return ACTION_TYPE.READ;
      } else if (
        allGroupsPermission === ACTION_TYPE.LIST ||
        findActionType(PolicyActionTypeInput.List)
      ) {
        return ACTION_TYPE.LIST;
      } else {
        return null;
      }
    },
    [allGroupsPermission, groupPolicies, isSuperAdmin],
  );

  // Get the permission level of a specific project
  // TODO: Is this logic correct?
  const getProjectPermission = useCallback(
    (projectId: string | null) => {
      const project = projectId ? projects.get(projectId) : undefined;
      const projectGroupId = project?.group?.id;

      const groupPermissions = getGroupPermission(projectGroupId ?? null);

      const findActionType = (actionType: PolicyActionTypeInput) => {
        if (!projectId) return false;
        return projectPolicies.some(
          policy => policy.objectId === projectId && policy.actionType === actionType,
        );
      };
      if (isSuperAdmin) return ACTION_TYPE.ADMIN;
      if (
        groupPermissions === ACTION_TYPE.ADMIN ||
        allProjectsPermission === ACTION_TYPE.ADMIN ||
        findActionType(PolicyActionTypeInput.Admin)
      ) {
        return ACTION_TYPE.ADMIN;
      } else if (
        groupPermissions === ACTION_TYPE.EDIT ||
        allProjectsPermission === ACTION_TYPE.EDIT ||
        findActionType(PolicyActionTypeInput.Edit)
      ) {
        return ACTION_TYPE.EDIT;
      } else if (
        groupPermissions === ACTION_TYPE.READ ||
        allProjectsPermission === ACTION_TYPE.READ ||
        findActionType(PolicyActionTypeInput.Read)
      ) {
        return ACTION_TYPE.READ;
      } else if (
        groupPermissions === ACTION_TYPE.LIST ||
        allProjectsPermission === ACTION_TYPE.LIST ||
        findActionType(PolicyActionTypeInput.List)
      ) {
        return ACTION_TYPE.LIST;
      } else {
        return null;
      }
    },
    [allProjectsPermission, getGroupPermission, projectPolicies, projects, isSuperAdmin],
  );

  return {
    allGroupsPermission,
    allProjectsPermission,
    getGroupPermission,
    getProjectPermission,
  };
};
