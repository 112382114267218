import { DualRangeSlider } from '@/LegacyExplore/components/DualRangeSlider/DualRangeSlider';
import { cn } from '@/LegacyExplore/utils/classname';
import { Tileset, TilesetStyle, toCartographic } from '@skand/viewer-component-v2';
import { useMemo } from 'react';

export interface ElevationSettingsProps {
  tileset: Tileset;
  style: TilesetStyle;
  setStyle: (style: TilesetStyle) => void;
}

export const ElevationSettings = ({ tileset, style, setStyle }: ElevationSettingsProps) => {
  // Compute the elevation of the tileset
  const height = useMemo(() => toCartographic(tileset.getPosition()).height, [tileset]);
  const min = Math.round(height - tileset.getBoundingRadius());
  const max = Math.round(height + tileset.getBoundingRadius());

  return (
    <div className={cn('flex flex-col w-auto mt-3 relative')} draggable={false}>
      <div className={cn('flex flex-row mb-4px mt-10px mb-3')}>
        <p className={cn('typo-text-small text-neutral-800')}>Elevation</p>
        <p className={cn('ml-1 typo-text-small text-neutral-400')}>{`(${min} - ${max})`}</p>
      </div>
      <DualRangeSlider
        max={max}
        maxVal={style.maxHeight}
        min={min}
        minVal={style.minHeight}
        onChange={(minHeight, maxHeight) => setStyle({ ...style, minHeight, maxHeight })}
      />
      <div className={cn('w-full h-5 mt-5 mb-2 flex flex-row justify-between items-center')}>
        <div className={cn('flex flex-row justify-between items-center')}>
          <p className={cn('typo-text-small text-neutral-700 mr-2')}>Min</p>
          <input
            className={cn(
              'flex',
              'justify-center',
              'text-center',
              'border-solid',
              'border-neutral-400',
              'b-1px',
              'rounded-4px',
              'w-36px',
              'h-28px',
              'typo-text-small',
              'text-neutral-700',
            )}
            onChange={e => setStyle({ ...style, minHeight: parseFloat(e.target.value) })}
            step={0.01}
            type="number"
            value={style.minHeight}
          />
        </div>

        <div className={cn('flex flex-row justify-between items-center')}>
          <p className={cn('typo-text-small text-neutral-700 mr-2')}>Max</p>
          <input
            className={cn(
              'flex',
              'justify-center',
              'text-center',
              'border-solid',
              'border-neutral-400',
              'b-1px',
              'rounded-4px',
              'w-36px',
              'h-28px',
              'typo-text-small',
              'text-neutral-700',
            )}
            onChange={e => setStyle({ ...style, maxHeight: parseFloat(e.target.value) })}
            step={0.01}
            type="number"
            value={style.maxHeight}
          />
        </div>
      </div>
    </div>
  );
};
