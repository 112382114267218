import { ANNOTATION_FIELD_TYPE } from '@/LegacyExplore/constants/annotation';
import {
  AnnotationTemplate,
  AnnotationTemplateDateField,
  AnnotationTemplateFileField,
  AnnotationTemplateImageField,
  AnnotationTemplateSelectField,
  AnnotationTemplateUrlField,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { sortByOrder } from '@/LegacyExplore/utils/annotation';
import { useMemo } from 'react';
import { FieldDate } from './FieldDate';
import { FieldFile } from './FieldFile';
import { FieldImage } from './FieldImage';
import { FieldInput } from './FieldInput';
import { FieldLink } from './FieldLink';
import { FieldSelect } from './FieldSelect';

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

export const AnnotaionFields = ({ disabled }: { disabled: boolean }) => {
  const template = useExplore(state => state.annotationDraft?.template);
  const fields = useMemo(() => sortByOrder(template?.fields ?? []), [template?.fields]);

  return (
    fields.map((field, index) => {
      if (field?.type === ANNOTATION_FIELD_TYPE.TEXT) {
        return <FieldInput disabled={disabled} field={field} key={`field-input-${index}`} />;
      }

      if (field?.type === ANNOTATION_FIELD_TYPE.SELECT) {
        return (
          <FieldSelect
            disabled={disabled}
            field={field as AnnotationTemplateSelectField}
            key={`field-select-${index}`}
          />
        );
      }

      if (field?.type === ANNOTATION_FIELD_TYPE.URL) {
        return (
          <FieldLink
            disabled={disabled}
            field={field as AnnotationTemplateUrlField}
            key={`field-link-${index}`}
          />
        );
      }

      if (field?.type === ANNOTATION_FIELD_TYPE.DATE) {
        return (
          <FieldDate
            disabled={disabled}
            field={field as AnnotationTemplateDateField}
            key={`field-date-${index}`}
          />
        );
      }

      if (field?.type === ANNOTATION_FIELD_TYPE.FILE) {
        return (
          <FieldFile
            disabled={disabled}
            field={field as AnnotationTemplateFileField}
            key={`field-file-${index}`}
          />
        );
      }

      if (field?.type === ANNOTATION_FIELD_TYPE.IMAGE) {
        return (
          <FieldImage
            disabled={disabled}
            field={field as AnnotationTemplateImageField}
            key={`field-image-${index}`}
          />
        );
      }

      return null;
    }) ?? null
  );
};
