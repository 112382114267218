// @unocss-include
import { ProjectGroup } from '@/LegacyExplore/utils/project';
import { create } from 'zustand';

export const GEOID_OPTIONS = [
  { label: 'N/A', value: 'default' },
  { label: 'GEOID18', value: 'GEOID18' },
  { label: 'GEOID12B', value: 'GEOID12B' },
  { label: 'GEOID09', value: 'GEOID09' },
  { label: 'GEOID03', value: 'GEOID03' },
  { label: 'GEOID06', value: 'GEOID06' },
  { label: 'GEOID99', value: 'GEOID99' },
  { label: 'GEOID96', value: 'GEOID96' },
] as const;

interface CreateProjectState {
  name: string;
  date: string;
  projectGroup: null | ProjectGroup;
  description: string;
  selectedGeoId: (typeof GEOID_OPTIONS)[number];
  address: string;
  latitude: number;
  longitude: number;
}

export const useCreateProject = create<CreateProjectState>()(() => ({
  address: '',
  date: '',
  description: '',
  latitude: -37.82321082611154,
  longitude: 144.92521917916557,
  name: '',
  projectGroup: null,
  selectedGeoId: GEOID_OPTIONS[0],
}));

export const resetCreateProject = () => {
  useCreateProject.setState({
    address: '',
    date: '',
    description: '',
    latitude: -37.82321082611154,
    longitude: 144.92521917916557,
    name: '',
    projectGroup: null,
    selectedGeoId: GEOID_OPTIONS[0],
  });
};

export const setAddress = (address: string) => useCreateProject.setState({ address });
export const setDate = (date: string) => useCreateProject.setState({ date });
export const setDescription = (description: string) => useCreateProject.setState({ description });
export const setLat = (latitude: string) =>
  useCreateProject.setState({ latitude: parseFloat(latitude) });
export const setLng = (longitude: string) =>
  useCreateProject.setState({ longitude: parseFloat(longitude) });
export const setName = (name: string) => useCreateProject.setState({ name });
export const setProjectGroup = (projectGroup: null | ProjectGroup) =>
  useCreateProject.setState({ projectGroup });
export const setSelectedGeoId = (selectedGeoId: (typeof GEOID_OPTIONS)[number]) =>
  useCreateProject.setState({ selectedGeoId });
