import { ANNOTATION_FIELD_TYPE } from '@/LegacyExplore/constants/annotation';
import { AnnotationTemplateImageField } from '@/LegacyExplore/graphql/codegen/graphql';
import { editAnnotationUpdateField } from '@/LegacyExplore/stores/explore';
import {
  startViewerScreenshot,
  stopViewerScreenshot,
  useViewer,
} from '@/LegacyExplore/stores/viewer';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { useSimpleUploader } from '@skand/uploader';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { ProgressBar } from '../ProgressBar';

interface UploadProps {
  field: AnnotationTemplateImageField;
  disabled?: boolean;
}

export const UploadLegacy = ({ field, disabled = false }: UploadProps) => {
  const { addFiles, upload, off, on } = useSimpleUploader({
    isDisabled: getShareLinkToken() !== null,
  });
  const enabledScreenshot = useViewer(state => state.enabledScreenshot);

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const files = [...(e.target.files ?? [])];
      addFiles(files);
      upload();
    },
    [addFiles, upload],
  );

  const handleScreenshot = () => {
    if (disabled) return;

    startViewerScreenshot(async (base64: string) => {
      stopViewerScreenshot();
      const resizedImageResponse = await fetch(base64);
      const blob = await resizedImageResponse.blob();
      const file = new File([blob], 'Screenshot', { type: blob.type });
      addFiles([file]);
      upload();
    });
  };

  useEffect(() => {
    const onAddFiles = () => {
      setIsUploading(true);
    };

    const onProgress = ({ progress }: { progress: number }) => {
      setProgress(progress);
    };

    const onCreateSuccess = ({ fileId }: { file: File; fileId: string }) => {
      editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.IMAGE, prev => {
        return { ...prev, fileId };
      });

      setIsUploading(false);
    };

    on('addFiles', onAddFiles);
    on('createSuccess', onCreateSuccess);
    on('progress', onProgress);
    return () => {
      off('addFiles', onAddFiles);
      off('createSuccess', onCreateSuccess);
      off('progress', onProgress);
    };
  }, [field, off, on]);

  if (isUploading) {
    return (
      <div className="h-4 py-1 pr-2">
        <ProgressBar progress={progress / 100} />
      </div>
    );
  }

  return (
    <>
      <label className="cursor-pointer color-neutral-600 underline typo-link-s">
        Upload
        <input
          accept="image/*"
          className="hidden"
          disabled={enabledScreenshot || disabled}
          onChange={handleChange}
          type="file"
        />
      </label>{' '}
      <span className="color-neutral-600 typo-text-s">or</span>{' '}
      <span
        className="cursor-pointer color-neutral-600 underline typo-text-s"
        onClick={handleScreenshot}
      >
        Take from 3D Scene
      </span>
    </>
  );
};
