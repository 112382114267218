import { CircleDrawable, Drawable } from '@skand/viewer-component-v2';
import { Color, Vector2 } from 'three';
import { Transform2 } from '../../Transform2';
import { Sketch2 } from './Sketch2';

/**
 * 2D point sketch object.
 */
export class PointSketch2 implements Sketch2 {
  private vertices: Vector2[];
  private color: Color;
  private radius: number;

  constructor(points: Vector2[], color: Color) {
    this.vertices = points;
    this.color = color;
    this.radius = 5;
  }

  buildDrawables() {
    const vertices = this.getVertices();
    return vertices.map(vertex => ({
      type: 'circle',
      radius: this.radius,
      color: this.color,
      position: vertex.clone(),
      opacity: 1.0,
      fill: true,
    })) as CircleDrawable[];
  }

  getVertices() {
    return this.vertices.map(vertex => vertex.clone());
  }

  transformDrawables(drawables: Drawable[], transform: Transform2): void {
    const circles = drawables as CircleDrawable[];
    for (let i = 0; i < this.vertices.length; i++) {
      circles[i].position = transform.imageToCanvasSpace(this.vertices[i]);
    }
  }

  /**
   * Test if a point is colliding with any of the circles.
   *
   * Algorithm: Point in circle test.
   *
   * @param point
   * @param transform
   * @returns
   */
  isColliding(point: Vector2, transform: Transform2) {
    const r2 = this.radius * this.radius;
    for (const vertex of this.vertices) {
      const canvasVertex = transform.imageToCanvasSpace(vertex);
      const dsq = canvasVertex.distanceToSquared(point);
      if (dsq <= r2) {
        return true;
      }
    }
    return false;
  }

  setColor(color: Color): void {
    this.color = color;
  }

  getColor(): Color {
    return this.color;
  }
}
