import {
  Annotation,
  AnnotationGroup,
  Layer,
  LayerGroup,
  Photo,
  PhotoGroup,
  useViewer,
} from '@/stores/viewer';
import { isEmpty } from '@/utils/empty';
import { search } from '@/utils/search';
import { cn, Tree, TreeNodeProps } from '@skand/ui';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { LayerFilterKey, TemplateFilterKey } from './FilterMenu';
export interface CreateNode {
  type: 'create';
  id: string;
  mode: 'layerGroup' | 'annotationGroup';
}

export interface SceneEntity {
  entity: LayerGroup | Layer | Annotation | AnnotationGroup | Photo | PhotoGroup | CreateNode;
  children: SceneEntity[];
}

export interface SceneEntityTreeProps {
  children: (props: TreeNodeProps<SceneEntity>) => ReactNode;
  layerFilterKeys?: Set<LayerFilterKey>;
  templateFilterKeys?: Set<TemplateFilterKey>;
  searchKey: string;
  createLayerNode?: boolean;
  createAnnotationNode?: boolean;
  targetLayer?: LayerGroup | Layer;
  refreshScene?: boolean;
  sceneEntityIds?: string[] | null;
  skippedEntities?: string[];
  onSelected?: (value: SceneEntity[]) => void;
  selected?: SceneEntity[];
  filteredAnnotationIds?: Annotation['id'][];
}

export const SceneEntityTree = ({
  layerFilterKeys,
  templateFilterKeys,
  searchKey,
  createLayerNode,
  createAnnotationNode,
  children,
  refreshScene,
  sceneEntityIds,
  skippedEntities,
  onSelected,
  selected,
  targetLayer,
  filteredAnnotationIds,
}: SceneEntityTreeProps) => {
  const targetProcess = useViewer(state => state.targetProcess);
  const layerGroups = useViewer(state => state.layerGroups);
  const layers = useViewer(state => state.layers);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const pinnedAnnotations = useViewer(state => state.pinnedAnnotations);
  const pinnedLayers = useViewer(state => state.pinnedLayers);
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);

  // Test if an annotation passes the filter
  const filterAnnotationFields = useCallback(
    (annotation: Annotation) => {
      // If no template filters are set, pass all annotations
      if (isEmpty(templateFilterKeys) || templateFilterKeys.size === 0) {
        return true;
      }

      // Check if the annotation template is included in the filter list
      const filterKeys: TemplateFilterKey[] = [];
      for (const key of templateFilterKeys) {
        if (key.templateId === annotation.template.id) {
          filterKeys.push(key);
        }
      }
      if (filterKeys.length === 0) {
        return false;
      }

      // Map of select fields to possible options
      const selectFilterEntries = new Map<string, (string | null)[]>();
      for (const key of templateFilterKeys) {
        if (key.templateId === annotation.template.id) {
          if (key.type === 'select') {
            const options = selectFilterEntries.get(key.fieldId) ?? [];
            options.push(key.optionId);
            selectFilterEntries.set(key.fieldId, options);
          }
        }
      }

      // Map of annotation field to option
      const selectFieldEntries = new Map<string, string>();
      for (const field of annotation.fields) {
        if (field.__typename !== 'AnnotationSelectField') continue;
        selectFieldEntries.set(field.fieldId as string, field.optionId as string);
      }

      // OR operation among options of a field
      // AND operation among fields of a template
      // OR operation among templates
      let pass = true;
      for (const [fieldId, optionIds] of selectFilterEntries) {
        const optionId = selectFieldEntries.get(fieldId) ?? null;
        if (!optionIds.includes(optionId)) {
          pass = false;
          break;
        }
      }
      return pass;
    },
    [templateFilterKeys],
  );

  // Construct tree data structure
  const [tree, templateFilteredAnnotations] = useMemo(() => {
    const entities = [
      ...layerGroups,
      ...layers,
      ...annotationGroups,
      ...photo2DGroups,
      ...panoramaGroups,
    ].filter(el => !skippedEntities?.includes(el.type));

    const nodeMap = new Map<(typeof entities)[number], SceneEntity>();
    const filteredAnnotations = new Set<Annotation['id']>();
    for (const entity of entities) {
      const node: SceneEntity = { entity, children: [] };
      if (entity.type === 'annotationGroup') {
        for (const annotation of entity.annotations) {
          // Add filtered annotations only if available
          if (
            filteredAnnotationIds &&
            filteredAnnotationIds.length &&
            !filteredAnnotationIds.includes(annotation.id)
          ) {
            continue;
          }
          if (filterAnnotationFields(annotation) && !skippedEntities?.includes('annotation')) {
            node.children.push({ entity: annotation, children: [] });
          } else {
            filteredAnnotations.add(annotation.id);
          }
        }
      } else if (entity.type === 'photoGroup' && !skippedEntities?.includes('photo')) {
        node.children = entity.photos.map(child => ({ entity: child, children: [] }));
      }
      nodeMap.set(entity, node);
    }

    let nodes: SceneEntity[] = [];
    if (createLayerNode) {
      nodes.push({
        entity: { type: 'create', id: 'CREATE_NODE', mode: 'layerGroup' },
        children: [],
      });
    }
    if (createAnnotationNode && !targetLayer) {
      nodes.push({
        entity: { type: 'create', id: 'CREATE_NODE', mode: 'annotationGroup' },
        children: [],
      });
    }
    for (const entity of entities) {
      const node = nodeMap.get(entity);

      if (node) {
        if (createAnnotationNode && targetLayer && targetLayer.id === node.entity.id) {
          node.children.push({
            entity: { type: 'create', id: 'CREATE_NODE', mode: 'annotationGroup' },
            children: [],
          });
        }

        if (entity.parent) {
          const parent = nodeMap.get(entity.parent);
          if (parent) {
            parent.children.push(node);
          }
        } else {
          nodes.push(node);
        }
      }
    }

    // Returns only selected sceneEntityIds if available
    if (sceneEntityIds) {
      nodes = nodes.filter(
        node =>
          'sceneEntityId' in node.entity && sceneEntityIds.includes(node.entity.sceneEntityId),
      );
    }

    return [nodes, filteredAnnotations];
  }, [
    layerGroups,
    layers,
    annotationGroups,
    photo2DGroups,
    panoramaGroups,
    createLayerNode,
    createAnnotationNode,
    targetLayer,
    sceneEntityIds,
    skippedEntities,
    filteredAnnotationIds,
    filterAnnotationFields,
  ]);

  // Test if a scene entity passes the filter
  const testFilter = useCallback(
    (sceneEntity: SceneEntity) => {
      if (isEmpty(layerFilterKeys) || !layerFilterKeys.size) return true;
      switch (sceneEntity.entity.type) {
        case 'layerGroup':
          return layerFilterKeys.has('layerGroup');
        case 'annotationGroup':
          return layerFilterKeys.has('annotationGroup');
        case 'photoGroup':
          return layerFilterKeys.has('photoGroup');
        case 'panorama':
          return layerFilterKeys.has('panorama');
        case 'photo2D':
          return layerFilterKeys.has('photo2D');
        case 'layer':
          return layerFilterKeys.has(sceneEntity.entity.formatType);
        case 'annotation': {
          const inProcess =
            !targetProcess ||
            (layerFilterKeys.has('process') &&
              targetProcess.kind === 'annotation' &&
              targetProcess.annotationIds.has(sceneEntity.entity.id));
          const hasSketch2D = layerFilterKeys.has('sketch2D') && sceneEntity.entity.sketch2D;
          const hasSketch3D = layerFilterKeys.has('sketch3D') && sceneEntity.entity.sketch3D;
          return inProcess || hasSketch2D || hasSketch3D;
        }
        case 'create':
          return true;
      }
    },
    [layerFilterKeys, targetProcess],
  );

  // Filter tree rows by options in the filter menu
  const [filterResult, filteredAnnotations, filteredLayers, filteredPhotos] = useMemo(() => {
    const filteredAnnotations = new Set(templateFilteredAnnotations);
    const filteredLayers = new Set<string>();
    const filteredPhotos = new Set<string>();

    let results = [...tree];
    if ((layerFilterKeys && layerFilterKeys.size) || searchKey.length) {
      results = [];
      const queue = [...tree];

      while (queue.length) {
        const node = queue.shift();
        if (!node) continue;
        if (
          node.entity.type === 'create' ||
          (testFilter(node) && search(node.entity.name, searchKey))
        ) {
          results.push(node);
        } else if (node.entity.type === 'photo2D' || node.entity.type === 'panorama') {
          filteredPhotos.add(node.entity.id);
        } else if (node.entity.type === 'annotation' && !pinnedAnnotations.has(node.entity.id)) {
          filteredAnnotations.add(node.entity.id);
        } else if (node.entity.type === 'layer' && !pinnedLayers.has(node.entity.id)) {
          filteredLayers.add(node.entity.id);
        }
        for (const child of node.children) {
          queue.push(child);
        }
      }
    }

    return [results, filteredAnnotations, filteredLayers, filteredPhotos];
  }, [
    layerFilterKeys,
    pinnedAnnotations,
    pinnedLayers,
    searchKey,
    templateFilteredAnnotations,
    testFilter,
    tree,
  ]);

  // Refresh the scene when the filter changes, toggling the visibility of layers and annotations
  useEffect(() => {
    if (!refreshScene) return;

    // Remove filtered annotations and layers if still in the tree
    const queue = [...filterResult];
    while (queue.length) {
      const node = queue.shift();
      if (!node) continue;
      if (node.entity.type === 'annotation') {
        filteredAnnotations.delete(node.entity.id);
      }
      if (node.entity.type === 'layer') {
        filteredLayers.delete(node.entity.id);
      }
      if (node.entity.type === 'photo2D' || node.entity.type === 'panorama') {
        filteredPhotos.delete(node.entity.id);
      }
      for (const child of node.children) {
        queue.push(child);
      }
    }
    useViewer.setState({ filteredAnnotations, filteredLayers, filteredPhotos });
  }, [filterResult, filteredAnnotations, filteredLayers, filteredPhotos, refreshScene]);

  // Tree row sort comparison function
  const treeSortCmp = useCallback((a: SceneEntity, b: SceneEntity) => {
    const typeScore = (node: SceneEntity) => {
      switch (node.entity.type) {
        case 'panorama':
          return 0;
        case 'photo2D':
          return 1;
        case 'annotation':
          return 2;
        case 'photoGroup':
          return 3;
        case 'annotationGroup':
          return 4;
        case 'layer':
          return 5;
        case 'layerGroup':
          return 6;
        case 'create':
          return 7;
      }
    };
    if (a.entity.type === 'layer' && b.entity.type === 'layer') {
      return a.entity.captureDate.getTime() - b.entity.captureDate.getTime();
    } else if (
      (a.entity.type === 'photo2D' && b.entity.type === 'photo2D') ||
      (a.entity.type === 'panorama' && b.entity.type === 'panorama')
    ) {
      return b.entity.name.localeCompare(a.entity.name, undefined, {
        numeric: true,
      });
    } else {
      return typeScore(a) - typeScore(b);
    }
  }, []);

  return (
    <Tree
      getKey={node => node.entity.id}
      multiSelectClassName={cn(enabledSelectMode && 'bg-neutral-200 rounded-1')}
      onSelected={onSelected ?? onSelected}
      quickListProps={{ scrollbarSpace: 20 }}
      roots={filterResult}
      selected={selected ?? []}
      sortCmp={treeSortCmp}
      treeNodeClassName={cn('mb-[2px]')}
      walker={node => node.children}
    >
      {children}
    </Tree>
  );
};
