import { useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { Process } from '@/LegacyExplore/utils/process';
import { search } from '@/LegacyExplore/utils/search';
import { MODEL_TRAINING } from '@/LegacyExplore/utils/split';
import { Button, Input } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMemo, useState } from 'react';
import { FindIcon } from '../IconButton';
import { ModelList } from '../ModelList';
import { ModelTrainingProcess } from '../ModelTrainingProcess/ModelTrainingProcess';
import { ProcessCard } from './ProcessCard';

export const ProcessTab = () => {
  const treatments = useTreatments([MODEL_TRAINING]);
  const isModelTrainingEnabled = treatments[MODEL_TRAINING].treatment === 'on';

  const processes = useViewer(state => state.processes);

  const [searchKey, setSearchKey] = useState('');
  const [showModelListModal, setShowModelListModal] = useState(false);
  const [showModelTrainingModal, setShowModelTrainingModal] = useState(false);

  const filtered = useMemo(
    () => processes.filter(process => search(process.id, searchKey)),
    [searchKey, processes],
  );

  return (
    <div className={cn('flex flex-col px-1 gap-3 h-full')}>
      <div className={cn('border-neutral-300', 'border-1', 'border-b-solid', 'pb-3')}>
        <p className={cn('typo-heading-4 color-neutral-800')}>Process</p>
      </div>
      <div className={cn('flex flex-col gap-2')}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search for processes"
            onChange={event => setSearchKey(event)}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
        {isModelTrainingEnabled && (
          <div className="flex gap-2">
            <Button
              className="flex-1 cursor-pointer px-5"
              onClick={() => setShowModelListModal(true)}
              primary
              size="s"
            >
              Model List
            </Button>
            <Button
              className="flex-1 cursor-pointer px-5"
              filled
              onClick={() => setShowModelTrainingModal(true)}
              primary
              size="s"
            >
              Start Training
            </Button>
          </div>
        )}
      </div>

      <div className="flex-1 overflow-auto">
        {filtered.map((process: Process) => (
          <ProcessCard key={process.id} process={process} />
        ))}
      </div>

      <ModelTrainingProcess active={showModelTrainingModal} setActive={setShowModelTrainingModal} />
      <ModelList active={showModelListModal} setActive={setShowModelListModal} />
    </div>
  );
};
