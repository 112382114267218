import { useExplore } from '@/LegacyExplore/stores/explore';
import { EPSG, toProjected } from '@skand/data-3d-loader';
import {
  areaToString,
  calculateCornerAngles,
  calculateEdgeLengths,
  calculateElevation,
  calculateHVLengths,
  calculatePolygonArea,
  lengthToString,
  toCartographic,
  toDegrees,
} from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { Card } from './Card';
import { Item } from './Item';

interface PointData {
  segmentPoints: string;
  segment: string;
  horizontalLengthPoints: string;
  horizontalLength: string;
  verticalLengthPoints: string;
  verticalLength: string;
  angle: number;
  east: number;
  north: number;
  elevation: number;
  lat: number;
  long: number;
  relativeHeightPoints: string;
  relativeHeight: string;
}

export interface AnnotationMetadataProps {
  points: Vector3[];
  isClosed: boolean;
}

export enum PointKey {
  Label = 'Label',
  Value = 'Value',
}

export const AnnotationMetadata = ({ points, isClosed }: AnnotationMetadataProps) => {
  const measurementUnit = useExplore(state => state.measurementUnit);
  const epsg = useExplore(state => state.epsg);

  const lengths = useMemo(() => calculateEdgeLengths(points, true), [points]);
  const totalLength = useMemo(() => {
    const lengths = calculateEdgeLengths(points, isClosed);
    return lengths.reduce((prev, curr) => prev + curr, 0);
  }, [isClosed, points]);
  const area = useMemo(() => calculatePolygonArea(points), [points]);
  const angles = useMemo(() => calculateCornerAngles(points, isClosed), [isClosed, points]);

  const [pointData, setPointData] = useState<PointData[]>([]);

  useEffect(() => {
    const fn = async () => {
      const result = [];
      for (let i = 0; i < points.length; i++) {
        const nextIndex = (i + 1) % points.length;
        const curr = points[i];
        const next = points[nextIndex];

        const epsgTokens = epsg.split('-');
        const coordinates = (
          await toProjected([curr], epsgTokens[0] as EPSG, epsgTokens.length > 1)
        )[0];
        const cartographic = toCartographic(curr);
        const caption = `P${i + 1}-P${nextIndex + 1}`;

        const [hLength, vLength] = calculateHVLengths(curr, next);
        const relativeHeight = calculateElevation(curr, next);
        result.push({
          segmentPoints: caption,
          segment: lengths && lengthToString(lengths[i], measurementUnit, 4),
          horizontalLengthPoints: caption,
          horizontalLength: lengthToString(hLength, measurementUnit, 4),
          verticalLengthPoints: caption,
          verticalLength: lengthToString(vLength, measurementUnit, 4),
          angle: angles && angles[i],
          east: coordinates.x,
          north: coordinates.y,
          elevation: coordinates.z,
          lat: toDegrees(cartographic.latitude),
          long: toDegrees(cartographic.longitude),
          relativeHeightPoints: caption,
          relativeHeight: lengthToString(relativeHeight, measurementUnit, 4),
        });
      }
      return result;
    };
    fn().then(setPointData);
  }, [angles, epsg, lengths, measurementUnit, points]);

  // Determine the shape type
  const shapeType = useMemo(() => {
    if (points.length === 1) return 'Point';
    else if (isClosed) return 'Polygon';
    else return 'Polyline';
  }, [isClosed, points.length]);

  const eastingString = useCallback(
    (point: PointData['east'], pointKey: PointKey) => {
      let value;
      let label;

      if (epsg === 'EPSG:4978') {
        value = lengthToString(point, measurementUnit, 5);
        label = 'X';
      } else if (epsg === 'EPSG:4326') {
        value = point.toFixed(5) + 'º';
        label = 'Longitude';
      } else {
        value = lengthToString(point, measurementUnit, 5);
        label = 'Easting';
      }

      return pointKey === PointKey.Label ? label : value;
    },
    [epsg, measurementUnit],
  );

  const northingString = useCallback(
    (point: PointData['north'], pointKey: PointKey) => {
      let value;
      let label;

      if (epsg === 'EPSG:4978') {
        value = lengthToString(point, measurementUnit, 5);
        label = 'Y';
      } else if (epsg === 'EPSG:4326') {
        value = point.toFixed(5) + 'º';
        label = 'Latitude';
      } else {
        value = lengthToString(point, measurementUnit, 5);
        label = 'Northing';
      }

      return pointKey === PointKey.Label ? label : value;
    },
    [epsg, measurementUnit],
  );

  const elevationString = useCallback(
    (point: PointData['elevation'], pointKey: PointKey) => {
      let value;
      let label;

      if (epsg === 'EPSG:4978') {
        value = lengthToString(point, measurementUnit, 2);
        label = 'Z';
      } else {
        value = lengthToString(point, measurementUnit, 2);
        label = 'Elevation';
      }
      return pointKey === PointKey.Label ? label : value;
    },
    [epsg, measurementUnit],
  );

  return (
    <>
      <Card title="Object details">
        <Item label="Type" value={shapeType} />
        <Item label="Total length" value={lengthToString(totalLength, measurementUnit, 4)} />
        {shapeType === 'Polygon' && area && (
          <Item label="Area" value={areaToString(area, measurementUnit, 4)} />
        )}
      </Card>

      {pointData.map((point, index) => (
        <Card key={index} title={`Point ${index + 1}`}>
          <Item
            label={`Relative height (${point.relativeHeightPoints})`}
            value={point.relativeHeight}
          />
          <Item label={`Segment (${point.segmentPoints})`} value={point.segment} />
          <Item
            label={`Horizontal length (${point.horizontalLengthPoints})`}
            value={point.horizontalLength}
          />
          <Item
            label={`Vertical length (${point.verticalLengthPoints})`}
            value={point.verticalLength}
          />

          <Item label="Angle" value={point.angle.toFixed(3) + 'º'} />
          <Item
            label={eastingString(point.east, PointKey.Label)}
            value={eastingString(point.east, PointKey.Value)}
          />
          <Item
            label={northingString(point.north, PointKey.Label)}
            value={northingString(point.north, PointKey.Value)}
          />
          <Item
            label={elevationString(point.elevation, PointKey.Label)}
            value={elevationString(point.elevation, PointKey.Value)}
          />
          <Item
            label="Latitude/Longitude"
            value={`${point.lat.toFixed(6)}/${point.long.toFixed(6)}`}
          />
        </Card>
      ))}
    </>
  );
};
