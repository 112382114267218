import { ANNOTATION_FIELD_TYPE } from '@/LegacyExplore/constants/annotation';
import { COLOR_FROM_FIELD_CUSTOM } from '@/LegacyExplore/constants/misc';
import { queryClient } from '@/LegacyExplore/graphql/client';
import { AnnotationTemplateField } from '@/LegacyExplore/graphql/codegen/graphql';
import {
  CREATE_PROJECT_ANNOTATION_TEMPLATE,
  UPDATE_ANNOTATION_TEMPLATE,
} from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useAnnotationTemplates } from '@/LegacyExplore/hooks/useAnnotationTemplates';
import { cancelEditAnnotation, useExplore } from '@/LegacyExplore/stores/explore';
import { useLayout } from '@/LegacyExplore/stores/layout';
import {
  WithMode,
  changeTemplateColor,
  changeTemplateDesc,
  changeTemplateName,
  clearTemplate,
  useTemplate,
} from '@/LegacyExplore/stores/template';
import { useEffect } from 'react';

import { AccessGate } from '@/LegacyExplore/components/AccessGate';
import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { ENTITLEMENT_NAME } from '@/LegacyExplore/hooks/useEntitlements';
import { useFetchProjectPermissions } from '@/LegacyExplore/hooks/useFetchProjectPermissions';
import { cn } from '@/LegacyExplore/utils/classname';
import { Button, Input, Select, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isEmpty } from '../../../utils/empty';
import { Chip } from './Chip';
import { DropArea } from './DropArea';
import { FieldTable } from './FieldTable';
import { TemplateCopy } from './TemplateCopy';

export const TemplatePanel = () => {
  const hideTemplatePanel = useLayout(state => state.hideTemplatePanel);
  const isShowingLeftSideBarI = useLayout(state => state.isShowingLeftSideBarI);
  const template = useTemplate(state => state.template);
  const fields = useTemplate(state => state.fields);
  const projectId = useExplore(state => state.projectId);
  const mode = useTemplate(state => state.mode);

  const { getProjectPermission } = useFetchProjectPermissions();
  const permission = getProjectPermission(projectId);
  const canEdit = canPolicyActionEdit(permission);

  useEffect(() => {
    if (!isEmpty(template) && !isEmpty(template.colorFromField)) {
      const isFieldAvailable = fields.some(field => field.name === template.colorFromField);
      if (!isFieldAvailable) {
        changeTemplateColor(COLOR_FROM_FIELD_CUSTOM);
      }
    }
  }, [template, fields]);

  const handleCancel = useCallback(() => {
    hideTemplatePanel();
    clearTemplate();
  }, [hideTemplatePanel]);

  const createTemplate = useMutation({
    mutationFn: () =>
      request(CREATE_PROJECT_ANNOTATION_TEMPLATE, {
        template: {
          name: template?.name,
          description: template?.description,
          fields: fields.map(field => ({ ...field, mode: undefined })),
          projectId,
          colorFromField: template?.colorFromField,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useAnnotationTemplates.getQueryKey(projectId));
      handleCancel();
      toast({ message: 'Successfully created', lifespan: 5000, clickToDismiss: true });
    },
  });

  // in the createTemplate thing. the SELECT type is now an object but the rest are still strings so there needs to be a specific filter for that.

  const updateTemplate = useMutation({
    mutationFn: () =>
      request(UPDATE_ANNOTATION_TEMPLATE, {
        template: {
          annotationTemplateId: template?.id as string,
          name: template?.name,
          description: template?.description,
          fields: fields.map(field => ({ ...field, __typename: undefined, mode: undefined })),
          colorFromField: template?.colorFromField,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useAnnotationTemplates.getQueryKey(projectId));
      handleCancel();
      toast({ message: 'Successfully updated', lifespan: 5000, clickToDismiss: true });
    },
  });

  const handleSave = useCallback(() => {
    if (mode === 'create') createTemplate.mutate();
    else if (mode === 'update') updateTemplate.mutate();
    cancelEditAnnotation();
  }, [createTemplate, mode, updateTemplate]);

  const enabled = () => (
    <Button
      className="ml-3"
      disabled={createTemplate.isLoading || updateTemplate.isLoading || !template?.name || !canEdit}
      filled
      onClick={handleSave}
      primary
      size="s"
    >
      {mode === 'create' && 'Create'}
      {mode === 'update' && 'Update'}
    </Button>
  );

  const disabled = () => (
    <Button className="ml-3" disabled onClick={handleSave} primary size="s">
      {mode === 'create' && 'Create'}
      {mode === 'update' && 'Update'}
    </Button>
  );

  return (
    <div
      className={cn(
        'bg-neutral-100 h-full flex-1 b-l-1 b-l-neutral-300 b-l-solid overflow-auto',
        'px-4 pt-18px',
        isShowingLeftSideBarI && 'ml-400px',
      )}
    >
      <div className="flex items-center b-b-1 b-b-neutral-300 b-b-solid pb-2">
        <h1 className="color-neutral-800 typo-heading-4">
          {mode === 'create' && 'Create a new template'}
          {mode === 'update' && 'Edit template'}
          {mode === 'view' && template?.name}
        </h1>
        <div className="flex-1" />

        <Button onClick={handleCancel} size="s">
          {mode === 'view' ? 'Close' : 'Cancel'}
        </Button>

        {mode !== 'view' && (
          <AccessGate
            disabled={disabled}
            enabled={enabled}
            loading={disabled}
            name={ENTITLEMENT_NAME.ANNOTATION_TEMPLATE}
          />
        )}
      </div>

      <p className="mt-3 color-neutral-800 typo-text-s">
        {mode === 'view'
          ? 'This template is a system default and cannot be edited. Create a new template to customise.'
          : 'Add fields and customise values.'}
      </p>

      {mode === 'create' && <TemplateCopy />}

      {mode !== 'view' && (
        <>
          <p className="mt-6 color-neutral-800 typo-text-m">Template details</p>

          <div className="mt-3">
            <Input
              disabled={!canEdit}
              expandable
              hint={template?.name ? undefined : 'Required'}
              label="Name"
              onChange={changeTemplateName}
              value={template?.name ?? ''}
            />
          </div>

          <div className="mt-3">
            <Input
              disabled={!canEdit}
              expandable
              label="Description"
              onChange={changeTemplateDesc}
              value={template?.description ?? ''}
            />
          </div>

          <p className="mt-3 color-neutral-800 typo-text-m">Colour source</p>
          <Select
            className="mt-3 w-50%"
            disabled={!canEdit}
            onChange={changeTemplateColor}
            options={[
              { key: COLOR_FROM_FIELD_CUSTOM, name: 'Custom (choose from explore)' },
              ...(fields
                ?.filter(
                  (field): field is SolidName<WithMode<AnnotationTemplateField>> =>
                    field?.type === ANNOTATION_FIELD_TYPE.SELECT && !!field.name,
                )
                .map(field => ({ key: field.name, name: field.name })) ?? []),
            ]}
            placeholder="Colour source"
            value={template?.colorFromField ?? COLOR_FROM_FIELD_CUSTOM}
            width="full"
          />

          <p className="mt-6 color-neutral-800 typo-text-m">Add field type</p>

          <div className="mt-3 flex gap-3">
            <DndProvider backend={HTML5Backend}>
              <Chip type="FILE" />
              <Chip type="DATE" />
              <Chip type="SELECT" />
              <Chip type="URL" />
              <Chip type="TEXT" />
              <Chip type="IMAGE" />
            </DndProvider>
          </div>
        </>
      )}

      <FieldTable />

      {mode !== 'view' && (
        <DndProvider backend={HTML5Backend}>
          <DropArea />
        </DndProvider>
      )}
    </div>
  );
};
