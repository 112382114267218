import { EmptyResourceMessage } from '@/LegacyExplore/components/EmptyResourceMessage';
import { ANALYTICS_EVENT_OBJECT } from '@/LegacyExplore/constants/analytics';
import React from 'react';
import { ProjectDisplayState } from '../ProjectPage';
import { Loading } from './Loading';

interface ProjectsResultsDisplayProps {
  children?: React.ReactNode;
  searchKey: string;
  setEnabledCreateProjectDialog: (value: boolean) => void;
  setIsCreateDropdownOpen: (value: boolean) => void;
  setProjectPageSearchKey: (value: string) => void;
  resultsDisplayState: 'loading' | 'searchResultsEmpty' | 'projectsAndGroupsEmpty' | 'default';
}

export const ProjectsResultsDisplay = ({
  searchKey,
  setProjectPageSearchKey,
  setEnabledCreateProjectDialog,
  setIsCreateDropdownOpen,
  children,
  resultsDisplayState,
}: ProjectsResultsDisplayProps) => {
  const stateMap = {
    [ProjectDisplayState.Loading]: <Loading />,
    [ProjectDisplayState.SearchResultsEmpty]: (
      <EmptyResourceMessage
        emptyResourceContent={{
          actionButton: () => {
            setProjectPageSearchKey('');
          },
          emptyDesc: (
            <div className="max-w-384px">
              <p className="font-medium">
                Your search did not match any project names. Here&apos;s what you can try:
              </p>
              <div className="mb-4">
                <ul className="ml-3 mt-2 list-disc">
                  <li>Double-check the spelling of the project name</li>
                  <li>Check for typographical errors</li>
                  <li>
                    If you&apos;re unsure of the exact name, use a partial term or fewer characters
                  </li>
                </ul>
              </div>
            </div>
          ),
          emptyTitle: (
            <div className="mb-3 w-384px overflow-hidden text-center">
              <p className="text-lg font-semibold text-gray-600">
                No results found for projects with the term{' '}
              </p>
              <p
                className="w-full overflow-hidden truncate text-lg font-bold text-primary-400"
                title={searchKey}
              >
                &apos;{searchKey}&apos;
              </p>
            </div>
          ),
          buttonLabel: 'Search Again',
        }}
      />
    ),
    [ProjectDisplayState.ProjectsAndGroupsEmpty]: (
      <EmptyResourceMessage
        emptyResourceContent={{
          actionButton: () => {
            setEnabledCreateProjectDialog(true);
            setIsCreateDropdownOpen(false);
          },
          emptyDesc: 'Use the Create button to start a new project.',
          emptyTitle: 'No projects available',
          buttonLabel: 'Create project',
          emptyToast: 'Check with your admin for your permissions if content is expected here.',
          dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.INITIATE_PROJECT_CREATION,
        }}
      />
    ),
    [ProjectDisplayState.Default]: children,
  };

  return stateMap[resultsDisplayState];
};
