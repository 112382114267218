import { AnnotationTemplateField } from '@/LegacyExplore/graphql/codegen/graphql';
import { useTemplate } from '@/LegacyExplore/stores/template';
import { cn } from '@/LegacyExplore/utils/classname';
import { Button } from '@skand/ui';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FieldType } from './FieldType';
import { Tr } from './Tr';
import { columns } from './columns';

export interface FieldTableProps {
  fields: AnnotationTemplateField[];
}

export const FieldTable = () => {
  const fields = useTemplate(state => state.fields);
  const mode = useTemplate(state => state.mode);

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    columns,
    data: [],
    enableSorting: mode === 'view',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
  });

  const sortedFields = useMemo(() => {
    if (mode !== 'view') {
      return fields.sort((a, b) => {
        return (a.order ?? 0) - (b.order ?? 0);
      });
    }

    if (sorting.length > 0) {
      const condition = sorting[0];

      const sorter = (a: AnnotationTemplateField, b: AnnotationTemplateField) => {
        const x = a[condition.id as 'name' | 'type'] ?? '';
        const y = b[condition.id as 'name' | 'type'] ?? '';
        const diff = x.localeCompare(y);
        if (condition.desc) return -diff;
        return diff;
      };

      return fields.sort(sorter);
    }

    return fields;
  }, [fields, mode, sorting]);

  const renderDefaultNameTextField = () => {
    return (
      <tr className="b-y-1 b-y-neutral-300 b-y-solid">
        <td className="py-4 color-neutral-800">
          <div className="h-full w-360px flex items-center justify-start">
            <div className="i-skand-reorder mr-3 cursor-grab color-neutral-400 hover:color-primary-400" />
            <span className="color-neutral-800 typo-text-s">Name</span>
          </div>
        </td>

        <td className="py-4 color-neutral-800">
          <FieldType type={'TEXT'} />
        </td>

        <td />

        <td className="py-4 color-neutral-800">
          <div className="ml-auto w-128px flex justify-end gap-2">
            <Button disabled size="s">
              Edit
            </Button>
            <Button disabled size="s">
              Delete
            </Button>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <table className="mt-6 w-full border-collapse table-auto">
      <thead>
        {table.getHeaderGroups().map(headerGroup => {
          return (
            <tr className="b-y-1 b-y-neutral-500 b-y-solid" key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isSortable = header.column.getCanSort();
                const sort = header.column.getIsSorted();

                return (
                  <th
                    className="py-3 text-left uppercase color-neutral-800 typo-button-xs"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}

                      {isSortable && (
                        <div
                          className={cn(
                            'i-skand-dropdown ml-2',
                            sort === false ? 'color-neutral-400' : 'color-neutral-800',
                            sort === 'asc' && 'rotate-180',
                          )}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>

      <tbody>
        {renderDefaultNameTextField()}
        <DndProvider backend={HTML5Backend}>
          {sortedFields.map((field, index) => (
            <Tr field={field} index={index} key={index} />
          ))}
        </DndProvider>
      </tbody>
    </table>
  );
};
