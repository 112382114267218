import { LIST_HISTORY_BY_ANNOTATION_ID } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { AnnotationVersion } from '@/LegacyExplore/utils/annotationVersion';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { createAnnotationVersion } from '@/LegacyExplore/utils/transformers';
import { User } from '@/LegacyExplore/utils/user';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useFetchUsers } from './useFetchUsers';

const getQueryKey = (annotationId: null | string) => [
  'LIST_HISTORY_BY_ANNOTATION_ID',
  annotationId,
];

export const useFetchAnnotationHistory = (annotationId: string | null) => {
  const projectId = useExplore(state => state.projectId);
  const fetchUsers = useFetchUsers();

  const [users, setUsers] = useState<User[]>([]);

  // Update user list
  useEffect(() => {
    fetchUsers().then(setUsers);
  }, [fetchUsers]);

  const response = useQuery({
    enabled: !!projectId && !!annotationId,
    queryFn: () => request(LIST_HISTORY_BY_ANNOTATION_ID, { annotationId } as never),
    queryKey: getQueryKey(annotationId),
  });

  return useCallback(async () => {
    const history: AnnotationVersion[] = [];
    if (!isEmpty(response.data) && !isEmpty(response.data.listHistoryByAnnotationId)) {
      for (const versionQuery of response.data.listHistoryByAnnotationId) {
        if (!isEmpty(versionQuery)) {
          const user = users.find(user => user.id === versionQuery.createdByUserId);
          const version = createAnnotationVersion(versionQuery, user ?? null);
          if (version) {
            history.push(version);
          }
        }
      }
    }
    return history;
  }, [response.data, users]);
};

useFetchAnnotationHistory.getQueryKey = getQueryKey;
