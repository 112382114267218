import { cn } from '@/utils/classname';
import { Button, Rnd } from '@skand/ui';

interface DrawContextMenuProps {
  doneOnClick: () => void;
  undoOnClick: () => void;
  trashOnClick: () => void;
  closeOnClick: () => void;
  canClose: boolean;
}

export const DrawContextMenu = ({
  doneOnClick,
  undoOnClick,
  trashOnClick,
  closeOnClick,
  canClose,
}: DrawContextMenuProps) => {
  return (
    <Rnd disableResizeX disableResizeY x={100} y={100}>
      <div
        className={cn(
          'flex',
          'flex-row',
          'items-center',
          'w-auto',
          'h-28px',
          'bg-neutral-100',
          'pt-4px',
          'pb-4px',
          'pr-4px',
          'rounded-1',
          'absolute',
          'pointer-events-auto',
          'transform-cpu',
          'translate-x-10%',
          'translate-y--50%',
          'will-change-transform',
        )}
      >
        <div className="ml-8px">
          <div className={cn('i-skand-reorder', 'cursor-grab', 'color-neutral-400', 'text-12px')} />
        </div>
        <div className={cn('w-1px', 'h-28px', 'bg-neutral-400', 'ml-8px')} />
        <div
          className={cn(
            'i-skand-done text-20px',
            'cursor-pointer',
            'ml-8px',
            'bg-neutral-800',
            'color-neutral-800',
            'hover:bg-neutral-500',
            'transition',
            'skand-rnd-enable',
          )}
          onClick={doneOnClick}
        />
        <div
          className={cn(
            'i-skand-undo text-20px',
            'cursor-pointer',
            'ml-8px',
            'bg-neutral-800',
            'hover:bg-neutral-500',
            'color-neutral-800',
            'transition',
            'skand-rnd-enable',
          )}
          onClick={undoOnClick}
        />
        <div
          className={cn(
            'i-skand-trashbin text-20px',
            'cursor-pointer',
            'ml-8px',
            'mr-8px',
            'bg-neutral-800',
            'hover:bg-neutral-500',
            'color-neutral-800',
            'transition',
            'skand-rnd-enable',
          )}
          onClick={trashOnClick}
        />
        <Button
          className={cn('pl-8px', 'pr-8px', 'pt-2px', 'pb-2px', 'skand-rnd-enable')}
          disabled={!canClose}
          filled
          onClick={closeOnClick}
          primary
          size="extraSmall"
        >
          Close shape
        </Button>
      </div>
    </Rnd>
  );
};
