import { cn } from '@/LegacyExplore/utils/classname';

export const FinishedTag = () => (
  <div
    className={cn(
      'flex',
      'flex-row',
      'items-center',
      'w-60px',
      'h-18px',
      'rounded-4px',
      'border-solid',
      'border-success-400',
      'border-1px',
      'pt-2px',
      'pb-2px',
      'pl-4px',
      'pr-4px',
      'justify-between',
      'bg-success-200',
    )}
  >
    <div className={cn('w-6px', 'h-6px', 'rounded-50%', 'bg-success-300')} />

    <p className="color-neutral-800 typo-text-xs">Finished</p>
  </div>
);
