import { Layer, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@skand/ui';
import { ModelNode, Tileset, TilesetStatistics } from '@skand/viewer-component-v2';
import { useEffect, useState } from 'react';

export interface TilesetStatisticsTabProps {
  layer: Layer;
}

export const TilesetStatisticsTab = ({ layer }: TilesetStatisticsTabProps) => {
  const api3D = useViewer(state => state.api3D);

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    // Need to manually insert the innerHTML to bypass react's rendering loop
    const cb = (statistics: TilesetStatistics) => {
      let html = '';
      for (const [key, value] of Object.entries(statistics)) {
        let prettyName = key.replace(/([a-z])([A-Z])/g, '$1 $2');
        prettyName = prettyName.charAt(0).toUpperCase() + prettyName.slice(1);
        html += `
          <div class="flex justify-between typo-text-s-em color-neutral-6 w-full">
            <span>${prettyName}</span>
            <span>${value}</span>
          </div>
        `;
      }
      if (container) {
        container.innerHTML = html;
      }
    };
    if (api3D) {
      api3D.statistics.tilesetTarget = (layer.sceneNode as ModelNode).getModel() as Tileset;
      api3D.statistics.eventRegister.register('updateTileset', cb);
    }
    return () => {
      api3D?.statistics.eventRegister.unregister('updateTileset', cb);
    };
  }, [api3D, container, layer]);

  return <div className={cn('flex flex-col w-full gap-1 mt-3')} ref={setContainer} />;
};
