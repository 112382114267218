import { MoreMenu } from '@/LegacyExplore/components/MoreMenu';
import { EXPLORE_ROOT } from '@/LegacyExplore/constants/paths';
import { queryClient } from '@/LegacyExplore/graphql/client';
import {
  DeleteShareLinkMutationVariables,
  UpdateShareLinkMutationVariables,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { DELETE_SHARE_LINK, UPDATE_SHARE_LINK } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useFetchShareLinkPermissions } from '@/LegacyExplore/hooks/useFetchShareLinkPermissions';
import { useFetchShareLinks } from '@/LegacyExplore/hooks/useFetchShareLinks';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/LegacyExplore/pages/ProjectPage/DeleteConfirmationModal';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { ShareLink, setSelectedShareLink, useShareLink } from '@/LegacyExplore/stores/shareLink';
import {
  addViewerEventListener,
  removeViewerEventListener,
  useViewer,
} from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { ExploreState, persist } from '@/LegacyExplore/utils/Persist';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { CameraMotionCallback, GlobeMode } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Quaternion, Vector3 } from 'three';

interface ShareLinkCardProps {
  shareLink: ShareLink;
  disabled?: boolean;
}

export const ShareLinkCard = ({ shareLink, disabled = false }: ShareLinkCardProps) => {
  const selectedShareLink = useShareLink(state => state.selectedShareLink);
  const projectId = useExplore(state => state.projectId);
  const projection = useViewer(state => state.projectionMode);
  const api3D = useViewer(state => state.api3D);
  const viewerSettings = useViewer(state => state.viewer3DSettings);
  const defaultGlobeMode = useViewer(state => state.globeMode);
  const defaultBackgroundColor = viewerSettings.backgroundColor;
  const defaultPanoramaIconSize = viewerSettings.panoramaIconSize;
  const defaultBaseMapType = useViewer(state => state.baseMapType);
  const defaultAnnotationMeasurementVisibility = viewerSettings.annotationMeasurementVisibility;
  const defaultAnnotationNameVisibility = viewerSettings.annotationNameVisibility;

  const epsg = useExplore(state => state.epsg);
  const shareLinkSettings = useShareLink(state => state.settings);
  const visibleLayerIds = useShareLink(state => state.visibleLayerIds);

  const cachedBackgroundColor = shareLinkSettings.get(shareLink.id)?.backgroundColor;
  const cachedGlobeMode = shareLinkSettings.get(shareLink.id)?.globeMode;
  const cachedCurrentEpsg = shareLinkSettings.get(shareLink.id)?.currentEpsg;
  const cachedCameraTransform = shareLinkSettings.get(shareLink.id)?.shareCameraTransform;
  const cachedPanoramaIconSize = shareLinkSettings.get(shareLink.id)?.panoramaIconSize;
  const cachedBaseMapType = shareLinkSettings.get(shareLink.id)?.baseMapType;
  const cachedAnnotationMeasurementVisibility = shareLinkSettings.get(
    shareLink.id,
  )?.annotationMeasurementVisibility;
  const cachedAnnotationNameVisibility = shareLinkSettings.get(
    shareLink.id,
  )?.annotationNameVisibility;

  const panoramaIconSize = cachedPanoramaIconSize
    ? cachedPanoramaIconSize
    : defaultPanoramaIconSize;
  const backgroundColor = cachedBackgroundColor ? cachedBackgroundColor : defaultBackgroundColor;
  const currentEpsg = cachedCurrentEpsg ? cachedCurrentEpsg : epsg;
  const globeMode = cachedGlobeMode ? cachedGlobeMode : defaultGlobeMode;
  const baseMapType = cachedBaseMapType ? cachedBaseMapType : defaultBaseMapType;
  const shareCameraTransform = cachedCameraTransform && cachedCameraTransform;
  const annotationMeasurementVisibility = cachedAnnotationMeasurementVisibility
    ? cachedAnnotationMeasurementVisibility
    : defaultAnnotationMeasurementVisibility;
  const annotationNameVisibility = cachedAnnotationNameVisibility
    ? cachedAnnotationNameVisibility
    : defaultAnnotationNameVisibility;

  const [cameraPosition, setCameraPosition] = useState(
    api3D?.navigation.getPosition() ?? new Vector3(),
  );
  const [cameraRotation, setCameraRotation] = useState(
    api3D?.navigation.getRotation() ?? new Quaternion(),
  );
  const [orthoMatrix, setOrthoMatrix] = useState(api3D?.navigation.getOrthoMatrixParams() ?? []);

  const url = useMemo(() => {
    const base = window.location.origin + EXPLORE_ROOT;
    if (!projectId || !shareLink || !panoramaIconSize || !backgroundColor || !currentEpsg) return;

    const params: [keyof ExploreState, string][] = [
      ['project', projectId],
      ['shareLinkToken', shareLink.shareToken],
      ['globe', globeMode as GlobeMode],
      ['panoramaIconSize', panoramaIconSize?.toString()],
      ['backgroundColor', backgroundColor?.getHexString()],
      ['srs', currentEpsg?.toString()],
      ['imageryBase', baseMapType],
      ['showLabels', JSON.stringify(annotationNameVisibility)],
      ['showMeasurements', JSON.stringify(annotationMeasurementVisibility)],
    ];
    if (shareCameraTransform && api3D) {
      params.push(['cameraPosition', JSON.stringify(cameraPosition.toArray())]);
      params.push(['cameraRotation', JSON.stringify(cameraRotation.toArray())]);
      params.push(['orthoMatrix', JSON.stringify(orthoMatrix)]);
      params.push(['projection', projection]);
    }
    if (visibleLayerIds) {
      params.push(['layers', JSON.stringify([...visibleLayerIds.values()])]);
    }
    const imageShareId = persist.get('image');
    if (imageShareId) {
      params.push(['image', imageShareId]);
    }

    const annotationShareId = persist.get('annotation');
    if (annotationShareId) {
      params.push(['annotation', annotationShareId]);
    }
    return `${base}?${params.map(([k, v]) => k + '=' + v).join('&')}`;
  }, [
    projectId,
    shareLink,
    panoramaIconSize,
    backgroundColor,
    currentEpsg,
    globeMode,
    baseMapType,
    annotationNameVisibility,
    annotationMeasurementVisibility,
    shareCameraTransform,
    api3D,
    visibleLayerIds,
    cameraPosition,
    cameraRotation,
    orthoMatrix,
    projection,
  ]);

  // Listen for changes to the camera position and rotation
  useEffect(() => {
    const cb: CameraMotionCallback = (position, rotation, params) => {
      setCameraPosition(position);
      setCameraRotation(rotation);
      setOrthoMatrix(params);
    };
    addViewerEventListener('onCameraMotion', cb);
    return () => removeViewerEventListener('onCameraMotion', cb);
  }, [api3D?.navigation]);

  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const updateShareLink = useMutation({
    mutationFn: (variables: UpdateShareLinkMutationVariables) => {
      return request(UPDATE_SHARE_LINK, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
      queryClient.invalidateQueries(useFetchShareLinkPermissions.getQueryKey(shareLink.id));
    },
  });

  const deleteShareLink = useMutation({
    mutationFn: (variables: DeleteShareLinkMutationVariables) => {
      return request(DELETE_SHARE_LINK, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
    },
  });

  // Handle copying the final URL
  const handleCopy = async () => {
    if (!url) return;
    await navigator.clipboard.writeText(url);
    toast({
      type: 'success',
      message: 'Link copied!',
      lifespan: 2000,
      clickToDismiss: true,
    });
  };

  const ActiveTag = () => (
    <div
      className={cn(
        'flex',
        'flex-row',
        'items-center',
        'w-47px',
        'h-18px',
        'rounded-4px',
        'border-solid',
        'border-success-400',
        'border-1px',
        'pt-2px',
        'pb-2px',
        'pl-4px',
        'pr-4px',
        'justify-between',
        'bg-success-200',
      )}
    >
      <div className={cn('w-6px', 'h-6px', 'rounded-50%', 'bg-success-300')} />

      <p className="color-neutral-800 typo-text-xs">Active</p>
    </div>
  );

  const InactiveTag = () => (
    <div
      className={cn(
        'flex',
        'flex-row',
        'items-center',
        'w-54px',
        'h-18px',
        'rounded-4px',
        'border-solid',
        'border-alert-400',
        'border-1px',
        'pt-2px',
        'pb-2px',
        'pl-4px',
        'pr-4px',
        'justify-between',
        'bg-alert-100',
      )}
    >
      <div className={cn('w-6px', 'h-6px', 'rounded-50%', 'bg-alert-300')} />

      <p className="color-neutral-800 typo-text-xs">Inactive</p>
    </div>
  );

  const handleToggleActive = async () => {
    if (shareLink.active) {
      updateShareLink.mutate({ shareLinkId: shareLink.id, isActivated: false });
    } else {
      updateShareLink.mutate({ shareLinkId: shareLink.id, isActivated: true });
    }
  };

  const handleSettings = () => {
    setSelectedShareLink(shareLink);
    useShareLink.setState({ shareLinkTab: 'settings' });
  };

  const handleDeleteShareLink = () => {
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Share Link',
      description: `Are you sure you want to delete the share link ${shareLink.name}?`,
      actionButton: 'Delete Share Link',
      actionFunction: () => {
        deleteShareLink.mutate({ shareLinkId: shareLink.id });
        setOpenConfirmationModal(state => {
          return { ...state, isOpen: false };
        });
      },
    });
  };

  return (
    <>
      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <div
        className={cn(
          selectedShareLink === shareLink
            ? 'border-primary-400 border-2'
            : 'border-neutral-400 border-1',
          'rounded-8px',
          'border-solid',
          'p-12px',
          'w-full',
          'mb-3',
        )}
      >
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mb-2')}>
          {shareLink.active ? <ActiveTag /> : <InactiveTag />}
          {!disabled && (
            <MoreMenu className="cursor-pointer">
              <Menu>
                <MenuItem className="cursor-pointer" onClick={handleToggleActive}>
                  {shareLink.active ? 'Deactivate' : 'Activate'}
                </MenuItem>
                <MenuItem className="cursor-pointer" onClick={handleDeleteShareLink}>
                  Delete
                </MenuItem>
              </Menu>
            </MoreMenu>
          )}
        </div>
        <p className="color-neutral-800 typo-text-medium-em">{shareLink.name}</p>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Date created</p>
          <p className="color-neutral-600 typo-text-small">
            {shareLink.createdAt.toLocaleDateString()}
          </p>
        </div>

        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Date updated</p>
          <p className="color-neutral-600 typo-text-small">
            {shareLink.updatedAt.toLocaleDateString()}
          </p>
        </div>
        <div
          className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-16px', 'gap-3')}
        >
          <Button className={cn('w-164px')} onClick={handleCopy} primary size="s">
            Copy link
          </Button>
          <Button className={cn('w-164px')} onClick={handleSettings} size="s">
            Share settings
          </Button>
        </div>
      </div>
    </>
  );
};
