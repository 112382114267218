import { LAYER_STATUS, LayerStatus } from '@/LegacyExplore/constants/layer';
import { GEOIDS } from '@/LegacyExplore/constants/misc';
import { canPolicyActionEdit } from '@/LegacyExplore/constants/policy';
import { queryClient } from '@/LegacyExplore/graphql/client';
import { UPDATE_PROJECT } from '@/LegacyExplore/graphql/mutations';
import { request } from '@/LegacyExplore/graphql/request';
import { useFetchLayers } from '@/LegacyExplore/hooks/useFetchLayers';
import { useFetchProjectPermissions } from '@/LegacyExplore/hooks/useFetchProjectPermissions';
import { useFetchProjects } from '@/LegacyExplore/hooks/useFetchProjects';
import {
  closeEditProject,
  setEditProjectProject,
  useProjectStore,
} from '@/LegacyExplore/stores/project';
import { Layer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { isEmpty } from '@/LegacyExplore/utils/empty';
import { Project } from '@/LegacyExplore/utils/project';
import { Button, Input, Modal, Select } from '@skand/ui';
import { toDegrees } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { forwardRef, useEffect, useState } from 'react';
import { AutoComplete } from '../CreateProject/InsertProjectDetails/AutoComplete';
import { SiteMap } from '../CreateProject/InsertProjectDetails/SiteMap';
import { island } from '../classes';

const LAYER_STATUS_MAP = {
  [LAYER_STATUS.PROCESSING]: 'Processing',
  [LAYER_STATUS.PUBLISHED]: 'Published',
  [LAYER_STATUS.UNPUBLISHED]: 'Unpublished',
};

const GEOID_OPTIONS = [
  { name: 'N/A', key: 'default' },
  ...GEOIDS.map(geoid => ({ name: geoid, key: geoid })),
];

export const EditProjectCore = () => {
  const project = useProjectStore(state => state.editProject.project) as Project;
  const fetchLayers = useFetchLayers(project.id);

  const [layers, setLayers] = useState<Layer[]>([]);
  const [name, setName] = useState(project.name);
  const [address, setAddress] = useState(project.address);
  const [geoid, setGeoid] = useState(project.geoid);
  const [description, setDescription] = useState(project.description);
  const [lat, setLat] = useState(
    toDegrees(!isEmpty(project.location) ? project.location.latitude : -37.82321082611154),
  );
  const [lng, setLng] = useState(
    toDegrees(!isEmpty(project.location) ? project.location.longitude : 144.92521917916557),
  );

  const [positions, setPositions] = useState<google.maps.LatLngLiteral>({
    lat: toDegrees(!isEmpty(project.location) ? project.location.latitude : -37.82321082611154),
    lng: toDegrees(!isEmpty(project?.location) ? project.location.longitude : 144.92521917916557),
  });

  const { getProjectPermission } = useFetchProjectPermissions();
  const projectPermission = getProjectPermission(project.id);
  const canEditProject = canPolicyActionEdit(projectPermission);

  useEffect(() => {
    setLat(positions.lat);
    setLng(positions.lng);
  }, [positions]);

  useEffect(() => {
    fetchLayers().then(setLayers);
  }, [fetchLayers]);

  const isDirty =
    name !== project.name ||
    address !== project.address ||
    geoid !== project.geoid ||
    description !== project.description;

  const updateProject = useMutation({
    mutationFn: () =>
      request(UPDATE_PROJECT, {
        projectId: project.id,
        name: name,
        address,
        geoid,
        description,
        latitude: lat,
        longitude: lng,
        projectGroupId: project.projectGroupId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
      closeEditProject();
    },
  });

  return (
    <Modal>
      <div
        className={cn(
          'flex',
          'flex-col',
          'max-h-90vh',
          'min-h-80',
          'relative',
          'grid overflow-y-auto auto-rows-max m-y-1',
          'scrollbar scrollbar-rounded',
        )}
      >
        <p className="color-neutral-800 typo-text-l">Project details</p>
        <div className="mt-6 flex-none">
          <Input disabled={!canEditProject} label="Project name" onChange={setName} value={name} />
        </div>

        <div className={cn('flex-1', 'pt-3')}>
          <AutoComplete
            disabled={!canEditProject}
            positions={positions}
            setAddress={setAddress}
            setPositions={setPositions}
          />
        </div>
        <div className={cn('typo-text-xs', 'pt-1', 'color-neutral-600')}>
          No address for the project? Select any point on the map to associate the project with a
          location.
        </div>
        <div className={cn('relative', 'pt-3')}>
          <SiteMap disabled={!canEditProject} positions={positions} setPositions={setPositions} />
          <div className={cn('bottom-4', 'left-4', 'absolute', 'flex', 'flex-row', 'items-center')}>
            <div className={cn('w-30', 'mr-3')}>
              <Input
                className={cn('b-none', 'overflow-hidden', 'h-5', 'text-neutral-500')}
                disabled={!canEditProject}
                onChange={prop => setLat(parseFloat(prop))}
                placeholder="LAT"
                value={lat}
              />
            </div>
            <div className={cn('w-30', 'mr-3')}>
              <Input
                className={cn('b-none', 'overflow-hidden', 'h-5', 'text-neutral-500')}
                disabled={!canEditProject}
                onChange={prop => setLng(parseFloat(prop))}
                placeholder="LNG"
                value={lng}
              />
            </div>
            {canEditProject && (
              <Button
                className={cn('w-15', 'h-9', 'hover:cursor-pointer', 'rounded-1')}
                filled
                onClick={() => setPositions({ lat: lat, lng: lng })}
                primary
                size="small"
              >
                Go
              </Button>
            )}
          </div>
        </div>
        <div className="mt-6 flex items-stretch gap-3">
          <div className="flex-1">
            <Select
              className="h-full w-full"
              disabled={!canEditProject}
              label="GEOID"
              onChange={setGeoid}
              options={GEOID_OPTIONS}
              value={geoid}
              width="full"
            />
          </div>

          <div className="flex-1">
            <Input disabled label="Date created" value={project.createdAt.toLocaleDateString()} />
          </div>
        </div>

        <div className={cn(island, 'mt-3 h-256px flex-1 overflow-auto  min-h-25')}>
          <table className="w-full border-collapse table-auto">
            <thead>
              <tr className="b-b-1 b-b-neutral-500 b-b-solid">
                <td className="pb-2 uppercase color-neutral-800 typo-button-xs">layers</td>
                <td className="pb-2 uppercase color-neutral-800 typo-button-xs">date created</td>
                <td className="pb-2 uppercase color-neutral-800 typo-button-xs">status</td>
              </tr>
            </thead>

            <tbody>
              {layers.map(layer => {
                return (
                  <tr className="" key={layer.id}>
                    <td className="pt-2 color-neutral-800 typo-text-s">{layer.name}</td>
                    <td className="pt-2 color-neutral-800 typo-text-s">
                      {layer.captureDate.toLocaleDateString()}
                    </td>
                    <td className="pt-2 color-neutral-800 typo-text-s">
                      {layer.status && LAYER_STATUS_MAP[layer.status as LayerStatus]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <p className="mt-4 color-neutral-800 typo-text-m">General information</p>
        <textarea
          className={cn(island, 'color-neutral-800 typo-text-s mt-2 resize-none h-64px  min-h-15')}
          disabled={!canEditProject}
          onChange={e => setDescription(e.target.value)}
          value={description}
        />

        <div className="mt-auto flex gap-3 pt-3">
          <Button
            className="flex-1 hover:cursor-pointer"
            onClick={() => setEditProjectProject(null)}
            size="s"
          >
            Cancel
          </Button>

          {canEditProject && (
            <Button
              className="flex-1 hover:cursor-pointer"
              disabled={!isDirty}
              filled
              onClick={() => updateProject.mutate()}
              primary
              size="s"
            >
              Update
            </Button>
          )}
        </div>
      </div>
      <div />
    </Modal>
  );
};

export const EditProject = forwardRef(EditProjectCore);
