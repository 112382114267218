import { ANALYTICS_EVENT_OBJECT } from '@/LegacyExplore/constants/analytics';
import { PROJECT_ROOT } from '@/LegacyExplore/constants/paths';
import { useAccountV2ByContext } from '@/LegacyExplore/hooks/useAccountV2ByContext';
import { useEntitlements } from '@/LegacyExplore/hooks/useEntitlements';
import { TabKey, useLayout } from '@/LegacyExplore/stores/layout';
import {
  MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG,
  PANORAMA_PROCESS_FEATURE_FLAG,
} from '@/LegacyExplore/utils/split';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Link } from 'react-router-dom';
import { cn } from '../utils/classname';

interface ItemProps {
  name: TabKey;
  title: string;
  iconClass: string;
  isDisabled?: boolean;
  dataAnalyticsEventObject?: string;
}

export const Item = ({
  name,
  title,
  iconClass,
  isDisabled,
  dataAnalyticsEventObject,
}: ItemProps) => {
  const tab = useLayout(state => state.tab);
  const switchTab = useLayout(state => state.switch);
  const hideLeftSideBarI = useLayout(state => state.hideLeftSideBarI);
  const isShowingLeftSideBarI = useLayout(state => state.isShowingLeftSideBarI);
  const showLeftSideBarI = useLayout(state => state.showLeftSideBarI);
  const isActive = name === tab;
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);

  const toggleTabs = () => {
    hideLeftSideBarII();
    if (isActive && isShowingLeftSideBarI) {
      hideLeftSideBarI();
    }
    if (!isShowingLeftSideBarI) {
      showLeftSideBarI();
    }

    switchTab(name);
  };

  return (
    <div
      className={cn(
        'cursor-pointer',
        'flex-col',
        'flex-nowrap',
        'flex',
        'h-62px',
        'items-center',
        'justify-center',
        'rounded-l-2',
        isActive && 'bg-neutral-100',
      )}
      data-analytics-event-object={dataAnalyticsEventObject}
      onClick={toggleTabs}
    >
      <div
        className={cn(
          'text-18px',
          iconClass,
          isDisabled ? 'color-neutral-300' : isActive ? 'color-neutral-800' : 'color-neutral-600',
        )}
      />
      <span
        className={cn(
          'm-t-2',
          'p-x-1',
          'typo-text-xxs',
          isDisabled ? 'color-neutral-300' : isActive ? 'color-neutral-800' : 'color-neutral-600',
        )}
      >
        {title}
      </span>
    </div>
  );
};

export const LeftTabBar = ({ isDisabled }: { isDisabled?: boolean }) => {
  const tabs = useLayout(state => state.tabs);
  const treatment = useTreatments([
    MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG,
    PANORAMA_PROCESS_FEATURE_FLAG,
  ]);
  const processTabFlag =
    treatment[MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG].treatment === 'on' ||
    treatment[PANORAMA_PROCESS_FEATURE_FLAG].treatment === 'on';

  const hasWhiteLabelling = useAccountV2ByContext();
  const entitlement = useEntitlements()?.feature('branding');
  const hasBannerSetUp = entitlement?.enabled && hasWhiteLabelling;

  return (
    <div
      className={cn(
        ' flex flex-grow flex-col justify-between overflow-hidden',
        hasBannerSetUp ? 'm-t-33px' : 'm-t-108px',
      )}
    >
      <div className="ml-4px flex flex-col">
        {tabs.map((item, index) => {
          if (!item.hide && !(item.key === 'process' && !processTabFlag))
            return (
              <Item
                dataAnalyticsEventObject={item.dataAnalyticsEventObject}
                iconClass={item.iconClass}
                isDisabled={isDisabled}
                key={`item-${index}`}
                name={item.key}
                title={item.title}
              />
            );
        })}
      </div>
      <Link className="decoration-none" to={PROJECT_ROOT}>
        <div
          className={cn(
            'cursor-pointer',
            'flex-col',
            'flex-nowrap',
            'flex',
            'h-62px',
            'h-62px',
            'items-center',
            'justify-center',
            'rounded-l-2',
            isDisabled ? 'color-neutral-300' : 'color-neutral-600',
          )}
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.CLOSE_PROJECT}
        >
          <div
            className={cn(
              'text-14px i-skand-close transform-rotate-90 ',
              isDisabled ? 'color-neutral-300' : 'color-neutral-600',
            )}
          />
          <span className={cn('m-t-2', 'p-x-1', 'typo-text-xxs ')}>Close Project</span>
        </div>
      </Link>
    </div>
  );
};
