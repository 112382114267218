import { USER_BY_CONTEXT } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { getShareLinkToken } from '@/LegacyExplore/utils/shareLink';
import { createUser } from '@/LegacyExplore/utils/transformers';
import { useQuery } from '@tanstack/react-query';

export const useFetchUser = () => {
  const shareLinkToken = getShareLinkToken();
  const result = useQuery({
    enabled: !shareLinkToken,
    queryFn: () => request(USER_BY_CONTEXT),
    queryKey: ['USER_BY_CONTEXT'],
  });

  if (result.data?.readUserByContext) {
    return createUser(result.data.readUserByContext);
  }
};
