import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { Layer, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { useEffect, useState } from 'react';
import { LayeringTab } from './LayeringTab';
import { PointCloudTab } from './PointCloudTab/PointCloudTab';
import { TilesetStatisticsTab } from './TilesetStatisticsTab';

export interface PointCloudSettingsMenuProps {
  layer: Layer;
}

export const PointCloudSettingsMenu = ({ layer }: PointCloudSettingsMenuProps) => {
  const viewer3DSettings = useViewer(state => state.viewer3DSettings);
  const enabledStatistics = viewer3DSettings.enabledStatistics;

  const [currentTab, setCurrentTab] = useState('layering');

  useEffect(() => {
    if (!enabledStatistics) {
      setCurrentTab('point_cloud');
    }
  }, [enabledStatistics]);

  const getTabComponent = () => {
    switch (currentTab) {
      case 'point_cloud':
        return <PointCloudTab layer={layer} />;
      case 'layering':
        return <LayeringTab layer={layer} />;
      default:
        return <TilesetStatisticsTab layer={layer} />;
    }
  };

  return (
    <div className={cn('w-full flex flex-col')}>
      <div
        className={cn(
          'items-center pb-2 flex h-auto justify-around w-full mt-1 b-solid b-neutral-400 border-b-1 border-l-0 border-r-0 border-t-0',
        )}
      >
        <button
          className={cn('bg-transparent', 'b-none', 'cursor-pointer', 'flex', 'flex-1')}
          onClick={() => setCurrentTab('layering')}
        >
          <p
            className={cn(
              'typo-text-small',
              currentTab === 'layering' ? 'text-neutral-800' : 'text-neutral-400',
            )}
          >
            Layering
          </p>
        </button>
        <button
          className={cn(
            'bg-transparent',
            'b-none',
            'cursor-pointer',
            'flex',
            'flex-1',
            'b-neutral-400',
          )}
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.POINT_CLOUD_TOOL}
          onClick={() => setCurrentTab('point_cloud')}
        >
          <p
            className={cn(
              'typo-text-small',
              currentTab === 'point_cloud' ? 'text-neutral-800' : 'text-neutral-400',
            )}
          >
            Point cloud
          </p>
        </button>
        {enabledStatistics && (
          <button
            className={cn(
              'bg-transparent',
              'b-none',
              'cursor-pointer',
              'flex',
              'flex-1',
              'b-neutral-400',
            )}
            onClick={() => setCurrentTab('statistics')}
          >
            <p
              className={cn(
                'typo-text-small',
                currentTab === 'statistics' ? 'text-neutral-800' : 'text-neutral-400',
              )}
            >
              Statistics
            </p>
          </button>
        )}
      </div>
      {getTabComponent()}
    </div>
  );
};
