import { UNGROUPED_GROUP_ID } from '@/LegacyExplore/constants/project';
import { foldGroup, unFoldGroup, useProjectStore } from '@/LegacyExplore/stores/project';
import { Project } from '@/LegacyExplore/utils/project';
import { useMemo } from 'react';

type GroupIdsInView = string | symbol;

interface ProjectGroupIdsAccumulator {
  projectGroupIdsInView: Set<GroupIdsInView>;
  ungroupedProjects: Project[];
}

export const useProjectsPageActions = (filteredProjects: Project[], searchKey: string) => {
  const foldState = useProjectStore(state => state.table.foldState);
  const {
    projectGroupIdsInView,
    isSearchResultsEmpty,
    ungroupedProjects,
    isAllGroupsFolded,
    isAllGroupsUnFolded,
  } = useMemo(() => {
    let isAllGroupsFolded = true;
    let isAllGroupsUnFolded = true;
    const { projectGroupIdsInView, ungroupedProjects } = filteredProjects.reduce(
      (acc: ProjectGroupIdsAccumulator, project) => {
        if (!project.group) {
          acc.ungroupedProjects.push(project);
        }
        const id = project?.group?.id !== undefined ? project?.group?.id : UNGROUPED_GROUP_ID;

        if (foldState[id as string]) {
          isAllGroupsUnFolded = false;
        } else {
          isAllGroupsFolded = false;
        }

        acc.projectGroupIdsInView.add(id as GroupIdsInView);
        return acc;
      },

      { projectGroupIdsInView: new Set<GroupIdsInView>(), ungroupedProjects: [] },
    );

    return {
      projectGroupIdsInView: Array.from(projectGroupIdsInView),
      ungroupedProjects,
      isSearchResultsEmpty: !!searchKey && !projectGroupIdsInView.size,
      isAllGroupsFolded,
      isAllGroupsUnFolded,
    };
  }, [filteredProjects, searchKey, foldState]);

  const handleExpandAllGroups = () => {
    projectGroupIdsInView.forEach(id => unFoldGroup(id as string));
  };

  const handleCollapseAllGroups = () => {
    projectGroupIdsInView.forEach(id => foldGroup(id as string));
  };

  return {
    projectGroupIdsInView,
    isSearchResultsEmpty,
    ungroupedProjects,
    isAllGroupsFolded,
    isAllGroupsUnFolded,
    handleExpandAllGroups,
    handleCollapseAllGroups,
  };
};
