import { AnnotationTemplate } from '@/LegacyExplore/graphql/codegen/graphql';
import { LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID } from '@/LegacyExplore/graphql/queries';
import { request } from '@/LegacyExplore/graphql/request';
import { useExplore } from '@/LegacyExplore/stores/explore';
import { hasId } from '@/LegacyExplore/utils/types';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export const ANNOTATION_FIELD_TYPE = {
  TEXT: { label: 'Text', value: 'TEXT' },
  FILE: { label: 'File', value: 'FILE' },
  DATE: { label: 'Date', value: 'DATE' },
  URL: { label: 'Link', value: 'URL' },
  SELECT: { label: 'Select', value: 'SELECT' },
};

const getQueryKey = (projectId: null | string) => [
  'LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID',
  projectId,
];

export const useAnnotationTemplates = () => {
  const projectId = useExplore(state => state.projectId);

  const response = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: getQueryKey(projectId),
  });

  const templates: SolidId<AnnotationTemplate>[] = useMemo(() => {
    return response.data?.listAnnotationTemplatesByProjectId?.filter(hasId) ?? [];
  }, [response.data?.listAnnotationTemplatesByProjectId]);

  const findTemplateByTemplateId = useCallback(
    (templateId: null | undefined | string) => {
      return templates.find(t => t.id === templateId);
    },
    [templates],
  );
  return { response, templates, findTemplateByTemplateId };
};

useAnnotationTemplates.getQueryKey = getQueryKey;
