import { MoreMenu } from '@/components/MoreMenu';
import { useLayout } from '@/stores/layout';
import { useShareLink } from '@/stores/shareLink';
import {
  Photo,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Menu, MenuItem } from '@skand/ui';

export interface PhotoNodeProps {
  photo: Photo;
  selected: boolean;
}

export const PhotoNode = ({ photo, selected }: PhotoNodeProps) => {
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);
  const loadingMutex = useViewer(state => state.loadingMutex);
  const switchTab = useLayout(state => state.switch);

  // Handle viewing the photo in the image viewer
  const handleViewImage = async () => {
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();
      if (photo.type === 'photo2D') {
        await startPhotoOverlay(photo);
      } else {
        await startPanoramaWalkthrough(photo);
      }
    }
  };

  // Handle opening the image as a popup
  const handleOpenImagePopup = () => {
    useViewer.setState(prev => {
      const popupPhotos = new Set(prev.popupPhotos);
      popupPhotos.add(photo);
      return { popupPhotos };
    });
  };

  // Handle create a share link from the annotation
  const handleCreateAnnotation = () => {
    switchTab('sharelink');
    useShareLink.setState({
      shareLinkTab: 'create',
      selectedImageIdByLayers: photo.id,
      selectedShareLink: null,
    });
  };

  return (
    <>
      <div className="i-skand-image w-5 text-3 color-neutral-600" />
      <p
        className={cn(
          'group',
          'cursor-pointer',
          'flex-1 truncate',
          selected ? 'typo-text-small-em text-neutral-800' : 'typo-text-small text-neutral-600',
        )}
        onClick={handleViewImage}
        title={photo.name}
      >
        {photo.name}
      </p>
      <div className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2">
        <MoreMenu className={cn('cursor-pointer', enabledSelectMode && 'hidden')}>
          <Menu className="z-2">
            <MenuItem className="cursor-pointer" onClick={handleOpenImagePopup}>
              Open in popup
            </MenuItem>
            <MenuItem className="cursor-pointer" onClick={handleCreateAnnotation}>
              Create share link to this image
            </MenuItem>
          </Menu>
        </MoreMenu>
      </div>
    </>
  );
};
