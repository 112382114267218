import {
  deselectCreateGroupProject,
  selectCreateGroupProject,
  useProjectStore,
} from '@/LegacyExplore/stores/project';
import { Project } from '@/LegacyExplore/utils/project';
import { Button } from '@skand/ui';
import { useCallback, useMemo } from 'react';

interface ProjectItemProps {
  project: Project;
}

export const ProjectItem = ({ project }: ProjectItemProps) => {
  const projectIds = useProjectStore(state => state.createGroup.projectIds);
  const isSelected = useMemo(() => projectIds.includes(project.id), [project, projectIds]);

  const handleClick = useCallback(() => {
    if (isSelected) deselectCreateGroupProject(project.id);
    else selectCreateGroupProject(project.id);
  }, [isSelected, project.id]);

  return (
    <div className="mt-2 flex" key={project.id}>
      <span className="flex-[0_0_50%] truncate color-neutral-800 typo-text-s">{project.name}</span>
      <span className="flex-[0_0_25%] color-neutral-800 typo-text-s">
        {project.createdAt.toLocaleDateString()}
      </span>
      <Button
        active={isSelected}
        className="flex-[0_0_25%] hover:cursor-pointer"
        onClick={handleClick}
        primary
        size="xs"
      >
        {isSelected ? 'remove' : 'select'}
      </Button>
    </div>
  );
};
