import {
  AnnotationTemplateField,
  AnnotationTemplateSelectField,
  AnnotationTemplateSelectOption,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { WithMode, useTemplate } from './store';

export const addOption = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];
      const newOptions = [...options, { color: '#FFFFFF', value: '' }];

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: newOptions,
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};

export const removeOption = (field: WithMode<AnnotationTemplateField>, index: number) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: options.filter((_, i) => i !== index),
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};

export const changeOption = (
  field: WithMode<AnnotationTemplateField>,
  index: number,
  newOption: Partial<AnnotationTemplateSelectOption>,
) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];
      const newOptions: AnnotationTemplateSelectOption[] = [];

      for (const [i, option] of options.entries()) {
        if (i !== index && option) newOptions.push(option);
        else newOptions.push({ ...option, ...newOption });
      }

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: newOptions,
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};

export const moveOption = (
  field: WithMode<AnnotationTemplateField>,
  fromIndex: number,
  toIndex: number,
) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];
      const option = options[fromIndex];
      const tmpOptions = options.filter((_, i) => i !== fromIndex);
      const newOptions = [];

      for (let i = 0; i < options.length; i++) {
        if (i < toIndex) newOptions.push(tmpOptions[i]);
        if (i === toIndex) newOptions.push(option);
        if (i > toIndex) newOptions.push(tmpOptions[i - 1]);
      }

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: newOptions,
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};
