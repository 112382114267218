import { queryClient } from '@/graphql/client';
import {
  CreateAnnotationGroupInput,
  MoveAnnotationsToGroupMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_ANNOTATION_GROUP, MOVE_ANNOTATIONS_TO_GROUP } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useRefetchAnnotations } from '@/hooks/useFetchAnnotations';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useAnnotationStore } from '@/stores/annotation';
import { useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, Input } from '@skand/ui';
import { Sketch } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { Matrix4 } from 'three';

interface AnnotationGroup {
  annotationGroupId?: string | null | undefined;
  createdAt?: string | null | undefined;
  id?: string | null | undefined;
  name?: string | null | undefined;
  __typename?: string | null | undefined;
}

export const AnnotationTabAddMoveGroup = () => {
  const [selectedNewGroup, setSelectedNewGroup] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');

  const annotationGroups = useViewer(state => state.annotationGroups);
  const projectId = useExplore(state => state.projectId);
  const setActiveAnnotationGroupId = useAnnotationStore(state => state.setActiveAnnotationGroupId);
  const setActiveAnnotationId = useAnnotationStore(state => state.setActiveAnnotationId);
  const viewerAnnotationGroups = useViewer(state => state.annotationGroups);
  const { clearActiveAnnotationInformation } = useAnnotationStore.getState();
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);

  const {
    activeAnnotationInformation: { activeAnnotationId, activeAnnotationGroupId },
  } = useAnnotationStore.getState();

  const moveAnnotation = useMutation({
    mutationFn: (variables: MoveAnnotationsToGroupMutationVariables) => {
      return request(MOVE_ANNOTATIONS_TO_GROUP, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      useViewer.setState(state => {
        for (const group of state.annotationGroups) {
          group.loadState = 'pending';
        }
        return { annotationGroups: [...state.annotationGroups] };
      });
    },
  });

  const searchResults = useMemo(() => {
    return annotationGroups.filter(annotationGroup =>
      annotationGroup.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [annotationGroups, searchQuery]);

  const { refetch: refetchAnnotations } = useRefetchAnnotations();

  const handleMoveToGroup = async () => {
    const currGroup = viewerAnnotationGroups.find(group => group.id === activeAnnotationGroupId);
    const nextGroup = viewerAnnotationGroups.find(group => group.id === selectedNewGroup);
    const annotation = currGroup?.annotations.find(
      annotation => annotation.id === activeAnnotationId,
    );
    if (currGroup && nextGroup && annotation && annotation.sketch3D) {
      const sketch = annotation.sketch3D.getModel() as Sketch;
      const transform = new Matrix4().compose(
        sketch.getPosition(),
        sketch.getRotation(),
        sketch.getScale(),
      );
      const inverseGroupTransform = new Matrix4()
        .compose(
          nextGroup.sceneNode.getPosition(),
          nextGroup.sceneNode.getRotation(),
          nextGroup.sceneNode.getScale(),
        )
        .invert();
      const vertices = sketch
        .getVertices()
        .map(vertex => vertex.applyMatrix4(transform).applyMatrix4(inverseGroupTransform));
      await moveAnnotation.mutateAsync({
        annotationIdsWithPositionAndRotation: [
          {
            annotationId: activeAnnotationId,
            positions: vertices,
            rotations: vertices.map(() => ({ x: 0, y: 0, z: 0, w: 1 })),
          },
        ],
        groupId: selectedNewGroup,
      });

      refetchAnnotations([currGroup.id, nextGroup.id]);

      setActiveAnnotationGroupId(selectedNewGroup);
      setSelectedNewGroup('');
      setActiveAnnotationId('');
      hideLeftSideBarII();
    }
  };

  return (
    <div className="relative h-full flex flex-col">
      {' '}
      {/* added relative */}
      <AnnotationTabHeader />
      <AnnotationTabSearchAndFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <div className="border border-neutral-500 border-y-solid py-3">
        <p className="color-neutral-800 typo-text-xs-em">ANNOTATION GROUP NAME</p>
      </div>
      <div className="flex-grow-1 overflow-y-auto">
        {' '}
        {/* added overflow-y-auto */}
        {searchResults &&
          searchResults.length > 0 &&
          searchResults.map(annotationGroup => (
            <React.Fragment key={annotationGroup.id}>
              <AddMoveAnnotationGroupitem
                annotationGroup={annotationGroup}
                selectedNewGroup={selectedNewGroup}
                setSelectedNewGroup={setSelectedNewGroup}
              />
            </React.Fragment>
          ))}
      </div>
      <form
        className={cn('flex gap-2 absolute bottom-0 w-full bg-white pt-4')}
        onSubmit={e => e.preventDefault()}
      >
        <Button
          className="w-full"
          onClick={() => {
            setSelectedNewGroup('');
            clearActiveAnnotationInformation();
            hideLeftSideBarII();
          }}
          primary
          size="s"
        >
          Cancel
        </Button>
        {selectedNewGroup && activeAnnotationId ? (
          <Button className="w-full" filled onClick={handleMoveToGroup} primary size="s">
            Move to group
          </Button>
        ) : (
          <Button className="w-full" disabled filled size="s">
            Move to group
          </Button>
        )}
      </form>
    </div>
  );
};

const AnnotationTabHeader = () => {
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);
  const clearActiveAnnotationInformation = useAnnotationStore(
    state => state.clearActiveAnnotationInformation,
  );

  return (
    <div
      className={cn(
        'flex justify-between items-end border-neutral-300 border-1 border-b-solid pb-6.5 mb-3 ',
      )}
    >
      <p className={cn('typo-heading-4 color-neutral-800')}>Add to annotation group</p>
      <div
        className="i-skand-close absolute right-0 top-0 mb-auto ml-auto cursor-pointer text-sm color-neutral-400"
        onClick={() => {
          clearActiveAnnotationInformation();
          hideLeftSideBarII();
        }}
      />
    </div>
  );
};

const AnnotationTabSearchAndFilter = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}) => {
  const projectId = useExplore(state => state.projectId);

  const createAnnotationGroup = useMutation({
    mutationFn: (annotationGroup: CreateAnnotationGroupInput) => {
      return request(CREATE_ANNOTATION_GROUP, { annotationGroup });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['LIST_ANNOTATIONS_BY_ANNOTATION_GROUP_ID']);
    },
  });

  return (
    <div className={cn('flex flex-col gap-3 mb-3')}>
      <form className={cn('flex gap-2 ')} onSubmit={e => e.preventDefault()}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search"
            onChange={event => setSearchQuery(event)}
            value={searchQuery}
          />
        </div>
        <Button className="px-5" size="s">
          Filter
        </Button>
      </form>

      <form className={cn('flex gap-2')} onSubmit={e => e.preventDefault()}>
        <Button
          className="w-full"
          onClick={() => {
            createAnnotationGroup.mutate({ projectId, name: 'New group name' });
          }}
          primary
          size="s"
        >
          Create new group
        </Button>

        {/* <Button size="s" active filled primary  className="w-full">
          Create Annotation
        </Button> */}
      </form>
    </div>
  );
};

const AddMoveAnnotationGroupitem = ({
  annotationGroup,
  selectedNewGroup,
  setSelectedNewGroup,
}: // isActiveGroup,
{
  annotationGroup: AnnotationGroup;
  selectedNewGroup: string;
  setSelectedNewGroup: (id: string) => void;
  // isActiveGroup: boolean;
}) => {
  const {
    activeAnnotationInformation: { activeAnnotationGroupId, activeAnnotationId },
  } = useAnnotationStore();

  let button;

  if (annotationGroup.id == activeAnnotationGroupId) {
    button = (
      <Button className="w-120px rounded px-2 typo-text-xs" disabled primary size="xs">
        {' '}
        CURRENT GROUP
      </Button>
    );
  } else if (selectedNewGroup == annotationGroup.id) {
    button = (
      <Button
        className="w-120px rounded px-2 typo-text-xs"
        filled
        onClick={() => setSelectedNewGroup('')}
        primary
        size="xs"
      >
        SELECTED
      </Button>
    );
  } else if (activeAnnotationGroupId && activeAnnotationId) {
    button = (
      <Button
        className="w-120px rounded px-2 typo-text-xs"
        onClick={() => setSelectedNewGroup(annotationGroup.id as string)}
        size="xs"
      >
        SELECT
      </Button>
    );
  } else {
    button = (
      <Button
        className="w-120px rounded px-2 typo-text-xs"
        disabled
        onClick={() => setSelectedNewGroup(annotationGroup.id as string)}
        size="xs"
      >
        Select Annotation
      </Button>
    );
  }

  return (
    <div className={cn('py-2')}>
      <div className="flex items-center justify-between">
        <p className="color-neutral-800 typo-text-s">{annotationGroup.name}</p>
        {button}
      </div>
    </div>
  );
};
