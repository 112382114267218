import { AnnotationShapeType } from '@/constants/annotation';
import { LayerFormatType, LayerStatus } from '@/constants/layer';
import { Policy } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import {
  AnnotationDateField,
  AnnotationField,
  AnnotationFieldInput,
  AnnotationFileField,
  AnnotationImageField,
  Annotation as AnnotationQuery,
  AnnotationSelectField,
  AnnotationTemplate,
  AnnotationTemplateFieldInput,
  AnnotationTemplateSelectField,
  AnnotationTextField,
  AnnotationUrlField,
  AssetFile,
  CameraModel as CameraModelQuery,
  FileNode,
  FolderNode,
  ImageField,
  ImageFile,
  Layer as LayerQuery,
  LinkNode,
  ObjFile,
  Ortho2dFile,
  PanoramicImageField,
  PermissionPolicy,
  Process as ProcessQuery,
  ProjectGroup as ProjectGroupQuery,
  Project as ProjectQuery,
  SceneEntity,
  ShareLink as ShareLinkQuery,
  Tileset3dFile,
  UserV2,
} from '@/graphql/codegen/graphql';
import { GET_DOWNLOAD_URLS_BY_FILE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { ShareLink } from '@/stores/shareLink';
import { SystemNode } from '@/stores/systemNodes';
import {
  Annotation,
  AnnotationGroup,
  Layer,
  LayerGroup,
  Panorama,
  Photo,
  Photo2D,
  PhotoGroup,
} from '@/stores/viewer';
import { CreateCSVAnnotation, CSVAnnotation } from '@/utils/annotation';
import { CameraModel, EPSG } from '@skand/data-3d-loader';
import {
  Cartographic,
  Geometry,
  Imagery,
  Model3D,
  ModelConfiguration,
  ModelNode,
  PanoramaConfiguration,
  PhotoCamera,
  SceneNode,
  Sketch,
  Terrain,
  toRadians,
  ViewerAPI,
} from '@skand/viewer-component-v2';
import { uniq } from 'lodash-es';
import { Color, Matrix4, Quaternion, Vector2, Vector3 } from 'three';
import { Viewer2DAPI } from './Viewer2D';
import { shapeTypeToGeometry } from './annotation';
import { AnnotationVersion } from './annotationVersion';
import { isEmpty } from './empty';
import { error } from './logger';
import { modelCache } from './modelCache';
import { Process, ProcessImage } from './process';
import { Project, ProjectGroup } from './project';
import { User } from './user';

export const createLayerModelConfiguration = (
  sceneEntity: SceneEntity,
): ModelConfiguration | undefined => {
  if (isEmpty(sceneEntity.renderObject)) return;
  const renderObject = sceneEntity.renderObject;
  switch (renderObject.__typename) {
    case 'CesiumIonRenderObject':
      switch (sceneEntity.rendererType) {
        case 'TILESET_3D':
          return {
            type: 'tileset',
            source: 'cesium',
            assetId: parseInt(renderObject.cesiumIonId as string),
          };
        case 'IMAGERY':
          return {
            type: 'imagery',
            source: 'cesium',
            assetId: parseInt(renderObject.cesiumIonId as string),
          };
        case 'TERRAIN':
          return {
            type: 'terrain',
            assetId: parseInt(renderObject.cesiumIonId as string),
          };
        case 'KML':
          return {
            type: 'kml',
            source: 'cesium',
            assetId: parseInt(renderObject.cesiumIonId as string),
            cacheKey: renderObject.id as string,
          };
        default:
          return;
      }
    case 'Tileset3dRenderObject':
      return {
        type: 'tileset',
        source: 'direct',
        url: (renderObject.file as Tileset3dFile).httpUrl as string,
      };
    case 'DxfRenderObject':
      return {
        type: 'dxf',
        cacheKey: renderObject.file?.id as string,
        url: (renderObject.file as AssetFile).signedGetObjectUrl as string,
        spatialReferenceSystem: renderObject.epsgCode
          ? (`EPSG:${renderObject.epsgCode}` as EPSG)
          : undefined,
      };
    case 'IfcRenderObject':
      return {
        type: 'ifc',
        cacheKey: renderObject.file?.id as string,
        url: (renderObject.file as AssetFile).signedGetObjectUrl as string,
        spatialReferenceSystem: renderObject.epsgCode
          ? (`EPSG:${renderObject.epsgCode}` as EPSG)
          : undefined,
      };
    case 'KmlRenderObject':
      return {
        type: 'kml',
        source: 'direct',
        cacheKey: renderObject.file?.id as string,
        url: (renderObject.file as AssetFile).signedGetObjectUrl as string,
      };
    case 'ObjRenderObject':
      return {
        type: 'obj',
        cacheKey: renderObject.file?.id as string,
        url: (renderObject.file as ObjFile).httpUrl as string,
        spatialReferenceSystem: renderObject.epsgCode
          ? (`EPSG:${renderObject.epsgCode}` as EPSG)
          : undefined,
      };
    case 'Ortho2dRenderObject':
      return {
        type: 'imagery',
        source: 'direct',
        url: ((renderObject.file as Ortho2dFile).httpUrl as string) + '/',
      };
    default:
      return;
  }
};

export const createLayerGroup = (
  sceneEntity: SceneEntity,
  parent: Layer | LayerGroup | undefined,
): LayerGroup | undefined => {
  if (
    !isEmpty(sceneEntity.id) &&
    !isEmpty(sceneEntity.name) &&
    !isEmpty(sceneEntity.position) &&
    !isEmpty(sceneEntity.rotation)
  ) {
    const position = new Vector3(
      sceneEntity.position.x as number,
      sceneEntity.position.y as number,
      sceneEntity.position.z as number,
    );
    const rotation = new Quaternion(
      sceneEntity.rotation.x as number,
      sceneEntity.rotation.y as number,
      sceneEntity.rotation.z as number,
      sceneEntity.rotation.w as number,
    );
    const scale = new Vector3(1, 1, 1);

    // Create the scene node.
    const sceneNode = new SceneNode();
    if (parent && parent.sceneNode instanceof SceneNode) {
      parent.sceneNode.add(sceneNode);
    }
    sceneNode.setLocalPosition(position);
    sceneNode.setLocalRotation(rotation);
    sceneNode.setLocalScale(scale);

    return {
      type: 'layerGroup',
      id: sceneEntity.id,
      name: sceneEntity.name,
      sceneEntityId: sceneEntity.id,
      parent,
      sceneNode,
    };
  }
};

export const createLayer = (
  sceneEntity: SceneEntity,
  layer: LayerQuery,
  parent: Layer | LayerGroup | undefined,
): Layer | LayerGroup | undefined => {
  const config = createLayerModelConfiguration(sceneEntity);
  if (
    !isEmpty(layer) &&
    !isEmpty(layer.id) &&
    !isEmpty(layer.captureDate) &&
    !isEmpty(sceneEntity.name) &&
    !isEmpty(layer.mainSceneEntityId) &&
    !isEmpty(layer.formatType) &&
    !isEmpty(layer.status)
  ) {
    const position = new Vector3(
      sceneEntity.position?.x ?? 0,
      sceneEntity.position?.y ?? 0,
      sceneEntity.position?.z ?? 0,
    );
    const rotation = new Quaternion(
      sceneEntity.rotation?.x ?? 0,
      sceneEntity.rotation?.y ?? 0,
      sceneEntity.rotation?.z ?? 0,
      sceneEntity.rotation?.w ?? 1,
    );
    const scale = new Vector3(1, 1, 1);

    // Create the scene node.
    let sceneNode: Layer['sceneNode'] = new SceneNode();
    const model = modelCache.get(layer.id);
    if (model instanceof Terrain || model instanceof Imagery) {
      sceneNode = model;
    } else if (model instanceof Model3D) {
      sceneNode = new ModelNode(model);
    }

    // Apply transform and add to scene tree
    if (sceneNode instanceof SceneNode) {
      if (parent && parent.sceneNode instanceof SceneNode) {
        parent.sceneNode.add(sceneNode);
      }
      sceneNode.setLocalPosition(position);
      sceneNode.setLocalRotation(rotation);
      sceneNode.setLocalScale(scale);
    }

    // If no render object is available (no permissions), then just return a layer group
    if (config) {
      return {
        type: 'layer',
        id: layer.id,
        captureDate: new Date(layer.captureDate),
        name: sceneEntity.name,
        config,
        sceneNode,
        sceneEntityId: layer.mainSceneEntityId,
        formatType: layer.formatType as LayerFormatType,
        parent,
        status: layer.status as LayerStatus,
      };
    } else if (sceneNode instanceof SceneNode) {
      return {
        type: 'layerGroup',
        id: layer.id,
        name: sceneEntity.name,
        sceneEntityId: layer.mainSceneEntityId,
        parent,
        sceneNode,
      };
    }
  } else {
    error(`Could not create layer (${layer.id}) because of missing properties.`);
  }
};

export const createPhotoGroup = (
  sceneEntity: SceneEntity,
  parent: Layer | LayerGroup | undefined,
): PhotoGroup | undefined => {
  if (
    !isEmpty(sceneEntity.id) &&
    !isEmpty(sceneEntity.renderObject) &&
    !isEmpty(sceneEntity.position) &&
    !isEmpty(sceneEntity.rotation) &&
    !isEmpty(sceneEntity.renderObject.id)
  ) {
    const position = new Vector3(
      sceneEntity.position.x as number,
      sceneEntity.position.y as number,
      sceneEntity.position.z as number,
    );
    const rotation = new Quaternion(
      sceneEntity.rotation.x as number,
      sceneEntity.rotation.y as number,
      sceneEntity.rotation.z as number,
      sceneEntity.rotation.w as number,
    );
    const scale = new Vector3(1, 1, 1);

    // Create the scene node.
    const sceneNode = new SceneNode();
    if (parent && parent.sceneNode instanceof SceneNode) {
      parent.sceneNode.add(sceneNode);
    }
    sceneNode.setLocalPosition(position);
    sceneNode.setLocalRotation(rotation);
    sceneNode.setLocalScale(scale);

    const renderObjectId = sceneEntity.renderObject.id;
    switch (sceneEntity.renderObject.__typename) {
      case 'ImageProjectionRenderObject':
        return {
          type: 'photoGroup',
          id: sceneEntity.id,
          name: isEmpty(sceneEntity?.name) ? `2D Images (${sceneEntity.id})` : sceneEntity.name,
          sceneEntityId: sceneEntity.id,
          sceneNode,
          parent,
          loadState: 'pending',
          renderObjectId,
          photos: [],
        };

      case 'PanoramicRenderObject':
        return {
          type: 'photoGroup',
          id: sceneEntity.id,
          name: isEmpty(sceneEntity?.name) ? `Panoramas (${sceneEntity.id})` : sceneEntity.name,
          sceneEntityId: sceneEntity.id,
          sceneNode,
          parent,
          loadState: 'pending',
          renderObjectId,
          photos: [],
        };
      default:
        return;
    }
  }
};

export const createCameraModel = (cameraModel: CameraModelQuery): CameraModel | undefined => {
  if (
    !isEmpty(cameraModel) &&
    !isEmpty(cameraModel.id) &&
    !isEmpty(cameraModel.distortion) &&
    !isEmpty(cameraModel.distortion.k1) &&
    !isEmpty(cameraModel.distortion.k2) &&
    !isEmpty(cameraModel.distortion.k3) &&
    !isEmpty(cameraModel.distortion.p1) &&
    !isEmpty(cameraModel.distortion.p2) &&
    !isEmpty(cameraModel.focalLength) &&
    !isEmpty(cameraModel.principalPoint) &&
    !isEmpty(cameraModel.principalPoint[0]) &&
    !isEmpty(cameraModel.principalPoint[1]) &&
    !isEmpty(cameraModel.imageSize) &&
    !isEmpty(cameraModel.imageSize[0]) &&
    !isEmpty(cameraModel.imageSize[1]) &&
    !isEmpty(cameraModel.skew) &&
    !isEmpty(cameraModel.sensorSize) &&
    !isEmpty(cameraModel.projectionString)
  ) {
    return {
      id: parseInt(cameraModel.id),
      distortion: {
        k1: cameraModel.distortion.k1,
        k2: cameraModel.distortion.k2,
        k3: cameraModel.distortion.k3,
        p1: cameraModel.distortion.p1,
        p2: cameraModel.distortion.p2,
      },
      focalLength: cameraModel.focalLength,
      principalPoint: new Vector2(cameraModel.principalPoint[0], cameraModel.principalPoint[1]),
      imageSize: new Vector2(cameraModel.imageSize[0], cameraModel.imageSize[1]),
      skew: cameraModel.skew,
      sensorSize: cameraModel.sensorSize,
      spatialReferenceProj: cameraModel.projectionString as EPSG,
    };
  } else {
    error(`Could not create camera model (${cameraModel.id}) because of missing properties.`);
  }
};

export const createPhoto2D = async (
  image: ImageField,
  group: PhotoGroup,
  api3D: ViewerAPI,
): Promise<Photo2D | undefined> => {
  if (
    !isEmpty(image) &&
    !isEmpty(image.fileId) &&
    !isEmpty(image.file) &&
    image.file.__typename === 'ImageFile' &&
    !isEmpty(image.file.fileName)
  ) {
    const photo: Photo2D = {
      type: 'photo2D',
      id: image.fileId,
      name: image.file.fileName,
      group,
    };
    if (
      !isEmpty(image.cameraModelId) &&
      !isEmpty(image.cameraRelativePosition) &&
      !isEmpty(image.cameraRelativeRotation)
    ) {
      const position = new Vector3(
        image.cameraRelativePosition[0],
        image.cameraRelativePosition[1],
        image.cameraRelativePosition[2],
      );
      const rotation = new Quaternion(
        image.cameraRelativeRotation[0],
        image.cameraRelativeRotation[1],
        image.cameraRelativeRotation[2],
        image.cameraRelativeRotation[3],
      );
      const scale = new Vector3(1, 1, 1);

      // Create the camera widget
      const photoCamera = await api3D.model.create({
        type: 'photo-camera',
        pixelSize: 10,
        color: new Color(1, 1, 0),
      });

      photo.widget = new ModelNode(photoCamera as PhotoCamera);
      group.sceneNode.add(photo.widget);
      photo.widget.setLocalPosition(position);
      photo.widget.setLocalRotation(rotation);
      photo.widget.setLocalScale(scale);
      photo.cameraModelId = parseInt(image.cameraModelId);
    }
    return photo;
  }
};

export const createPanorama = async (
  panorama: PanoramicImageField,
  group: PhotoGroup,
  api3D: ViewerAPI,
): Promise<Panorama | undefined> => {
  if (
    !isEmpty(panorama) &&
    !isEmpty(panorama.fileId) &&
    !isEmpty(panorama.file) &&
    panorama.file.__typename === 'ImageFile' &&
    !isEmpty(panorama.file.fileName)
  ) {
    const photo: Panorama = {
      type: 'panorama',
      id: panorama.fileId,
      name: panorama.file.fileName,
      group,
    };
    if (!isEmpty(panorama.relativePosition) && !isEmpty(panorama.relativeRotation)) {
      const position = new Vector3(
        panorama.relativePosition[0],
        panorama.relativePosition[1],
        panorama.relativePosition[2],
      );
      const rotation = new Quaternion(
        panorama.relativeRotation[0],
        panorama.relativeRotation[1],
        panorama.relativeRotation[2],
        panorama.relativeRotation[3],
      );
      const scale = new Vector3(1, 1, 1);

      // Create the panorama model
      let config: PanoramaConfiguration;
      if (
        panorama.file.tiles &&
        !isEmpty(panorama.file.tiles.rows) &&
        !isEmpty(panorama.file.tiles.cols)
      ) {
        photo.tileset = {
          rows: panorama.file.tiles.rows,
          cols: panorama.file.tiles.cols,
          tileURLs: new Map(), // Lazy load
        };
        config = {
          type: 'panorama',
          panoramaType: 'tiled',
          name: panorama.file.fileName,
          rows: panorama.file.tiles.rows,
          columns: panorama.file.tiles.cols,

          // Lazy load these properties
          url: () => '',
          thumbnailUrl: '',
        };
      } else {
        config = {
          type: 'panorama',
          panoramaType: 'full',
          name: panorama.file.fileName,

          // Lazy load these properties
          url: '',
          thumbnailUrl: '',
        };
      }
      const panoramaModel = await api3D.model.create(config);
      photo.widget = new ModelNode(panoramaModel as PhotoCamera);
      group.sceneNode.add(photo.widget);
      photo.widget.setLocalPosition(position);
      photo.widget.setLocalRotation(rotation);
      photo.widget.setLocalScale(scale);
    }
    return photo;
  }
};

export const createAnnotationGroup = (
  sceneEntity: SceneEntity,
  parent: Layer | LayerGroup | undefined,
): AnnotationGroup | undefined => {
  if (
    !isEmpty(sceneEntity.renderObject) &&
    sceneEntity.renderObject.__typename === 'AnnotationRenderObject' &&
    !isEmpty(sceneEntity.renderObject.id) &&
    !isEmpty(sceneEntity.renderObject.createdAt) &&
    !isEmpty(sceneEntity.id) &&
    !isEmpty(sceneEntity.name) &&
    !isEmpty(sceneEntity.position) &&
    !isEmpty(sceneEntity.rotation)
  ) {
    const position = new Vector3(
      sceneEntity.position.x as number,
      sceneEntity.position.y as number,
      sceneEntity.position.z as number,
    );
    const rotation = new Quaternion(
      sceneEntity.rotation.x as number,
      sceneEntity.rotation.y as number,
      sceneEntity.rotation.z as number,
      sceneEntity.rotation.w as number,
    );
    const scale = new Vector3(1, 1, 1);

    // Create the scene node.
    const sceneNode = new SceneNode();
    if (parent && parent.sceneNode instanceof SceneNode) {
      parent.sceneNode.add(sceneNode);
    }
    sceneNode.setLocalPosition(position);
    sceneNode.setLocalRotation(rotation);
    sceneNode.setLocalScale(scale);

    return {
      type: 'annotationGroup',
      id: sceneEntity.renderObject.id,
      name: sceneEntity.name,
      sceneEntityId: sceneEntity.id,
      parent,
      sceneNode,
      loadState: 'pending',
      annotations: [],
    };
  }
};

export const createAnnotation = async (
  annotation: AnnotationQuery,
  group: AnnotationGroup,
  template: AnnotationTemplate,
  photoGroups: PhotoGroup[],
  api3D: ViewerAPI,
  api2D: Viewer2DAPI,
  color: null | undefined | string,
): Promise<Annotation | undefined> => {
  if (
    !isEmpty(annotation) &&
    !isEmpty(annotation.annotationId) &&
    !isEmpty(annotation.id) &&
    !isEmpty(annotation.name) &&
    !isEmpty(annotation.color) &&
    !isEmpty(annotation.fields) &&
    !isEmpty(annotation.createdAt)
  ) {
    let photo: Photo | undefined;
    for (const group of photoGroups) {
      photo = group.photos.find(photo => photo.id === annotation.annotation2d?.imageFileId);
      if (photo) {
        break;
      }
    }

    const fields: AnnotationField[] = [];
    for (const field of annotation.fields) {
      if (!isEmpty(field)) {
        fields.push(field);
      }
    }

    const result: Annotation = {
      type: 'annotation',
      id: annotation.annotationId,
      versionId: annotation.id,
      name: annotation.name,
      photo,
      group,
      template,
      fields,
      metadata: annotation,
      updatedAt: new Date(annotation.createdAt),
      updatedBy: annotation.createdByUserId ?? null,
      photoId: annotation.annotation2d?.imageFileId ?? undefined,
    };
    if (
      !isEmpty(annotation.annotation2d) &&
      !isEmpty(annotation.annotation2d.shapeType) &&
      !isEmpty(annotation.annotation2d.points)
    ) {
      // Compute geometry
      const geometry = shapeTypeToGeometry(
        annotation.annotation2d.shapeType as AnnotationShapeType,
      );

      // Compute 2D positions
      const points: Vector2[] = [];
      for (const point of annotation.annotation2d.points) {
        if (!isEmpty(point)) {
          points.push(new Vector2(point.x as number, point.y as number));
        }
      }
      result.sketch2D = api2D.editor.createSketch(
        geometry,
        points,
        new Color(color ?? annotation.color),
      );
    }
    if (
      !isEmpty(annotation.annotation3d) &&
      !isEmpty(annotation.annotation3d.shapeType) &&
      !isEmpty(annotation.annotation3d.positions) &&
      !isEmpty(annotation.annotation3d.rotations)
    ) {
      // Compute geometry
      const geometry: Geometry = shapeTypeToGeometry(
        annotation.annotation3d.shapeType as AnnotationShapeType,
      );

      // Compute 3D positions
      const points: Vector3[] = [];
      const groupTransform = new Matrix4().compose(
        group.sceneNode.getPosition(),
        group.sceneNode.getRotation(),
        group.sceneNode.getScale(),
      );
      for (let i = 0; i < annotation.annotation3d.positions.length; i++) {
        const position = annotation.annotation3d.positions[i];
        if (!isEmpty(position)) {
          const relativePosition = new Vector3(
            position.x as number,
            position.y as number,
            position.z as number,
          );
          points.push(relativePosition.applyMatrix4(groupTransform));
        }
      }
      const model = await api3D.model.create({
        type: 'sketch',
        color: new Color(color ?? annotation.color),
        name: annotation.name,
        geometry,
        points,
      });
      result.sketch3D = new ModelNode(model as Sketch);
      group.sceneNode.add(result.sketch3D);
    }
    return result;
  }
};

export const createShareLink = (shareLink: ShareLinkQuery): ShareLink | undefined => {
  if (
    !isEmpty(shareLink.id) &&
    !isEmpty(shareLink.isActivated) &&
    !isEmpty(shareLink.accountId) &&
    !isEmpty(shareLink.createdAt) &&
    !isEmpty(shareLink.updatedAt) &&
    !isEmpty(shareLink.name) &&
    !isEmpty(shareLink.shareToken)
  ) {
    return {
      id: shareLink.id,
      name: shareLink.name,
      active: shareLink.isActivated,
      accountId: shareLink.accountId,
      shareToken: shareLink.shareToken,
      createdAt: new Date(shareLink.createdAt),
      updatedAt: new Date(shareLink.updatedAt),
    };
  }
};

export const createSystemNode = (
  node: null | FileNode | FolderNode | LinkNode,
): SystemNode | undefined => {
  // TODO: handle LinkNode
  if (node && node.__typename === 'LinkNode') {
    return undefined;
  }

  if (
    node !== null &&
    !isEmpty(node.id) &&
    node.parentNodeId !== undefined &&
    !isEmpty(node.name) &&
    !isEmpty(node.createdAt) &&
    node.lastDownloadedAt !== undefined
  ) {
    return {
      type: node.__typename === 'FileNode' ? 'file' : 'folder',
      id: node.id,
      parentId: node.parentNodeId,
      name: node.name,
      createdAt: new Date(node.createdAt),
      lastDownloadedAt: node.lastDownloadedAt ? new Date(node.lastDownloadedAt) : null,
    };
  }
};

export const createUser = (user: UserV2): User | undefined => {
  if (
    !isEmpty(user) &&
    !isEmpty(user.id) &&
    !isEmpty(user.email) &&
    !isEmpty(user.name) &&
    !isEmpty(user.name.first) &&
    !isEmpty(user.name.last)
  ) {
    return {
      type: 'user',
      id: user.id,
      firstName: user.name.first,
      lastName: user.name.last,
      email: user.email,
      isSuperAdmin: user.isSuperAdmin,
    };
  }
};

export const createAnnotationVersion = (
  version: AnnotationQuery,
  user: User | null,
): AnnotationVersion | undefined => {
  if (
    !isEmpty(version.id) &&
    !isEmpty(version.name) &&
    !isEmpty(version.annotationId) &&
    !isEmpty(version.isActiveVersion) &&
    !isEmpty(version.createdAt) &&
    !isEmpty(version.fields) &&
    !isEmpty(version.color)
  ) {
    const fields: AnnotationField[] = [];
    for (const field of version.fields) {
      if (!isEmpty(field)) {
        fields.push(field);
      }
    }
    return {
      type: 'annotationHistory',
      id: version.id,
      annotationId: version.annotationId,
      name: version.name,
      createdAt: new Date(version.createdAt),
      createdBy: user,
      active: version.isActiveVersion,
      fields,
      color: new Color(version.color),
    };
  }
};

export const createProjectGroup = (group: ProjectGroupQuery): ProjectGroup | undefined => {
  if (
    !isEmpty(group.id) &&
    !isEmpty(group.name) &&
    !isEmpty(group.createdAt) &&
    !isEmpty(group.updatedAt) &&
    !isEmpty(group.description) &&
    !isEmpty(group.accountId)
  ) {
    return {
      type: 'projectGroup',
      id: group.id,
      accountId: group.accountId,
      name: group.name,
      updatedAt: new Date(group.updatedAt),
      createdAt: new Date(group.createdAt),
      description: group.description,
      projects: [],
    };
  }
};

export const createProject = (
  project: ProjectQuery,
  group: ProjectGroup | undefined,
): Project | undefined => {
  if (
    !isEmpty(project.id) &&
    !isEmpty(project.name) &&
    !isEmpty(project.address) &&
    !isEmpty(project.accountId) &&
    !isEmpty(project.createdAt) &&
    !isEmpty(project.updatedAt)
  ) {
    let location: null | Cartographic = null;
    if (!isEmpty(project.longitude) && !isEmpty(project.latitude)) {
      location = new Cartographic(toRadians(project.longitude), toRadians(project.latitude));
    }
    return {
      type: 'project',
      id: project.id,
      accountId: project.accountId,
      name: project.name,
      projectGroupId: project.projectGroupId ?? null,
      geoid: project.geoid ?? 'Default',
      createdAt: new Date(project.createdAt),
      updatedAt: new Date(project.updatedAt),
      description: project.description ?? '',
      address: project.address,
      location,
      group,
    };
  }
};

export const createProcess = (process: ProcessQuery): Process | undefined => {
  if (
    !isEmpty(process.id) &&
    !isEmpty(process.captureId) &&
    !isEmpty(process.createdAt) &&
    !isEmpty(process.accountId) &&
    !isEmpty(process.__typename) &&
    !isEmpty(process.lastStep)
  ) {
    const step =
      process.lastStep === 'CREATED' || process.lastStep === 'READY_TO_PLACE' ? 'created' : 'done';
    switch (process.__typename) {
      case 'AnnotationProcess': {
        const images: Map<string, ProcessImage> = new Map();
        for (const image of process.imagesWithStatus ?? []) {
          if (
            !isEmpty(image) &&
            !isEmpty(image.id) &&
            !isEmpty(image.fileId) &&
            !isEmpty(image.status)
          ) {
            const status: ProcessImage['status'] =
              image.status.toLowerCase() as ProcessImage['status'];
            images.set(image.fileId, {
              processImageId: image.id,
              status,
            });
          }
        }
        return {
          type: 'process',
          kind: 'annotation',
          id: process.id,
          layerId: process.captureId,
          createdAt: new Date(process.createdAt),
          name: process.name ?? 'Annotation Process',
          step,
          annotationIds: new Set(process.annotationIds as string[]),
          images,
        };
      }
      case 'PanoramicProcess': {
        const images: Map<string, ProcessImage> = new Map();
        for (const image of process.imagesWithStatus ?? []) {
          if (
            !isEmpty(image) &&
            !isEmpty(image.id) &&
            !isEmpty(image.fileId) &&
            !isEmpty(image.status)
          ) {
            const status: ProcessImage['status'] =
              image.status === 'PLACED' ? 'checked' : 'created';
            images.set(image.fileId, {
              processImageId: image.id,
              status,
            });
          }
        }
        return {
          type: 'process',
          kind: 'panorama',
          id: process.id,
          layerId: process.captureId,
          createdAt: new Date(process.createdAt),
          name: 'Panorama Process',
          step,
          images,
        };
      }
    }
  }
};

export const createPolicy = (policyQuery: PermissionPolicy): Policy | undefined => {
  if (
    !isEmpty(policyQuery.objectId) &&
    !isEmpty(policyQuery.objectType) &&
    !isEmpty(policyQuery.actionType)
  ) {
    return {
      type: 'policy',
      objectId: policyQuery.objectId,
      objectType: policyQuery.objectType as Policy['objectType'],
      actionType: policyQuery.actionType as Policy['actionType'],
    };
  }
};

// Check for missing template fields and add them to the missingFields array
const checkMissingTemplateFields = (
  annotations: CSVAnnotation[],
  templates: AnnotationTemplateFieldInput[],
) => {
  const requiredTemplateNames = templates.map(template => template.name);
  const missingFields: AnnotationTemplateFieldInput['name'][] = [];

  for (const annotation of annotations) {
    const existingFieldNames = annotation.fields.map(field => field.name);

    for (const templateName of requiredTemplateNames) {
      if (!existingFieldNames.includes(templateName)) {
        missingFields.push(templateName);
      }
    }
  }

  return uniq(missingFields);
};

export const createCSVAnnotation = (
  csvAnnotations: CSVAnnotation[],
  templateFields: AnnotationTemplateFieldInput[],
  projectId: AnnotationQuery['projectId'],
  templateId: AnnotationQuery['templateId'],
  groupId: AnnotationQuery['groupId'],
): CreateCSVAnnotation | undefined => {
  if (
    !isEmpty(groupId) &&
    !isEmpty(templateId) &&
    !isEmpty(projectId) &&
    !isEmpty(templateFields) &&
    !isEmpty(csvAnnotations)
  ) {
    const annotations = [];
    const incompatibleAnnotations = [];
    for (const annotation of csvAnnotations) {
      const matchedFields = [];
      if (annotation.fields) {
        for (const annotationField of annotation.fields) {
          const matchingField = templateFields.find(
            templateField =>
              templateField.name === annotationField?.name &&
              templateField.type === annotationField?.type,
          );

          if (matchingField) {
            const matchedField: AnnotationFieldInput = {
              fieldId: matchingField.id,
              name: matchingField.name,
              type: matchingField.type,
            };

            if (matchingField.type === 'SELECT' && matchingField.options) {
              const option = matchingField.options.find(
                option => option?.value === annotationField?.value,
              );
              if (option) {
                matchedField.optionId = option.id;
                matchedFields.push(matchedField);
              } else {
                incompatibleAnnotations.push(annotation);
              }
            } else if (
              matchingField.type === 'TEXT' &&
              matchingField.name === annotationField.name
            ) {
              matchedField.text = annotationField.value;
              matchedFields.push(matchedField);
            } else if (
              matchingField.type === 'DATE' &&
              matchingField.name === annotationField.name
            ) {
              matchedField.start = annotationField.value;
              matchedFields.push(matchedField);
            } else if (
              matchingField.type === 'URL' &&
              matchingField.name === annotationField.name
            ) {
              matchedField.urls = annotationField.urls;
              matchedFields.push(matchedField);
            }
          }
        }
        if (matchedFields.length > 0) {
          const annotation3d = {
            positions: annotation.annotation3d?.positions,
            shapeType: annotation.annotation3d?.shapeType,
            rotations: annotation.annotation3d?.positions?.map(() => ({ x: 0, y: 0, z: 0, w: 1 })),
          };

          annotations.push({
            name: annotation.name,
            annotation3d,
            fields: matchedFields,
            projectId,
            templateId,
            groupId,
            color: annotation.color,
          });
        }
      }
    }

    return {
      annotations,
      namesOfMissingFields: checkMissingTemplateFields(csvAnnotations, templateFields),
      incompatibleAnnotations,
    };
  }
};

// Type guards
const isImageField = (field: AnnotationField): field is AnnotationImageField => 'file' in field;
const isSelectField = (field: AnnotationField): field is AnnotationSelectField =>
  'optionId' in field;
const isUrlField = (field: AnnotationField): field is AnnotationUrlField => 'urls' in field;
const isDateField = (field: AnnotationField): field is AnnotationDateField => 'start' in field;
const isTextField = (field: AnnotationField): field is AnnotationTextField => 'text' in field;
const isFileField = (field: AnnotationField): field is AnnotationFileField => 'files' in field;

export const getTemplateFields = async (
  template: AnnotationTemplate,
  fields: AnnotationField[],
) => {
  const tempFields = [];

  if (!fields || !template || !template.fields) return;
  for (const field of fields) {
    const templateField = template.fields.find(tempEl => field.fieldId === tempEl?.id);
    if (!templateField) continue;

    if (field.type === 'TEXT' && isTextField(field) && field.text) {
      tempFields.push({
        type: field.type,
        name: templateField.name,
        value: field.text,
      });
    } else if (field.type === 'DATE' && isDateField(field) && field.start) {
      tempFields.push({
        type: field.type,
        name: templateField.name,
        value: field.start,
      });
    } else if (isUrlField(field) && field.urls && field.urls[0] && field.type === 'URL') {
      for (const templateFields of template.fields) {
        if (!templateFields) continue;
        if (templateFields.id === field.fieldId) {
          tempFields.push({
            type: field.type,
            name: templateFields.name,
            urls: field.urls,
          });
        }
      }
    } else if (field.type === 'SELECT' && isSelectField(field) && field.optionId) {
      for (const templateFields of template.fields as AnnotationTemplateSelectField[]) {
        if (!templateFields) continue;
        if (templateFields.id === field.fieldId) {
          tempFields.push({
            type: field.type,
            name: templateFields.name,
            value: templateFields.options?.find(option => option?.id === field.optionId)?.value,
          });
        }
      }
    } else if (field.type === 'IMAGE' && isImageField(field) && field.file) {
      const result = await queryClient.fetchQuery({
        queryFn: () =>
          request(GET_DOWNLOAD_URLS_BY_FILE_ID, { fileIds: [field.file?.id as string] }),
        queryKey: ['GET_DOWNLOAD_URLS_BY_FILE_ID', [field.file?.id as string]],
      });
      const urls = result.filesByIds?.map(file => file?.signedGetObjectDownloadUrl);

      if (urls && urls[0]) {
        tempFields.push({
          type: field.type,
          name: (field.file as ImageFile).fileName,
          value: urls[0],
        });
      }
    } else if (isFileField(field) && field.files && field.files[0] && field.type === 'FILE') {
      const filenames = new Map<string, string>();
      for (const file of field.files ?? []) {
        if (file && file.fileId && file.name) {
          filenames.set(file.fileId, file.name);
        }
      }
      const fileIds = [...filenames.keys()];
      const result = await queryClient.fetchQuery({
        queryFn: () => request(GET_DOWNLOAD_URLS_BY_FILE_ID, { fileIds }),
        queryKey: ['GET_DOWNLOAD_URLS_BY_FILE_ID', fileIds],
      });

      const files = [];
      for (const file of result.filesByIds ?? []) {
        if (file && file.id && file.signedGetObjectDownloadUrl) {
          const name = filenames.get(file.id);
          if (name) {
            files.push({
              url: file.signedGetObjectDownloadUrl,
              name,
            });
          }
        }
      }
      tempFields.push({
        type: field.type,
        name: templateField.name,
        files: files,
      });
    }
  }

  return tempFields;
};

const getShapeType = (shapeType: string) => {
  switch (shapeType) {
    case 'lines':
      return 'POLYLINE';
    case 'polygon':
      return 'POLYGON';
    case 'point':
      return 'POINT';
  }
};

export const createGeoJsonAnnotation = async (group: AnnotationGroup) => {
  if (group.annotations) {
    const annotations = await Promise.all(
      group.annotations.map(async annotation => {
        const fields = await getTemplateFields(
          annotation.template,
          annotation.fields as AnnotationField[],
        );
        const sketch3D = annotation.sketch3D?.getModel() as Sketch;
        if (!sketch3D) return null;
        const transform = new Matrix4().compose(
          sketch3D.getPosition(),
          sketch3D.getRotation(),
          sketch3D.getScale(),
        );
        const points = sketch3D.getVertices().map(vertex => vertex.applyMatrix4(transform));
        return {
          id: annotation.id,
          name: annotation.name,
          color: `#${sketch3D.getColor().getHexString()}`,
          annotation3d: {
            positions: points,
            shapeType: getShapeType(sketch3D.getType()),
          },
          fields,
        };
      }),
    );

    return annotations.filter(annotation => annotation !== null); // Filter out null values
  }
};
